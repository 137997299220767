import React, { Component } from "react";
import { connector, PropsFromRedux } from "../../../_reducers";
import { Table } from "react-bootstrap";

class ResultatFiscalModal extends Component<PropsFromRedux,any, {}> {
  render() {
    const {model} = this.props;
    let items = model.situationFiscale.resultatFiscales;
    let annee = new Date().getFullYear() + 1;
    return (
      <div className="w-100 d-flex">
        <Table striped bordered hover size="sm" className="m-0 text-center text-dark-blue">
          <thead>
            <tr>
              <th>Année</th>
              <th>Impôt payé</th>
              <th>TMI</th>
              <th>Taux d'endettement</th>
              <th>ISF</th>
            </tr>
          </thead>
          <tbody>
            { items && 
              items.map((item, i) => {
              return (
                <tr className="resultatFiscal" key={i}>
                  <td style={{fontWeight : "normal"}}>{annee + i}</td>
                  <td style={{ fontWeight: "normal" }}>{item?.impot} €</td>
                  <td style={{ fontWeight: "normal" }}>{item?.trancheMarginalImposition} %</td>
                  <td style={{ fontWeight: "normal" }}>{item?.tauxEndettement}%</td>
                  <td style={{ fontWeight: "normal" }}>{item?.impotSurFortune} €</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}
const connected = connector(ResultatFiscalModal);
export { connected as ResultatFiscalModal };
