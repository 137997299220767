import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { appConstants } from "../../_constants";
import {
  AnneesFutursRevenuNetImposable,
  AnneesFutursBenefices,
  AnneesFutursNombrePartFiscales,
  AnneesFuturs,
} from "../common"; 
import { ResultatFiscalModal } from "../common/ResultatFiscalModal/ResultatFiscalModal";
import { connector } from "../../_reducers";

type CustomModalProps = RouteComponentProps<any> & {
  handleClose?: () => any;
  show?: boolean;
};

class CustomModal extends Component<CustomModalProps & any, {}> {
  render() {
    const { model, closeModal } = this.props;
    const { modal } = model;
    return (
      <Modal
        show={model.isModalOpen}
        onHide={() => closeModal(model)}
        backdrop={modal.backdrop}
        size={modal.size}
      >
        <Modal.Header closeButton className="custom-modal-header">
          {model.isModalOpen && this.getHeader()}
        </Modal.Header>
        <Modal.Body>{model.isModalOpen && this.getBody()}</Modal.Body>
        <Modal.Footer>{model.isModalOpen && this.getFooter()}</Modal.Footer>
      </Modal>
    );
  }

  getHeader() {
    const { model } = this.props;
    const { modal } = model;
    return <Modal.Title>{modal.title}</Modal.Title>;
  }

  getBody() {
    const { model } = this.props;
    const { modal } = model;
   
    switch (modal.mode) {
      case appConstants.ANNEE_FUTURE_REVENU_NET_IMPOSABLE:
        return <AnneesFutursRevenuNetImposable />;
      case appConstants.ANNEE_FUTURE_BENEFICE:
        return <AnneesFutursBenefices />;
      case appConstants.ANNEE_FUTURE_NOMBRE_PART_FISCALE:
        return <AnneesFutursNombrePartFiscales />;
      case appConstants.RESULTAT_FISCALE:
        return <ResultatFiscalModal />;
      case 'error':
        return modal.data;
      default:
        return <AnneesFuturs />;
    }
  }
  
  async setCalcutaxe() {
    const { model,replaceModel } = this.props;
    let res = await model.calculTax();
    if (res) {
      // model.situationFiscale.revenu.newCalcule = false;
      replaceModel(model);
      console.log("replace");
    }
  }

  getFooter() {
    const { model, closeModal } = this.props;
    const {revenu, quotientFamilial} = model.situationFiscale;
    return (
      <div className={"w-100 d-flex " + (model?.modal?.showCancel ? 'justify-content-between' : 'justify-content-end')}>
       {model?.modal?.showCancel && <button
          className="btn font-weight-light text-white bg-orange text-uppercase"
          style={{ fontSize: "80%" }}
          // (!revenu.newCalcule && !quotientFamilial.newCalcule)
          //onClick={() => closeModal(model,false)}
          onClick={() => { revenu.newCalcule = false; quotientFamilial.newCalcule = false ;closeModal(model, false) }}
        >
          Retour
        </button>}
      {(model?.modal.showOk)&&
        <button
          className="btn font-weight-light text-white bg-orange text-uppercase"
          style={{ fontSize: "80%" }}
          // onClick={() => closeModal(model, true)}
          onClick={function (event) {closeModal(model, false) }}
        >
          Ok
        </button>
      }
      {
        (!model?.modal.showOk)&&
        <button
          className="btn font-weight-light text-white bg-orange text-uppercase"
          style={{ fontSize: "80%" }}
          // onClick={() => closeModal(model, true)}
          onClick={() => { this.setCalcutaxe(); closeModal(model, true) }}
        >
          Valider
        </button>
      }
        
      </div>
    );  
  }

  // calculTax(){
  //   const { model, replaceModel} = this.props;
  //   let res = model.calculTax();
  //   if(res){
  //     replaceModel(model);
  //   }
  // }
}

const connected = connector(CustomModal);
export { connected as CustomModal };
