class LieuResidence {
  id: number | null;
  nom: string;
  
  constructor(
    id?: any,
    nom: string = "",
  ) {
    this.id = id;
    this.nom = nom;
  }
  
}

export { LieuResidence };
