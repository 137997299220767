import React, { Component } from "react";
import { PropsFromRedux, connector } from "../../_reducers";
import { appConstants } from "../../_constants";

class DepenseRow extends Component<PropsFromRedux, any> {
  render() {
    const value1 = this.getValue(
      appConstants.INPUT_KEYS.REALISES_METROPOLE
    );
    const checkValue1 = this.getValue(
      appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE
    );
    const value2 = this.getValue(
      appConstants.INPUT_KEYS.REALISES_OUTRE_MER
    );
    const checkValue2 = this.getValue(
      appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER
    );

    return (
      <div className="col-lg-12  justify-content-around o-h h-95 pt-0">
        <div className="row mt-1 mt-0 pt-0">
          <div className="col col-lg-12 col-md-12 px-3 mb-0">
            <p className="big-text my-0 text-left">Montants réalisés en Métropole,<label className="mb-0 text-danger small-radio">&nbsp;hors déficit foncier</label></p>
          </div>
        </div>
        <div className="row mt-1 mt-0 pt-0">
          <div className="col col-lg-6 col-md-6 px-3 mb-0">
            <div className="d-flex col-lg-12 col-md-12 border-light-gray align-items-center px-0">
              <div className="inputs align-items-center py-1 px-1">
                <input
                  type="number" step="0.01"
                  min="0"
                  id={appConstants.INPUT_KEYS.REALISES_METROPOLE}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value1}
                />
              </div>

              <div className="align-items-end training py-1 text-center">
                <small className="font-weight-light text-blue">€/an</small>
              </div>
            </div>
          </div>
          <div className="col col-lg-5 mb-0 px-0">
            <div className="custom-control d-flex justify-content-left custom-checkbox">
              <input
                type="checkbox"
                disabled={value1 === 0 || value1 === null}
                checked={checkValue1}
                onChange={(e) => this.handleCheckAnneeFutur(e)}
                className="custom-control-input"
                id={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE}
              />
              <label
                className="custom-control-label big-text-dark font-weight-light small-radio"
                htmlFor={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_METROPOLE}
              >
                Saisir années futures
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-1 mt-0 pt-2">
          <div className="col col-lg-12 col-md-12 px-3 mb-0">
            <p className="big-text my-0 text-left">Montants réalisés en Outre Mer,
            <label className="mb-0 text-danger small-radio">
                &nbsp;hors déficit foncier
            </label></p>
          </div>
        </div>
        <div className="row mt-1 mt-0 pt-0">
          <div className="col col-lg-6 col-md-6 px-3 mb-0">
            <div className="d-flex col-lg-12 col-md-12 border-light-gray align-items-center px-0">
              <div className="inputs align-items-center py-1 px-1">
                <input
                  type="number" step="0.01"
                  min="0"
                  id={appConstants.INPUT_KEYS.REALISES_OUTRE_MER}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={(e) => this.setCalcutaxe()}
                  value={value2}
                />
              </div>
              <div className="align-items-end training py-1 text-center">
                <small className="font-weight-light text-blue">€/an</small>
              </div>
            </div>
          </div>
          <div className="col col-lg-5 mb-0 px-0">
            <div className="custom-control d-flex justify-content-left custom-checkbox">
              <input
                type="checkbox"
                disabled={value2 === 0 || value2 === null}
                checked={checkValue2}
                onChange={(e) => this.handleCheckAnneeFutur(e)}
                className="custom-control-input"
                id={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER}
              />
              <label
                className="custom-control-label big-text-dark font-weight-light small-radio"
                htmlFor={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_REALISES_OUTRE_MER}
              >
                Saisir années futures
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-1 mt-0 pt-3">
          <div className="col col-lg-12 col-md-12 px-3 mb-0">
          </div>
          <div className="col col-lg-7 col-md-7 px-2 mb-0">

          </div>
          <div className="col col-lg-5 mb-5 px-0">

          </div>
        </div>
      </div>
    );
  }

  getValue(key: string): any {
    const { model } = this.props;
    return model.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.getValue(
      key
    );
  }

  async setCalcutaxe() {
    const { model, replaceModel } = this.props;
    model.loading = true;
    replaceModel(model);
    let res = await model.calculTax();
    model.loading = false;
    replaceModel(model);
    if (res) {
      // this.props.replaceModel(model);
      model.situationFiscale.revenu.newCalcule = false;
      this.props.replaceModel(model);
      console.log("replace");
    }
  }


  handleCheckAnneeFutur(e: any) {
    const { model, replaceModel } = this.props;
    const { id, checked } = e.target;
    model.loading = true;
    if (checked) {
      model.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.onChange(
        e
      );
      model.eventUpdateDossier = true;
    }
    model.situationFiscale.revenu.newCalcule = true;
    replaceModel(model);
    model.isModalOpen = true;
    model.loading = false;
    model.modal.provider =
      model.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage;
    model.modal.size = "lg";
    model.modal.showCancel = true;
    model.modal.mode = id;
    model.modal.title = model.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.getModalTitle(
      id
    );
    setTimeout(() => {
      replaceModel(model);
    }, 1000);
  }

  updateMontant(e: any) {
    const { model, replaceModel } = this.props;
    const res = model.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.onChange(
      e
    );
    if (res) {
      model.eventUpdateDossier = true;
      model.situationFiscale.revenu.newCalcule = true;
      replaceModel(model);
    }
  }
}

export default connector(DepenseRow);
