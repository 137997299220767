import { appConstants } from '../_constants';

class Placement {
    capitalisationDuree: number | null;
    capitalisationExedMulti: boolean;
    capitalisationMontant: number | null;
    capitalisationTx: number | null;
    id: number | null;

    placementDuree: number | null;
    placementMontant: number | null;
    placementTva: boolean;
    placementTx: number | null;

    errCapiDuree: boolean;
    errCapiTx: boolean;
    errPlaceDuree: boolean;
    errPlaceTx: boolean;
    isAdd: boolean;

    constructor() {
        this.capitalisationDuree = null;
        this.capitalisationExedMulti = false;
        this.capitalisationMontant = null;
        this.capitalisationTx = null;
        this.placementDuree = null;
        this.placementMontant = null;
        this.placementTx = null;
        this.placementTva = false;
        this.id = null;
        this.errCapiDuree = true;
        this.errCapiTx = true;
        this.errPlaceDuree = false;
        this.errPlaceTx = false;
        this.isAdd = false;
    }

    onchange(e: any) {
        const { id, value, checked } = e.target;
        let res = true;
        console.log(id, value);
        switch (id) {
            case appConstants.PLACEMENT.CAPITALISATIONTRESO:
                this.capitalisationExedMulti = checked;
                break;
            case appConstants.PLACEMENT.DUREEPENDANT:
                this.checkCapiDuree(value);
                break;
            case appConstants.PLACEMENT.TAUXCAPITALISATION:
                this.checkCapiTx(value);
                break;
            case appConstants.PLACEMENT.PLACEMENT_TVA:
                this.placementTva = checked;
                break;
            case appConstants.PLACEMENT.DUREEPENDANT_TVA:
                this.checkplacementDuree(value);
                break;
            case appConstants.PLACEMENT.TAUXCAPITALISATION_TVA:
                this.checkplacementTx(value);
                break;
            default:
                res = false;
                break;
        }
        return res;
    }

    checkCapiDuree(val: any) {
        if (val === "" || val <= 0) {
            this.errCapiDuree = true;
            this.capitalisationDuree = val;
        } else {
            this.errCapiDuree = false;
            this.capitalisationDuree = val;
        }
    }

    checkCapiTx(val: any) {
        if (val === "" || val <= 0) {
            this.errCapiTx = true;
            this.capitalisationTx = val;
        } else {
            this.errCapiTx = false;
            this.capitalisationTx = val;
        }
    }

    checkplacementDuree(val: any) {
        if (val === "" || val <= 0) {
            this.errPlaceDuree = true;
            this.placementDuree = val;
        } else {
            this.errPlaceDuree = false;
            this.placementDuree = val;
        }
    }

    checkplacementTx(val: any) {
        if (val === "" || val <= 0) {
            this.errPlaceTx = true;
            this.placementTx = val;
        } else {
            this.errPlaceTx = false;
            this.placementTx = val;
        }
    }

    getValue(key: any) {
        switch (key) {
            case appConstants.PLACEMENT.CAPITALISATIONTRESO:
                return this.capitalisationExedMulti;
            case appConstants.PLACEMENT.DUREEPENDANT:
                return this.capitalisationDuree;
            case appConstants.PLACEMENT.TAUXCAPITALISATION:
                return this.capitalisationTx;
            case appConstants.PLACEMENT.PLACEMENT_TVA:
                return this.placementTva;
            case appConstants.PLACEMENT.DUREEPENDANT_TVA:
                return this.placementDuree;
            case appConstants.PLACEMENT.TAUXCAPITALISATION_TVA:
                return this.placementTx;
            default:
                console.log("err")
                break;
        }
    }

    getValidPlacement() {
        this.errCapiDuree = this.checkValue(this.capitalisationDuree);
        this.errCapiTx = this.checkValue(this.capitalisationTx);

        if (this.errCapiDuree || this.errCapiTx) {
            return false;
        }
        return true;
    }
    getValidPlacementTVA() {
        this.errPlaceTx = this.checkValue(this.placementTx);
        this.errPlaceDuree = this.checkValue(this.placementDuree);

        if (this.errPlaceTx || this.errPlaceDuree) {
            return false;
        }
        return true;
    }

    serialisePlacement() {
        return {
            capitalisationDuree: this.capitalisationDuree,
            capitalisationExedMulti: this.capitalisationExedMulti,
            capitalisationMontant: this.capitalisationMontant,
            capitalisationTx: this.capitalisationTx,
            id: null,
            placementDuree: this.placementDuree,
            placementMontant: this.placementMontant,
            placementTva: this.placementTva,
            placementTx: this.placementTx,
        };
    }

    hydratePlacement(data: any) {
        this.capitalisationDuree = data?.capitalisationDuree;
        this.capitalisationExedMulti = data?.capitalisationExedMulti;
        this.capitalisationMontant = data?.capitalisationMontant;
        this.capitalisationTx = data?.capitalisationTx;
        this.placementDuree = data?.placementDuree;
        this.placementMontant = data?.placementMontant;
        this.placementTva = data?.placementTva;
        this.placementTx = data?.placementTx;
        this.id = data?.id;

        this.errCapiDuree = (data === null || data?.capitalisationDuree === null) ? true : false;
        this.errCapiTx = (data === null || data?.capitalisationTx === null) ? true : false;
    }

    checkValue(value: any) {
        return value == null || value === 0 || value === "" ? true : false;
    }

    resetPlacement() {
        this.capitalisationDuree = null;
        this.capitalisationExedMulti = false;
        this.capitalisationMontant = null;
        this.capitalisationTx = null;
        this.placementDuree = null;
        this.placementMontant = null;
        this.placementTx = null;
        this.placementTva = false;
        this.errCapiDuree = true;
        this.errCapiTx = true;
        this.errPlaceDuree = false;
        this.errPlaceTx = false;
        this.isAdd = false;
      }
} export { Placement }