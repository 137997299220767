import { appConstants } from "../_constants/app.constant";
import { ChargeIntegre, ChoixImmobilier } from "../_entities";

class SupportImmobilier {
  choixImmobilier: ChoixImmobilier;
  chargeIntegre: ChargeIntegre;
  tmi: number | null;
  revenue_fiscale: number | null;
  locatif_max: number | null;
  errDate: boolean | null;
  errMontant: boolean | null;
  errTaux: boolean | null;
  errRevenueLocatifMenssuel: boolean | null;
  errRevenueLocatifPourcent: boolean | null;
  errMontantTaxe: boolean | null;
  errExonerea: boolean | null;
  errExonerependant: boolean | null;
  nLot: number;
  loi: string;
  epargneMoyen: number;
  id: number | null;
  listZone = [];
  currentZone = "";

  constructor() {
    this.choixImmobilier = new ChoixImmobilier();
    this.chargeIntegre = new ChargeIntegre();
    this.tmi = null;
    this.revenue_fiscale = null;
    this.locatif_max = null;
    this.errDate = true;
    this.errMontant = true;
    this.errTaux = true;
    this.errRevenueLocatifMenssuel = true;
    this.errRevenueLocatifPourcent = true;
    this.errMontantTaxe = true;
    this.errExonerea = true;
    this.errExonerependant = true;
    this.nLot = 1;
    this.loi = "Pinel";
    this.epargneMoyen = 333;
    this.id = null;
  }

  resetSupportImmo() {
    this.choixImmobilier = new ChoixImmobilier();
    this.chargeIntegre = new ChargeIntegre();
    this.tmi = null;
    this.revenue_fiscale = null;
    this.locatif_max = null;
    this.errDate = true;
    this.errMontant = true;
    this.errTaux = true;
    this.errRevenueLocatifMenssuel = true;
    this.errRevenueLocatifPourcent = true;
    this.errMontantTaxe = true;
    this.errExonerea = true;
    this.errExonerependant = true;
    this.nLot = 1;
    this.loi = "Pinel";
    this.epargneMoyen = 333;
    this.id = null;
    this.currentZone = "";
  }

  getValue(key: string) {
    switch (key) {
      case appConstants.SUPPORTS.CHARGE_COPROPRIETE:
        return this.chargeIntegre.charge_copropriete;
      case appConstants.SUPPORTS.DATE_PREVISIONNELLE:
        return this.choixImmobilier.date_previsionnelle;
      case appConstants.SUPPORTS.DONT_LOYER_DROIT_COMUN:
        return this.choixImmobilier.dont_loyer_droit_comun;
      case appConstants.SUPPORTS.DONT_MONTANT_REDUCTIBLE:
        return this.choixImmobilier.dont_montant_reductible;
      case appConstants.SUPPORTS.EXONEREE_A:
        return this.chargeIntegre.exoneree_a;
      case appConstants.SUPPORTS.EXONEREE_PENDANT:
        return this.chargeIntegre.exoneree_pendant;
      case appConstants.SUPPORTS.FRAIS_GESTION:
        return this.chargeIntegre.frais_gestion;
      case appConstants.SUPPORTS.FRAIS_SUPLEMENTAIRE:
        return this.choixImmobilier.frais_suplementaire;
      case appConstants.SUPPORTS.MONTANT_PRODUIT:
        return this.choixImmobilier.montant_produit;
      case appConstants.SUPPORTS.MONTANT_TAXE_FISCAL:
        return this.chargeIntegre.montant_taxe_fiscal;
      case appConstants.SUPPORTS.NOM_SUPPORT:
        return this.choixImmobilier.nom_support;
      case appConstants.SUPPORTS.MONTANT_REVENUE_LOCATIF:
        return this.choixImmobilier.revenu_locatif_mensuel;
      case appConstants.SUPPORTS.REVENU_LOCATIF_POURCENT:
        return this.choixImmobilier.revenu_locatif_pourcent;
      case appConstants.SUPPORTS.REVENU_LOCATIF_TOUS_LES:
        return this.choixImmobilier.revenu_locatif_tous_les;
      case appConstants.SUPPORTS.TAUX_REVALORISATION:
        return this.choixImmobilier.taux_revalorisation;
      case appConstants.SUPPORTS.TYPE:
        return this.choixImmobilier.type;
      case appConstants.SUPPORTS.SURFACE_TOTALE:
        return this.choixImmobilier.surface_totale;
      case appConstants.SUPPORTS.DIFFERE_AVANT_PREMIER_LOYER:
        return this.choixImmobilier.differe_avant_premier_loyer;
      case appConstants.SUPPORTS.GARANTIE_LOCATIVE:
        return this.chargeIntegre.garantie_locative;
      case appConstants.SUPPORTS.ADHESION_AFUL:
        return this.choixImmobilier.adhesion_aful;
      case appConstants.SUPPORTS.MONTANT_TVA_RECUPERE:
        return this.choixImmobilier.montantTVARecup;
      case appConstants.SUPPORTS.FRAIS_RESTAURATION:
        return this.choixImmobilier.frais_restauration;
      case appConstants.SUPPORTS.NOMBRE_PART:
        return this.choixImmobilier.nombre_part;
      case appConstants.SUPPORTS.VALEUR_PART:
        return this.choixImmobilier.valeur_part;
      case appConstants.SUPPORTS.LOYERMAXI:
        return null != this.choixImmobilier.loyer_maxi ? (Math.round(this.choixImmobilier.loyer_maxi * 100) / 100).toFixed(2) : "-";
      case appConstants.SUPPORTS.ZONE:
        return this.choixImmobilier.zone;
      default:
        break;
    }
    return false;
  }

  onChange(e: any): boolean {
    const { id, value } = e.target;
    let res = true;
    switch (id) {
      case appConstants.SUPPORTS.CHARGE_COPROPRIETE:
        this.chargeIntegre.charge_copropriete = value;
        break;
      case appConstants.SUPPORTS.DATE_PREVISIONNELLE:
        this.checkDatePreisionnel(value);
        break;
      case appConstants.SUPPORTS.DONT_LOYER_DROIT_COMUN:
        this.choixImmobilier.dont_loyer_droit_comun = value;
        break;
      case appConstants.SUPPORTS.DONT_MONTANT_REDUCTIBLE:
        this.choixImmobilier.dont_montant_reductible = value;
        break;
      case appConstants.SUPPORTS.EXONEREE_A:
        this.checkExoneeA(value);
        break;
      case appConstants.SUPPORTS.EXONEREE_PENDANT:
        this.checkExonerrPendant(value);
        break;
      case appConstants.SUPPORTS.FRAIS_GESTION:
        this.chargeIntegre.frais_gestion = value;
        break;
      case appConstants.SUPPORTS.FRAIS_SUPLEMENTAIRE:
        this.choixImmobilier.frais_suplementaire = value;
        break;
      case appConstants.SUPPORTS.MONTANT_PRODUIT:
        this.checkMontant(value);
        break;
      case appConstants.SUPPORTS.MONTANT_TAXE_FISCAL:
        this.checkMontantTaxe(value);
        break;
      case appConstants.SUPPORTS.NOM_SUPPORT:
        this.choixImmobilier.nom_support = value;
        break;
      case appConstants.SUPPORTS.MONTANT_REVENUE_LOCATIF:
        this.checkRevenueLocatifMensuelle(value);
        break;
      case appConstants.SUPPORTS.REVENU_LOCATIF_POURCENT:
        this.checkRevenueLocatifPourcent(value);
        break;
      case appConstants.SUPPORTS.REVENU_LOCATIF_TOUS_LES:
        this.choixImmobilier.revenu_locatif_tous_les = value;
        break;
      case appConstants.SUPPORTS.TAUX_REVALORISATION:
        this.checkTaux(value);
        break;
      case appConstants.SUPPORTS.TYPE:
        this.choixImmobilier.type = value;
        break;
      case appConstants.SUPPORTS.SURFACE_TOTALE:
        this.choixImmobilier.surface_totale = value;
        if (
          this.choixImmobilier.zone != null &&
          // eslint-disable-next-line eqeqeq
          this.choixImmobilier.zone != "zone" &&
          // eslint-disable-next-line eqeqeq
          this.choixImmobilier.zone != undefined
        ) {
          let z =
            typeof this.choixImmobilier.zone === "string"
              ? JSON.parse(this.choixImmobilier.zone)
              : this.choixImmobilier.zone;
          this.choixImmobilier.loyer_maxi = z === null ? 0 : Math.round(z.value * value * 100) / 100;
        } else {
          this.choixImmobilier.loyer_maxi = 0;
        }
        break;
      case appConstants.SUPPORTS.DIFFERE_AVANT_PREMIER_LOYER:
        this.choixImmobilier.differe_avant_premier_loyer = value;
        break;
      case appConstants.SUPPORTS.GARANTIE_LOCATIVE:
        this.chargeIntegre.garantie_locative = value;
        break;
      case appConstants.SUPPORTS.ADHESION_AFUL:
        this.choixImmobilier.adhesion_aful = value;
        break;
      case appConstants.SUPPORTS.MONTANT_TVA_RECUPERE:
        this.choixImmobilier.montantTVARecup = value;
        break;
      case appConstants.SUPPORTS.FRAIS_RESTAURATION:
        this.choixImmobilier.frais_restauration = value;
        break;
      case appConstants.SUPPORTS.NOMBRE_PART:
        this.choixImmobilier.nombre_part = value;
        break;
      case appConstants.SUPPORTS.VALEUR_PART:
        this.choixImmobilier.valeur_part = value;
        break;
      case appConstants.SUPPORTS.ZONE:
        console.log(value);

        if (value !== null || value !== "zone" || value !== undefined) {
          this.choixImmobilier.zone = JSON.parse(value);
          this.choixImmobilier.loyer_maxi =
            Math.round(this.choixImmobilier.zone.value * this.choixImmobilier.surface_totale * 100) / 100;
        }
        break;
      default:
        throw new Error("Invalid label key");
    }
    return res;
  }

  checkDatePreisionnel(val: any) {
    if (val === "" || val === null) {
      this.errDate = true;
      this.choixImmobilier.date_previsionnelle = val;
    } else {
      this.errDate = false;
      this.choixImmobilier.date_previsionnelle = val;
    }
  }
  checkMontant(val: any) {
    if (val === "" || val < 0) {
      this.errMontant = true;
      this.choixImmobilier.montant_produit = val;
    } else {
      this.errMontant = false;
      this.choixImmobilier.montant_produit = val;
    }
  }
  checkTaux(val: any) {
    if (val === "" || val < 0) {
      this.errTaux = true;
      this.choixImmobilier.taux_revalorisation = val;
    } else {
      this.errTaux = false;
      this.choixImmobilier.taux_revalorisation = val;
    }
  }

  checkRevenueLocatifMensuelle(val: any) {
    if (val === "" || val < 0) {
      this.errRevenueLocatifMenssuel = true;
      this.choixImmobilier.revenu_locatif_mensuel = val;
      
    } else {
      this.errRevenueLocatifMenssuel = false;
      this.choixImmobilier.revenu_locatif_mensuel = val;
    }
  }

  checkRevenueLocatifPourcent(val: any) {
    if (val === "" || val < 0) {
      this.errRevenueLocatifPourcent = true;
      this.choixImmobilier.revenu_locatif_pourcent = val;
    } else {
      this.errRevenueLocatifPourcent = false;
      this.choixImmobilier.revenu_locatif_pourcent = val;
    }
  }

  checkMontantTaxe(val: any) {
    if (val === "") {
      this.errMontantTaxe = true;
      this.chargeIntegre.montant_taxe_fiscal = val;
    } else {
      this.errMontantTaxe = false;
      this.chargeIntegre.montant_taxe_fiscal = val;
    }
  }

  checkExoneeA(val: any) {
    if (val === "") {
      this.errExonerea = true;
      this.chargeIntegre.exoneree_a = val;
    } else {
      this.errExonerea = false;
      this.chargeIntegre.exoneree_a = val;
    }
  }

  checkExonerrPendant(val: any) {
    if (val === "") {
      this.errExonerependant = true;
      this.chargeIntegre.exoneree_pendant = val;
    } else {
      this.errExonerependant = false;
      this.chargeIntegre.exoneree_pendant = val;
    }
  }
}
export { SupportImmobilier };

