import React, { Component } from "react";
import { PropsFromRedux, connector } from "../../_reducers";
import DepenseRow from "./DepenseRow";

class Depense extends Component<PropsFromRedux, any> {
  render() {
    return (
      <div className="d-flex flex-column declarant-wrapper3 px-2 pb-2">
        <h1 className="title-section" style={{ textAlign: "left" }}>
          <span className="px-3 big-text font-weight-bold">Endettement</span>
        </h1>
        <DepenseRow />
      </div>
    );
  }
}

export default connector(Depense);
