
class Emprunt {
  id: number | null;
  libelle: string;
  dateDebut: string;
  montant: number | null;
  duree: number | null;
  taux: number | null;

  constructor(
   data?:any
  ) {
    this.id = data?.id || null;
    this.libelle = data?.intitule || "";
    this.dateDebut = data?.date || "";
    this.montant = data?.montant || null;
    this.duree = data?.duree || null;
    this.taux = data?.taux || null;
  }
}

export { Emprunt }