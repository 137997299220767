import { Placement } from '../_entities';

import { Financement } from './financement';
import { SidebarSolution } from './sidebarSolution';
import { Support } from './support';
import { TypeFiscaliteModel } from './typeFiscaliteModel';


const iconFiscalite = require("../_assets/images/sidebarIcons/fiscalite.png");
const iconFiscaliteActif = require("../_assets/images/sidebarIcons/fiscalite_active.png");
const iconResultat = require("../_assets/images/sidebarIcons/support.png");
const iconResultatActif = require("../_assets/images/sidebarIcons/support_active.png");
const iconmono = require("../_assets/images/sidebarIcons/mono.png");
const iconmonoActif = require("../_assets/images/sidebarIcons/mono_active.png");
const iconmulti = require("../_assets/images/sidebarIcons/multi.png");
const iconmultiActif = require("../_assets/images/sidebarIcons/multi_active.png");
const iconDetailIfi = require("../_assets/images/sidebarIcons/placement.png");
const iconDetailIfiActif = require("../_assets/images/sidebarIcons/placement_active.png");

class SolutionFiscale {
  activeSection: string;
  typeFiscaliteModel: TypeFiscaliteModel;
  scpi: boolean;
  sidebarSolution: SidebarSolution[];
  support: Support;
  mono_financement_mode: boolean;
  financement: Financement;
  currentFiscalite: String;
  placement: Placement;

  constructor() {
    this.activeSection = "fiscalites";
    this.typeFiscaliteModel = new TypeFiscaliteModel();
    this.scpi = false;
    this.mono_financement_mode = true;
    this.financement = new Financement();
    this.sidebarSolution = [
      new SidebarSolution("Fiscalité", "fiscalites", "iconFiscalites", iconFiscalite, iconFiscaliteActif),
      new SidebarSolution("Support immobilier", "resultats", "iconResultatFoncier", iconResultat, iconResultatActif),
      new SidebarSolution("Mono financement", "mono", "iconmono", iconmono, iconmonoActif),
      new SidebarSolution("Multi financements", "multi", "iconmulti", iconmulti, iconmultiActif),
      new SidebarSolution("Placement", "placement", "iconDetail", iconDetailIfi, iconDetailIfiActif),
    ];
    this.support = new Support();
    this.placement = new Placement();
    this.currentFiscalite = "PINEL"
  }

  

  modeFinancement(e: any) {
    const { id } = e.target;
    let res = true;
    switch (id) {
      case "mono":
        this.mono_financement_mode = true;
        this.financement.monofinancement.choix_financement.apportPersonnel = this.financement.apportPersonnel || null;

        this.financement.monofinancement.choix_financement.pasFinancement = this.financement.pasFinancement;
        this.financement.monofinancement.choix_financement.financementPremierAnnee = this.financement.financementravaux;
        this.financement.monofinancement.choix_financement.financementFraisDeductible = this.financement.frais_deductible;
        this.financement.monofinancement.choix_financement.apportPersonnel = this.financement.apportPersonnel ;
        this.financement.monofinancement.choix_financement.apportPersonnelSav = this.financement.apportPersonnelSav ;

        this.changeSection("mono", false);
        break;
      case "multi":
        this.mono_financement_mode = false;
        this.financement.apportPersonnel = this.financement.monofinancement.choix_financement.apportPersonnel || null;
        this.financement.apportPersonnelSav = this.financement.monofinancement.choix_financement.apportPersonnelSav;
        this.financement.apportPersonnelSavStatus = this.financement.monofinancement.choix_financement.apportPersonnelSavStatus;
        this.financement.frais_deductible = this.financement.monofinancement.choix_financement.financementFraisDeductible;
        this.financement.financementravaux = this.financement.monofinancement.choix_financement.financementPremierAnnee;
        this.financement.pasFinancement = this.financement.monofinancement.choix_financement.pasFinancement;
        this.changeSection("multi", false);
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  changeSection(value: string, flag: boolean) {
    if (value === 'multi' && this.mono_financement_mode) {

    } else if (value === 'mono' && !this.mono_financement_mode) {

    } else {
      console.log("-----------" + flag)
      this.activeSection = value;
      this.financement.reInitCollapse();
      this.financement.indexArray = null;
      this.financement.isAdd = flag;
    }
  }

  onChange() {
    this.support.isAdd = false;
    this.scpi = !this.scpi;
    if (this.scpi) {
      this.sidebarSolution = [
        new SidebarSolution("Fiscalité", "fiscalites", "iconFiscalites", iconFiscalite, iconFiscaliteActif),
        new SidebarSolution("Support SCPI", "support scpi", "iconResultatFoncier", iconResultat, iconResultatActif),
        new SidebarSolution("Mono financement", "mono", "iconmono", iconmono, iconmonoActif),
        new SidebarSolution("Multi financements", "multi", "iconmulti", iconmulti, iconmultiActif),
        new SidebarSolution("Placement", "placement", "iconDetail", iconDetailIfi, iconDetailIfiActif),
      ]
    } else {
      this.sidebarSolution = [
        new SidebarSolution("Fiscalité", "fiscalites", "iconFiscalites", iconFiscalite, iconFiscaliteActif),
        new SidebarSolution("Support immobilier", "resultats", "iconResultatFoncier", iconResultat, iconResultatActif),
        new SidebarSolution("Mono financement", "mono", "iconmono", iconmono, iconmonoActif),
        new SidebarSolution("Multi financements", "multi", "iconmulti", iconmulti, iconmultiActif),
        new SidebarSolution("Placement", "placement", "iconDetail", iconDetailIfi, iconDetailIfiActif),
      ]
    }
  }

  onChangeScpiRest(scpi: boolean) {
    this.support.isAdd = false;
    this.scpi = scpi;
    if (this.scpi) {
      this.sidebarSolution = [
        new SidebarSolution("Fiscalité", "fiscalites", "iconFiscalites", iconFiscalite, iconFiscaliteActif),
        new SidebarSolution("Support SCPI", "support scpi", "iconResultatFoncier", iconResultat, iconResultatActif),
        new SidebarSolution("Mono financement", "mono", "iconmono", iconmono, iconmonoActif),
        new SidebarSolution("Multi financements", "multi", "iconmulti", iconmulti, iconmultiActif),
        new SidebarSolution("Placement", "placement", "iconDetail", iconDetailIfi, iconDetailIfiActif),
      ]
    } else {
      this.sidebarSolution = [
        new SidebarSolution("Fiscalité", "fiscalites", "iconFiscalites", iconFiscalite, iconFiscaliteActif),
        new SidebarSolution("Support immobilier", "resultats", "iconResultatFoncier", iconResultat, iconResultatActif),
        new SidebarSolution("Mono financement", "mono", "iconmono", iconmono, iconmonoActif),
        new SidebarSolution("Multi financements", "multi", "iconmulti", iconmulti, iconmultiActif),
        new SidebarSolution("Placement", "placement", "iconDetail", iconDetailIfi, iconDetailIfiActif),
      ]
    }
  }

  getPrevSection(): string {
    switch (this.activeSection) {
      case "support scpi":
        return "fiscalites";
      case "resultats":
        return "fiscalites";
      case "mono":
        if (this.scpi) {
          return "support scpi"
        } else {
          return "resultats";
        }
      case "multi":
        if (this.scpi) {
          return "support scpi"
        } else {
          return "resultats";
        }
      case "placement":
        if (this.mono_financement_mode) {
          return "mono"
        } else {
          return "multi";
        }
      default:
        return this.activeSection;
    }
  }

  getNextSection(): string {
    switch (this.activeSection) {
      case "fiscalites":
        if (this.scpi) {
          return "support scpi"
        } else {
          return "resultats";
        }
      case "resultats":
        if (this.mono_financement_mode) {
          return "mono";
        } else {
          return "multi";
        }
      case "support scpi":
        if (this.mono_financement_mode) {
          return "mono"
        } else {
          return "multi";
        }
      case "mono":
        return "placement";
      case "multi":
        return "placement";
      default:
        return this.activeSection;
    }
  }

  // Debut loi (tupe de fiscalité)
  // formating to send and received pinel 
  serializePinel() {
    return {
      sixAns: this.typeFiscaliteModel.pinel.engargement_min,
      neufAns: this.typeFiscaliteModel.pinel.engargement_max,
      metropole: this.typeFiscaliteModel.pinel.metropole,
      outremer: this.typeFiscaliteModel.pinel.outre_mere,
      tauxReduction: this.typeFiscaliteModel.pinel.taux,
      plafond: this.typeFiscaliteModel.pinel.plafond_investissement,
      dureeFiscale: this.typeFiscaliteModel.pinel.passage,
      enRegimeGeneral: this.typeFiscaliteModel.pinel.regime,
      enLmnp: this.typeFiscaliteModel.pinel.lmnp,
      etabLmnp: this.typeFiscaliteModel.pinel.frais_etablissement,
      comptaLmnp: this.typeFiscaliteModel.pinel.frais_comptabilite,
      terrainLmnp: this.typeFiscaliteModel.pinel.valeur_terrain,
      mobLmnp: this.typeFiscaliteModel.pinel.valeur_mobilier,
      taxExoSupPourc: this.typeFiscaliteModel.pinel.taxe_fonciere,
      taxExoSupAn: this.typeFiscaliteModel.pinel.pendant,
      id: this.typeFiscaliteModel.pinel.id,
      loi: this.typeFiscaliteModel.pinel.loi,
      scpi: this.scpi
    };
  }

  hydratePinel(data?: any) {

    this.typeFiscaliteModel.pinel.engargement_min = data?.sixAns;
    this.typeFiscaliteModel.pinel.engargement_max = data?.neufAns;
    this.typeFiscaliteModel.pinel.metropole = data?.metropole;
    this.typeFiscaliteModel.pinel.outre_mere = data?.outremer;
    this.typeFiscaliteModel.pinel.taux = data?.tauxReduction || 12;
    this.typeFiscaliteModel.pinel.plafond_investissement = data?.plafond || 300000;
    this.typeFiscaliteModel.pinel.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.pinel.regime = data?.enRegimeGeneral;
    this.typeFiscaliteModel.pinel.lmnp = data?.enLmnp ;
    this.typeFiscaliteModel.pinel.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.pinel.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.pinel.valeur_terrain = data?.terrainLmnp;
    this.typeFiscaliteModel.pinel.valeur_mobilier = data?.mobLmnp;
    this.typeFiscaliteModel.pinel.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.pinel.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.pinel.id = data?.id;
    this.typeFiscaliteModel.pinel.loi = data?.loi;
    this.scpi = data?.loi
  }

  // formating to send and received LMNP
  serializeLmnp() {
    return {
      cenciBouvard: this.typeFiscaliteModel.lmnp.reduction_non ? false : true,
      amortInit: this.typeFiscaliteModel.lmnp.decomposition_non ? false : true,
      amortMob: this.typeFiscaliteModel.lmnp.partie_mobiliere,
      amortImmo: this.typeFiscaliteModel.lmnp.partie_immobiliere,
      compta: this.typeFiscaliteModel.lmnp.comptabilite_annuel,
      regimeCotisation: this.typeFiscaliteModel.lmnp.regime_soumis_non ? false : true,
      tva: this.typeFiscaliteModel.lmnp.tva_non ? false : true,
      reducLmnp: this.typeFiscaliteModel.lmnp.taux_reduction,
      plafondLmnp: this.typeFiscaliteModel.lmnp.plafond,
      amortRepart1: this.typeFiscaliteModel.lmnp.oeuvre_pourcent,
      amortRepart10: this.typeFiscaliteModel.lmnp.oeuvre_an,
      amortRepart2: this.typeFiscaliteModel.lmnp.facade_pourcent,
      amortRepart20: this.typeFiscaliteModel.lmnp.facade_an,
      amortRepart3: this.typeFiscaliteModel.lmnp.equipement_pourcent,
      amortRepart30: this.typeFiscaliteModel.lmnp.equipement_an,
      amortRepart4: this.typeFiscaliteModel.lmnp.agencement_pourcent,
      amortRepart40: this.typeFiscaliteModel.lmnp.agencement_an,
      cotisFor: this.typeFiscaliteModel.lmnp.base_forfaitaire,
      cotisPro: this.typeFiscaliteModel.lmnp.taux_taxe_professionnelle,
      cotisTx: this.typeFiscaliteModel.lmnp.taxe_cotisation,
      id: this.typeFiscaliteModel.lmnp.id,
      loi: this.typeFiscaliteModel.lmnp.loi,
      scpi: this.scpi
    };
  }

  hydrateLmnp(data?: any) {
    this.typeFiscaliteModel.lmnp.reduction_non = (data?.cenciBouvard) ? false : true;
    this.typeFiscaliteModel.lmnp.reduction_oui = (data?.cenciBouvard) ? true : false;
    this.typeFiscaliteModel.lmnp.decomposition_non = (data?.amortInit) ? false : true;
    this.typeFiscaliteModel.lmnp.decomposition_oui = (data?.amortInit) ? true : false;
    this.typeFiscaliteModel.lmnp.partie_mobiliere = data?.amortMob;
    this.typeFiscaliteModel.lmnp.partie_immobiliere = data?.amortImmo;
    this.typeFiscaliteModel.lmnp.comptabilite_annuel = data?.compta;
    this.typeFiscaliteModel.lmnp.regime_soumis_non = data?.regimeCotisation ? false : true;
    this.typeFiscaliteModel.lmnp.regime_soumis_oui = data?.regimeCotisation ? true : false;
    this.typeFiscaliteModel.lmnp.tva_non = data?.tva ? false : true;
    this.typeFiscaliteModel.lmnp.tva_oui = data?.tva ? true : false;
    this.typeFiscaliteModel.lmnp.taux_reduction = data?.reducLmnp;
    this.typeFiscaliteModel.lmnp.plafond = data?.plafondLmnp;
    this.typeFiscaliteModel.lmnp.oeuvre_pourcent = data?.amortRepart1;
    this.typeFiscaliteModel.lmnp.oeuvre_an = data?.amortRepart10;
    this.typeFiscaliteModel.lmnp.facade_pourcent = data?.amortRepart2;
    this.typeFiscaliteModel.lmnp.facade_an = data?.amortRepart20;
    this.typeFiscaliteModel.lmnp.equipement_pourcent = data?.amortRepart3;
    this.typeFiscaliteModel.lmnp.equipement_an = data?.amortRepart30;
    this.typeFiscaliteModel.lmnp.agencement_pourcent = data?.amortRepart4;
    this.typeFiscaliteModel.lmnp.agencement_an = data?.amortRepart40;
    this.typeFiscaliteModel.lmnp.base_forfaitaire = data?.cotisFor;
    this.typeFiscaliteModel.lmnp.taux_taxe_professionnelle = data?.cotisPro;
    this.typeFiscaliteModel.lmnp.taxe_cotisation = data?.cotisTx;
    this.typeFiscaliteModel.lmnp.id = data?.id;
    this.typeFiscaliteModel.lmnp.loi = data?.loi;
    this.scpi = data?.scpi
  }
  // formating to send and received LMP
  serializeLmp() {
    return {
      cenciBouvard: this.typeFiscaliteModel.lmp.reduction_non ? false : true,
      amortInit: this.typeFiscaliteModel.lmp.decomposition_non ? false : true,
      amortMob: this.typeFiscaliteModel.lmp.partie_mobiliere,
      amortImmo: this.typeFiscaliteModel.lmp.partie_immobiliere,
      compta: this.typeFiscaliteModel.lmp.comptabilite_annuel,
      regimeCotisation: this.typeFiscaliteModel.lmp.regime_soumis_non ? false : true,
      tva: this.typeFiscaliteModel.lmp.tva_non ? false : true,
      reducLmnp: this.typeFiscaliteModel.lmp.taux_reduction,
      plafondLmnp: this.typeFiscaliteModel.lmp.plafond,
      amortRepart1: this.typeFiscaliteModel.lmp.oeuvre_pourcent,
      amortRepart10: this.typeFiscaliteModel.lmp.oeuvre_an,
      amortRepart2: this.typeFiscaliteModel.lmp.facade_pourcent,
      amortRepart20: this.typeFiscaliteModel.lmp.facade_an,
      amortRepart3: this.typeFiscaliteModel.lmp.equipement_pourcent,
      amortRepart30: this.typeFiscaliteModel.lmp.equipement_an,
      amortRepart4: this.typeFiscaliteModel.lmp.agencement_pourcent,
      amortRepart40: this.typeFiscaliteModel.lmp.agencement_an,
      // dureeFiscale: this.typeFiscaliteModel.lmp.pas,
      cotisFor: this.typeFiscaliteModel.lmp.base_forfaitaire,
      cotisPro: this.typeFiscaliteModel.lmp.taux_taxe_professionnelle,
      cotisTx: this.typeFiscaliteModel.lmp.taxe_cotisation,
      id: this.typeFiscaliteModel.lmp.id,
      loi: this.typeFiscaliteModel.lmp.loi,
      scpi: this.scpi
    };
  }

  hydrateLmp(data?: any) {
    this.typeFiscaliteModel.lmp.reduction_non = (data?.cenciBouvard) ? false : true;
    this.typeFiscaliteModel.lmp.reduction_oui = (data?.cenciBouvard) ? true : false;
    this.typeFiscaliteModel.lmp.decomposition_non = (data?.amortInit) ? false : true;
    this.typeFiscaliteModel.lmp.decomposition_oui = (data?.amortInit) ? true : false;
    this.typeFiscaliteModel.lmp.partie_mobiliere = data?.amortMob;
    this.typeFiscaliteModel.lmp.partie_immobiliere = data?.amortImmo;
    this.typeFiscaliteModel.lmp.comptabilite_annuel = data?.compta;
    this.typeFiscaliteModel.lmp.regime_soumis_non = data?.regimeCotisation ? false : true;
    this.typeFiscaliteModel.lmp.regime_soumis_oui = data?.regimeCotisation ? true : false;
    this.typeFiscaliteModel.lmp.tva_non = data?.tva ? false : true;
    this.typeFiscaliteModel.lmp.tva_oui = data?.tva ? true : false;
    this.typeFiscaliteModel.lmp.taux_reduction = data?.reducLmnp;
    this.typeFiscaliteModel.lmp.plafond = data?.plafondLmnp;
    this.typeFiscaliteModel.lmp.oeuvre_pourcent = data?.amortRepart1;
    this.typeFiscaliteModel.lmp.oeuvre_an = data?.amortRepart10;
    this.typeFiscaliteModel.lmp.facade_pourcent = data?.amortRepart2;
    this.typeFiscaliteModel.lmp.facade_an = data?.amortRepart20;
    this.typeFiscaliteModel.lmp.equipement_pourcent = data?.amortRepart3;
    this.typeFiscaliteModel.lmp.equipement_an = data?.amortRepart30;
    this.typeFiscaliteModel.lmp.agencement_pourcent = data?.amortRepart4;
    this.typeFiscaliteModel.lmp.agencement_an = data?.amortRepart40;
    this.typeFiscaliteModel.lmp.base_forfaitaire = data?.cotisFor;
    this.typeFiscaliteModel.lmp.taux_taxe_professionnelle = data?.cotisPro;
    this.typeFiscaliteModel.lmp.taxe_cotisation = data?.cotisTx;
    this.typeFiscaliteModel.lmp.id = data?.id;
    this.typeFiscaliteModel.lmp.loi = data?.loi;
    this.scpi = data?.scpi
  }

  // formating to send and received BorlooPopulaire
  serializeBorlooPopulaire() {
    return {
      comptaLmnp: this.typeFiscaliteModel.borloo.frais_comptabilite,
      dureeFiscale: this.typeFiscaliteModel.borloo.passage,
      enLmnp: this.typeFiscaliteModel.borloo.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.borloo.regime,
      etabLmnp: this.typeFiscaliteModel.borloo.frais_etablissement,
      id: this.typeFiscaliteModel.borloo.id,
      loi: "BORLOO_POPULAIRE",
      mobLmnp: this.typeFiscaliteModel.borloo.valeur_mobilier,
      scpi: this.scpi,
      taxExoSupAn: this.typeFiscaliteModel.borloo.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.borloo.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.borloo.valeur_terrain,
    }
  }

  hydrateBorloo(data?: any) {
    this.typeFiscaliteModel.borloo.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.borloo.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.borloo.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.borloo.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.borloo.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.borloo.id = data?.id;
    this.typeFiscaliteModel.borloo.valeur_mobilier = data?.mobLmnp;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.borloo.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.borloo.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.borloo.valeur_terrain = data?.terrainLmnp
  }

  // formating to send and received Denormandie
  serializeDenormandie() {
    return {
      sixAns: this.typeFiscaliteModel.denormandie.engargement_min,
      neufAns: this.typeFiscaliteModel.denormandie.engargement_max,
      metropole: this.typeFiscaliteModel.denormandie.metropole,
      outremer: this.typeFiscaliteModel.denormandie.outre_mere,
      tauxReduction: this.typeFiscaliteModel.denormandie.taux,
      plafond: this.typeFiscaliteModel.denormandie.plafond_investissement,
      dureeFiscale: this.typeFiscaliteModel.denormandie.passage,
      enRegimeGeneral: this.typeFiscaliteModel.denormandie.regime,
      enLmnp: this.typeFiscaliteModel.denormandie.lmnp,
      etabLmnp: this.typeFiscaliteModel.denormandie.frais_etablissement,
      comptaLmnp: this.typeFiscaliteModel.denormandie.frais_comptabilite,
      terrainLmnp: this.typeFiscaliteModel.denormandie.valeur_terrain,
      mobLmnp: this.typeFiscaliteModel.denormandie.valeur_mobilier,
      taxExoSupPourc: this.typeFiscaliteModel.denormandie.taxe_fonciere,
      taxExoSupAn: this.typeFiscaliteModel.denormandie.pendant,
      id: this.typeFiscaliteModel.denormandie.id,
      loi: this.typeFiscaliteModel.denormandie.loi,
      scpi: this.scpi
    };
  }

  hydrateDenormandie(data?: any) {
    this.typeFiscaliteModel.denormandie.engargement_min = data?.sixAns;
    this.typeFiscaliteModel.denormandie.engargement_max = data?.neufAns;
    this.typeFiscaliteModel.denormandie.metropole = data?.metropole;
    this.typeFiscaliteModel.denormandie.outre_mere = data?.outremer;
    this.typeFiscaliteModel.denormandie.taux = data?.tauxReduction;
    this.typeFiscaliteModel.denormandie.plafond_investissement = data?.plafond;
    this.typeFiscaliteModel.denormandie.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.denormandie.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.denormandie.lmnp = data?.enLmnp || false;
    this.typeFiscaliteModel.denormandie.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.denormandie.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.denormandie.valeur_terrain = data?.terrainLmnp;
    this.typeFiscaliteModel.denormandie.valeur_mobilier = data?.mobLmnp;
    this.typeFiscaliteModel.denormandie.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.denormandie.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.denormandie.id = data?.id;
    this.typeFiscaliteModel.denormandie.loi = data?.loi;
    this.scpi = data?.loi
  }

  // formating to send and received Duflot
  serializeDuflot() {
    return {
      sixAns: this.typeFiscaliteModel.duflot.engargement_min,
      neufAns: this.typeFiscaliteModel.duflot.engargement_max,
      metropole: this.typeFiscaliteModel.duflot.metropole,
      outremer: this.typeFiscaliteModel.duflot.outre_mere,
      tauxReduction: this.typeFiscaliteModel.duflot.taux,
      plafond: this.typeFiscaliteModel.duflot.plafond_investissement,
      dureeFiscale: this.typeFiscaliteModel.duflot.passage,
      enRegimeGeneral: this.typeFiscaliteModel.duflot.regime,
      enLmnp: this.typeFiscaliteModel.duflot.lmnp,
      etabLmnp: this.typeFiscaliteModel.duflot.frais_etablissement,
      comptaLmnp: this.typeFiscaliteModel.duflot.frais_comptabilite,
      terrainLmnp: this.typeFiscaliteModel.duflot.valeur_terrain,
      mobLmnp: this.typeFiscaliteModel.duflot.valeur_mobilier,
      taxExoSupPourc: this.typeFiscaliteModel.duflot.taxe_fonciere,
      taxExoSupAn: this.typeFiscaliteModel.duflot.pendant,
      id: this.typeFiscaliteModel.duflot.id,
      loi: this.typeFiscaliteModel.duflot.loi,
      scpi: this.scpi
    };
  }

  hydrateDuflot(data?: any) {
    this.typeFiscaliteModel.duflot.engargement_min = data?.sixAns;
    this.typeFiscaliteModel.duflot.engargement_max = data?.neufAns;
    this.typeFiscaliteModel.duflot.metropole = data?.metropole;
    this.typeFiscaliteModel.duflot.outre_mere = data?.outremer;
    this.typeFiscaliteModel.duflot.taux = data?.tauxReduction;
    this.typeFiscaliteModel.duflot.plafond_investissement = data?.plafond;
    this.typeFiscaliteModel.duflot.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.duflot.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.duflot.lmnp = data?.enLmnp || false;
    this.typeFiscaliteModel.duflot.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.duflot.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.duflot.valeur_terrain = data?.terrainLmnp;
    this.typeFiscaliteModel.duflot.valeur_mobilier = data?.mobLmnp;
    this.typeFiscaliteModel.duflot.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.duflot.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.duflot.id = data?.id;
    this.typeFiscaliteModel.duflot.loi = data?.loi;
    this.scpi = data?.loi
  }

  // formating to send and received Giradin
  serializeGiradin() {
    return {
      comptaLmnp: this.typeFiscaliteModel.giradin.frais_comptabilite,
      dat: (this.typeFiscaliteModel.giradin.annee_precedant_livraison_non) ? false : true,
      dureeFiscale: this.typeFiscaliteModel.giradin.passage,
      enLmnp: this.typeFiscaliteModel.giradin.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.giradin.regime,
      etabLmnp: this.typeFiscaliteModel.giradin.frais_etablissement,
      girTaux2010: this.typeFiscaliteModel.giradin.taux_reduction_annee,
      id: this.typeFiscaliteModel.giradin.id,
      locInter: this.typeFiscaliteModel.giradin.locatif_intermediaire,
      locLib: this.typeFiscaliteModel.giradin.locatif_libre,
      locPrinc: this.typeFiscaliteModel.giradin.habitat_principale,
      loi: this.typeFiscaliteModel.giradin.id,
      mobLmnp: this.typeFiscaliteModel.giradin.valeur_mobilier,
      scpi: this.scpi,
      tauxReduction: this.typeFiscaliteModel.giradin.taux_reduction,
      taxExoSupAn: this.typeFiscaliteModel.giradin.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.giradin.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.giradin.valeur_terrain,
    }
  }

  hydrateGiradin(data?: any) {
    this.typeFiscaliteModel.giradin.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.giradin.annee_precedant_livraison_non = (data?.dat) ? false : true;
    this.typeFiscaliteModel.giradin.annee_precedant_livraison_oui = (data?.dat) ? true : false;
    this.typeFiscaliteModel.giradin.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.giradin.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.giradin.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.giradin.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.giradin.taux_reduction_annee = data?.girTaux2010;
    this.typeFiscaliteModel.giradin.id = data?.id;
    this.typeFiscaliteModel.giradin.locatif_intermediaire = data?.locInter;
    this.typeFiscaliteModel.giradin.locatif_libre = data?.locLib;
    this.typeFiscaliteModel.giradin.habitat_principale = data?.locPrinc;
    this.typeFiscaliteModel.giradin.id = data?.loi;
    this.typeFiscaliteModel.giradin.valeur_mobilier = data?.mobLmnp;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.giradin.taux_reduction = data?.tauxReduction;
    this.typeFiscaliteModel.giradin.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.giradin.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.giradin.valeur_terrain = data?.terrainLmnp;
  }
  // formating to send and received Giradin
  serializeMalraux() {
    return {
      comptaLmnp: this.typeFiscaliteModel.marlaux.frais_comptabilite,
      dureeFiscale: this.typeFiscaliteModel.marlaux.passage,
      enLmnp: this.typeFiscaliteModel.marlaux.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.marlaux.regime,
      etabLmnp: this.typeFiscaliteModel.marlaux.frais_etablissement,
      id: this.typeFiscaliteModel.marlaux.id,
      loi: this.typeFiscaliteModel.marlaux.loi,
      malTaux2010: this.typeFiscaliteModel.marlaux.taux,
      mobLmnp: this.typeFiscaliteModel.marlaux.valeur_mobilier,
      repartFraisMal: this.typeFiscaliteModel.marlaux.date_repatition,
      scpi: this.scpi,
      secteurSauv: this.typeFiscaliteModel.marlaux.secteur_sauvage,
      subFuture: (this.typeFiscaliteModel.subvention.montantMalrauxSubventions.length > 0) ? true : false,
      subFutureList: this.typeFiscaliteModel.subvention.SubventionMalrauxArraySerialize(),
      subMh: this.typeFiscaliteModel.subvention.montantMalrauxSubvention.montant,
      tauxReduction: this.typeFiscaliteModel.marlaux.taux_reduction,
      taxExoSupAn: this.typeFiscaliteModel.marlaux.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.marlaux.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.marlaux.valeur_terrain,
      zppaup: this.typeFiscaliteModel.marlaux.zppaup
    }
  }

  hydrateMalraux(data?: any) {
    this.typeFiscaliteModel.marlaux.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.marlaux.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.marlaux.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.marlaux.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.marlaux.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.marlaux.id = data?.id;
    this.typeFiscaliteModel.marlaux.loi = data?.loi;
    this.typeFiscaliteModel.marlaux.taux = data?.malTaux2010;
    this.typeFiscaliteModel.marlaux.valeur_mobilier = data?.mobLmnp;
    this.typeFiscaliteModel.marlaux.date_repatition = data?.repartFraisMal;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.marlaux.secteur_sauvage = data?.secteurSauv;
    this.typeFiscaliteModel.subvention.hasAnneeFuturMalrauxSubvention = data?.subFuture;
    this.typeFiscaliteModel.subvention.montantMalrauxSubventions = this.typeFiscaliteModel.subvention.hydrateArrayMalrauxModelSubvention(data?.subFutureList);
    this.typeFiscaliteModel.subvention.montantMalrauxSubvention.montant = data?.subMh;
    this.typeFiscaliteModel.marlaux.taux_reduction = data?.tauxReduction;
    this.typeFiscaliteModel.marlaux.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.marlaux.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.marlaux.valeur_terrain = data?.terrainLmnp;
    this.typeFiscaliteModel.marlaux.zppaup = data?.zppaup;
    this.typeFiscaliteModel.marlaux.zppaup = data?.surface_totale;
  }
  // formating to send and received Monument
  serializeMonument() {
    return {
      abbatementForfaitaire: this.typeFiscaliteModel.monument.abatement_forfaitaire,
      comptaLmnp: this.typeFiscaliteModel.monument.frais_comptabilite,
      dureeFiscale: this.typeFiscaliteModel.monument.passage,
      enLmnp: this.typeFiscaliteModel.monument.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.monument.regime,
      etabLmnp: this.typeFiscaliteModel.monument.frais_etablissement,
      id: this.typeFiscaliteModel.monument.id,
      loi: this.typeFiscaliteModel.monument.loi,
      mhPub: this.typeFiscaliteModel.monument.charge_publicite,
      mobLmnp: this.typeFiscaliteModel.monument.valeur_mobilier,
      ouverturePublic: (this.typeFiscaliteModel.monument.public) ? true : false,
      parcOuJardin: (this.typeFiscaliteModel.monument.espace_vert_non) ? false : true,
      repartFraisMal: this.typeFiscaliteModel.monument.date_repatition,
      scpi: this.scpi,
      subFuture: this.typeFiscaliteModel.subvention.montantMonumentSubventions.length > 0 ? true : false,
      subFutureList: this.typeFiscaliteModel.subvention.SubventionMonumentArraySerialize(),
      subMh: this.typeFiscaliteModel.subvention.montantMonumentSubvention.montant,
      tauxDeductionCharge: this.typeFiscaliteModel.monument.taux_deduction,
      tauxEvalCharge: this.typeFiscaliteModel.monument.taux_reevaluation,
      tauxReduction: this.typeFiscaliteModel.monument.taux_reduction,
      taxExoSupAn: this.typeFiscaliteModel.monument.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.monument.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.monument.valeur_terrain,
      tva: (this.typeFiscaliteModel.monument.tva_non) ? false : true
    }
  }

  hydrateMonument(data?: any) {
    this.typeFiscaliteModel.monument.abatement_forfaitaire = data?.abbatementForfaitaire;
    this.typeFiscaliteModel.monument.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.monument.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.monument.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.monument.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.monument.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.monument.id = data?.id;
    this.typeFiscaliteModel.monument.loi = data?.loi;
    this.typeFiscaliteModel.monument.charge_publicite = data?.mhPub;
    this.typeFiscaliteModel.monument.valeur_mobilier = data?.mobLmnp;
    this.typeFiscaliteModel.monument.public = (data?.ouverturePublic) ? true : false;
    this.typeFiscaliteModel.monument.location = (data?.ouverturePublic) ? false : true;
    this.typeFiscaliteModel.monument.espace_vert_non = (data?.parcOuJardin) ? false : true;
    this.typeFiscaliteModel.monument.espace_vert_oui = (data?.parcOuJardin) ? true : false;
    this.typeFiscaliteModel.monument.date_repatition = data?.repartFraisMal;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.subvention.hasAnneeFuturMonumentSubvention = data?.subFuture;
    this.typeFiscaliteModel.subvention.montantMonumentSubventions = this.typeFiscaliteModel.subvention.hydrateArrayMonumentModelSubvention(data?.subFutureList);
    this.typeFiscaliteModel.subvention.montantMonumentSubvention.montant = data?.subMh;
    this.typeFiscaliteModel.monument.taux_deduction = data?.tauxDeductionCharge;
    this.typeFiscaliteModel.monument.taux_reevaluation = data?.tauxEvalCharge;
    this.typeFiscaliteModel.monument.taux_reduction = data?.tauxReduction;
    this.typeFiscaliteModel.monument.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.monument.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.monument.valeur_terrain = data?.terrainLmnp;
    this.typeFiscaliteModel.monument.tva_non = (data?.tva) ? false : true;
    this.typeFiscaliteModel.monument.tva_oui = (data?.tva) ? true : false;
  }
  // formating to send and received regime generale
  serializeRegimeGeneral() {
    return {
      comptaLmnp: this.typeFiscaliteModel.regime.frais_comptabilite,
      dureeFiscale: this.typeFiscaliteModel.regime.passage,
      enLmnp: this.typeFiscaliteModel.regime.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.regime.regime,
      etabLmnp: this.typeFiscaliteModel.regime.frais_etablissement,
      id: this.typeFiscaliteModel.regime.id,
      loi: this.typeFiscaliteModel.regime.id,
      mobLmnp: this.typeFiscaliteModel.regime.valeur_mobilier,
      scpi: this.scpi,
      taxExoSupAn: this.typeFiscaliteModel.regime.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.regime.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.regime.valeur_terrain,
      repartFraisMal: this.typeFiscaliteModel.regime.date_repatition,
      tva: this.typeFiscaliteModel.regime.tva_non ? false : true,
    };
  }

  hydrateRegimeGenerale(data?: any) {
    this.typeFiscaliteModel.regime.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.regime.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.regime.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.regime.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.regime.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.regime.id = data?.id;
    this.typeFiscaliteModel.regime.id = data?.loi;
    this.typeFiscaliteModel.regime.valeur_mobilier = data?.mobLmnp;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.regime.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.regime.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.regime.valeur_terrain = data?.terrainLmnp;
    this.typeFiscaliteModel.regime.date_repatition = data?.repartFraisMal;
    this.typeFiscaliteModel.regime.tva_non = (data?.tva) ? false : true;
    this.typeFiscaliteModel.regime.tva_oui = (data?.tva) ? true : false;
  }
  // formating to send and received robien en zrr
  serializeRobienEnZrr() {
    return {
      comptaLmnp: this.typeFiscaliteModel.robienZrr.frais_comptabilite,
      dureeFiscale: this.typeFiscaliteModel.robienZrr.passage,
      enLmnp: this.typeFiscaliteModel.robienZrr.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.robienZrr.regime,
      etabLmnp: this.typeFiscaliteModel.robienZrr.frais_etablissement,
      id: this.typeFiscaliteModel.robienZrr.id,
      loi: "ROBIEN_EN_ZRR",
      mobLmnp: this.typeFiscaliteModel.robienZrr.valeur_mobilier,
      scpi: this.scpi,
      taxExoSupAn: this.typeFiscaliteModel.robienZrr.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.robienZrr.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.robienZrr.valeur_terrain,
    }
  }

  hydrateRobienEnZRR(data?: any) {
    this.typeFiscaliteModel.robienZrr.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.robienZrr.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.robienZrr.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.robienZrr.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.robienZrr.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.robienZrr.id = data?.id;
    this.typeFiscaliteModel.robienZrr.valeur_mobilier = data?.mobLmnp;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.robienZrr.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.robienZrr.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.robienZrr.valeur_terrain = data?.terrainLmnp
  }

  // formating to send and received robien recentre
  serializeRobienRecentre() {
    return {
      comptaLmnp: this.typeFiscaliteModel.robienRecentre.frais_comptabilite,
      dureeFiscale: this.typeFiscaliteModel.robienRecentre.passage,
      enLmnp: this.typeFiscaliteModel.robienRecentre.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.robienRecentre.regime,
      etabLmnp: this.typeFiscaliteModel.robienRecentre.frais_etablissement,
      id: this.typeFiscaliteModel.robienRecentre.id,
      loi: "ROBIEN_RECENTRE",
      mobLmnp: this.typeFiscaliteModel.robienRecentre.valeur_mobilier,
      scpi: this.scpi,
      taxExoSupAn: this.typeFiscaliteModel.robienRecentre.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.robienRecentre.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.robienRecentre.valeur_terrain,
    }
  }

  hydrateRobienRecentre(data?: any) {
    this.typeFiscaliteModel.robienRecentre.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.robienRecentre.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.robienRecentre.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.robienRecentre.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.robienRecentre.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.robienRecentre.id = data?.id;
    this.typeFiscaliteModel.robienRecentre.valeur_mobilier = data?.mobLmnp;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.robienRecentre.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.robienRecentre.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.robienRecentre.valeur_terrain = data?.terrainLmnp
  }

  // formating to send and received scellier
  serializeScellierNeufAns() {
    return {
      comptaLmnp: this.typeFiscaliteModel.scellier.frais_comptabilite,
      dureeFiscale: this.typeFiscaliteModel.scellier.passage,
      enLmnp: this.typeFiscaliteModel.scellier.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.scellier.regime,
      etabLmnp: this.typeFiscaliteModel.scellier.frais_etablissement,
      id: this.typeFiscaliteModel.scellier.id,
      loi: this.typeFiscaliteModel.scellier.loi,
      mobLmnp: this.typeFiscaliteModel.scellier.valeur_mobilier,
      scpi: this.scpi,
      taxExoSupAn: this.typeFiscaliteModel.scellier.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.scellier.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.scellier.valeur_terrain,
      lotBbc: this.typeFiscaliteModel.scellier.lot_bbc,
      metropole: this.typeFiscaliteModel.scellier.metropole,
      neufAns: this.typeFiscaliteModel.scellier.engargement_max,
      outremer: this.typeFiscaliteModel.scellier.outre_mere,
      plafond: this.typeFiscaliteModel.scellier.plafond_investissement,
      scellierZrr: this.typeFiscaliteModel.scellier.zone_social_oui,
      sixAns: this.typeFiscaliteModel.scellier.engargement_min,
      tauxReduction: this.typeFiscaliteModel.scellier.taux
    }
  }

  hydrateScellierNeufAns(data?: any) {
    this.typeFiscaliteModel.scellier.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.scellier.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.scellier.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.scellier.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.scellier.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.scellier.id = data?.id;
    this.typeFiscaliteModel.scellier.loi = data?.loi;
    this.typeFiscaliteModel.scellier.valeur_mobilier = data?.mobLmnp;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.scellier.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.scellier.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.scellier.valeur_terrain = data?.terrainLmnp;
    this.typeFiscaliteModel.scellier.lot_bbc = data?.lotBbc;
    this.typeFiscaliteModel.scellier.metropole = data?.metropole;
    this.typeFiscaliteModel.scellier.engargement_max = data?.neufAns;
    this.typeFiscaliteModel.scellier.outre_mere = data?.outremer;
    this.typeFiscaliteModel.scellier.plafond_investissement = data?.plafond;
    this.typeFiscaliteModel.scellier.zone_social_oui = data?.scellierZrr;
    this.typeFiscaliteModel.scellier.engargement_min = data?.sixAns;
    this.typeFiscaliteModel.scellier.taux = data?.tauxReduction
  }

  // formating to send and received scellier interm
  serializeScellierIntern() {
    return {
      comptaLmnp: this.typeFiscaliteModel.scellierInterm.frais_comptabilite,
      dureeFiscale: this.typeFiscaliteModel.scellierInterm.passage,
      enLmnp: this.typeFiscaliteModel.scellierInterm.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.scellierInterm.regime,
      etabLmnp: this.typeFiscaliteModel.scellierInterm.frais_etablissement,
      id: this.typeFiscaliteModel.scellierInterm.id,
      loi: this.typeFiscaliteModel.scellierInterm.loi,
      mobLmnp: this.typeFiscaliteModel.scellierInterm.valeur_mobilier,
      scpi: this.scpi,
      taxExoSupAn: this.typeFiscaliteModel.scellierInterm.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.scellierInterm.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.scellierInterm.valeur_terrain,
      lotBbc: this.typeFiscaliteModel.scellierInterm.lot_bbc,
      metropole: this.typeFiscaliteModel.scellierInterm.metropole,
      neufAns: this.typeFiscaliteModel.scellierInterm.engargement_max,
      outremer: this.typeFiscaliteModel.scellierInterm.outre_mere,
      plafond: this.typeFiscaliteModel.scellierInterm.plafond_investissement,
      reducSupScellier: this.typeFiscaliteModel.scellierInterm.taux_reduction,
      sixAns: this.typeFiscaliteModel.scellierInterm.engargement_min,
      tauxReduction: this.typeFiscaliteModel.scellierInterm.taux
    }
  }

  hydrateScellierInterm(data?: any) {
    this.typeFiscaliteModel.scellierInterm.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.scellierInterm.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.scellierInterm.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.scellierInterm.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.scellierInterm.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.scellierInterm.id = data?.id;
    this.typeFiscaliteModel.scellierInterm.loi = data?.loi;
    this.typeFiscaliteModel.scellierInterm.valeur_mobilier = data?.mobLmnp;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.scellierInterm.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.scellierInterm.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.scellierInterm.valeur_terrain = data?.terrainLmnp;
    this.typeFiscaliteModel.scellierInterm.lot_bbc = data?.lotBbc;
    this.typeFiscaliteModel.scellierInterm.metropole = data?.metropole;
    this.typeFiscaliteModel.scellierInterm.engargement_max = data?.neufAns;
    this.typeFiscaliteModel.scellierInterm.outre_mere = data?.outremer;
    this.typeFiscaliteModel.scellierInterm.plafond_investissement = data?.plafond;
    this.typeFiscaliteModel.scellierInterm.engargement_min = data?.sixAns;
    this.typeFiscaliteModel.scellierInterm.taux = data?.tauxReduction;
    this.typeFiscaliteModel.scellierInterm.taux_reduction = data?.reducSupScellier
  }

  // formating to send and received ZRR
  serializeZrr() {
    return {
      comptaLmnp: this.typeFiscaliteModel.zrr.frais_comptabilite,
      dureeFiscale: this.typeFiscaliteModel.zrr.passage,
      enLmnp: this.typeFiscaliteModel.zrr.lmnp,
      enRegimeGeneral: this.typeFiscaliteModel.zrr.regime,
      etabLmnp: this.typeFiscaliteModel.zrr.frais_etablissement,
      id: this.typeFiscaliteModel.zrr.id,
      loi: this.typeFiscaliteModel.zrr.loi,
      mobLmnp: this.typeFiscaliteModel.zrr.valeur_mobilier,
      scpi: this.scpi,
      taxExoSupAn: this.typeFiscaliteModel.zrr.pendant,
      taxExoSupPourc: this.typeFiscaliteModel.zrr.taxe_fonciere,
      terrainLmnp: this.typeFiscaliteModel.zrr.valeur_terrain,
      reducSupScellier: this.typeFiscaliteModel.zrr.taux_reduction,
      etalReducZrr: this.typeFiscaliteModel.zrr.etalement_reduction,
      reducZrr: this.typeFiscaliteModel.zrr.taux_reduction,
      tauxReduction: this.typeFiscaliteModel.zrr.taux_reduction,
      tva: (this.typeFiscaliteModel.zrr.tva_oui) ? true : false,
    }
  }

  hydrateZRR(data?: any) {
    this.typeFiscaliteModel.zrr.frais_comptabilite = data?.comptaLmnp;
    this.typeFiscaliteModel.zrr.passage = data?.dureeFiscale;
    this.typeFiscaliteModel.zrr.lmnp = data?.enLmnp;
    this.typeFiscaliteModel.zrr.regime = data?.enRegimeGeneral || true;
    this.typeFiscaliteModel.zrr.frais_etablissement = data?.etabLmnp;
    this.typeFiscaliteModel.zrr.id = data?.id;
    this.typeFiscaliteModel.zrr.loi = data?.loi;
    this.typeFiscaliteModel.zrr.valeur_mobilier = data?.mobLmnp;
    this.scpi = data?.scpi;
    this.typeFiscaliteModel.zrr.pendant = data?.taxExoSupAn;
    this.typeFiscaliteModel.zrr.taxe_fonciere = data?.taxExoSupPourc;
    this.typeFiscaliteModel.zrr.valeur_terrain = data?.taxExoSupPourc;
    this.typeFiscaliteModel.zrr.taux_reduction = data?.terrainLmnp;
    this.typeFiscaliteModel.zrr.etalement_reduction = data?.etalReducZrr;
    this.typeFiscaliteModel.zrr.taux_reduction = data?.tauxReduction;
    this.typeFiscaliteModel.zrr.tva_oui = (data?.tva) ? true : false;
    this.typeFiscaliteModel.zrr.tva_non = (data?.tva) ? false : true
  }

  // formating to send and received denormandie
  serializeDemembrement() {
    return {
      id: this.typeFiscaliteModel.demembrement.id,
      loi: this.typeFiscaliteModel.demembrement.loi,
      scpi: this.scpi,
    }
  }

  hydrateDemembrement(data?: any) {
    this.typeFiscaliteModel.demembrement.id = data?.id;
    this.typeFiscaliteModel.demembrement.loi = data?.loi;
    this.scpi = data?.scpi;
  }

  //Debut support pinel
  serialiseSupportPinelImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      zone: (typeof (this.support.supportImmobilier.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportImmobilier.choixImmobilier.zone) : this.support.supportImmobilier.choixImmobilier.zone,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      plafondLoyer: this.support.supportImmobilier.choixImmobilier.loyer_maxi,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.dont_montant_reductible,
      aful: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      ...this.support.supportImmobilier.chargeIntegre.serializeCharge(),
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }


  serialiseSupportLmnpImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportImmobilier.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.valeur_part,
      difLoyer: this.support.supportImmobilier.choixImmobilier.differe_avant_premier_loyer,
      loyerMensuel: this.support.supportImmobilier.choixImmobilier.revenu_locatif_mensuel,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      fraisGestion: this.support.supportImmobilier.chargeIntegre.frais_gestion,
      aful: this.support.revenuLocatifImmobillier.montantTvaRecuper.montant,
      tva: this.support.revenuLocatifImmobillier.montantTvaRecuper.montant,
      tvaFutureList: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper,
      tvaFuture: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper.length > 0 ? true : false,
      taxeFonciere: this.support.supportImmobilier.chargeIntegre.montant_taxe_fiscal,
      chargeGarantie: this.support.supportImmobilier.chargeIntegre.garantie_locative,
      chargeCopropriete: this.support.supportImmobilier.chargeIntegre.charge_copropriete,
      pourcExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_a,
      dureeExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_pendant,
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportLmpImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportImmobilier.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.valeur_part,
      difLoyer: this.support.supportImmobilier.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportImmobilier.chargeIntegre.frais_gestion,
      aful: this.support.revenuLocatifImmobillier.montantTvaRecuper.montant,
      tvaFutureList: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper,
      tvaFuture: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper.length > 0 ? true : false,
      taxeFonciere: this.support.supportImmobilier.chargeIntegre.montant_taxe_fiscal,
      chargeGarantie: this.support.supportImmobilier.chargeIntegre.garantie_locative,
      chargeCopropriete: this.support.supportImmobilier.chargeIntegre.charge_copropriete,
      pourcExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_a,
      dureeExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_pendant,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportRegimeGeneralImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportImmobilier.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.revenuLocatifImmobillier.fraisRestauration.montant,
      difLoyer: this.support.supportImmobilier.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportImmobilier.chargeIntegre.frais_gestion,
      aful: this.support.revenuLocatifImmobillier.montantTvaRecuper.montant,
      tvaFutureList: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper,
      tvaFuture: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper.length > 0 ? true : false,
      taxeFonciere: this.support.supportImmobilier.chargeIntegre.montant_taxe_fiscal,
      chargeGarantie: this.support.supportImmobilier.chargeIntegre.garantie_locative,
      chargeCopropriete: this.support.supportImmobilier.chargeIntegre.charge_copropriete,
      pourcExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_a,
      dureeExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_pendant,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      fraisRestaurationFutureList: this.support.revenuLocatifImmobillier.ArraymontantFraisRestauration,
      fraisRestaurationFuture: this.support.revenuLocatifImmobillier.ArraymontantFraisRestauration.length > 0 ? true : false,
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportDemembrementImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      // valMobFoncier: this.support.supportImmobilier.choixImmobilier.nombre_part,
      // produitFiscalNonAmorti: this.support.revenuLocatifImmobillier.fraisRestauration.montant,  
      difLoyer: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      fraisGestion: this.support.supportImmobilier.chargeIntegre.frais_gestion,
      aful: this.support.supportImmobilier.choixImmobilier.adhesion_aful,
      tvaFutureList: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper,
      tvaFuture: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper.length > 0 ? true : false,
      taxeFonciere: this.support.supportImmobilier.chargeIntegre.montant_taxe_fiscal,
      chargeGarantie: this.support.supportImmobilier.chargeIntegre.garantie_locative,
      chargeCopropriete: this.support.supportImmobilier.chargeIntegre.charge_copropriete,
      pourcExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_a,
      dureeExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_pendant,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      fraisRestaurationFutureList: this.support.revenuLocatifImmobillier.ArraymontantFraisRestauration,
      fraisRestaurationFuture: this.support.revenuLocatifImmobillier.ArraymontantFraisRestauration.length > 0 ? true : false,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.dont_montant_reductible,
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
      //
      // travauxDeductibe: nonAmmortie,
      //Durée du demembrement: difLoyer,
    }
  }

  serialiseSupportMonumenthistoImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportImmobilier.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.revenuLocatifImmobillier.fraisRestauration.montant,
      difLoyer: this.support.supportImmobilier.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportImmobilier.chargeIntegre.frais_gestion,
      aful: this.support.revenuLocatifImmobillier.montantTvaRecuper.montant,
      tvaFutureList: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper,
      tvaFuture: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper.length > 0 ? true : false,
      taxeFonciere: this.support.supportImmobilier.chargeIntegre.montant_taxe_fiscal,
      chargeGarantie: this.support.supportImmobilier.chargeIntegre.garantie_locative,
      chargeCopropriete: this.support.supportImmobilier.chargeIntegre.charge_copropriete,
      pourcExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_a,
      dureeExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_pendant,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      fraisRestaurationFutureList: this.support.revenuLocatifImmobillier.ArraymontantFraisRestauration,
      fraisRestaurationFuture: this.support.revenuLocatifImmobillier.ArraymontantFraisRestauration.length > 0 ? true : false,
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportGirardinImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportImmobilier.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.revenuLocatifImmobillier.fraisRestauration.montant,
      difLoyer: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      fraisGestion: this.support.supportImmobilier.chargeIntegre.frais_gestion,
      aful: this.support.revenuLocatifImmobillier.montantTvaRecuper.montant,
      tvaFutureList: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper,
      tvaFuture: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper.length > 0 ? true : false,
      taxeFonciere: this.support.supportImmobilier.chargeIntegre.montant_taxe_fiscal,
      chargeGarantie: this.support.supportImmobilier.chargeIntegre.garantie_locative,
      chargeCopropriete: this.support.supportImmobilier.chargeIntegre.charge_copropriete,
      pourcExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_a,
      dureeExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_pendant,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      fraisRestaurationFutureList: this.support.revenuLocatifImmobillier.ArraymontantFraisRestauration,
      fraisRestaurationFuture: this.support.revenuLocatifImmobillier.ArraymontantFraisRestauration.length > 0 ? true : false,
      zone: (typeof (this.support.supportImmobilier.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportImmobilier.choixImmobilier.zone) : this.support.supportImmobilier.choixImmobilier.zone,
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  //fin support Immobilier


  //debut support SCPI
  serialiseSupportPinelScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      zone: (typeof (this.support.supportSCPI.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportSCPI.choixImmobilier.zone) : this.support.supportSCPI.choixImmobilier.zone,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }

  serialiseSupportLmnpScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      surface: this.support.supportSCPI.choixImmobilier.surface_totale,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }

  serialiseSupportLmpScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      surface: this.support.supportSCPI.choixImmobilier.surface_totale,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }

  serialiseSupportRegimeGeneraleScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      surface: this.support.supportSCPI.choixImmobilier.surface_totale,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }

  serialiseSupportMonumentHistoScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      surface: this.support.supportSCPI.choixImmobilier.surface_totale,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }

  serialiseSupportDemembrementScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      surface: this.support.supportSCPI.choixImmobilier.surface_totale,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }

  serialiseSupportGirardinScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      surface: this.support.supportSCPI.choixImmobilier.surface_totale,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }

  //fin support SCPI

  // pinel

  //Debut support Denormandie
  serialiseSupportDenormandieImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      zone: (typeof (this.support.supportImmobilier.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportImmobilier.choixImmobilier.zone) : this.support.supportImmobilier.choixImmobilier.zone,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      plafondLoyer: this.support.supportImmobilier.choixImmobilier.loyer_maxi,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.dont_montant_reductible,
      aful: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      ...this.support.supportImmobilier.chargeIntegre.serializeCharge(),
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais,
      difLoyer: this.support.supportImmobilier.choixImmobilier.differe_avant_premier_loyer
    }
  }

  serialiseSupportDenormandieScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      zone: (typeof (this.support.supportSCPI.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportSCPI.choixImmobilier.zone) : this.support.supportSCPI.choixImmobilier.zone,
      scpi: true,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }
  // Denormandie

  //Debut support MALRAUX
  serialiseSupportMalrauxImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      // zone: this.support.supportImmobilier.choixImmobilier.zone,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      // plafondLoyer: this.support.supportImmobilier.choixImmobilier.loyer_maxi,
      produitFiscalNonAmorti: this.support.revenuLocatifImmobillier.fraisRestauration.montant,
      fraisRestaurationFuture: (this.support.revenuLocatifImmobillier.ArraymontantFraisRestauration.length > 0) ? true : false,
      fraisRestaurationFutureList: this.support.revenuLocatifImmobillier.fraisRestaurationArraySerialize(),
      aful: this.support.supportImmobilier.choixImmobilier.adhesion_aful,
      ...this.support.supportImmobilier.chargeIntegre.serializeCharge(),
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais,
      difLoyer: this.support.supportImmobilier.choixImmobilier.differe_avant_premier_loyer
    }
  }

  serialiseSupportMalrauxScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }
  // Malraux

  //Debut support Duflot
  serialiseSupportDuflotImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      zone: (typeof (this.support.supportImmobilier.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportImmobilier.choixImmobilier.zone) : this.support.supportImmobilier.choixImmobilier.zone,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      plafondLoyer: this.support.supportImmobilier.choixImmobilier.loyer_maxi,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.dont_montant_reductible,
      aful: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      ...this.support.supportImmobilier.chargeIntegre.serializeCharge(),
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportDuflotScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }
  // pinel

  //Debut support ScellierInterm
  serialiseSupportScellierIntermImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      zone: (typeof (this.support.supportImmobilier.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportImmobilier.choixImmobilier.zone) : this.support.supportImmobilier.choixImmobilier.zone,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      plafondLoyer: this.support.supportImmobilier.choixImmobilier.loyer_maxi,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.dont_montant_reductible,
      aful: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      ...this.support.supportImmobilier.chargeIntegre.serializeCharge(),
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportScellierIntermScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }
  // ScellierInterm

  //Debut support ScellierNeuf
  serialiseSupportScellierNeufImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      zone: (typeof (this.support.supportImmobilier.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportImmobilier.choixImmobilier.zone) : this.support.supportImmobilier.choixImmobilier.zone,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      plafondLoyer: this.support.supportImmobilier.choixImmobilier.loyer_maxi,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.dont_montant_reductible,
      aful: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      ...this.support.supportImmobilier.chargeIntegre.serializeCharge(),
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportScellierNeufScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }
  // ScellierNeuf

  //Debut support RobienRecentre
  serialiseSupportRobienRecentreImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      zone: (typeof (this.support.supportImmobilier.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportImmobilier.choixImmobilier.zone) : this.support.supportImmobilier.choixImmobilier.zone,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      plafondLoyer: this.support.supportImmobilier.choixImmobilier.loyer_maxi,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.dont_montant_reductible,
      aful: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      ...this.support.supportImmobilier.chargeIntegre.serializeCharge(),
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportRobienRecentreScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }
  // RobienRecentre

  //Debut support RobienZRR
  serialiseSupportRobienZRRImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      zone: (typeof (this.support.supportImmobilier.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportImmobilier.choixImmobilier.zone) : this.support.supportImmobilier.choixImmobilier.zone,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      plafondLoyer: this.support.supportImmobilier.choixImmobilier.loyer_maxi,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.dont_montant_reductible,
      aful: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      ...this.support.supportImmobilier.chargeIntegre.serializeCharge(),
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportRobienZRRScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      scpi: true,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }
  // RobienRecentre

  //Debut support Borloo
  serialiseSupportBorlooImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      zone: (typeof (this.support.supportImmobilier.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportImmobilier.choixImmobilier.zone) : this.support.supportImmobilier.choixImmobilier.zone,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      plafondLoyer: this.support.supportImmobilier.choixImmobilier.loyer_maxi,
      produitFiscalNonAmorti: this.support.supportImmobilier.choixImmobilier.dont_montant_reductible,
      aful: this.support.supportImmobilier.choixImmobilier.dont_loyer_droit_comun,
      ...this.support.supportImmobilier.chargeIntegre.serializeCharge(),
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportBorlooScpi() {
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      surface: this.support.supportSCPI.choixImmobilier.surface_totale,
      zone: (typeof (this.support.supportSCPI.choixImmobilier.zone) === "string") ? JSON.parse(this.support.supportSCPI.choixImmobilier.zone) : this.support.supportSCPI.choixImmobilier.zone,
      scpi: true,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }
  // RobienRecentre

  //Debut support ZRR
  serialiseSupportZrrImmo() {
    return {
      ...this.support.serializeAnneFuturCommunImmo(),
      ...this.support.supportImmobilier.choixImmobilier.serializeDataCommunChoixMobilier(),
      difLoyer: this.support.supportImmobilier.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportImmobilier.chargeIntegre.frais_gestion,
      tva: this.support.revenuLocatifImmobillier.montantTvaRecuper.montant,
      tvaFutureList: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper,
      tvaFuture: this.support.revenuLocatifImmobillier.ArrayMontantTvaRecuper.length > 0 ? true : false,
      taxeFonciere: this.support.supportImmobilier.chargeIntegre.montant_taxe_fiscal,
      chargeGarantie: this.support.supportImmobilier.chargeIntegre.garantie_locative,
      chargeCopropriete: this.support.supportImmobilier.chargeIntegre.charge_copropriete,
      pourcExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_a,
      dureeExoneration: this.support.supportImmobilier.chargeIntegre.exoneree_pendant,
      surface: this.support.supportImmobilier.choixImmobilier.surface_totale,
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      estimateurFrais: {
        ...this.support.estimateurFrais.serialise(),
      },
      estimateur: this.support.supportImmobilier.choixImmobilier.estimateurFrais
    }
  }

  serialiseSupportZrrScpi() {
    console.log(this.support.supportImmobilier.choixImmobilier.nombre_part);
    return {
      ...this.support.serializeAnneFuturCommunSCPI(),
      ...this.support.supportSCPI.choixImmobilier.serializeDataCommunChoixMobilier(),
      valMobFoncier: this.support.supportSCPI.choixImmobilier.nombre_part,
      produitFiscalNonAmorti: this.support.supportSCPI.choixImmobilier.valeur_part,
      difLoyer: this.support.supportSCPI.choixImmobilier.differe_avant_premier_loyer,
      fraisGestion: this.support.supportSCPI.chargeIntegre.frais_gestion,
      aful: this.support.supportSCPI.choixImmobilier.duree_scpi,
      taxeFonciere: this.support.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi,
      chargeGarantie: this.support.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.support.supportSCPI.chargeIntegre.frais_sortie,
      surface: this.support.supportSCPI.choixImmobilier.surface_totale,
      scpi: true,
      zoneScpi: this.support.supportSCPI.choixImmobilier.zoneScpi,
    }
  }
  // RobienRecentre
}
export { SolutionFiscale };

