import { ImpotFortuneImmobilier } from "./ImpotFortuneImmobilier";
import { PlafonnementAvantagesFiscaux } from "./PlafonnementAvantagesFiscaux";

class DetailsIFI {
  impotFortuneImmobilier: ImpotFortuneImmobilier;
  plafonnementAvantagesFiscaux: PlafonnementAvantagesFiscaux;

  constructor(
    impotFortuneImmobilier: ImpotFortuneImmobilier = new ImpotFortuneImmobilier(),
    plafonnementAvantagesFiscaux: PlafonnementAvantagesFiscaux = new PlafonnementAvantagesFiscaux()
  ) {
    this.impotFortuneImmobilier = impotFortuneImmobilier;
    this.plafonnementAvantagesFiscaux = plafonnementAvantagesFiscaux;
  }
}

export { DetailsIFI }