class ProduitAdossement {
    nom_produit: string | null;
    duree: number | null;
    taux_capitalisation: number | null;
    taux_frais_entree: number | null;
    taux_frais_versement: number | null;
    rachat_echelone: boolean;
    axation_interet: boolean;
    cotisation_tresor: boolean;
    capitalisation_tva: boolean;
    id: number | null;

    constructor() {
        this.nom_produit = null;
        this.duree = null;
        this.taux_capitalisation = null;
        this.taux_frais_versement = null;
        this.taux_frais_entree = null;
        this.rachat_echelone = false;
        this.axation_interet = false;
        this.cotisation_tresor = false;
        this.capitalisation_tva = false;
        this.id = null;
    }

    resetInput() {
        this.nom_produit = null;
        this.duree = null;
        this.taux_capitalisation = null;
        this.taux_frais_versement = null;
        this.taux_frais_entree = null;
        this.rachat_echelone = false;
        this.axation_interet = false;
        this.cotisation_tresor = false;
        this.capitalisation_tva = false;
    }

}
export { ProduitAdossement };
