import { appConstants } from "../_constants";

class Dossier {
    nom: String;
    address1: String;
    address2: String;
    codePostal: number|null;
    telephone: string|null;
    profession: String;
    date_naissance: Date|null;
    isNom: boolean |null;
    isTelephone: boolean |null;
    id: number | null;

    constructor(){
        this.nom = '';
        this.address1 = '';
        this.address2 = '';
        this.codePostal = null;
        this.telephone = null;
        this.profession = '';
        this.date_naissance = null;
        this.isNom = null;
        this.isTelephone = null;
        this.id = null;
    }

    onChange(e:any){
        const { id, value } = e.target;
        let res = true;
        console.log(id, value);
        switch (id) {
            case appConstants.DOSSIER_KEY.NOM_CLIENT:
            this.nom = value;
            this.isNom = (value!== "")? true:false
            break;
            case appConstants.DOSSIER_KEY.ADDRESS1:
            this.address1 = value;
            break;
            case appConstants.DOSSIER_KEY.ADDRESS2:
            this.address2 = value;
            break;
            case appConstants.DOSSIER_KEY.CODE_POSTAL:
            this.codePostal = value;
            break;
            case appConstants.DOSSIER_KEY.TELEPHONE:
            this.telephone = value;
            this.isTelephone = (value!== "")? true:false
            break;
            case appConstants.DOSSIER_KEY.PROFESSION:
            this.profession = value;
            break;
            case appConstants.DOSSIER_KEY.DATE_NAISSANCE:
            this.date_naissance = value;
            break;

            default:
            res = false;
            break;
        }
        return res;
    }

    getValue(key: string) {
        switch (key) {
          case appConstants.DOSSIER_KEY.NOM_CLIENT:
            return this.nom;
          case appConstants.DOSSIER_KEY.ADDRESS1:
            return this.address1;  
          case appConstants.DOSSIER_KEY.ADDRESS2:
            return this.address2;  
          case appConstants.DOSSIER_KEY.CODE_POSTAL:
            return this.codePostal;  
          case appConstants.DOSSIER_KEY.TELEPHONE:
            return this.telephone; 
          case appConstants.DOSSIER_KEY.PROFESSION:
            return this.profession; 
          case appConstants.DOSSIER_KEY.DATE_NAISSANCE:
            return this.date_naissance; 
            default:
            break;
        }
      return false;
    }
}

export {Dossier}