import React, { Component } from "react";
import { history, utils } from "../../_helpers";
import { Link } from "react-router-dom";
import { BsPersonFill, BsHouseFill } from "react-icons/bs";
import { FaUserTie, FaEuroSign, FaPowerOff } from "react-icons/fa";
import { PropsFromRedux, connector } from "../../_reducers";
import { Modal } from "react-bootstrap";
import { BibliothequeFinanciere,BibliothequeImmobillier,GestionClient,Compte } from "../dropdown";

const iconProfil = require("../../_assets/images/iconProfil.PNG");
const caretDown = require("../../_assets/images/caretDown.PNG");
const iconHome = require("../../_assets/images/iconHome.PNG");

class Navbar extends Component<PropsFromRedux, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      display: true,
      showModal: false,
      showRetour: true,
      showValide: true,
      data: null,
      size: "",
      title: "Easy Defisc",
    };
    this.handleWindowClick = this.handleWindowClick.bind(this);     
  }


  handleWindowClick(event: any) {
    // const {display} = this.state;
    const { id } = event.target;
    if (id !== "navbar-img1" && id !== "navbar-img2") {
      this.setState({ display: true });
    }
  }

  componentDidMount() {
    window.addEventListener("click", this.handleWindowClick);
  }

  handleClick(e: any) {
    const { display } = this.state;
    this.setState({ display: !display });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClick);
  }

  getModal(): React.ReactNode {
      const { showModal, data, title , size} = this.state;
      return (
          <Modal
            show={showModal}
            onHide={() => this.closeModal()}
            backdrop={true}
            data= {data}
            title = {title}
            dialogClassName={size}
            >
            <Modal.Header closeButton className="custom-modal-header">
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center p-2">{data}</Modal.Body>
            <Modal.Footer>{showModal && this.getFooter()}</Modal.Footer>
          </Modal>
      );
  }

  closeModal( ) {
      this.setState({
          showModal: false,
          showOk: true,
          showOuiNon: false,
          title: "Easy Defisc",
      });
  //     const { model } = this.props.Section;
  //    return model.solutionFiscale.support.estimateurFrais;
  }

  getFooter() {
    return (
      <div className="w-100 d-flex justify-content-end">
        
        <button
          className="btn font-weight-light text-white bg-orange text-uppercase"
          style={{ fontSize: "80%" }}
          onClick={() => this.closeModal()}
        >
          ok
        </button>
      </div>
    );
  }

  openPopup(val: string){
    
    if (val === "immobillier") {
      return this.setState({
        showModal: true,   
        title: "Bibliotheque Immobiliere",
        size: "modal-90w",
        data: <BibliothequeImmobillier/>,
      });
    } else if (val === "finance") {
      return this.setState({
        showModal: true,   
        title: "Bibliotheque Financière",
        size: "modal-90w",
        data: <BibliothequeFinanciere/>,
      });
    }
    else if (val === "compte") {
      return this.setState({
        showModal: true,   
        title: "Mon Compte",
        size: "",
        data: <Compte/>,
      });
    }else if (val === "client") {
      return this.setState({
        showModal: true,   
        title: "Gestion du Client",
        size: "modal-90w",
        data: <GestionClient/>,
      });
    }
  }


  render() {
    const { display } = this.state;
    const { model } = this.props;
    const name = model.auth.loginAuth.utilisateur.prenom+" "+model.auth.loginAuth.utilisateur.nom;
    return (
      <div className="navMain">
        <nav id="main-navbar" className="navbar d-flex justify-content-between">
          <div className="left-navbar-content d-flex justify-content-between align-items-center col-md-4">
            <Link
              to="/accueil"
              className="navbar-brand font-weight-bold"
            >
              Easy Defisc
            </Link>
            {utils().isSituation() && (
              <p id="navbar-subtile" className="font-weight-light text-white">
                Situation Fiscale
              </p>
            )}
             {utils().isSolution() && (
              <p id="navbar-subtile" className="font-weight-light text-white">
                Solution Fiscale
              </p>
            )}
              {utils().isSynthese() && (
              <p id="navbar-subtile" className="font-weight-light text-white">
                Résultat de la défiscalisation
              </p>
            )}
          </div>
          <div className="right-navbar-content d-flex justify-content-between align-items-center col-md-4">
            {(utils().isSolution() || utils().isSituation() || utils().isSynthese()) && (
              <ul className="navbar-nav">
                <li className="navbar-item d-flex align-items-center" onClick={(e) =>{model.isUpdateModel = false; model.reinitialiseBaseModel(); localStorage.removeItem('nouveauDossier'); history.push("/accueil") }}>
                  <img src={iconHome} alt="profile" />
                  Accueil
                </li>
              </ul>
            )}
            {!utils().isSolution() && !utils().isSituation() && !utils().isSynthese() && (
              <p id="navbar-subtile" className="font-weight-light text-white">
                Bienvenue {name}
              </p>
            )}
            <div className="profile-group">
              <div className="profile-pic">
                <img
                  src={iconProfil}
                  alt="profile"
                  id="navbar-img1"
                  onClick={(e) => this.handleClick(e)}
                />
                <img
                  src={caretDown}
                  alt="caret-down"
                  id="navbar-img2"
                  onClick={(e) => this.handleClick(e)}
                />
              </div>
            </div>
          </div>
        </nav>

        {/* dropdown */}
        <div
          className="dropdown-user mt-2"
          style={{ display: display ? "none" : "block" }}
        >
          <div className="d-flex align-items-center dropdow-item" onClick={()=>this.openPopup("compte")}>
            <div className="icon mr-2 ">
              <BsPersonFill />
            </div>
            <div className="text-content">Mon Compte</div>
          </div>

          <div className="d-flex align-items-center dropdow-item" onClick={()=>this.openPopup("client")}>
            <div className="icon mr-2 ">
              <FaUserTie />
            </div>
            <div className="text-content">Gestion client</div>
          </div>

          <div className="d-flex align-items-center dropdow-item"  onClick={()=>this.openPopup("immobillier")} >
            <div className="icon mr-2 ">
              <BsHouseFill />
            </div>
            <div className="text-content">Bibliotheque immobilière</div>
          </div>

          <div className="d-flex align-items-center dropdow-item"  onClick={()=>this.openPopup("finance")}>
            <div className="icon mr-2 ">
              <FaEuroSign />
            </div>
            <div className="text-content">Bibliotheque Financière</div>
          </div>

          <div
            className="d-flex align-items-center dropdow-item"
            onClick={() => this.deconnexion()}
          >
            <div className="icon mr-2 ">
              <FaPowerOff />
            </div>
            <div className="text-content">Déconnexion</div>
          </div>
        </div>
        {this.getModal()}
      </div>
    );
  }
  getValue(): any {
    const { model } = this.props;
    return model.navBarEmpty;
  }

  async deconnexion(){
    const { model,replaceModel } = this.props;
    model.loading = true;
    replaceModel(model);
    let dataAuth = {
      ...model.auth.loginAuth.utilisateur.currentUser(),
      adresseIp: await model.auth.loginAuth.getIp()
    };
    console.log(dataAuth);
    let res = await model.logout(dataAuth);
    model.loading = false;
    replaceModel(model);
    if(!res){
      this.setState({
        showModal: true,   
        title: "Easy Defisc",
        size: "",
        data: "Une erreur est survenue, Rééssayez",
      })
    }else{
      localStorage.removeItem("userData");
      localStorage.removeItem('nouveauDossier');
      model.auth.loginAuth.reinitialisationAuth();
      replaceModel(model);
      history.replace("/")
    }
  }
}
const connected = connector(Navbar);
export { connected as Navbar };
