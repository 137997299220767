import { appConstants } from "../_constants";
import { AppelFond } from "./AppelFond";

class EstimateurFrais {
  duree_construction: number | null;
  taux_interet_credit: number | null;
  taux_frais_garantie: number | null;
  neuf: boolean;
  ancien: boolean;
  montant_lot: number | null;
  estimation_frais_notaire: number | null;
  montant_int: number | null;
  montant_frais_garantie: number | null;
  appelFonds: Array<AppelFond>;
  totalPourcent: number;
  id: number | null;
  modifierFraisNotaire: boolean;
  montantFraisDeductibleTotal: number | null;
  nbLot: number | null;
  produitFiscalTaux: number | null;
  dateLivraison: Date | null;
  loyerMensuel: number | null;
  loyerMensuelRevalTaux: number | null;
  montantTotalLot: number | null;
  eventEditChamp: boolean;
  montantRecupTva: number | null;

  constructor() {
    this.duree_construction = null;
    this.taux_interet_credit = null;
    this.taux_frais_garantie = 2.02;
    this.neuf = true;
    this.ancien = false;
    this.montant_lot = null;
    this.estimation_frais_notaire = null;
    this.montant_int = null;
    this.montant_frais_garantie = null;
    this.appelFonds = [
      new AppelFond(null, 0),
      new AppelFond(null, 0),
      new AppelFond(null, 0),
      new AppelFond(null, 0),
      new AppelFond(null, 0),
      new AppelFond(null, 0),
      new AppelFond(null, 0),
    ];
    this.totalPourcent = 0;
    this.id = null;
    this.modifierFraisNotaire = false;
    this.montantFraisDeductibleTotal = null;
    this.nbLot = 1;
    this.produitFiscalTaux = null;
    this.dateLivraison = null;
    this.loyerMensuel = null;
    this.loyerMensuelRevalTaux = null;
    this.montantTotalLot = null;
    this.eventEditChamp = false;
    this.montantRecupTva = null;
  }

  getValue(key: string) {
    switch (key) {
      case appConstants.ESTIMATION.DUREE_DE_CONSTRUCTION:
        return this.duree_construction;
      case appConstants.ESTIMATION.TAUX_INTERET_CREDIT:
        return this.taux_interet_credit;
      case appConstants.ESTIMATION.TAUX_FRAIS_GARANTIE:
        return this.taux_frais_garantie;
      case appConstants.ESTIMATION.NEUF:
        return this.neuf;
      case appConstants.ESTIMATION.ANCIEN:
        return this.ancien;
      case appConstants.ESTIMATION.MONTANT_LOT:
        return this.montant_lot;
      case appConstants.ESTIMATION.ESTIMATION_FRAIS_NOTAIRE:
        return this.estimation_frais_notaire;
      case appConstants.ESTIMATION.MONTANT_INT_INTERCALAIRE:
        return this.montant_int;
      case appConstants.ESTIMATION.MONTANT_FRAIS_GARANTIE:
        return this.montant_frais_garantie;
      case appConstants.ESTIMATION.MODIFIERFRAISNAOTAIRE:
        return this.modifierFraisNotaire;
      case appConstants.SUPPORTS.MONTANT_TVA_RECUPERE:
        return this.montantRecupTva;
      default:
        break;
    }
    return false;
  }

  onChange(e: any) {
    const { id, value, checked } = e.target;
    let res = true;
    switch (id) {
      case appConstants.ESTIMATION.DUREE_DE_CONSTRUCTION:
        this.duree_construction = value;
        break;
      case appConstants.ESTIMATION.TAUX_INTERET_CREDIT:
        this.taux_interet_credit = value;
        break;
      case appConstants.ESTIMATION.TAUX_FRAIS_GARANTIE:
        this.taux_frais_garantie = value;
        break;
      case appConstants.ESTIMATION.NEUF:
        this.neuf = true;
        this.ancien = false;
        break;
      case appConstants.ESTIMATION.ANCIEN:
        this.ancien = true;
        this.neuf = false;
        break;
      case appConstants.ESTIMATION.MONTANT_LOT:
        this.montant_lot = value;
        break;
      case appConstants.ESTIMATION.ESTIMATION_FRAIS_NOTAIRE:
        this.estimation_frais_notaire = value;
        break;
      case appConstants.ESTIMATION.MONTANT_INT_INTERCALAIRE:
        this.montant_int = value;
        break;
      case appConstants.ESTIMATION.MODIFIERFRAISNAOTAIRE:
        this.modifierFraisNotaire = checked;
        break;
      case appConstants.SUPPORTS.MONTANT_TVA_RECUPERE:
        this.montantRecupTva = value;
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  getAppleFondMois(val: any) {
    for (let i = 0; i < this.appelFonds.length; i++) {
      if ("mois" + i === val) {
        return this.appelFonds[i].mois;
      }
    }
  }

  getAppleFondPourcentage(val: any) {
    for (let i = 0; i < this.appelFonds.length; i++) {
      if ("pourcentage" + i === val) {
        return this.appelFonds[i].pourcentage;
      }
    }
  }

  onChangeAppleFondMois(e: any) {
    const { id, value } = e.target;
    for (let i = 0; i < this.appelFonds.length; i++) {
      if ("mois" + i === id) {
        this.appelFonds[i].mois = value;
      }
    }
  }

  onChangeAppleFondPourcentage(e: any) {
    const { id, value } = e.target;
    let somme = 0;
    this.totalPourcent = 0;
    for (let i = 0; i < this.appelFonds.length; i++) {
      if ("pourcentage" + i === id) {
        this.appelFonds[i].pourcentage = value;
      }
      somme = somme + Math.round(this.appelFonds[i].pourcentage);
    }
    this.totalPourcent = somme;
  }

  serialise() {
    return {
      appelDeFondsM1: this.appelFonds[0].mois,
      appelDeFondsM2: this.appelFonds[1].mois,
      appelDeFondsM3: this.appelFonds[2].mois,
      appelDeFondsM4: this.appelFonds[3].mois,
      appelDeFondsM5: this.appelFonds[4].mois,
      appelDeFondsM6: this.appelFonds[5].mois,
      appelDeFondsM7: this.appelFonds[6].mois,
      appelDeFondsP1: this.appelFonds[0].pourcentage,
      appelDeFondsP2: this.appelFonds[1].pourcentage,
      appelDeFondsP3: this.appelFonds[2].pourcentage,
      appelDeFondsP4: this.appelFonds[3].pourcentage,
      appelDeFondsP5: this.appelFonds[4].pourcentage,
      appelDeFondsP6: this.appelFonds[5].pourcentage,
      appelDeFondsP7: this.appelFonds[6].pourcentage,
      dureeConstruc: this.duree_construction,
      id: this.id,
      modifierFraisNotaire: this.modifierFraisNotaire,
      montantFraisDeductibleTotal: this.montantFraisDeductibleTotal,
      nbLot: this.nbLot,
      produitFiscalTaux: this.produitFiscalTaux,
      montantLot: this.montant_lot,
      montantTotalLot: this.montantTotalLot,
      txEmprunt: this.taux_interet_credit,
      txHypo: this.taux_frais_garantie,
      typeAncien: this.ancien,
      typeNeuf: this.neuf,
      valeurFraisGarantie: this.montant_frais_garantie,
      valeurFraisIntercalaire: this.montant_int,
      valeurFraisNotaire: this.estimation_frais_notaire,
      loyerMensuel: this.loyerMensuel,
      loyerMensuelRevalTaux: this.loyerMensuelRevalTaux,
      dateLivraison: this.dateLivraison,
      montantRecupTva: this.montantRecupTva,
    };
  }

  hydrateEstimateur(data: any) {
    this.appelFonds[0].mois = data?.appelDeFondsM1;
    this.appelFonds[1].mois = data?.appelDeFondsM2;
    this.appelFonds[2].mois = data?.appelDeFondsM3;
    this.appelFonds[3].mois = data?.appelDeFondsM4;
    this.appelFonds[4].mois = data?.appelDeFondsM5;
    this.appelFonds[5].mois = data?.appelDeFondsM6;
    this.appelFonds[6].mois = data?.appelDeFondsM7;
    this.appelFonds[0].pourcentage = data?.appelDeFondsP1;
    this.appelFonds[1].pourcentage = data?.appelDeFondsP2;
    this.appelFonds[2].pourcentage = data?.appelDeFondsP3;
    this.appelFonds[3].pourcentage = data?.appelDeFondsP4;
    this.appelFonds[4].pourcentage = data?.appelDeFondsP5;
    this.appelFonds[5].pourcentage = data?.appelDeFondsP6;
    this.appelFonds[6].pourcentage = data?.appelDeFondsP7;
    this.id = data?.id;
    this.modifierFraisNotaire = data?.modifierFraisNotaire;
    this.montantFraisDeductibleTotal = data?.montantFraisDeductibleTotal;
    this.nbLot = data?.nbLot;
    this.produitFiscalTaux = data?.produitFiscalTaux;
    this.montant_lot = data?.montantLot;
    this.montantTotalLot = data?.montantTotalLot;
    this.taux_interet_credit = data?.txEmprunt;
    this.taux_frais_garantie = data?.txHypo;
    this.ancien = data?.typeAncien;
    this.neuf = data?.typeNeuf;
    this.montant_frais_garantie = data?.valeurFraisGarantie;
    this.montant_int = data?.valeurFraisIntercalaire;
    this.estimation_frais_notaire = data?.valeurFraisNotaire;
    this.loyerMensuel = data?.loyerMensuel;
    this.loyerMensuelRevalTaux = data?.loyerMensuelRevalTaux;
    this.dateLivraison = data?.dateLivraison;
    this.duree_construction = data?.dureeConstruc;
    this.montantRecupTva = data?.montantRecupTva;

    let somme = 0;
    this.totalPourcent = 0;
    for (let i = 0; i < this.appelFonds.length; i++) {
      somme = somme + Math.round(this.appelFonds[i].pourcentage);
    }
    this.totalPourcent = somme;
  }
}

export { EstimateurFrais };

