import { Devise } from "./Devise";
import { Annee } from "./Annee";
import { BaseAmount } from "./BaseAmount";

class VersementSupprementaire extends BaseAmount {
  nom: string;
  idkey: number | null;
  constructor(
    nom: string = "",
    data?: any
  ) {
    super(data?.id || 0, data?.montant || null, new Devise(), data?.taux || 0, new Annee());
    this.nom = nom;
    this.idkey = data?.id || null;
  }
}

export { VersementSupprementaire }