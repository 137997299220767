import { appConstants } from "../_constants";
import { Declarant, QuotientFamilial } from "../_entities";
import { BaseModel } from "../_models";

export const appActions = {
  replaceModel,
  closeModal,
  openModal,
  initAnneFuturRNI,
  initAnneFuturBenefice,
  initAnneFuturPartFiscale,
  onInputChange,
  onInputBlur,
  initResultatFiscale,
  changeSection,
  changeSectionSolution,
  initAnneFuturFoyer1,
};

function replace(model?: BaseModel) {
  if (model) {
    return {
      type: appConstants.REPLACE_MODEL_REQUEST,
      model: model.clone()
    };
  } else {
    return {
      type: appConstants.REPLACE_MODEL_REQUEST,
      model: BaseModel.getInstance().clone(),
    };
  }
}

function replaceModel(model?: BaseModel) {
  return async function (dispatch: any) {
    dispatch(replace(model));
  };
}

function openModal(model: BaseModel) {
  return async function (dispatch: any) {
    model.isModalOpen = true;
    dispatch(replace(model));
  };
}
function changeSection(model: BaseModel, key: string) {
  return async function (dispatch: any) {
    model?.situationFiscale?.changeSection(key);
    dispatch(replace(model));
  };
}

function changeSectionSolution(model: BaseModel, key: string, flag: boolean) {
  return async function (dispatch: any) {
    model?.solutionFiscale?.changeSection(key, flag);
    dispatch(replace(model));
  };
}

function closeModal(model: BaseModel, flag?: boolean) {
  if (typeof model.modal?.provider?.onModalClose === "function") {
    model.modal.provider.onModalClose(flag, model.modal.mode);
  } else if (typeof model.modal?.data?.onModalClose === "function") {
    model.modal.data.onModalClose(flag, model.modal.mode);
  } else if (typeof model.modal?.data?.declarant?.onModalClose === "function") {
    model.modal.data?.declarant?.onModalClose(flag, model.modal.mode);
  } else if (typeof model.modal?.data?.quotientFamilial?.onModalClose === "function") {
    model.modal.data?.quotientFamilial?.onModalClose(flag, model.modal.mode);
  }
  return async function (dispatch: any) {
    model.isModalOpen = false;
    model.modal.showOk = false;
    model.activeRefreshModel = true;
    dispatch(replace(model));
  };
}

function initAnneFuturRNI(model: BaseModel, declarant: Declarant) {
  return async function (dispatch: any) {
    model.loading = true;
    model.eventUpdateDossier = true;
    dispatch(replace(model));
    await declarant.initRevenuNetImposables();
    model.isModalOpen = true;
    model.loading = false;
    model.modal.data = { declarant };
    model.modal.size = "lg";
    model.modal.showCancel = true;
    model.modal.mode = appConstants.ANNEE_FUTURE_REVENU_NET_IMPOSABLE;
    model.modal.title = `Revenu Net imposable du ${declarant.nom} sur les 20 prochaines années`;
    setTimeout(() => {
      dispatch(replace(model));
    }, 1000);
  };
}

function initAnneFuturBenefice(model: BaseModel, declarant: Declarant) {
  return async function (dispatch: any) {
    model.loading = true;
    model.eventUpdateDossier = true;
    dispatch(replace(model));
    await declarant.initBenefices();
    model.isModalOpen = true;
    model.loading = false;
    model.modal.data = { declarant };
    model.modal.size = "lg";
    model.modal.showCancel = true;
    model.modal.mode = appConstants.ANNEE_FUTURE_BENEFICE;
    model.modal.title = `Bénéfice du ${declarant.nom} sur les 20 prochaines années`;
    setTimeout(() => {
      dispatch(replace(model));
    }, 1000);
  };
}

function initAnneFuturPartFiscale(
  model: BaseModel,
  quotientFamilial: QuotientFamilial
) {
  model.eventUpdateDossier = true;
  return async function (dispatch: any) {
    model.loading = true;
    model.eventUpdateDossier = true;
    model.situationFiscale.revenu.newCalcule = true;
    dispatch(replace(model));
    await quotientFamilial.initNombrePartFiscales();
    model.isModalOpen = true;
    model.loading = false;
    model.modal.data = { quotientFamilial };
    model.modal.size = "";
    model.modal.showCancel = true;
    model.modal.mode = appConstants.ANNEE_FUTURE_NOMBRE_PART_FISCALE;
    model.modal.title = `Nombre de part fiscale sur 20 ans`;
    setTimeout(() => {
      dispatch(replace(model));
    }, 1000);
  };
}

function initAnneFuturFoyer1(model: BaseModel, declarant: Declarant) {
  return async function (dispatch: any) {
    model.loading = true;
    dispatch(replace(model));
    await declarant.initRevenuNetImposables();
    model.eventUpdateDossier = true;
    model.isModalOpen = true;
    model.loading = false;
    model.modal.data = { declarant };
    model.modal.size = "lg";
    model.modal.showCancel = true;
    model.modal.mode = appConstants.ANNEE_FUTURE_REVENU_NET_IMPOSABLE;
    model.modal.title = `Resultat foncier existant`;
    setTimeout(() => {
      dispatch(replace(model));
    }, 1000);
  };
}

function initResultatFiscale(model: BaseModel) {
  return async function (dispatch: any) {
    model.loading = true;
    model.eventUpdateDossier = true;
    dispatch(replace(model));
    model.isModalOpen = true;
    model.loading = false;
    model.modal.data = {};
    model.modal.showCancel = false;
    model.modal.size = "lg";
    model.modal.mode = appConstants.RESULTAT_FISCALE;
    model.modal.title = `Résultat fiscal sur les 10 dernières années`;
    setTimeout(() => {
      dispatch(replace(model));
    }, 1000);
  };
}

function onInputChange(model: BaseModel, handler: any, e: any) {
  return async function (dispatch: any) {
    if (typeof handler?.onChange === "function") {
      if (handler.onChange(e)) {
        if (model.situationFiscale.activeSection === "revenus" && model.isModalOpen === false) {
          model.situationFiscale.revenu.newCalcule = true;
        }
        dispatch(replace(model));
      }
    }
  };
}

function onInputBlur(model: BaseModel, handler: any, e: any) {
  return async function (dispatch: any) {
    if (typeof handler?.onBlur === "function") {
      if (handler.onBlur(e)) {
        dispatch(replace(model));
      }
    }
  };
}

