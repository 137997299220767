import React, { Component } from "react";
import { connect } from "react-redux";
import { appConstants } from "../../../_constants";
import { appActions } from "../../../_actions";

class Objectif extends Component<any> {
  render() {
    const { declarant } = this.props;
    return (
      <div className="declarant-wrapper px-2">
        <h1 style={{ textAlign: "left" }}>
          <span className="px-3 big-text font-weight-bold">{declarant?.nom}</span>
        </h1>

        <div className="container-fluid">
          <div className="row">
            <div className="custom-control custom-checkbox col-lg-4 mx-3 mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id={appConstants.OBJECTIF_KEY.REDUIRE_IMPOTS}
                value={this.getValue(appConstants.OBJECTIF_KEY.REDUIRE_IMPOTS)}
                defaultChecked={(this.getValue(appConstants.OBJECTIF_KEY.REDUIRE_IMPOTS) as boolean)}
                onChange={(e) => this.setValue(e)}
              />
              <label
                className="custom-control-label text-dark-blue font-weight-light"
                htmlFor={appConstants.OBJECTIF_KEY.REDUIRE_IMPOTS}
              >
                Réduire ses impôts
              </label>
            </div>
            <div className="custom-control custom-checkbox col-lg-4 mx-3 mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id={appConstants.OBJECTIF_KEY.CONSTITER_PATRIMOINE}
                value={this.getValue(appConstants.OBJECTIF_KEY.CONSTITER_PATRIMOINE)}
                defaultChecked={(this.getValue(appConstants.OBJECTIF_KEY.CONSTITER_PATRIMOINE) as boolean)}
                onChange={(e) => this.setValue(e)}
              />
              <label
                className="custom-control-label text-dark-blue font-weight-light"
                htmlFor={appConstants.OBJECTIF_KEY.CONSTITER_PATRIMOINE}
              >
                Se constituer son patrimone
              </label>
            </div>
            <div className="custom-control custom-checkbox col-lg-4 mx-3 mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id={appConstants.OBJECTIF_KEY.PROTEGER_FAMILLE}
                value={this.getValue(appConstants.OBJECTIF_KEY.PROTEGER_FAMILLE)}
                defaultChecked={(this.getValue(appConstants.OBJECTIF_KEY.PROTEGER_FAMILLE) as boolean)}
                onChange={(e) => this.setValue(e)}
              />
              <label
                className="custom-control-label text-dark-blue font-weight-light"
                htmlFor={appConstants.OBJECTIF_KEY.PROTEGER_FAMILLE}
              >
                Protéger sa famille
              </label>
            </div>
            <div className="custom-control custom-checkbox col-lg-4 mx-3 mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id={appConstants.OBJECTIF_KEY.COMPLETER_RETRAITRE}
                value={this.getValue(appConstants.OBJECTIF_KEY.COMPLETER_RETRAITRE)}
                defaultChecked={(this.getValue(appConstants.OBJECTIF_KEY.COMPLETER_RETRAITRE) as boolean)}
                onChange={(e) => this.setValue(e)}
              />
              <label
                className="custom-control-label text-dark-blue font-weight-light"
                htmlFor={appConstants.OBJECTIF_KEY.COMPLETER_RETRAITRE}
              >
                Compléter sa retraite
              </label>
            </div>
            <div className="custom-control custom-checkbox col-lg-4 mx-3 mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id={appConstants.OBJECTIF_KEY.PROFITER_INVESTISSEMENT}
                value={this.getValue(appConstants.OBJECTIF_KEY.PROFITER_INVESTISSEMENT)}
                defaultChecked={(this.getValue(appConstants.OBJECTIF_KEY.PROFITER_INVESTISSEMENT) as boolean)}
                onChange={(e) => this.setValue(e)}
              />
              <label
                className="custom-control-label text-dark-blue font-weight-light"
                htmlFor={appConstants.OBJECTIF_KEY.PROFITER_INVESTISSEMENT}
              >
                Profiter de son investissement
              </label>
            </div>
            <div className="custom-control custom-checkbox col-lg-4 mx-3 mt-2">
              <input
                type="checkbox"
                className="custom-control-input"
                id={appConstants.OBJECTIF_KEY.COMPLETER_REVENUE}
                value={this.getValue(appConstants.OBJECTIF_KEY.COMPLETER_REVENUE)}
                defaultChecked={(this.getValue(appConstants.OBJECTIF_KEY.COMPLETER_REVENUE) as boolean)}
                onChange={(e) => this.setValue(e)}
              />
              <label
                className="custom-control-label text-dark-blue font-weight-light"
                htmlFor={appConstants.OBJECTIF_KEY.COMPLETER_REVENUE}
              >
                Compléter son revenus
              </label>
            </div>
          </div>
          <div className="row mt-1 mx-1 mt-2">
            <p className="col-lg-3 px-0">Durée de l'investissement:</p>

            <div className="custom-control custom-radio col-lg-3">
              <input
                type="radio"
                name="duree_investissement"
                id={appConstants.OBJECTIF_KEY.MOINDE5ANS}
                defaultChecked={(this.getValue(appConstants.OBJECTIF_KEY.MOINDE5ANS) as boolean)}
                onChange={(e) => this.setValue(e)}
                className="custom-control-input"
              />
              <label
                className="custom-control-label text-dark-blue font-weight-light"
                htmlFor={appConstants.OBJECTIF_KEY.MOINDE5ANS}
              >
                Moins de 5 ans
              </label>
            </div>

            <div className="custom-control custom-radio col-lg-3">
              <input
                type="radio"
                name="duree_investissement"
                id={appConstants.OBJECTIF_KEY.DE5A9ANS}
                defaultChecked={(this.getValue(appConstants.OBJECTIF_KEY.DE5A9ANS) as boolean)}
                onChange={(e) => this.setValue(e)}
                className="custom-control-input"
              />
              <label
                className="custom-control-label text-dark-blue font-weight-light"
                htmlFor={appConstants.OBJECTIF_KEY.DE5A9ANS}
              >
                De 5 ans à 9 ans
              </label>
            </div>

            <div className="custom-control custom-radio col-lg-3 ">
              <input
                type="radio"
                name="duree_investissement"
                id={appConstants.OBJECTIF_KEY.PLUSDE9ANS}
                defaultChecked={(this.getValue(appConstants.OBJECTIF_KEY.PLUSDE9ANS) as boolean)}
                onChange={(e) => this.setValue(e)}
                className="custom-control-input"
              />
              <label
                className="custom-control-label text-dark-blue font-weight-light"
                htmlFor={appConstants.OBJECTIF_KEY.PLUSDE9ANS}
              >
                9 ans ou plus
              </label>
            </div>
          </div>
        </div>
        <div className="d-flex mt-1 justify-content-start w-100 mb-2 mx-3">
          <label
            className="text-dark-blue font-weight-light mr-2"
          >
            Protection déja en place pour y arriver
          </label>

          <div className="d-flex border-light-gray w-25  align-items-center px-1">
            <input 
              type="text"
              id={appConstants.OBJECTIF_KEY.PROTECTION_EN_PLACE}
              value={this.getValue(appConstants.OBJECTIF_KEY.PROTECTION_EN_PLACE)} 
              onChange={(e) => this.setValue(e)}
              className="form-control2 flex-1 mr-1" 
            />
          </div>
        </div>
      </div>
    );
  }

  getValue(key: string): any {
    const { model } = this.props;
    return model.situationFiscale.client.objectifRechercher.getValue(key);
  }

  setValue(e: any): any {
    const { model, replaceModel } = this.props;
    const res = model.situationFiscale.client.objectifRechercher.onChange(e);
    if (res) {
      model.eventUpdateDossier = true;
      replaceModel(model);
    }
  }
}
const mapStateToProps = (state: any) => ({
  model: state.app.model,
});

const dispatchToProps = {
  ...appActions,
};
const connected = connect(mapStateToProps, dispatchToProps)(Objectif);

export { connected as Objectif };
