export const ESTIMATION = {
    DUREE_DE_CONSTRUCTION: "DUREE_DE_CONSTRUCTION",
    TAUX_INTERET_CREDIT: "TAUX_INTERET_CREDIT",
    TAUX_FRAIS_GARANTIE: "TAUX_FRAIS_GARANTIE",
    NEUF: "NEUF",
    ANCIEN: "ANCIEN",
    MONTANT_LOT: "MONTANT_LOT",
    ESTIMATION_FRAIS_NOTAIRE: "ESTIMATION_FRAIS_NOTAIRE",
    MONTANT_INT_INTERCALAIRE: "MONTANT_INT_INTERCALAIRE",
    MONTANT_FRAIS_GARANTIE: "MONTANT_FRAIS_GARANTIE",
    MODIFIERFRAISNAOTAIRE: "MODIFIERFRAISNAOTAIRE",
}