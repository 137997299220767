import React, { Component } from 'react';
import { connector, PropsFromRedux} from "../../../_reducers";
import { appConstants } from "../../../_constants";
import "../../../_assets/scss/array.scss";

const caretup = require("../../../_assets/images/optionIcons/caret-up.png");
const caretdown = require("../../../_assets/images/optionIcons/caret-down.png");

class TableHeader extends Component<PropsFromRedux> {
    render() {
        return (
            <thead>
                <tr>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Nom du produit Immobilier
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.NOM_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.NOM_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Loi defiscalisation
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.LOI_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.LOI_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Année de livraison
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.ANNELIVRAISON_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.ANNELIVRAISON_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                            
                        </div>
                    </th>
                    
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Surface fiscale
                                <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.SURFACEFISCALE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.SURFACEFISCALE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                               
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            zone
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.ZONE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.ZONE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                               
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                                Montant total produit
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.MONTANTTOTAL_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.MONTANTTOTAL_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Loyer mensuel
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.LOYERMENSUEL_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.LOYERMENSUEL_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>    
                        </div>
                    </th>
                    
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Frais de gestion
                        <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.FRAISGESTION_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.FRAISGESTION_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>     
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Frais de garantie
                         <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.FRAISGARANTI_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.FRAISGARANTI_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>            
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Charge de copropriété
                        <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.CHARGECOPROPRIETE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.CHARGECOPROPRIETE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Montant taxe foncière
                               <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.MONTANT_TAXE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.MONTANT_TAXE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div> 
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Exo. taxe foncière
                           <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.EXOTAXE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.EXOTAXE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>     
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "100px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Exo. taxe foncière pendant
                          <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_IMMOBILIER.EXOTAXEPENDANT_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_IMMOBILIER.EXOTAXEPENDANT_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>      
                        </div>
                    </th>
                    
                </tr>
            </thead>
        );
    }

    setValue(e: React.MouseEvent<HTMLImageElement, MouseEvent>): any {
        const { model, replaceModel } = this.props;
        const res = model.onChangeProdImmo(e);
        if (res) {
            replaceModel(model);
        }
    }
}
const connected = connector(TableHeader);
export { connected as TableHeader };