export const MARLAUX= {
    ZPPAUP: "ZPPAUP",
    SECTEUR_SAUVEGARDE: "SECTEUR_SAUVEGARDE",
    TAUX:"TAUX",
    PASSAGE: "PASSAGE",
    REGIME:"REGIME",
    LMNP:"LMNp",
    SUBVENTION:"SUBVENTION",
    DATE_REPARTITION: "DATE_REPARTITION",
    FRAIS_ETABLISSEMENT_LMNP:"FRAIS_ETABLISSEMENT_LMNP",
    FRAIS_COMPTABILITE_ANNUEL:"FRAIS_COMPTABILITE_ANNUEL",
    VALEUR_TERRAIN: "VALEUR_TERRAIN",
    VALEUR_MOBILIER: "VALEUR_MOBILIER",
    TAXE_FONCIERE_EXONEREE: "TAXE_FONCIERE_EXONEREE",
    PENDANT: "PENDANT",
    TAUX_REDUCTION: "TAUX_REDUCTION",
    TVA_OUI: "TVA_OUI",
    TVA_NON: "TVA_NON",
    PUBLIC: "PUBLIC",
    LOCATION: "LOCATION",
    TAUX_REEVALUATION: "TAUX_REEVALUATION",
    ESPACE_VERT_OUI:"ESPACE_VERT_OUI",
    ESPACE_VERT_NON:"ESPACE_VERT_NON",
    ABATTEMENT_FORFAITAIRE:"ABATTEMENT_FORFAITAIRE",
    TAUX_DEDUCTION:"TAUX_DEDUCTION",
    ETALEMENT_REDUCTION:"ETALEMENT_REDUCTION",
    CHARGE_PUBLICITAIRE: "CHARGE_PUBLICITAIRE"
}