import { NombreEnfantCharge } from "./NombreEnfantCharge";
import { NombreEnfantTotal } from "./NombreEnfantTotal";

class NombreEnfant {
  hasAnneeFuturNombreEnfantCharge: boolean;
  hasAnneeFuturNombreEnfantTotal: boolean;
  nombreEnfantCharge: NombreEnfantCharge;
  nombreEnfantTotal: NombreEnfantTotal;
  nombreEnfantCharges: Array<NombreEnfantCharge>;
  nombreEnfantTotals: Array<NombreEnfantTotal>;

  ancienNombreEnfantCharges?: Array<NombreEnfantCharge>;
  ancienNombreEnfantTotals?: Array<NombreEnfantTotal>;

  constructor(
    hasAnneeFuturNombreEnfantCharge: boolean = false,
    hasAnneeFuturNombreEnfantTotal: boolean = false,
    nombreEnfantCharge: NombreEnfantCharge = new NombreEnfantCharge(),
    nombreEnfantTotal: NombreEnfantTotal = new NombreEnfantTotal(),
    nombreEnfantCharges: Array<NombreEnfantCharge> = new Array<NombreEnfantCharge>(),
    nombreEnfantTotals: Array<NombreEnfantTotal> = new Array<NombreEnfantTotal>()
  ) {
    this.hasAnneeFuturNombreEnfantCharge = hasAnneeFuturNombreEnfantCharge;
    this.hasAnneeFuturNombreEnfantTotal = hasAnneeFuturNombreEnfantTotal;
    this.nombreEnfantCharge = nombreEnfantCharge;
    this.nombreEnfantTotal = nombreEnfantTotal;
    this.nombreEnfantCharges = nombreEnfantCharges;
    this.nombreEnfantTotals = nombreEnfantTotals;
  }

  serialize(data:any){
    return{
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  nombreEnfantChargesArraySerialize(){
    if (this.nombreEnfantCharges.length > 0){
      return this.nombreEnfantCharges.map((elt)=>this.serialize(elt))
    }
    return []
  }

  nombreEnfantTotalsArraySerialize(){
    if (this.nombreEnfantTotals.length > 0){
      return this.nombreEnfantTotals.map((elt)=>this.serialize(elt))
    }
    return []
  }
  hydrateArrayModelEnfantCharges(data:Array<any>){
    if(data?.length > 0){
      return data?.map((elt?) => new NombreEnfantCharge(elt))
    }
      return []
  }

  hydrateArrayModelEnfantTotal(data:Array<any>){
    if (data?.length > 0) {
      return data?.map((elt?) => new NombreEnfantTotal(elt))
    }
    return []
  }
}

export { NombreEnfant }