import { RevenueLocatif } from "../_entities/revenueLocatif";
import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { cloneDeep } from "lodash";

class RevenueLocatifs {
  montantRevenueLocatif: RevenueLocatif;
  montantTvaRecuper: RevenueLocatif;
  fraisRestauration: RevenueLocatif;
  dureeScpi: RevenueLocatif;

  hasAnneeFuturRevenueLocatif: boolean;
  hasAnneeFuturMontantTvaRecuper: boolean;
  hasAnneeFuturFraisRestauration: boolean;
  hasAnneeFuturDureeScpi: boolean;

  ArraymontantRevenueLocatif: Array<RevenueLocatif>;
  ArrayMontantTvaRecuper: Array<RevenueLocatif>;
  ArraymontantFraisRestauration: Array<RevenueLocatif>;
  ArraymontantDureeScpi: Array<RevenueLocatif>;

  ancienMontantRevenueLocatif?: Array<RevenueLocatif>;
  ancienMontantFraisRestauration?: Array<RevenueLocatif>;
  ancienMontantTvaRecuper?: Array<RevenueLocatif>;
  ancienMontantDureeScpi?: Array<RevenueLocatif>;

  constructor(
    montantRevenueLocatif: RevenueLocatif = new RevenueLocatif(),
    hasAnneeFuturRevenueLocatif: boolean = false,
    ArraymontantRevenueLocatif: Array<RevenueLocatif> = new Array<RevenueLocatif>(),

    montantTvaRecuper: RevenueLocatif = new RevenueLocatif(),
    hasAnneeFuturMontantTvaRecuper: boolean = false,
    ArrayMontantTvaRecuper: Array<RevenueLocatif> = new Array<RevenueLocatif>(),

    fraisRestauration: RevenueLocatif = new RevenueLocatif(),
    hasAnneeFuturFraisRestauration: boolean = false,
    ArraymontantFraisRestauration: Array<RevenueLocatif> = new Array<RevenueLocatif>(),

    dureeScpi: RevenueLocatif = new RevenueLocatif(),
    hasAnneeFuturDureeScpi: boolean = false,
    ArraymontantDureeScpi: Array<RevenueLocatif> = new Array<RevenueLocatif>(),
  ) {
    this.montantRevenueLocatif = montantRevenueLocatif;
    this.hasAnneeFuturRevenueLocatif = hasAnneeFuturRevenueLocatif;
    this.ArraymontantRevenueLocatif = ArraymontantRevenueLocatif;

    this.montantTvaRecuper = montantTvaRecuper;
    this.hasAnneeFuturMontantTvaRecuper = hasAnneeFuturMontantTvaRecuper;
    this.ArrayMontantTvaRecuper = ArrayMontantTvaRecuper;

    this.fraisRestauration = fraisRestauration;
    this.hasAnneeFuturFraisRestauration = hasAnneeFuturFraisRestauration;
    this.ArraymontantFraisRestauration = ArraymontantFraisRestauration;

    this.dureeScpi = dureeScpi;
    this.hasAnneeFuturDureeScpi = hasAnneeFuturDureeScpi;
    this.ArraymontantDureeScpi = ArraymontantDureeScpi;
  }

  serialize(data: any) {
    return {
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  hydrateArrayRevenueLocatif(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new RevenueLocatif(elt))
    }
    return this.ArraymontantRevenueLocatif
  }

  hydrateArrayTva(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new RevenueLocatif(elt))
    }
    return this.ArrayMontantTvaRecuper
  }

  hydrateArrayFraisSupp(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new RevenueLocatif(elt))
    }
    return this.ArraymontantFraisRestauration
  }

  RevenueLocatifArraySerialize() {
    if (this.ArraymontantRevenueLocatif.length > 0) {
      return this.ArraymontantRevenueLocatif.map((elt) => this.serialize(elt))
    }
    return []
  }

  TvaFutureArraySerialize() {
    if (this.ArrayMontantTvaRecuper.length > 0) {
      return this.ArrayMontantTvaRecuper.map((elt) => this.serialize(elt))
    }
    return []
  }

  fraisRestaurationArraySerialize() {
    if (this.ArraymontantFraisRestauration.length > 0) {
      return this.ArraymontantFraisRestauration.map((elt) => this.serialize(elt))
    }
    return []
  }

  getValue(key: string): any {
    switch (key) {
      case appConstants.SUPPORTS.MONTANT_REVENUE_LOCATIF:
        return this.montantRevenueLocatif.montant;
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF:
        return this.hasAnneeFuturRevenueLocatif;

      case appConstants.SUPPORTS.FRAIS_RESTAURATION:
        return this.fraisRestauration.montant;
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION:
        return this.hasAnneeFuturFraisRestauration;

      case appConstants.SUPPORTS.MONTANT_TVA_RECUPERE:
        return this.montantTvaRecuper.montant;
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE:
        return this.hasAnneeFuturMontantTvaRecuper;
      default:
        break;
    }
  }

  setMontantRevenuLocatif(montant: number) {
    while (this.ArraymontantRevenueLocatif.length) {
      this.ArraymontantRevenueLocatif.pop();
    }
    if (this.ancienMontantRevenueLocatif?.length) {
      while (this.ancienMontantRevenueLocatif.length) {
        this.ancienMontantRevenueLocatif.pop();
      }
    }
    this.hasAnneeFuturRevenueLocatif = false;
    this.montantRevenueLocatif.montant = montant;
  }

  setFraisRestauration(montant: number) {
    while (this.ArraymontantFraisRestauration.length) {
      this.ArraymontantFraisRestauration.pop();
    }
    if (this.ancienMontantFraisRestauration?.length) {
      while (this.ancienMontantFraisRestauration.length) {
        this.ancienMontantFraisRestauration.pop();
      }
    }
    this.hasAnneeFuturFraisRestauration = false;
    this.fraisRestauration.montant = montant;
  }

  setMontantTvaRecuper(montant: number) {
    while (this.ArrayMontantTvaRecuper.length) {
      this.ArrayMontantTvaRecuper.pop();
    }
    if (this.ancienMontantTvaRecuper?.length) {
      while (this.ancienMontantTvaRecuper.length) {
        this.ancienMontantTvaRecuper.pop();
      }
    }
    this.hasAnneeFuturMontantTvaRecuper = false;
    this.montantTvaRecuper.montant = montant;
  }

  onBlur(e: any) {
    return false;
  }

  getLabelConfig(key: string) {
    switch (key) {
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF:
        return {
          leading: this.montantRevenueLocatif.annee.value,
          trailling: this.montantRevenueLocatif.devise.symbole,
          label: true,
          value: this.montantRevenueLocatif.montant,
        };
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION:
        return {
          leading: this.fraisRestauration.annee.value,
          trailling: this.fraisRestauration.devise.symbole,
          label: true,
          value: this.fraisRestauration.montant,
        };
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE:
        return {
          leading: this.montantTvaRecuper.annee.value,
          trailling: this.montantTvaRecuper.devise.symbole,
          label: true,
          value: this.montantTvaRecuper.montant,
        };
      default:
        throw new Error("Invalid label key");
    }
  }

  getItems(key: string) {
    switch (key) {
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF:
        return this.getMontantRevenueLocatif();
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION:
        return this.getMontantFraisRestauration();
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE:
        return this.getMontantTvaRecuperer();
      default:
        throw new Error("Invalid label key");
    }
  }

  getMontantRevenueLocatif() {
    if (this.ancienMontantRevenueLocatif?.length && !this.ArraymontantRevenueLocatif.length) {
      this.ArraymontantRevenueLocatif = cloneDeep(this.ancienMontantRevenueLocatif);
    } else {
      if (this.ArraymontantRevenueLocatif.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.montantRevenueLocatif);
          item.annee.value += i;
          item.id = i;
          this.ArraymontantRevenueLocatif.push(item);
        }
      }
    }
    return this.ArraymontantRevenueLocatif;
  }

  getMontantFraisRestauration() {
    if (this.ancienMontantFraisRestauration?.length && !this.ArraymontantFraisRestauration.length) {
      this.ArraymontantFraisRestauration = cloneDeep(this.ancienMontantFraisRestauration);
    } else {
      if (this.ArraymontantFraisRestauration.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.fraisRestauration);
          item.annee.value += i;
          item.id = i;
          this.ArraymontantFraisRestauration.push(item);
        }
      }
    }
    return this.ArraymontantFraisRestauration;
  }

  getMontantTvaRecuperer() {
    if (this.ancienMontantTvaRecuper?.length && !this.ArrayMontantTvaRecuper.length) {
      this.ArrayMontantTvaRecuper = cloneDeep(this.ancienMontantTvaRecuper);
    } else {
      if (this.ArrayMontantTvaRecuper.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.montantTvaRecuper);
          item.annee.value += i;
          item.id = i;
          this.ArrayMontantTvaRecuper.push(item);
        }
      }
    }
    return this.ArrayMontantTvaRecuper;
  }

  getInputConfig(key: string, item: any, options: any) {
    switch (key) {
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      default:
        throw new Error("Invalid label key");
    }
  }

  getModalTitle(key: string) {
    switch (key) {
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF:
        return "Revenu Locatif pour les 20 prochaines années";
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION:
        return "Frais de restauration pour les 20 prochaines années";
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE:
        return "Montatnt TVA récupérer pour les 20 prochaines années";
      default:
        throw new Error("Invalid label key");
    }
  }

  onModalClose(save: boolean, key?: string) {
    if (save) {
      switch (key) {
        case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF:
          this.ancienMontantRevenueLocatif = cloneDeep(this.ArraymontantRevenueLocatif);
          break;
        case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION:
          this.ancienMontantFraisRestauration = cloneDeep(this.ArraymontantFraisRestauration);
          break;
        case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE:
          this.ancienMontantTvaRecuper = cloneDeep(this.ArrayMontantTvaRecuper);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF:
          if (!this.ancienMontantRevenueLocatif) {
            this.hasAnneeFuturRevenueLocatif = false;
          }
          while (this.ArraymontantRevenueLocatif.length) {
            this.ArraymontantRevenueLocatif.pop();
          }
          break;
        case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION:
          if (!this.ancienMontantFraisRestauration) {
            this.hasAnneeFuturFraisRestauration = false;
          }
          while (this.ArraymontantFraisRestauration.length) {
            this.ArraymontantFraisRestauration.pop();
          }
          break;
        case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE:
          if (!this.ancienMontantTvaRecuper) {
            this.hasAnneeFuturMontantTvaRecuper = false;
          }
          while (this.ArrayMontantTvaRecuper.length) {
            this.ArrayMontantTvaRecuper.pop();
          }
          break;
        default:
          break;
      }
    }
  }

  updateMontantRevenueLocatif(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.ArraymontantRevenueLocatif.length;
    const index = this.ArraymontantRevenueLocatif.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    console.log(value);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.ArraymontantRevenueLocatif[j].montant || 0 : this.montantRevenueLocatif.montant || 0;
        if (value === 0) {
          this.ArraymontantRevenueLocatif[i].montant = montant;
        } else {
          this.ArraymontantRevenueLocatif[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.ArraymontantRevenueLocatif[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.ArraymontantRevenueLocatif[i].montant = Math.round(value);
        this.ArraymontantRevenueLocatif[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateMontantFraisRestauration(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.ArraymontantFraisRestauration.length;
    const index = this.ArraymontantFraisRestauration.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.ArraymontantFraisRestauration[j].montant || 0 : this.fraisRestauration.montant || 0;
        if (value === 0) {
          this.ArraymontantFraisRestauration[i].montant = montant;
        } else {
          this.ArraymontantFraisRestauration[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.ArraymontantFraisRestauration[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.ArraymontantFraisRestauration[i].montant = Math.round(value);
        this.ArraymontantFraisRestauration[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateMontantTvaRecuper(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.ArrayMontantTvaRecuper.length;
    const index = this.ArrayMontantTvaRecuper.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.ArrayMontantTvaRecuper[j].montant || 0 : this.montantTvaRecuper.montant || 0;
        if (value === 0) {
          this.ArrayMontantTvaRecuper[i].montant = montant;
        } else {
          this.ArrayMontantTvaRecuper[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.ArrayMontantTvaRecuper[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.ArrayMontantTvaRecuper[i].montant = Math.round(value);
        this.ArrayMontantTvaRecuper[i].revalorisationAnnuelle = 0;
      }
    }
  }

  checkAnneeFuturChange(e: any): boolean {
    const key = e.target.dataset.key;
    let res = true;
    switch (key) {
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF:
        this.updateMontantRevenueLocatif(e);
        break;
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE:
        this.updateMontantTvaRecuper(e);
        break;
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION:
        this.updateMontantFraisRestauration(e);
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  onChange(e: any): boolean {
    const { id, checked } = e.target;
    let res = true;
    const val = utils().getNumberInputValue(e);
    console.log(id);
    switch (id) {
      case appConstants.SUPPORTS.MONTANT_REVENUE_LOCATIF:
        this.setMontantRevenuLocatif(val);
        break;
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_REVENUE_LOCATIF:
        this.hasAnneeFuturRevenueLocatif = checked;
        break;

      case appConstants.SUPPORTS.MONTANT_TVA_RECUPERE:
        this.setMontantTvaRecuper(val);
        break;
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_MONTANT_TVA_RECUPERE:
        this.hasAnneeFuturMontantTvaRecuper = checked;
        break;

      case appConstants.SUPPORTS.FRAIS_RESTAURATION:
        this.setFraisRestauration(val);
        break;
      case appConstants.SUPPORTS.HAS_ANNEE_FUTUR_FRAIS_RESTAURATION:
        this.hasAnneeFuturFraisRestauration = checked;
        break;
      default:
        const res2 = this.checkAnneeFuturChange(e);
        if (!res2) {
          throw new Error("Invalid label key");
        }
        break;
    }
    return res;
  }
}

export { RevenueLocatifs }