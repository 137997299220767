import React, { Component } from 'react';
import { TableHeader } from "./tableBibliothequeFinanciere/tableHeader";
import { TableBody } from "./tableBibliothequeFinanciere/tableBody";

class BibliothequeFinanciere extends Component {
    render() {
        return (
            <div className="container-fluid ">
                <div className="table-responsive">
                    <div className="table-box mx-3 mt-4 ">
                        <table className="table table-striped ">
                            <TableHeader />
                            <TableBody/>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}
export {BibliothequeFinanciere}

