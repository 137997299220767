import { Devise } from "./Devise";
import { Annee } from "./Annee";

class Benefice {
  id: number | null ;
  idkey: number | null;
  montant: number ;
  ancienMontant: number = 0;
  devise: Devise;
  revalorisationAnnuelle: number;
  ancienRevalorisationAnnuelle: number = 0;
  annee: Annee;

  constructor(data?:any) {
    this.id = data?.id || null;
    this.idkey = data?.id || null;
    this.montant = data?.montant || null;
    this.devise = new Devise();
    this.annee = new Annee();
    this.revalorisationAnnuelle = data?.taux || 0;
  }
}

export { Benefice }