import { Annee } from "./Annee";
import { Devise } from "./Devise";

class RevenuNetImposable {
  id: number;
  idkey: number | null;
  montant: number;
  ancienMontant: number = 0;
  revalorisationAnnuelle: number;
  ancienRevalorisationAnnuelle: number = 0;
  devise: Devise;
  annee: Annee;

  constructor(data?:any) {
    this.id = data?.id ||0;
    this.idkey = data?.id || null;
    this.montant = data?.montant || null;
    this.devise = new Devise();
    this.annee = new Annee();
    this.revalorisationAnnuelle = data?.taux || 0;
  }
}

export { RevenuNetImposable }