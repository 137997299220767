export const ROBIEN= {
    PASSAGE: "PASSAGE",
    REGIME:"REGIME",
    LMNP:"LMNp",
    FRAIS_ETABLISSEMENT_LMNP:"FRAIS_ETABLISSEMENT_LMNP",
    FRAIS_COMPTABILITE_ANNUEL:"FRAIS_COMPTABILITE_ANNUEL",
    VALEUR_TERRAIN: "VALEUR_TERRAIN",
    VALEUR_MOBILIER: "VALEUR_MOBILIER",
    TAXE_FONCIERE_EXONEREE: "TAXE_FONCIERE_EXONEREE",
    PENDANT: "PENDANT"
}