class ChoixImmobilier {
  date_previsionnelle: string | null;
  nom_support: string | null;
  surface_totale: number;
  type: string | null;
  montant_produit: number | null;
  taux_revalorisation: number | null;
  nombre_part: number | null;
  valeur_part: number | null;
  dont_montant_reductible: number | null;
  revenu_locatif_mensuel: number | null;
  revenu_locatif_pourcent: number | null;
  revenu_locatif_tous_les: number | null;
  differe_avant_premier_loyer: number | null;
  frais_suplementaire: number | null;
  dont_loyer_droit_comun: number | null;
  duree_scpi: number | null;
  adhesion_aful: number | null;
  montantTVARecup: number | null;
  frais_restauration: number | null;
  loyer_maxi: number | null;
  zone: any;
  estimateurFrais: boolean;
  zoneScpi: any;

  constructor(
    date_previsionnelle: string = "",
    nom_support: string = "",
    surface_totale: any = "",
    type: string = "",
    montant_produit: any = "",
    taux_revalorisation: any = "",
    nombre_part: any = "",
    valeur_part: any = "",
    dont_montant_reductible: any = "",
    revenu_locatif_mensuel: any = "",
    revenu_locatif_pourcent: any = "",
    revenu_locatif_tous_les: any = "",
    differe_avant_premier_loyer: any = "",
    frais_suplementaire: any = "",
    dont_loyer_droit_comun: any = "",
    duree_scpi: any = "",
    adhesion_aful: any = null,
    montantTVARecup: any = null,
    frais_restauration: any = null,
    zone: any = null,
    zoneScpi: any = null,
  ) {
    this.date_previsionnelle = date_previsionnelle;
    this.differe_avant_premier_loyer = differe_avant_premier_loyer;
    this.dont_loyer_droit_comun = dont_loyer_droit_comun;
    this.dont_montant_reductible = dont_montant_reductible;
    this.duree_scpi = duree_scpi;
    this.frais_suplementaire = frais_suplementaire;
    this.montant_produit = montant_produit;
    this.nom_support = nom_support;
    this.nombre_part = nombre_part;
    this.revenu_locatif_mensuel = revenu_locatif_mensuel;
    this.revenu_locatif_pourcent = revenu_locatif_pourcent;
    this.revenu_locatif_tous_les = revenu_locatif_tous_les;
    this.surface_totale = surface_totale;
    this.taux_revalorisation = taux_revalorisation;
    this.type = type;
    this.valeur_part = valeur_part;
    this.adhesion_aful = adhesion_aful;
    this.montantTVARecup = montantTVARecup;
    this.frais_restauration = frais_restauration;
    this.loyer_maxi = 0;
    this.zone = zone;
    this.estimateurFrais = false;
    this.zoneScpi = zoneScpi;
  }

  serialize() {
    return {
      fraisDeductible: this.frais_suplementaire,
      dateLivraison: this.date_previsionnelle,
      difLoyer: this.differe_avant_premier_loyer,
      nomProdImoSaisie: this.nom_support,
      plafondLoyer: this.loyer_maxi,
      produitFiscalMontant: this.montant_produit,
      // produitFiscalNonAmorti: this.dont_montant_reductible,
      produitFiscalTaux: this.taux_revalorisation,
      surface: this.surface_totale,
      loyerMensuelRevalTaux: this.revenu_locatif_pourcent,
      loyerMensuelRevalAnnuel: this.revenu_locatif_tous_les,
      valMobFoncier: this.nombre_part,
      montantTVARecup: this.montantTVARecup,
      adhesion_aful: this.adhesion_aful,
      dont_montant_reductible: this.dont_montant_reductible,
      revenu_locatif_mensuel: this.revenu_locatif_mensuel,
      zone: JSON.parse(this.zone),
    };
  }

  serializeDataCommunChoixMobilier() {
    return {
      dateLivraison: this.date_previsionnelle,
      nomProdImoSaisie: this.nom_support,
      produitFiscalMontant: this.montant_produit,
     // loyerMensuel: this.revenu_locatif_mensuel,
      loyerMensuelRevalTaux: this.revenu_locatif_pourcent,
      loyerMensuelRevalAnnuel: this.revenu_locatif_tous_les,
      produitFiscalTaux: this.taux_revalorisation,
      fraisDeductible: this.frais_suplementaire,
      nom_support: this.nom_support,
      montant_produit: this.montant_produit,
    };
  }
}
export { ChoixImmobilier };
