import React, { Component } from 'react';
import { connector, PropsFromRedux} from "../../../_reducers";

import { appConstants } from "../../../_constants";
import "../../../_assets/scss/array.scss";

const caretup = require("../../../_assets/images/optionIcons/caret-up.png");
const caretdown = require("../../../_assets/images/optionIcons/caret-down.png");

class TableHeader extends Component<PropsFromRedux> {
    render() {
        return (
            <thead>
                <tr>
                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Nom du produit de financement
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.NOM_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.NOM_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Type emprunt
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.TYPEEMPRUNT_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.TYPEEMPRUNT_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Durée emprunt
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.DUREE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.DUREE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                            
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Taux d'emprunt
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.TAUXEMPRUNT_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.TAUXEMPRUNT_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                            
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Ass. décés inv. pour 10000€
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.ASSURANCE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.ASSURANCE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                            
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Différé rembours. du capital emprunté
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.DIFFERECAPITAL_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.DIFFERECAPITAL_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                                
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Nbre mois du différé du capital 
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.DIFFERECAPITALMOIS_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.DIFFERECAPITALMOIS_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                                
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Différé rembours. des intérêts d'emprunt
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.DIFFEREINTERET_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.DIFFEREINTERET_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", width: "150px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Nbre mois du différé des intérêts
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.DIFFERETINTERETMOIS_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.DIFFERETINTERETMOIS_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </th>
                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                        <div className="d-flex justify-content-center align-items-center">
                            Taux capitalisation
                            <div className="d-flex flex-column ml-1">
                                <img src={caretup} alt="sort asc " id={appConstants.SORT_FISCALE.TAUXCAPITAL_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_FISCALE.TAUXCAPITAL_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                            </div>
                        </div>
                    </th>
                </tr>
            </thead>
        );
    }

    setValue(e: React.MouseEvent<HTMLImageElement, MouseEvent>): any {
        const { model, replaceModel } = this.props;
        const res = model.onChangeProdFisc(e);
        if (res) {
            replaceModel(model);
        }
    }
}
const connected = connector(TableHeader);
export {connected as TableHeader};

