import { ImpotPaye } from "./ImpotPaye";
import { TauxMarginaleImposition } from "./TauxMarginaleImposition";
import { ISF } from "./ISF";
import { TauxEndettement } from "./TauxEndettement";
import { Annee } from "./Annee";

class ResultatFiscale {
  id: number| null;
  impotPaye: ImpotPaye;
  tauxMarginaleImposition: TauxMarginaleImposition;
  tauxEndettement: TauxEndettement;
  isf: ISF;

  anneeImpotPaye: Annee;
  anneeTrancheMarginal: Annee;
  anneeTaux: Annee;
  anneeISF: Annee;

  constructor(
    id = null,
    impotPaye: ImpotPaye = new ImpotPaye(),
    tauxMarginaleImposition: TauxMarginaleImposition = new TauxMarginaleImposition(),
    tauxEndettement: TauxEndettement = new TauxEndettement(),
    isf: ISF = new ISF(),
    anneeImpotPaye: Annee = new Annee(0,new Date().getFullYear()+1),
    anneeTrancheMarginal: Annee = new Annee(1,new Date().getFullYear()+1),
    anneeTaux: Annee = new Annee(2,new Date().getFullYear()+1),
    anneeISF: Annee = new Annee(3,new Date().getFullYear()+1),
  ) {
    this.id = id;
    this.impotPaye = impotPaye;
    this.tauxMarginaleImposition = tauxMarginaleImposition;
    this.tauxEndettement = tauxEndettement;
    this.isf = isf;
    this.anneeISF = anneeISF;
    this.anneeTrancheMarginal = anneeTrancheMarginal;
    this.anneeImpotPaye = anneeImpotPaye;
    this.anneeTaux = anneeTaux;
  }

  onChange(e:any){
    const {id, value} = e.target;
    let res = true;
    switch (id) {
      case "IMPOT IMPAYE":
        this.impotPaye.montant = value;
        break;
      case "TAUX D'ENDETTEMENT":
        this.tauxEndettement.pourcentage = value;
        break;
      case "ISF":
        this.isf.montant = value;
        break;
      default:
        res = false;
      break;
    }
    return res;
  }

  getValue(key: any){
    switch (key){
      case "ANNEE IMPOT IMPAYE":
          return this.anneeImpotPaye.value;
      case "IMPOT IMPAYE":
          return this.impotPaye.montant;
      case "ANNEE TAUX D'ENDETTEMENT":
          return this.anneeTaux.value
      case "TAUX D'ENDETTEMENT":
          return this.tauxEndettement.pourcentage
      case "ANNEE ISF":
          return this.anneeISF.value
      case "ISF":
          return this.isf.montant
      case "ANNEE TRANCHE MARGINALE":
          return this.anneeTrancheMarginal.value
      default:
      break  
    }
    return false;
  }

  resultaFiscalFromJson(data:any){
      this.id = data?.id;
      this.impotPaye.montant = data?.impot;
      this.tauxEndettement.pourcentage = data?.tauxEndettement;
      this.tauxMarginaleImposition.pourcentage = data?.trancheMarginalImposition;
      this.isf.montant = data?.impotSurFortune;
  }
}

export { ResultatFiscale }