import { EstimateurFrais } from "../_entities/estimateurFrais";
import { Lot } from "../_entities/lot";
import { RevenueLocatifs } from "./revenueLocatif";
import { SupportImmobilier } from "./SupportImmobilier";
import { SupportSCPI } from "./SupportSCPI";
// import { appConstants } from '../_constants';

class Support {
  supportImmobilier: SupportImmobilier;
  supportSCPI: SupportSCPI;
  suppImmobiliers: Array<SupportImmobilier>;
  //suppSCPIs: Array<SupportSCPI>;
  revenuLocatifImmobillier: RevenueLocatifs;
  revenuLocatifSCPI: RevenueLocatifs;
  estimateurFrais: EstimateurFrais;
  lot: Lot;
  listLot: Array<Lot>;
  indexArray: number | null;
  isAdd: boolean;
  montantAfinancer: number | null;
  // listSupportImmo: Array<any>;

  montantTotalProduit: number | null;
  FraisSupplementaire: number | null;

  constructor(
    suppImmobiliers: Array<SupportImmobilier> = new Array<SupportImmobilier>(),
    //suppSCPIs: Array<SupportSCPI> = new Array<SupportSCPI>(),
    revenuLocatifImmobillier: RevenueLocatifs = new RevenueLocatifs(),
    revenuLocatifSCPI: RevenueLocatifs = new RevenueLocatifs(),
    // listSupportImmo: Array<any> ;
    listLot: Array<Lot> = Array<Lot>()
  ) {
    this.supportImmobilier = new SupportImmobilier();
    this.supportSCPI = new SupportSCPI();
    this.suppImmobiliers = suppImmobiliers;
    //this.suppSCPIs = suppSCPIs;
    this.revenuLocatifImmobillier = revenuLocatifImmobillier;
    this.revenuLocatifSCPI = revenuLocatifSCPI;
    this.estimateurFrais = new EstimateurFrais();
    this.indexArray = null;
    this.listLot = listLot;
    this.lot = new Lot();
    this.isAdd = false;
    this.montantTotalProduit = null;
    this.FraisSupplementaire = null;
    this.montantAfinancer = null;
  }
  resetEstimateur() {
    this.estimateurFrais = new EstimateurFrais();
  }
  resetSupport() {
    this.supportImmobilier.resetSupportImmo();
    this.supportSCPI = new SupportSCPI();
    this.revenuLocatifImmobillier = new RevenueLocatifs();
    this.revenuLocatifSCPI = new RevenueLocatifs();
    this.estimateurFrais = new EstimateurFrais();
    this.indexArray = null;
    this.isAdd = false;
  }

  hydrateListLot(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new Lot(elt));
    }
    return this.listLot;
  }

  serializeLotImmo() {
    return {
      montantAfinancer: this.supportImmobilier.choixImmobilier.montant_produit,
      chargeCopropriete: this.supportImmobilier.chargeIntegre
        .charge_copropriete,
      chargeGarantie: this.supportImmobilier.chargeIntegre.garantie_locative,
      dateLivraison: this.supportImmobilier.choixImmobilier.date_previsionnelle,
      dureeExoneration: this.supportImmobilier.chargeIntegre.exoneree_pendant,
      fraisGestion: this.supportImmobilier.chargeIntegre.frais_gestion,
      id: null,
      loyerMensuel: this.revenuLocatifImmobillier.montantRevenueLocatif.montant,
      nomProdImoSaisie: this.supportImmobilier.choixImmobilier.nom_support,
      pourcExoneration: this.supportImmobilier.chargeIntegre.exoneree_a,
      produitFiscalMontant: this.supportImmobilier.choixImmobilier
        .montant_produit,
      surface: this.supportImmobilier.choixImmobilier.surface_totale,
      zone: (typeof (this.supportSCPI.choixImmobilier.zone) === "string") ? JSON.parse(this.supportSCPI.choixImmobilier.zone) : this.supportSCPI.choixImmobilier.zone,
      taxeFonciere: this.supportImmobilier.chargeIntegre.montant_taxe_fiscal,
      estimateur: this.supportImmobilier.choixImmobilier.estimateurFrais,
      estimateurFrais: {
        ...this.estimateurFrais.serialise(),
      },

    };
  }

  serializeLotSCPI() {
    return {
      montantAfinancer: this.supportSCPI.choixImmobilier.montant_produit,
      dateLivraison: this.supportSCPI.choixImmobilier.date_previsionnelle,
      fraisGestion: this.supportSCPI.chargeIntegre.frais_gestion,
      id: null,
      loyerMensuel: this.revenuLocatifSCPI.montantRevenueLocatif.montant,
      nomProdImoSaisie: this.supportSCPI.choixImmobilier.nom_support,
      produitFiscalMontant: this.supportSCPI.choixImmobilier.montant_produit,
      surface: this.supportSCPI.choixImmobilier.surface_totale,
      taxeFonciere: this.supportSCPI.chargeIntegre
        .franchise_sans_loyer_fin_scpi,
      zone: (typeof (this.supportSCPI.choixImmobilier.zone) === "string") ? JSON.parse(this.supportSCPI.choixImmobilier.zone) : this.supportSCPI.choixImmobilier.zone,
      chargeGarantie: this.supportSCPI.chargeIntegre.frais_entree,
      chargeCopropriete: this.supportSCPI.chargeIntegre.frais_sortie,
      zoneScpi: this.supportSCPI.choixImmobilier.zoneScpi,
    };
  }

  editingFieldBySelectLotImmo(e: any, model: any) {
    const { value } = e.target;
    let loi: any;
    try {
      loi = this.listLot[value].loi;
    } catch (e) {
      loi = "NOT_SELECTED";
    }
    console.log(loi);
    if (loi !== "NOT_SELECTED") {
      switch (loi) {
        case "Pinel":
          this.hydrateSupportPinelImmo(this.listLot[value]);
          break;
        case "LMNP":
          this.hydrateSupportLmnpImmo(this.listLot[value]);
          break;
        case "LMP":
          this.hydrateSupportLmpImmo(this.listLot[value]);
          break;
        case "Demembrement":
          this.hydrateSupportDemembrementImmo(this.listLot[value]);
          break;
        case "Girardin":
          this.hydrateSupportGirardinImmo(this.listLot[value]);
          break;
        case "Monument histo":
          this.hydrateSupportMonumentHistoImmo(this.listLot[value]);
          break;
        case "Denormandie":
          this.hydrateSupportDenormandieImmo(this.listLot[value]);
          break;
        case "Malraux":
          this.hydrateSupportMalrauxImmo(this.listLot[value]);
          break;
        case "Régime général":
          this.hydrateSupportRegimeGeneralImmo(this.listLot[value]);
          break;
        case "ZRR":
          this.hydrateSupportZrrImmo(this.listLot[value]);
          break;
        default:
          this.hydrateSupportPinelImmo(this.listLot[value]);
          break;
      }
      this.indexArray = this.listLot[value]?.id;
    } else {
      this.resetSupport();
      model.solutionFiscale.financement.resetFinancement();
      model.solutionFiscale.placement.resetPlacement();

      /*  this.supportImmobilier.chargeIntegre.charge_copropriete = 0;
       this.supportImmobilier.chargeIntegre.garantie_locative = 0;
       this.supportImmobilier.choixImmobilier.date_previsionnelle = "";
       this.supportImmobilier.chargeIntegre.exoneree_pendant = 0;
       this.supportImmobilier.chargeIntegre.frais_gestion = 0;
       this.supportImmobilier.choixImmobilier.nom_support = "";
       this.supportImmobilier.chargeIntegre.exoneree_a = 0;
       this.supportImmobilier.choixImmobilier.montant_produit = 0;
       this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = 0;
 
       // data commun
       this.supportImmobilier.choixImmobilier.date_previsionnelle = "";
       this.supportImmobilier.choixImmobilier.loyer_maxi = 0;
       this.supportImmobilier.choixImmobilier.montant_produit = 0;
       this.supportImmobilier.choixImmobilier.nom_support = "";
       this.revenuLocatifImmobillier.montantRevenueLocatif.montant = 0;
       this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent = 0;
       this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les = 0;
       this.supportImmobilier.choixImmobilier.frais_suplementaire = 0;
       this.supportImmobilier.choixImmobilier.taux_revalorisation = 0;
       // other data
       this.supportImmobilier.choixImmobilier.estimateurFrais = false;
       this.supportImmobilier.choixImmobilier.dont_montant_reductible = 0;
       this.supportImmobilier.choixImmobilier.dont_loyer_droit_comun = 0;
       this.supportImmobilier.chargeIntegre.charge_copropriete = 0;
       this.supportImmobilier.chargeIntegre.exoneree_pendant = 0;
       this.supportImmobilier.chargeIntegre.exoneree_a = 0;
       this.supportImmobilier.chargeIntegre.garantie_locative = 0;
       this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = 0;
       this.supportImmobilier.chargeIntegre.frais_gestion = 0;
       this.supportImmobilier.choixImmobilier.surface_totale = 0;
 
       this.montantTotalProduit = 0;
       this.FraisSupplementaire = 0;
       this.montantAfinancer = 0; */
    }
    this.getActiveAjoutSupportImmobilier();
    //  this.indexArray = 0;
  }

  editingFieldBySelectLotSCPI(e: any, model: any) {
    const { value } = e.target;
    let loi: any;
    try {
      loi = this.listLot[value].loi;
    } catch (e) {
      loi = "NOT_SELECTED";
    }
    console.log(loi);
    if (loi !== "NOT_SELECTED") {
      switch (this.listLot[value]?.loi) {
        case "Pinel":
          this.hydrateSupportPinelSCPI(this.listLot[value]);
          break;
        case "LMNP":
          this.hydrateSupportLmnpSCPI(this.listLot[value]);
          break;
        case "LMP":
          this.hydrateSupportLmpSCPI(this.listLot[value]);
          break;
        case "Demembrement":
          this.hydrateSupportDemembrementSCPI(this.listLot[value]);
          break;
        case "Girardin":
          this.hydrateSupportGirardinSCPI(this.listLot[value]);
          break;
        case "Monument histo":
          this.hydrateSupportMonumentHistoSCPI(this.listLot[value]);
          break;
        case "Denormandie":
          this.hydrateSupportDenormandieSCPI(this.listLot[value]);
          break;
        case "Malraux":
          this.hydrateSupportMalrauxSCPI(this.listLot[value]);
          break;
        case "Régime général":
          this.hydrateSupportRegimeGeneralSCPI(this.listLot[value]);
          break;
        case "ZRR":
          this.hydrateSupportZrrScpi(this.listLot[value]);
          break;
        default:
          this.hydrateSupportPinelSCPI(this.listLot[value]);
          break;
      }
      this.indexArray = this.listLot[value]?.id;
    } else {

      this.resetSupport();
      model.solutionFiscale.financement.resetFinancement();
      model.solutionFiscale.placement.resetPlacement();
      this.indexArray = 0;


    }

    this.getActiveAjoutSupportSCPI();
  }

  getActiveAjoutSupportImmobilier() {
    this.supportImmobilier.errDate = this.checkValue(this.supportImmobilier.choixImmobilier.date_previsionnelle);
    this.supportImmobilier.errExonerea = this.checkValueWithoutZero(this.supportImmobilier.chargeIntegre.exoneree_pendant);
    this.supportImmobilier.errExonerependant = this.checkValueWithoutZero(this.supportImmobilier.chargeIntegre.exoneree_pendant);
    this.supportImmobilier.errMontant = this.checkValue(this.supportImmobilier.choixImmobilier.montant_produit);
    this.supportImmobilier.errMontantTaxe = this.checkValue(this.supportImmobilier.chargeIntegre.montant_taxe_fiscal);
    this.supportImmobilier.errRevenueLocatifMenssuel = this.checkValue(this.revenuLocatifImmobillier.montantRevenueLocatif.montant);
    this.supportImmobilier.errRevenueLocatifPourcent = this.checkValueWithoutZero(this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent);
    this.supportImmobilier.errTaux = this.checkValueWithoutZero(this.supportImmobilier.choixImmobilier.taux_revalorisation);

    if (
      this.supportImmobilier.errDate ||
      this.supportImmobilier.errExonerea ||
      this.supportImmobilier.errExonerependant ||
      this.supportImmobilier.errMontant ||
      this.supportImmobilier.errMontantTaxe ||
      this.supportImmobilier.errRevenueLocatifMenssuel ||
      this.supportImmobilier.errRevenueLocatifPourcent ||
      this.supportImmobilier.errTaux
    ) {
      return false;
    } else {
      return true;
    }
  }

  getActiveAjoutSupportSCPI() {

    this.supportSCPI.errDate = this.checkValue(this.supportSCPI.choixImmobilier.date_previsionnelle);
    this.supportSCPI.errMontant = this.checkValue(this.supportSCPI.choixImmobilier.montant_produit);
    this.supportSCPI.errRevenueLocatifMenssuel = this.checkValue(this.revenuLocatifSCPI.montantRevenueLocatif.montant);
    this.supportSCPI.errRevenueLocatifPourcent = this.checkValue(this.supportSCPI.choixImmobilier.revenu_locatif_pourcent);
    this.supportSCPI.errTaux = this.checkValue(this.supportSCPI.choixImmobilier.taux_revalorisation);

    if (
      this.supportSCPI.errDate ||
      this.supportSCPI.errMontant ||
      this.supportSCPI.errRevenueLocatifMenssuel ||
      this.supportSCPI.errRevenueLocatifPourcent ||
      this.supportSCPI.errTaux
    ) {
      return false;
    } else {
      return true;
    }
  }

  serializeAnneFurur() {
    return {
      loyerMensuel: this.revenuLocatifImmobillier.montantRevenueLocatif.montant,
      loyerMensuelFuture:
        this.revenuLocatifImmobillier.ArraymontantRevenueLocatif.length > 0
          ? true
          : false,
      loyerMensuelFutureList: this.revenuLocatifImmobillier.RevenueLocatifArraySerialize(),

      tva: this.revenuLocatifImmobillier.montantTvaRecuper.montant,
      tvaFuture:
        this.revenuLocatifImmobillier.ArrayMontantTvaRecuper.length > 0
          ? true
          : false,
      tvaFutureList: this.revenuLocatifImmobillier.TvaFutureArraySerialize(),

      fraisRestaurationFuture:
        this.revenuLocatifImmobillier.ArraymontantFraisRestauration.length > 0
          ? true
          : false,
      fraisRestaurationFutureList: this.revenuLocatifImmobillier.fraisRestaurationArraySerialize(),
    };
  }



  serializeAnneFuturCommunImmo() {
    return {
      loyerMensuel: this.revenuLocatifImmobillier.montantRevenueLocatif.montant,
      loyerMensuelFuture:
        this.revenuLocatifImmobillier.ArraymontantRevenueLocatif.length > 0
          ? true
          : false,
      loyerMensuelFutureList: this.revenuLocatifImmobillier.RevenueLocatifArraySerialize(),
    };
  }

  serializeAnneFuturCommunSCPI() {
    return {
      loyerMensuel: this.revenuLocatifSCPI.montantRevenueLocatif.montant,
      loyerMensuelFuture:
        this.revenuLocatifSCPI.ArraymontantRevenueLocatif.length > 0
          ? true
          : false,
      loyerMensuelFutureList: this.revenuLocatifSCPI.RevenueLocatifArraySerialize(),
    };
  }

  dataCalculEstimation() {
    if (this.estimateurFrais.ancien == null && this.estimateurFrais.neuf == null) {
      this.estimateurFrais.ancien = false;
      this.estimateurFrais.neuf = true;
    }
    return {
      appelDeFondsM1: this.estimateurFrais.appelFonds[0].mois,
      appelDeFondsM2: this.estimateurFrais.appelFonds[1].mois,
      appelDeFondsM3: this.estimateurFrais.appelFonds[2].mois,
      appelDeFondsM4: this.estimateurFrais.appelFonds[3].mois,
      appelDeFondsM5: this.estimateurFrais.appelFonds[4].mois,
      appelDeFondsM6: this.estimateurFrais.appelFonds[5].mois,
      appelDeFondsM7: this.estimateurFrais.appelFonds[6].mois,
      appelDeFondsP1: this.estimateurFrais.appelFonds[0].pourcentage,
      appelDeFondsP2: this.estimateurFrais.appelFonds[1].pourcentage,
      appelDeFondsP3: this.estimateurFrais.appelFonds[2].pourcentage,
      appelDeFondsP4: this.estimateurFrais.appelFonds[3].pourcentage,
      appelDeFondsP5: this.estimateurFrais.appelFonds[4].pourcentage,
      appelDeFondsP6: this.estimateurFrais.appelFonds[5].pourcentage,
      appelDeFondsP7: this.estimateurFrais.appelFonds[6].pourcentage,
      dureeConstruc: this.estimateurFrais.duree_construction,
      id: this.estimateurFrais.id || null,
      modifierFraisNotaire: this.estimateurFrais.modifierFraisNotaire || false,
      montantFraisDeductibleTotal: this.supportImmobilier.choixImmobilier
        .frais_suplementaire,
      nbLot: this.estimateurFrais.nbLot || 1,
      produitFiscalTaux: this.supportImmobilier.choixImmobilier
        .taux_revalorisation,
      montantLot: this.supportImmobilier.choixImmobilier.montant_produit,
      montantTotalLot: this.estimateurFrais.montantTotalLot,
      txEmprunt: this.estimateurFrais.taux_interet_credit,
      txHypo: this.estimateurFrais.taux_frais_garantie,
      typeAncien: this.estimateurFrais.ancien,
      typeNeuf: this.estimateurFrais.neuf,
      valeurFraisGarantie: this.estimateurFrais.montant_frais_garantie,
      valeurFraisIntercalaire: this.estimateurFrais.montant_int,
      valeurFraisNotaire: this.estimateurFrais.estimation_frais_notaire,
      loyerMensuel: this.revenuLocatifImmobillier.montantRevenueLocatif.montant,
      loyerMensuelRevalTaux: this.supportImmobilier.choixImmobilier
        .revenu_locatif_pourcent,
      dateLivraison: this.supportImmobilier.choixImmobilier.date_previsionnelle,
      montantRecupTva: this.revenuLocatifImmobillier.montantTvaRecuper.montant,
    };
  }

  dataCalculEstimationSCPI() {
    if (this.estimateurFrais.ancien == null && this.estimateurFrais.neuf == null) {
      this.estimateurFrais.ancien = false;
      this.estimateurFrais.neuf = true;
    }
    return {
      appelDeFondsM1: this.estimateurFrais.appelFonds[0].mois,
      appelDeFondsM2: this.estimateurFrais.appelFonds[1].mois,
      appelDeFondsM3: this.estimateurFrais.appelFonds[2].mois,
      appelDeFondsM4: this.estimateurFrais.appelFonds[3].mois,
      appelDeFondsM5: this.estimateurFrais.appelFonds[4].mois,
      appelDeFondsM6: this.estimateurFrais.appelFonds[5].mois,
      appelDeFondsM7: this.estimateurFrais.appelFonds[6].mois,
      appelDeFondsP1: this.estimateurFrais.appelFonds[0].pourcentage,
      appelDeFondsP2: this.estimateurFrais.appelFonds[1].pourcentage,
      appelDeFondsP3: this.estimateurFrais.appelFonds[2].pourcentage,
      appelDeFondsP4: this.estimateurFrais.appelFonds[3].pourcentage,
      appelDeFondsP5: this.estimateurFrais.appelFonds[4].pourcentage,
      appelDeFondsP6: this.estimateurFrais.appelFonds[5].pourcentage,
      appelDeFondsP7: this.estimateurFrais.appelFonds[6].pourcentage,
      dureeConstruc: this.estimateurFrais.duree_construction,
      id: this.estimateurFrais.id || null,
      modifierFraisNotaire: this.estimateurFrais.modifierFraisNotaire || false,
      montantFraisDeductibleTotal: this.supportSCPI.choixImmobilier
        .frais_suplementaire,
      nbLot: this.estimateurFrais.nbLot || 1,
      produitFiscalTaux: this.supportSCPI.choixImmobilier
        .taux_revalorisation,
      montantLot: this.supportSCPI.choixImmobilier.montant_produit,
      montantTotalLot: this.estimateurFrais.montantTotalLot,
      txEmprunt: this.estimateurFrais.taux_interet_credit,
      txHypo: this.estimateurFrais.taux_frais_garantie,
      typeAncien: this.estimateurFrais.ancien,
      typeNeuf: this.estimateurFrais.neuf,
      valeurFraisGarantie: this.estimateurFrais.montant_frais_garantie,
      valeurFraisIntercalaire: this.estimateurFrais.montant_int,
      valeurFraisNotaire: this.estimateurFrais.estimation_frais_notaire,
      loyerMensuel: this.revenuLocatifSCPI.montantRevenueLocatif.montant,
      loyerMensuelRevalTaux: this.supportSCPI.choixImmobilier
        .revenu_locatif_pourcent,
      dateLivraison: this.supportSCPI.choixImmobilier.date_previsionnelle,
      montantRecupTva: this.revenuLocatifSCPI.montantTvaRecuper.montant,
    };
  }

  // recharge des données pour la partie
  // support immobillier et support SCPI

  hydrateSupportPinelImmo(data: any) {
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportImmobilier.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportImmobilier.choixImmobilier.zone = data?.zone;
    this.supportImmobilier.currentZone = data?.zone?.nom;
    // other data
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    this.supportImmobilier.choixImmobilier.dont_montant_reductible = data?.produitFiscalNonAmorti;
    this.supportImmobilier.choixImmobilier.dont_loyer_droit_comun = data?.aful;
    this.supportImmobilier.chargeIntegre.charge_copropriete = data?.chargeCopropriete;
    this.supportImmobilier.chargeIntegre.exoneree_pendant = data?.dureeExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.garantie_locative = data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    // check empty value
    this.supportImmobilier.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportImmobilier.errExonerea = data === null || data?.pourcExoneration === null ? true : false;
    this.supportImmobilier.errExonerependant = data === null || data?.dureeExoneration === null ? true : false;
    this.supportImmobilier.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportImmobilier.errMontantTaxe =
      data === null || data?.taxeFonciere === null ? true : false;
    this.supportImmobilier.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportImmobilier.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportImmobilier.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportPinelSCPI(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportSCPI.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;
    // other data
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi = data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;
    // check empty value
    this.supportSCPI.errDate = data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportDenormandieImmo(data: any) {
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportImmobilier.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    // other data
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    this.supportImmobilier.choixImmobilier.dont_montant_reductible = data?.produitFiscalNonAmorti;
    this.supportImmobilier.choixImmobilier.dont_loyer_droit_comun = data?.aful;
    this.supportImmobilier.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportImmobilier.chargeIntegre.charge_copropriete = data?.chargeCopropriete;
    this.supportImmobilier.choixImmobilier.zone = data?.zone;
    this.supportImmobilier.chargeIntegre.exoneree_pendant = data?.dureeExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.garantie_locative = data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;
    this.supportImmobilier.choixImmobilier.zone = data?.zone;

    // check empty value
    this.supportImmobilier.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportImmobilier.errExonerea = data === null || data?.pourcExoneration === null ? true : false;
    this.supportImmobilier.errExonerependant = data === null || data?.dureeExoneration === null ? true : false;
    this.supportImmobilier.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportImmobilier.errMontantTaxe = data === null || data?.taxeFonciere === null ? true : false;
    this.supportImmobilier.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportImmobilier.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportImmobilier.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportDenormandieSCPI(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportSCPI.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;

    // other data
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi = data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;

    // check empty value
    this.supportSCPI.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent =
      data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportMalrauxImmo(data: any) {
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportImmobilier.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    // other data
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    this.revenuLocatifImmobillier.fraisRestauration.montant = data?.produitFiscalNonAmorti;
    this.revenuLocatifImmobillier.hasAnneeFuturFraisRestauration = data?.fraisRestaurationFuture;
    this.revenuLocatifImmobillier.ArraymontantFraisRestauration = this.revenuLocatifImmobillier.hydrateArrayFraisSupp(data?.fraisRestaurationFutureList);
    this.supportImmobilier.choixImmobilier.adhesion_aful = data?.aful;
    this.supportImmobilier.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportImmobilier.chargeIntegre.charge_copropriete = data?.chargeCopropriete;
    this.supportImmobilier.chargeIntegre.exoneree_pendant = data?.dureeExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.garantie_locative = data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    // check empty value
    this.supportImmobilier.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportImmobilier.errExonerea = data === null || data?.pourcExoneration === null ? true : false;
    this.supportImmobilier.errExonerependant = data === null || data?.dureeExoneration === null ? true : false;
    this.supportImmobilier.errMontant =
      data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportImmobilier.errMontantTaxe = data === null || data?.taxeFonciere === null ? true : false;
    this.supportImmobilier.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportImmobilier.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportImmobilier.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportMalrauxSCPI(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportSCPI.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;

    // other data
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi = data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;

    // check empty value
    this.supportSCPI.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportLmnpSCPI(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportSCPI.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;

    // other data
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi = data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;

    // check empty value
    this.supportSCPI.errDate = data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportLmnpImmo(data: any) {
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportImmobilier.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(data?.loyerMensuelFutureList);
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    // other data
    this.supportImmobilier.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportImmobilier.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportImmobilier.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.revenuLocatifImmobillier.montantTvaRecuper.montant = data?.aful;
    this.supportImmobilier.choixImmobilier.montantTVARecup = data?.aful;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.garantie_locative = data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.charge_copropriete = data?.chargeCopropriete;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_pendant = data?.dureeExoneration;
    this.revenuLocatifImmobillier.ArrayMontantTvaRecuper = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.tvaFuture
    );
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    // check empty value
    this.supportImmobilier.errDate = data === null || data?.dateLivraison === null ? true : false;
    this.supportImmobilier.errExonerea = data === null || data?.pourcExoneration === null ? true : false;
    this.supportImmobilier.errExonerependant = data === null || data?.dureeExoneration === null ? true : false;
    this.supportImmobilier.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportImmobilier.errMontantTaxe = data === null || data?.taxeFonciere === null ? true : false;
    this.supportImmobilier.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportImmobilier.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportImmobilier.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportLmpImmo(data: any) {
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.revenuLocatifImmobillier.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    //this.supportImmobilier.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    // other data
    this.supportImmobilier.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportImmobilier.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportImmobilier.choixImmobilier.differe_avant_premier_loyer =
      data?.difLoyer;
    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.revenuLocatifImmobillier.montantTvaRecuper.montant = data?.aful;
    this.supportImmobilier.choixImmobilier.montantTVARecup = data?.aful;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.garantie_locative = data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.charge_copropriete = data?.chargeCopropriete;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_pendant = data?.dureeExoneration;
    this.revenuLocatifImmobillier.ArrayMontantTvaRecuper = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.tvaFuture
    );
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    // check empty value
    this.supportImmobilier.errDate = data === null || data?.dateLivraison === null ? true : false;
    this.supportImmobilier.errExonerea = data === null || data?.pourcExoneration === null ? true : false;
    this.supportImmobilier.errExonerependant = data === null || data?.dureeExoneration === null ? true : false;
    this.supportImmobilier.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportImmobilier.errMontantTaxe = data === null || data?.taxeFonciere === null ? true : false;
    this.supportImmobilier.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportImmobilier.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportImmobilier.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportLmpSCPI(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.revenuLocatifSCPI.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;
    this.revenuLocatifSCPI.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportSCPI.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;

    // other data
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi = data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;

    // check empty value
    this.supportSCPI.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportMonumentHistoSCPI(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.revenuLocatifSCPI.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;
    this.revenuLocatifSCPI.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportSCPI.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;

    // other data
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi = data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;

    // check empty value
    this.supportSCPI.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportMonumentHistoImmo(data: any) {
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportImmobilier.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;
    this.revenuLocatifImmobillier.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(data?.loyerMensuelFutureList);
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    // other data
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;
    this.revenuLocatifImmobillier.fraisRestauration.montant =
      data?.produitFiscalNonAmorti;
    this.revenuLocatifImmobillier.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    this.revenuLocatifImmobillier.ArraymontantFraisRestauration = this.revenuLocatifImmobillier.hydrateArrayFraisSupp(
      data?.fraisRestaurationFutureList
    );
    this.supportImmobilier.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.revenuLocatifImmobillier.montantTvaRecuper.montant = data?.aful;
    this.supportImmobilier.choixImmobilier.montantTVARecup = data?.aful;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.garantie_locative = data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.charge_copropriete = data?.chargeCopropriete;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_pendant = data?.dureeExoneration;
    this.revenuLocatifImmobillier.ArrayMontantTvaRecuper = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.tvaFuture
    );
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    // check empty value
    this.supportImmobilier.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportImmobilier.errExonerea = data === null || data?.pourcExoneration === null ? true : false;
    this.supportImmobilier.errExonerependant = data === null || data?.dureeExoneration === null ? true : false;
    this.supportImmobilier.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportImmobilier.errMontantTaxe = data === null || data?.taxeFonciere === null ? true : false;
    this.supportImmobilier.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportImmobilier.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportImmobilier.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportGirardinSCPI(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.revenuLocatifSCPI.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    //this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportSCPI.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;
    // other data
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi = data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;

    // check empty value
    this.supportSCPI.errDate = data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportGirardinImmo(data: any){
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant =
      data?.loyerMensuel;
    this.revenuLocatifImmobillier.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    //this.supportImmobilier.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;
    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    // other data
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;
    this.supportImmobilier.choixImmobilier.dont_loyer_droit_comun = data?.difLoyer;
    this.supportImmobilier.choixImmobilier.zone = data?.zone;
    this.revenuLocatifImmobillier.fraisRestauration.montant = data?.produitFiscalNonAmorti;
    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.revenuLocatifImmobillier.montantTvaRecuper.montant = data?.aful;
    this.supportImmobilier.choixImmobilier.montantTVARecup = data?.aful;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.garantie_locative = data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.charge_copropriete = data?.chargeCopropriete;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_pendant = data?.dureeExoneration;
    this.revenuLocatifImmobillier.ArrayMontantTvaRecuper = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.tvaFuture
    );
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    // check empty value
    this.supportImmobilier.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportImmobilier.errExonerea = data === null || data?.pourcExoneration === null ? true : false;
    this.supportImmobilier.errExonerependant = data === null || data?.dureeExoneration === null ? true : false;
    this.supportImmobilier.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportImmobilier.errMontantTaxe = data === null || data?.taxeFonciere === null ? true : false;
    this.supportImmobilier.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportImmobilier.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportImmobilier.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.supportImmobilier.errExonerea = data?.pourcExoneration !== null || data !== null ? false : true;
    this.supportImmobilier.errExonerependant = data?.dureeExoneration !== null || data !== null ? false : true;
    this.supportImmobilier.errMontant = data?.produitFiscalMontant !== null || data !== null ? false : true;
    this.supportImmobilier.errMontantTaxe = data?.taxeFonciere !== null || data !== null ? false : true;
    this.supportImmobilier.errRevenueLocatifMenssuel = data?.loyerMensuel !== null || data !== null ? false : true;
    this.supportImmobilier.errRevenueLocatifPourcent = data?.loyerMensuelRevalTaux !== null || data !== null ? false : true;
    this.supportImmobilier.errTaux = data?.produitFiscalTaux !== null || data !== null ? false : true;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportRegimeGeneralImmo(data: any) {
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant =
      data?.loyerMensuel;
      this.revenuLocatifImmobillier.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    // other data
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;
    this.revenuLocatifImmobillier.fraisRestauration.montant = data?.produitFiscalNonAmorti;
    this.revenuLocatifImmobillier.ArraymontantFraisRestauration = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.fraisRestaurationFutureList
    );
    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.revenuLocatifImmobillier.montantTvaRecuper.montant = data?.aful;
    this.supportImmobilier.choixImmobilier.montantTVARecup = data?.aful;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.garantie_locative = data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.charge_copropriete = data?.chargeCopropriete;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_pendant = data?.dureeExoneration;
    this.supportImmobilier.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.revenuLocatifImmobillier.ArrayMontantTvaRecuper = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.tvaFuture
    );
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    // check empty value
    this.supportImmobilier.errDate = data !== null ? false : true;
    this.supportImmobilier.errExonerea = data !== null ? false : true;
    this.supportImmobilier.errExonerependant = data !== null ? false : true;
    this.supportImmobilier.errMontant = data !== null ? false : true;
    this.supportImmobilier.errMontantTaxe = data !== null ? false : true;
    this.supportImmobilier.errRevenueLocatifMenssuel = data !== null ? false : true;
    this.supportImmobilier.errRevenueLocatifPourcent = data !== null ? false : true;
    this.supportImmobilier.errTaux = data !== null ? false : true;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportRegimeGeneralSCPI(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.revenuLocatifSCPI.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    //this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;
    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportSCPI.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;
    // other data
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer =
      data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi =
      data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;

    // check empty value
    this.supportSCPI.errDate = data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel =
      data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportDemembrementSCPI(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit =
      data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.revenuLocatifImmobillier.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    //this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;

    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent =
      data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les =
      data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire =
      data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation =
      data?.produitFiscalTaux;
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;
    // other data
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer =
      data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi =
      data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;

    // check empty value
    this.supportSCPI.errDate = data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportDemembrementImmo(data: any) {
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit = data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant =
      data?.loyerMensuel;
      this.revenuLocatifImmobillier.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent =
      data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les =
      data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire =
      data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation =
      data?.produitFiscalTaux;
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    // other data
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;
    this.supportImmobilier.choixImmobilier.dont_montant_reductible =
      data?.produitFiscalNonAmorti;
      
    this.revenuLocatifImmobillier.ArraymontantFraisRestauration = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.fraisRestaurationFutureList
    );
    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.revenuLocatifImmobillier.montantTvaRecuper.montant = data?.aful;
    this.supportImmobilier.choixImmobilier.montantTVARecup = data?.aful;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal = data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.garantie_locative = data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.charge_copropriete = data?.chargeCopropriete;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_pendant = data?.dureeExoneration;
    this.supportImmobilier.choixImmobilier.dont_loyer_droit_comun = data?.difLoyer;
    this.revenuLocatifImmobillier.ArrayMontantTvaRecuper = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.tvaFuture
    );
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    this.supportImmobilier.choixImmobilier.adhesion_aful = data?.aful;


    // check empty value
    this.supportImmobilier.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportImmobilier.errExonerea = data === null || data?.pourcExoneration === null ? true : false;
    this.supportImmobilier.errExonerependant = data === null || data?.dureeExoneration === null ? true : false;
    this.supportImmobilier.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportImmobilier.errMontantTaxe = data === null || data?.taxeFonciere === null ? true : false;
    this.supportImmobilier.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportImmobilier.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportImmobilier.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.supportImmobilier.errExonerea = data?.pourcExoneration !== null || data !== null ? false : true;
    this.supportImmobilier.errExonerependant = data?.dureeExoneration !== null || data !== null ? false : true;
    this.supportImmobilier.errMontant = data?.produitFiscalMontant !== null || data !== null ? false : true;
    this.supportImmobilier.errMontantTaxe = data?.taxeFonciere !== null || data !== null ? false : true;
    this.supportImmobilier.errRevenueLocatifMenssuel =
      data?.loyerMensuel !== null || data !== null ? false : true;
    this.supportImmobilier.errRevenueLocatifPourcent = data?.loyerMensuelRevalTaux !== null || data !== null ? false : true;
    this.supportImmobilier.errTaux = data?.produitFiscalTaux !== null || data !== null ? false : true;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportZrrImmo(data: any) {
    // data commun
    this.supportImmobilier.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportImmobilier.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportImmobilier.choixImmobilier.montant_produit =
      data?.produitFiscalMontant;
    this.supportImmobilier.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifImmobillier.hasAnneeFuturRevenueLocatif = data?.loyerMensuelFuture;
    this.revenuLocatifImmobillier.montantRevenueLocatif.montant =
      data?.loyerMensuel;
    this.revenuLocatifImmobillier.ArraymontantRevenueLocatif = this.revenuLocatifImmobillier.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );

    this.revenuLocatifImmobillier.montantTvaRecuper.montant =
      data?.tva;
    this.revenuLocatifImmobillier.ArrayMontantTvaRecuper = this.revenuLocatifImmobillier.hydrateArrayTva(
      data?.tvaFutureList
    );
    this.supportImmobilier.choixImmobilier.revenu_locatif_pourcent =
      data?.loyerMensuelRevalTaux;
    this.supportImmobilier.choixImmobilier.revenu_locatif_tous_les =
      data?.loyerMensuelRevalAnnuel;
    this.supportImmobilier.choixImmobilier.frais_suplementaire =
      data?.fraisDeductible;
    this.supportImmobilier.choixImmobilier.taux_revalorisation =
      data?.produitFiscalTaux;
    this.supportImmobilier.choixImmobilier.estimateurFrais = data?.estimateur;
    this.estimateurFrais.hydrateEstimateur(data?.estimateurFrais);
    // other data
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;
    this.supportImmobilier.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportImmobilier.choixImmobilier.differe_avant_premier_loyer =
      data?.difLoyer;
    this.supportImmobilier.choixImmobilier.duree_scpi = data?.aful;
    this.supportImmobilier.id = data?.id;
    this.supportImmobilier.choixImmobilier.surface_totale = data?.surface;

    this.supportImmobilier.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportImmobilier.chargeIntegre.montant_taxe_fiscal =
      data?.taxeFonciere;
    this.supportImmobilier.chargeIntegre.garantie_locative =
      data?.chargeGarantie;
    this.supportImmobilier.chargeIntegre.charge_copropriete =
      data?.chargeCopropriete;
    this.supportImmobilier.chargeIntegre.exoneree_a = data?.pourcExoneration;
    this.supportImmobilier.chargeIntegre.exoneree_pendant =
      data?.dureeExoneration;
    // check empty value
    this.supportImmobilier.errDate =
      data === null || data?.dateLivraison === null ? true : false;
    this.supportImmobilier.errExonerea = data === null || data?.pourcExoneration === null ? true : false;
    this.supportImmobilier.errExonerependant = data === null || data?.dureeExoneration === null ? true : false;
    this.supportImmobilier.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportImmobilier.errMontantTaxe = data === null || data?.taxeFonciere === null ? true : false;
    this.supportImmobilier.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportImmobilier.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportImmobilier.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  hydrateSupportZrrScpi(data: any) {
    // data commun
    this.supportSCPI.choixImmobilier.date_previsionnelle = data?.dateLivraison;
    this.supportSCPI.choixImmobilier.loyer_maxi = data?.plafondLoyer;
    this.supportSCPI.choixImmobilier.montant_produit =
      data?.produitFiscalMontant;
    this.supportSCPI.choixImmobilier.nom_support = data?.nomProdImoSaisie;
    this.revenuLocatifSCPI.montantRevenueLocatif.montant = data?.loyerMensuel;
    this.supportSCPI.choixImmobilier.revenu_locatif_mensuel = data?.loyerMensuel;
    this.revenuLocatifSCPI.ArraymontantRevenueLocatif = this.revenuLocatifSCPI.hydrateArrayRevenueLocatif(
      data?.loyerMensuelFutureList
    );
    this.supportSCPI.choixImmobilier.revenu_locatif_pourcent = data?.loyerMensuelRevalTaux;
    this.supportSCPI.choixImmobilier.revenu_locatif_tous_les = data?.loyerMensuelRevalAnnuel;
    this.supportSCPI.choixImmobilier.frais_suplementaire = data?.fraisDeductible;
    this.supportSCPI.choixImmobilier.taux_revalorisation = data?.produitFiscalTaux;
    // other data
    this.supportSCPI.choixImmobilier.surface_totale = data?.surface;
    this.supportSCPI.choixImmobilier.nombre_part = data?.valMobFoncier;
    this.supportSCPI.choixImmobilier.valeur_part = data?.produitFiscalNonAmorti;
    this.supportSCPI.choixImmobilier.differe_avant_premier_loyer = data?.difLoyer;
    this.supportSCPI.chargeIntegre.frais_gestion = data?.fraisGestion;
    this.supportSCPI.choixImmobilier.duree_scpi = data?.aful;
    this.supportSCPI.chargeIntegre.franchise_sans_loyer_fin_scpi = data?.taxeFonciere;
    this.supportSCPI.chargeIntegre.frais_entree = data?.chargeGarantie;
    this.supportSCPI.chargeIntegre.frais_sortie = data?.chargeCopropriete;
    this.supportSCPI.id = data?.id;
    this.supportSCPI.choixImmobilier.zoneScpi = data?.zoneScpi;

    // check empty value
    this.supportSCPI.errDate = data === null || data?.dateLivraison === null ? true : false;
    this.supportSCPI.errMontant = data === null || data?.produitFiscalMontant === null ? true : false;
    this.supportSCPI.errRevenueLocatifMenssuel = data === null || data?.loyerMensuel === null ? true : false;
    this.supportSCPI.errRevenueLocatifPourcent = data === null || data?.loyerMensuelRevalTaux === null ? true : false;
    this.supportSCPI.errTaux = data === null || data?.produitFiscalTaux === null ? true : false;

    this.montantTotalProduit = data?.produitFiscalMontant;
    this.FraisSupplementaire = data?.fraisDeductible;
    this.montantAfinancer = data?.montantAfinancer;
  }

  // fin recharge des données pour la partie
  // fin support immobillier et support SCPI

  hydrateSupport(loi: String, isScpi: boolean, data: any) {
    if (isScpi) {
      switch (loi) {
        case "PINEL":
          this.hydrateSupportPinelSCPI(data);
          break;
        case "LMNP":
          this.hydrateSupportLmnpSCPI(data);
          break;
        case "MALRAUX":
          this.hydrateSupportMalrauxSCPI(data);
          break;
        case "REGIME_GENERAL":
          this.hydrateSupportRegimeGeneralSCPI(data);
          break;
        case "DENORMANDIE":
          this.hydrateSupportDenormandieSCPI(data);
          break;
        case "LMP":
          this.hydrateSupportLmpSCPI(data);
          break;
        case "MONUMENT_HISTORIQUE":
          this.hydrateSupportMonumentHistoSCPI(data);
          break;
        case "DEMEMBREMENT":
          this.hydrateSupportDemembrementSCPI(data);
          break;

        case "GIRARDIN":
          this.hydrateSupportGirardinSCPI(data);
          break;

        case "ZRR":
          this.hydrateSupportZrrScpi(data);
          break;
        default:
          this.hydrateSupportPinelSCPI(data);
          break;
      }
    } else {
      switch (loi) {
        case "Pinel":
          this.hydrateSupportPinelImmo(data);
          break;
        case "LMNP":
          this.hydrateSupportLmnpImmo(data);
          break;
        case "MALRAUX":
          this.hydrateSupportMalrauxImmo(data);
          break;
        case "REGIME_GENERAL":
          this.hydrateSupportRegimeGeneralImmo(data);
          break;
        case "DENORMANDIE":
          this.hydrateSupportDenormandieImmo(data);
          break;
        case "LMP":
          this.hydrateSupportLmpImmo(data);
          break;

        case "MONUMENT_HISTORIQUE":
          this.hydrateSupportMonumentHistoImmo(data);
          break;

        case "DEMEMBREMENT":
          this.hydrateSupportDemembrementImmo(data);
          break;

        case "GIRARDIN":
          this.hydrateSupportGirardinImmo(data);
          break;

        case "ZRR":
          this.hydrateSupportZrrImmo(data);
          break;
        default:
          console.log("defaulddd");
          this.hydrateSupportPinelImmo(data);
          break;
      }
    }
  }

  checkValue(value: any) {
    return value == null || value === 0 || value === "" ? true : false;
  }

  checkValueWithoutZero(value: any) {
    return value == null || value === "" ? true : false;
  }

}
export { Support };

