import React, { Component } from 'react';
import { connect } from "react-redux";
import { appActions } from "../../_actions";
import { Dossier } from './dossier/dossier';
import { Declarant } from './declarant/declarant';

class ClientItem extends Component<any> {
    
    render() {
        const { declarant } = this.props;
        return (
            <div className="declarant-wrapper1 px-2 pb-2">
                <h1 style={{ textAlign: "left" }}>
                    <span className="px-3 big-text font-weight-bold">{declarant?.nom}</span>
                </h1>
                { declarant?.nom ==="Dossier"? (<Dossier />) : (<Declarant name={declarant?.id} />) }
                
            </div>
        );
    }

}

const mapStateToProps = (state: any) => ({
    model: state.app.model,
  });
  
const dispatchToProps = {
...appActions,
};
const connected = connect(mapStateToProps, dispatchToProps)(ClientItem);

export { connected as ClientItem }