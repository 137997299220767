import { appConstants } from "../_constants";
import {
  SORT_CLIENT,
  SORT_FISCALE,
  SORT_IMMOBILIER
} from "../_constants/sort_array";
import { OtherData } from "../_entities";
import { restClient } from "../_helpers";
import { Accueil } from "./Accueil";
import { Auth } from "./auth";
import { GestionBibliotheFiscale } from "./GestionBibliotheFiscale";
import { GestionBibliothequeImmobilier } from "./GestionBibliothequeImmobilier";
import { GestionClient } from "./GestionClient";
import { Modal } from "./Modal";
import { SituationFiscale } from "./SituationFiscale";
import { SolutionFiscale } from "./SolutionFiscale";
import { Synthese } from "./synthese";

const cloneDeep = require("lodash/cloneDeep");


class BaseModel {
  loading: boolean;
  isModalOpen: boolean;
  modalTitle: string;
  modalMode: string;
  modal: Modal;
  situationFiscale: SituationFiscale;
  solutionFiscale: SolutionFiscale;
  auth: Auth;
  navBarEmpty: boolean;
  accueilArray: Accueil[];
  isFindDossier = false;
  nombreDossierTotal = 0;
  clientArray: GestionClient[];
  produitfiscArray: GestionBibliotheFiscale[];
  produitImmobilierArray: GestionBibliothequeImmobilier[];
  isUpdateModel: boolean;
  private static _instance: BaseModel | undefined;
  id: number | null;
  eventUpdateDossier: boolean;
  dateSimulation: string | null;
  other: OtherData;
  // montageFinancier: MontageFinancier;
  // solutionDispositif: SolutionDispositif;
  // charges: ChargeSynthese;
  // situationFoyer: SituationFoyer;
  // scpiType: ScpiType;
  // chargeScpi: ChargeScpiSynthese;
  activeRefreshModel = true;
  synthese: Synthese;

  pages = {
    page: 0,
    size: 10,
  }
  nbDossier = 0;

  constructor() {
    if (BaseModel._instance) {
      throw new Error(
        "Error : Instanciation failed : Use BaseModel.getInstance() instead of new."
      );
    }
    this.navBarEmpty = true;
    this.loading = false;
    this.isModalOpen = false;
    this.modalTitle = "";
    this.modalMode = "";
    this.modal = new Modal();
    this.situationFiscale = new SituationFiscale();
    this.solutionFiscale = new SolutionFiscale();
    this.auth = new Auth();
    this.accueilArray = new Array<Accueil>();
    this.produitImmobilierArray = new Array<GestionBibliothequeImmobilier>();
    this.clientArray = new Array<GestionClient>();
    this.produitfiscArray = new Array<GestionBibliotheFiscale>();
    this.isUpdateModel = false;
    this.id = null;
    this.eventUpdateDossier = false;
    this.dateSimulation = null;
    this.other = new OtherData();
    this.synthese = new Synthese();
    // this.solutionDispositif = new SolutionDispositif();
    // this.charges = new ChargeSynthese();
    // this.chargeScpi = new ChargeScpiSynthese();
    // this.situationFoyer = new SituationFoyer();
    // this.scpiType = new ScpiType();
    BaseModel._instance = this;
  }

  reinitialiseBaseModel() {
    this.situationFiscale = new SituationFiscale();
    this.solutionFiscale = new SolutionFiscale();
    this.accueilArray = new Array<Accueil>();
    this.clientArray = new Array<GestionClient>();
    this.isUpdateModel = false;
    this.eventUpdateDossier = false;
    this.navBarEmpty = true;
    this.loading = false;
    this.isModalOpen = false;
    this.modalTitle = "";
    this.modalMode = "";
    this.dateSimulation = null;
    this.modal = new Modal();
    this.id = null;
  }

  public static getInstance(): BaseModel {
    if (!BaseModel._instance) {
      return new BaseModel();
    }
    return BaseModel._instance;
  }

  public static reset() {
    BaseModel._instance = undefined;
  }

  clone() {
    return cloneDeep(this);
  }

  changeEtatNav(val: boolean) {
    let res = true;
    this.navBarEmpty = val;
    return res;
  }

  async initresultat() {
    if (this.eventUpdateDossier) {
      await this.calculTax();
    }
  }

  serializeDossier() {
    return {
      id: this.id,
      client: this.situationFiscale.client.serialize(),

      compte: this.situationFiscale.getPartenaire(),
      dateSimulation: this.dateSimulation,
      declarationRevenus: {
        id: this.situationFiscale.idDeclarationRevenu,
        impot: {
          id: this.situationFiscale.resultatFiscale.id,
          impot:
            this.situationFiscale.resultatFiscale.impotPaye.montant === 0
              ? null
              : this.situationFiscale.resultatFiscale.impotPaye.montant,
          impotSurFortune:
            this.situationFiscale.resultatFiscale.isf.montant === 0
              ? null
              : this.situationFiscale.resultatFiscale.isf.montant,
          tauxEndettement:
            this.situationFiscale.resultatFiscale.tauxEndettement
              .pourcentage === 0
              ? null
              : this.situationFiscale.resultatFiscale.tauxEndettement
                .pourcentage,
          trancheMarginalImposition:
            this.situationFiscale.resultatFiscale.tauxMarginaleImposition
              .pourcentage === 0
              ? null
              : this.situationFiscale.resultatFiscale.tauxMarginaleImposition
                .pourcentage,
          impotFutureList: this.situationFiscale.resultatFiscales,
        },
        revenu1: this.situationFiscale.revenu.serializeRevenu1(),
        revenu2: this.situationFiscale.revenu.serializeRevenu2(),
        quotientFamilial: {
          id: this.situationFiscale.quotientFamilial.id,
          marieOrPacse: this.situationFiscale.quotientFamilial.isMarieOuPacse,
          parentIsole: this.situationFiscale.quotientFamilial.isParentIsole,
          celibataire: this.situationFiscale.quotientFamilial.isCelibataire,
          nbPart: this.situationFiscale.quotientFamilial.nombrePartFiscale
            .valeur,
          nbPartFutureList: this.situationFiscale.quotientFamilial.nombrePartFiscaleSerialize(),
          nbPartFuture:
            this.situationFiscale.quotientFamilial.nombrePartFiscales.length > 0
              ? true
              : false,
        },
        empruntList: this.situationFiscale.endettement.serialize(),
        saisirEmprunt: this.situationFiscale.endettement.checkDetailEmprunt,
      },

      detail: {
        id: this.situationFiscale.idDetail,
        avantage:
          this.situationFiscale.detail.plafonnementAvantagesFiscaux
            .montantAvantage.realiseMetropole.montant === 0
            ? null
            : this.situationFiscale.detail.plafonnementAvantagesFiscaux
              .montantAvantage.realiseMetropole.montant,
        avantageFutureList: this.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.realiseMetropolesArraySerialize(),
        avantageFuture:
          this.situationFiscale.detail.plafonnementAvantagesFiscaux
            .montantAvantage.realiseMetropoles.length > 0
            ? true
            : false,

        avantageOutermer:
          this.situationFiscale.detail.plafonnementAvantagesFiscaux
            .montantAvantage.realiseOutreMer.montant === 0
            ? null
            : this.situationFiscale.detail.plafonnementAvantagesFiscaux
              .montantAvantage.realiseOutreMer.montant,
        avantageOutermerFutureList: this.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.realiseOutreMersArraySerialize(),
        avantageOutermerFuture:
          this.situationFiscale.detail.plafonnementAvantagesFiscaux
            .montantAvantage.realiseOutreMers.length > 0
            ? true
            : false,

        chargeAutre:
          this.situationFiscale.endettement.depense.autreCharge.montant === 0
            ? null
            : this.situationFiscale.endettement.depense.autreCharge.montant,
        chargeAutreFutureList: this.situationFiscale.endettement.depense.autreChargesArraySerialize(),
        chargeAutreFuture:
          this.situationFiscale.endettement.depense.autreCharges.length > 0
            ? true
            : false,

        chargeLoyer:
          this.situationFiscale.endettement.depense.loyerDonne.montant === 0
            ? null
            : this.situationFiscale.endettement.depense.loyerDonne.montant,
        chargeLoyerFutureList: this.situationFiscale.endettement.depense.loyerDonnesArraySerialize(),
        chargeLoyerFuture:
          this.situationFiscale.endettement.depense.loyerDonnes.length > 0
            ? true
            : false,

        loyerPercu:
          this.situationFiscale.endettement.depense.loyerPercu.montant === 0
            ? null
            : this.situationFiscale.endettement.depense.loyerPercu.montant,
        loyerPercuFutureList: this.situationFiscale.endettement.depense.loyerPercusArraySerialize(),
        loyerPercuFuture:
          this.situationFiscale.endettement.depense.loyerPercus.length > 0
            ? true
            : false,

        nbEnfantAcharge:
          this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant
            .nombreEnfantCharge.montant === 0
            ? null
            : this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant
              .nombreEnfantCharge.montant,
        nbEnfantAchargeFutureList: this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant.nombreEnfantChargesArraySerialize(),
        nbEnfantAchargeFuture:
          this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant
            .nombreEnfantCharges.length > 0
            ? true
            : false,

        nbEnfantEnTout:
          this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant
            .nombreEnfantTotal.montant === 0
            ? null
            : this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant
              .nombreEnfantTotal.montant,
        nbEnfantEnToutFutureList: this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant.nombreEnfantTotalsArraySerialize(),
        nbEnfantEnToutFuture:
          this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant
            .nombreEnfantTotals.length > 0
            ? true
            : false,

        valeurPatrimoine:
          this.situationFiscale.detail.impotFortuneImmobilier
            .valorisationNettePatrimoine.montant === 0
            ? null
            : this.situationFiscale.detail.impotFortuneImmobilier
              .valorisationNettePatrimoine.montant,
        valeurPatrimoineFutureList: this.situationFiscale.detail.impotFortuneImmobilier.valorisationNettePatrimoinesArraySerialize(),
        valeurPatrimoineFuture:
          this.situationFiscale.detail.impotFortuneImmobilier
            .valorisationNettePatrimoines.length > 0
            ? true
            : false,
      },
      resultatFoncier: {
        id: this.situationFiscale.idResultatFoncier,
        resultatFoncier:
          this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier
            .resultatFoncierExistant.montant === 0
            ? null
            : this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier
              .resultatFoncierExistant.montant,
        resultatFoncierFutureList: this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.resultatFoncierExistantsArraySerialize(),
        resultatFoncierFuture:
          this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier
            .resultatFoncierExistants.length > 0
            ? true
            : false,

        perisol:
          this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier
            .perisolEnCour.montant === 0
            ? null
            : this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier
              .perisolEnCour.montant,
        perisolFutureList: this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.perisolEnCoursArraySerialize(),
        perisolFuture:
          this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier
            .perisolEnCours.length > 0
            ? true
            : false,

        besson:
          this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier
            .deficitNonImpute.montant === 0
            ? null
            : this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier
              .deficitNonImpute.montant,
        bessonFutureList: this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.deficitNonImputesArraySerialize(),
        bessonFuture:
          this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier
            .deficitNonImputes.length > 0
            ? true
            : false,

        deductionFiscale:
          this.situationFiscale.autre.foyerFiscalAutre.deductionFiscale
            .montant === 0
            ? null
            : this.situationFiscale.autre.foyerFiscalAutre.deductionFiscale
              .montant,
        deductionFiscaleFutureList: this.situationFiscale.autre.foyerFiscalAutre.deductionFiscalesArraySerialize(),
        deductionFiscaleFuture:
          this.situationFiscale.autre.foyerFiscalAutre.deductionFiscales
            .length > 0
            ? true
            : false,

        reductionFiscale:
          this.situationFiscale.autre.foyerFiscalAutre.reductionFiscale
            .montant === 0
            ? null
            : this.situationFiscale.autre.foyerFiscalAutre.reductionFiscale
              .montant,
        reductionFiscaleFutureList: this.situationFiscale.autre.foyerFiscalAutre.reductionFiscalesArraySerialize(),
        reductionFiscaleFuture:
          this.situationFiscale.autre.foyerFiscalAutre.reductionFiscales
            .length > 0
            ? true
            : false,

        pension:
          this.situationFiscale.autre.foyerFiscalAutre.pension.montant === 0
            ? null
            : this.situationFiscale.autre.foyerFiscalAutre.pension.montant,
        pensionFutureList: this.situationFiscale.autre.foyerFiscalAutre.pensionsArraySerialize(),
        pensionFuture:
          this.situationFiscale.autre.foyerFiscalAutre.pensions.length > 0
            ? true
            : false,

        dividende:
          this.situationFiscale.autre.foyerFiscalAutre.revenusMobilierCapitaux
            .montant === 0
            ? null
            : this.situationFiscale.autre.foyerFiscalAutre
              .revenusMobilierCapitaux.montant,
        dividendeFutureList: this.situationFiscale.autre.foyerFiscalAutre.revenusMobilierCapitauxsArraySerialize(),
        dividendeFuture:
          this.situationFiscale.autre.foyerFiscalAutre.revenusMobilierCapitauxs
            .length > 0
            ? true
            : false,

        guadeloupeMartiniqueReunion:
          this.situationFiscale.autre.lieuResidence.nom === "Guadeloupe, Martinique, Réunion"
            ? true
            : false,
        guyannePolynesie:
          this.situationFiscale.autre.lieuResidence.nom === "Guyanne et Polynésie"
            ? true
            : false,
        metropole:
          this.situationFiscale.autre.lieuResidence.nom === "Métropole"
            ? true
            : false,
      },

      // etude:{
      //   fiscalite: {
      //     dispositifPinel: this.solutionFiscale.typeFiscaliteModel.pinel.serialize(),
      //   },
      //   financement:{
      //     // saisirEmprunt:
      //   }
      // }
    };
  }

  JsonFromMap(data: any) {
    this.id = data?.id;

    this.dateSimulation = data?.dateSimulation;
    this.situationFiscale.client.JsonFromMap(data?.client);
    this.situationFiscale.idDeclarationRevenu = data?.declarationRevenus?.id;
    this.situationFiscale.resultatFiscale.id = data?.declarationRevenus?.impot?.id;
    this.situationFiscale.resultatFiscale.impotPaye.montant = data?.declarationRevenus?.impot?.impot;
    this.situationFiscale.resultatFiscale.isf.montant = data?.declarationRevenus?.impot?.impotSurFortune;
    this.situationFiscale.resultatFiscale.tauxEndettement.pourcentage = data?.declarationRevenus?.impot?.tauxEndettement;
    this.situationFiscale.resultatFiscale.tauxMarginaleImposition.pourcentage = data?.declarationRevenus?.impot?.trancheMarginalImposition;
    this.situationFiscale.resultatFiscales = data?.declarationRevenus?.impot?.impotFutureList;
    this.situationFiscale.revenu.JsonFromMapRevenu1(      data?.declarationRevenus?.revenu1    );
    this.situationFiscale.revenu.JsonFromMapRevenu2(      data?.declarationRevenus?.revenu2    );
    this.situationFiscale.quotientFamilial.id = data?.declarationRevenus?.quotientFamilial?.id;
    this.situationFiscale.quotientFamilial.isMarieOuPacse = data?.declarationRevenus?.quotientFamilial?.marieOrPacse;
    this.situationFiscale.quotientFamilial.isParentIsole = data?.declarationRevenus?.quotientFamilial?.parentIsole;
    this.situationFiscale.quotientFamilial.isCelibataire = data?.declarationRevenus?.quotientFamilial?.celibataire;
    this.situationFiscale.quotientFamilial.hasAnneNombrePartFiscales = data?.declarationRevenus?.quotientFamilial?.nbPartFuture;
    this.situationFiscale.quotientFamilial.nombrePartFiscale.valeur = data?.declarationRevenus?.quotientFamilial?.nbPart;
    this.situationFiscale.quotientFamilial.nombrePartFiscales = this.situationFiscale.quotientFamilial.hydrateArrayModelQuotient(      data?.declarationRevenus?.quotientFamilial?.nbPartFutureList    );
    this.situationFiscale.endettement.hydateModelEndetement(      data?.declarationRevenus?.empruntList    );
    this.situationFiscale.endettement.checkDetailEmprunt = data?.declarationRevenus?.saisirEmprunt;

    // detail
    this.situationFiscale.idDetail = data?.detail?.id;
    this.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.realiseMetropole.montant = data?.detail?.avantage;
    this.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.realiseMetropoles = this.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.hydrateArrayModelRealiseMetropoles(      data?.detail?.avantageFutureList    );

    this.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.realiseOutreMer.montant = data?.detail?.avantageOutermer;
    this.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.realiseOutreMers = this.situationFiscale.detail.plafonnementAvantagesFiscaux.montantAvantage.hydrateArrayModelRealiseMetropoles(      data?.detail?.avantageOutermerFutureList    );

    this.situationFiscale.endettement.depense.autreCharge.montant = data?.detail?.chargeAutre;
    this.situationFiscale.endettement.depense.autreCharges = this.situationFiscale.endettement.depense.hydrateArrayModelAutreCharge(      data?.detail?.chargeAutreFutureList    );

    this.situationFiscale.endettement.depense.loyerDonne.montant = data?.detail?.chargeLoyer;
    this.situationFiscale.endettement.depense.loyerDonnes = this.situationFiscale.endettement.depense.hydrateArrayModelLoyerDonner(      data?.detail?.chargeLoyerFutureList    );

    this.situationFiscale.endettement.depense.loyerPercu.montant = data?.detail?.loyerPercu;
    this.situationFiscale.endettement.depense.loyerPercus = this.situationFiscale.endettement.depense.hydrateArrayModelLoyerPercue(      data?.detail?.loyerPercuFutureList    );

    this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant.nombreEnfantCharge.montant = data?.detail?.nbEnfantAcharge;
    this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant.nombreEnfantCharges = this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant.hydrateArrayModelEnfantCharges(      data?.detail?.nbEnfantAchargeFutureList    );

    this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant.nombreEnfantTotal.montant = data?.detail?.nbEnfantEnTout;
    this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant.nombreEnfantTotals = this.situationFiscale.detail.impotFortuneImmobilier.nombreEnfant.hydrateArrayModelEnfantTotal(      data?.detail?.nbEnfantEnToutFutureList    );

    this.situationFiscale.detail.impotFortuneImmobilier.valorisationNettePatrimoine.montant = data?.detail?.valeurPatrimoine;
    this.situationFiscale.detail.impotFortuneImmobilier.valorisationNettePatrimoines = this.situationFiscale.detail.impotFortuneImmobilier.hydrateArrayModelValorisationNettePatrimoines(      data?.detail?.chargeAutreFutureList    );
    //resultat fonctier
    this.situationFiscale.idResultatFoncier = data?.resultatFoncier?.id;
    this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.resultatFoncierExistant.montant = data?.resultatFoncier?.resultatFoncier;
    this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.resultatFoncierExistants = this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.hydrateArrayModelresultatFoncierExistants(
      data?.resultatFoncier?.resultatFoncierFutureList
    );

    this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.perisolEnCour.montant = data?.resultatFoncier?.perisol;
    this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.perisolEnCours = this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.hydrateArrayModelperisolEnCours(      data?.resultatFoncier?.perisolFutureList    );

    this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.deficitNonImpute.montant = data?.resultatFoncier?.besson;
    this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.deficitNonImputes = this.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.hydrateArrayModeldeficitNonImputes(      data?.resultatFoncier?.bessonFutureList    );

    this.situationFiscale.autre.foyerFiscalAutre.deductionFiscale.montant = data?.resultatFoncier?.deductionFiscale;
    this.situationFiscale.autre.foyerFiscalAutre.deductionFiscales = this.situationFiscale.autre.foyerFiscalAutre.hydrateArrayModelDeductionFiscale(      data?.resultaFoncier?.deductionFiscaleFutureList    );

    this.situationFiscale.autre.foyerFiscalAutre.reductionFiscale.montant = data?.resultatFoncier?.reductionFiscale;
    this.situationFiscale.autre.foyerFiscalAutre.reductionFiscales = this.situationFiscale.autre.foyerFiscalAutre.hydrateArrayModelDeductionFiscale(      data?.resultaFoncier?.reductionFiscaleFutureList    );

    this.situationFiscale.autre.foyerFiscalAutre.pension.montant = data?.resultatFoncier?.pension;
    this.situationFiscale.autre.foyerFiscalAutre.pensions = this.situationFiscale.autre.foyerFiscalAutre.hydrateArrayModelDeductionFiscale(      data?.resultaFoncier?.pensionFutureList    );

    this.situationFiscale.autre.foyerFiscalAutre.revenusMobilierCapitaux.montant = data?.resultatFoncier?.dividende;
    this.situationFiscale.autre.foyerFiscalAutre.revenusMobilierCapitauxs = this.situationFiscale.autre.foyerFiscalAutre.hydrateArrayModelDeductionFiscale(      data?.resultaFoncier?.dividendeFutureList    );

    this.situationFiscale.autre.lieuResidence.nom = data?.resultatFoncier
      ?.guadeloupeMartiniqueReunion
      ? "Guadeloupe, Martinique, Réunion"
      : data?.resultatFoncier?.guyannePolynesie
        ? "Guyanne et Polynésie"
        : data?.resultatFoncier?.metropole
          ? "Métropole"
          : "";
    this.situationFiscale.autre.lieuResidence.id = data?.resultatFoncier
      ?.guadeloupeMartiniqueReunion
      ? 2
      : data?.resultatFoncier?.guyannePolynesie
        ? 3
        : data?.resultatFoncier?.metropole
          ? 1
          : null;

    // etude
    this.solutionFiscale.hydrateBorloo(      data?.etude?.fiscalite?.dispositifBorlooPopulaire    );
    this.solutionFiscale.hydrateDenormandie(      data?.etude?.fiscalite?.dispositifDeNormandie    );
    this.solutionFiscale.hydrateDemembrement(      data?.etude?.fiscalite?.dispositifDemenbrement    );
    this.solutionFiscale.hydrateDuflot(      data?.etude?.fiscalite?.dispositifDuflot    );
    this.solutionFiscale.hydrateGiradin(      data?.etude?.fiscalite?.dispositifGirardin    );
    this.solutionFiscale.hydrateLmnp(data?.etude?.fiscalite?.dispositifLmnp);
    this.solutionFiscale.hydrateLmp(data?.etude?.fiscalite?.dispositifLmp);
    this.solutionFiscale.hydrateMalraux(      data?.etude?.fiscalite?.dispositifMalraux    );
    this.solutionFiscale.hydrateMonument(      data?.etude?.fiscalite?.dispositifMonumentHistorique    );
    this.solutionFiscale.hydratePinel(data?.etude?.fiscalite?.dispositifPinel);
    this.solutionFiscale.hydrateRegimeGenerale(      data?.etude?.fiscalite?.dispositifRegimeGeneral    );
    this.solutionFiscale.hydrateRobienEnZRR(      data?.etude?.fiscalite?.dispositifRobienEnZrr    );
    this.solutionFiscale.hydrateRobienRecentre(      data?.etude?.fiscalite?.dispositifRobienRecentre    );
    this.solutionFiscale.hydrateScellierNeufAns(      data?.etude?.fiscalite?.dispositifScellier9Ans    );
    this.solutionFiscale.hydrateScellierInterm(      data?.etude?.fiscalite?.dispositifScellierInterm    );
    this.solutionFiscale.hydrateZRR(data?.etude?.fiscalite?.dispositifZrr);
    this.solutionFiscale.typeFiscaliteModel.id = data?.etude?.fiscalite?.id;
    this.solutionFiscale.currentFiscalite = data?.etude?.fiscalite?.loi === undefined || data?.etude?.fiscalite?.loi === null ? "-" : data?.etude?.fiscalite?.loi;
    this.solutionFiscale.scpi = data?.etude?.scpi;
    this.solutionFiscale.onChangeScpiRest(data?.etude?.scpi);
    this.solutionFiscale.support.hydrateSupport(data?.etude?.fiscalite?.loi, data?.etude?.scpi, data?.etude?.supportImmobilier);
    this.other.revenuFiscaleReference = data?.etude?.revenuFiscaleReference;
    this.other.nouvelleEtude = data?.etude?.nouvelleEtude;
    this.other.evolutionActuelle = data?.etude?.evolutionActuelle;
    this.other.resultatFonctier = data?.revenuFoncier?.montant;
    // hydrate financement mono and multi
    this.solutionFiscale.mono_financement_mode = data?.etude?.financement
      ?.multiFinancement
      ? false
      : true;
    this.solutionFiscale.financement.monofinancement.hydrateModelMonoFinancement(      data?.etude?.financement    );
    this.solutionFiscale.financement.hydrateMultiplefinancemet(      data?.etude?.financement    );

    // hydrate placement
    this.solutionFiscale.placement.hydratePlacement(data?.etude?.placement);
  }

  async logout(data: any){
    let res = await restClient.post("/logoutByToken", data)
    console.log(res)
    if (res.status !== 200 ) {
      return false;
    } else {
      return true;
    }
  }

  async getdispositifSynthese(id: any) {
    let dossierId = id;

    let res = await (await restClient.post("/dossier/Synthese/" + dossierId))
      .data;
    console.log("======== start Dossier synthese ========");
    console.log(res);
    console.log("======== end Dossier synthese ========");
    if (res === null) {
      return false;
    } else {
      return res.synthese;
    }
  }

  async calculTax() {
    let localRef = window.location.pathname;
    if (localRef === "/situation-fiscal") {
      let val = this.situationFiscale.revenu.declarant1.revenuNetImposable.montant;
      let vel = this.situationFiscale.quotientFamilial.nombrePartFiscale.valeur;
      console.log(val);
      console.log(vel);
      if (val === null || val === 0) {
        console.log("echec");
      } else if (vel === 0 || vel === null) {
        console.log("echec");
      } else {
        console.log("save");

        let data = this.serializeDossier();
        console.log("=== init calc dossier ===");
        console.log(data);
        console.log("=== end calc dossier ===");
        let res = await restClient.post("/dossier/calulateTax", data);
        if (res.status === 200) {
          this.JsonFromMap(res.data);
          this.situationFiscale.revenu.newCalcule = false;
          this.situationFiscale.quotientFamilial.newCalcule = false;
          return true;
        }
        console.log("echec");
        return false;
      }
    } else {
      console.log("Happy is a wonderfull code")
    }
  }

  async addDossier() {
    let data = this.serializeDossier();
    console.log("=== init add dossier ===");
    console.log(data);
    console.log("=== end add dossier ===");
    let res = await restClient.post("/dossier/addDossier", data);
    if (res.status === 200) {
      localStorage.setItem("keyupdate", JSON.stringify(res.data.id));
      localStorage.removeItem("nouveauDossier");
      return true;
    }
    return false;
  }

  async updateDossier() {
    let data = this.serializeDossier();
    console.log(data);
    let res = await restClient.put("/dossier/updateDossier", data);
    if (res.status === 200) {
      localStorage.removeItem("nouveauDossier");
      console.log("=== start data update =====");
      this.JsonFromMap(res.data);
      console.log("=== end data update =====");
      return true;
    }
    return false;
  }

  async deleteDossier(dossierId: number) {
    let res = await restClient.post(
      "/dossier/deleteDossier/" + dossierId,
      dossierId
    );
    console.log(res);
    if (res.status === 200) {
      return true;
    }
    return false;
  }

  async getDossier() {
    let res: any = await restClient.get("/dossier/all");
    console.log(res);
    let data: Array<any> = res?.data;
    return await data.map((elt: any) => new Accueil(elt));
  }

  async filterDossier(client: any, pages: any) {
    let userData = this.auth.loginAuth.utilisateur;
    let route: String;
    if (client === "") {
      route = "/dossier/dossiers/" +
        userData.id +
        "/" +
        pages?.page +
        "/" +
        pages.size;
    } else {
      route = "/dossier/dossiers/" +
        userData.id +
        "/" +
        client +
        "/" +
        pages?.page +
        "/" +
        pages.size;
    }
    let res: Array<any> = await (await restClient.get(route)).data;
    console.log(res);
    if (res === null) {
      return (this.accueilArray = []);
    }
    if (res.length > 0) {
      return await res.map((elt) => new Accueil(elt));
    }
    else {
      return (this.accueilArray = []);
    }
  }

  async getDossierByPartenaire(pages: any) {
    let userData = this.auth.loginAuth.utilisateur;
    let res: Array<any> = await (
      await restClient.get(
        "/dossier/dossiers/" +
        userData.id +
        "/" +
        pages?.page +
        "/" +
        pages.size
      )
    ).data;
    console.log(res);
    if (res === null) {
      return this.accueilArray;
    }
    if (res.length > 0) {
      return await res?.map((elt) => new Accueil(elt));
    }
    return this.accueilArray;
  }

  async getProduitImmobilierByPartenaireId(pages: any) {
    let userData = this.auth.loginAuth.utilisateur;
    let res: Array<any> = await (
      await restClient.get(
        "/immobilier/LotImmobiliers/" +
        userData.id +
        "/" +
        pages?.page +
        "/" +
        pages.size
      )
    ).data;
    console.log(res);
    if (res === null) {
      return this.produitImmobilierArray;
    }
    if (res.length > 0) {
      return await res.map((elt) => new GestionBibliothequeImmobilier(elt));
    }
    return this.produitImmobilierArray;
  }

  async getProduitFiscaleByPartenaire(pages: any) {
    let userData = this.auth.loginAuth.utilisateur;
    let res: Array<any> = await (
      await restClient.get(
        "/produitfinancier/ProduitFinanciers/" +
        userData.id +
        "/" +
        pages?.page +
        "/" +
        pages.size
      )
    ).data;
    console.log(res);
    if (res === null) {
      return this.produitfiscArray;
    }
    if (res.length > 0) {
      return await res.map((elt) => new GestionBibliotheFiscale(elt));
    }
    return this.produitfiscArray;
  }

  async getClientByPartenaire(pages: any) {
    let userData = this.auth.loginAuth.utilisateur;
    let res: Array<any> = await (
      await restClient.get(
        "/client/clients/" + userData.id + "/" + pages?.page + "/" + pages.size
      )
    ).data;
    console.log(res);
    if (res === null) {
      return this.clientArray;
    }
    if (res.length > 0) {
      return await res.map((elt) => new GestionClient(elt));
    }
    return this.clientArray;
  }

  async getNbDossierByPartenaire(client?: any) {
    let userData = this.auth.loginAuth.utilisateur;


    if (client !== null && client !== undefined && client !== "") {
      let res = await restClient.get("/dossier/dossiers/nombre/" + userData.id + "/" + client);
      console.log(res);
      if (res.status === 200) {
        return res.data;
      } else {
        return null;
      }
    } else {
      let res = await restClient.get("/dossier/dossiers/nombre/" + userData.id);
      console.log(res);
      if (res.status === 200) {
        console.log("ghkhjk" + res.data);
        return res.data;
      } else {
        return null;
      }
    }

  }

  async getNbProdImoByPartenaire() {
    let userData = this.auth.loginAuth.utilisateur;
    let res = await restClient.get(
      "/immobilier/LotImmobiliers/nombre/" + userData.id
    );
    console.log(res);
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  }

  async getNbProdFiscByPartenaire() {
    let userData = this.auth.loginAuth.utilisateur;
    let res = await restClient.get(
      "/produitfinancier/ProduitFinanciers/nombre/" + userData.id
    );
    console.log(res);
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  }

  async getOneDossier(dossierId: any) {
    let res: any = await (
      await restClient.post("/dossier/dossier/" + dossierId, dossierId)
    ).data;
    console.log(res);
    if (res) {
      this.JsonFromMap(res);
      return true;
    }
    return false;
  }

  async getMontageFinancier(dossierId: any | null) {
    let res: any = await (
      await restClient.post("/dossier/Synthese/" + dossierId, dossierId)
    ).data;
    if (res) {
      await this.synthese.hydrateSynthese(res?.synthese);
      return true;
    }
    return false;
  }

  async getDataOneDossier(dossierId: number) {
    let res: any = await (
      await restClient.post("/dossier/dossier/" + dossierId, dossierId)
    ).data;
    console.log(res);
    if (res) {
      this.JsonFromMap(res);
      return res;
    }
    return false;
  }

  loadFiscalite(fiscalite: any) {
    switch (fiscalite) {
      case "Pinel":
        return this.addFiscalite(
          "/fiscalite/pinel/",
          this.solutionFiscale.serializePinel()
        );

      case "LMNP":
        return this.addFiscalite(
          "/fiscalite/lmnp/",
          this.solutionFiscale.serializeLmnp()
        );

      case "Malraux":
        return this.addFiscalite(
          "/fiscalite/malraux/",
          this.solutionFiscale.serializeMalraux()
        );

      case "Régime général":
        return this.addFiscalite(
          "/fiscalite/regimegeneral/",
          this.solutionFiscale.serializeRegimeGeneral()
        );

      case "Denormandie":
        return this.addFiscalite(
          "/fiscalite/denormandie/",
          this.solutionFiscale.serializeDenormandie()
        );

      case "LMP":
        return this.addFiscalite(
          "/fiscalite/lmp/",
          this.solutionFiscale.serializeLmp()
        );

      case "Monument histo":
        return this.addFiscalite(
          "/fiscalite/monumenthistorique/",
          this.solutionFiscale.serializeMonument()
        );

      case "Demembrement":
        return this.addFiscalite(
          "/fiscalite/demenbrement/",
          this.solutionFiscale.serializeDemembrement()
        );

      case "Girardin":
        return this.addFiscalite(
          "/fiscalite/girardin/",
          this.solutionFiscale.serializeGiradin()
        );

      case "Duflot":
        return this.addFiscalite(
          "/fiscalite/duflot/",
          this.solutionFiscale.serializeDuflot()
        );

      case "Scellier Interm":
        return this.addFiscalite(
          "/fiscalite/scellierinterm/",
          this.solutionFiscale.serializeScellierIntern()
        );

      case "Scellier 9 ans":
        return this.addFiscalite(
          "/fiscalite/scellier9ans/",
          this.solutionFiscale.serializeScellierNeufAns()
        );

      case "Robien recentré":
        return this.addFiscalite(
          "/fiscalite/robienrecentre/",
          this.solutionFiscale.serializeRobienRecentre()
        );

      case "Robien en ZRR":
        return this.addFiscalite(
          "/fiscalite/robienenzrr/",
          this.solutionFiscale.serializeRobienEnZrr()
        );

      case "Borloo populaire":
        return this.addFiscalite(
          "/fiscalite/borloopopulaire/",
          this.solutionFiscale.serializeBorlooPopulaire()
        );

      case "ZRR":
        return this.addFiscalite(
          "/fiscalite/zrr/",
          this.solutionFiscale.serializeZrr()
        );

      default:
        break;
    }
  }
  async addFiscalite(path: String, fiscalite: any) {
    let data = fiscalite;
    // console.log("==== add fiscalite ====");
    // console.log(data);
    // console.log("==== end fiscalite ====");
    let id = localStorage.getItem("keyupdate");
    let res = await restClient.post(path + "" + id, data);

    if (res.status === 200) {
      // console.log(" === add fiscalité ===");
      // console.log(res.data);
      // console.log(" === end fiscalité ===");
      return true;
    } else {
      return false;
    }
  }

  async calculEstimateurFrais(data: any) {
    let id = localStorage.getItem("keyupdate");
    console.log("===== extimation ======");
    console.log(data);
    console.log("===== extimation ======");
    let res = await restClient.post(
      "supportimmobilier/fraisimmobilier/estimation/calcul/" + id,
      data
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return false;
    }
  }
  async getAllZone(val: any) {
    let res = await restClient.get("/data/zone/all");
    if (res.status === 200) {
      return this.getZoneBySupport(val, res.data);
    }
  }

  async getAllScpi() {
    let res = await restClient.get("/data/zone/scpi");
    if (res.status === 200) {
      return res.data;
    }
  }

  getZoneBySupport(key: any, zone: any) {
    return zone[key];
  }

  async getLotImobillier() {
    let userData = this.auth.loginAuth.utilisateur;
    let res = await restClient.get("/immobilier/LotImmobiliers/" + userData.id);
    console.log(res);
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  }

  async addSupport(loi: String, isScpi: boolean) {
    let data = this.loadDataBySupport(loi, isScpi);
    let id = localStorage.getItem("keyupdate");
    console.log("==== support immobillier =====");
    console.log(data);
    console.log("==== support immobilier =====");
    let res = await restClient.post(
      "/supportimmobilier/addorupdate/" + id,
      data
    );
    if (res.status === 200) {
      console.log(res.data);
    }
  }

  loadDataBySupport(loi: String, isScpi: boolean) {
    if (isScpi) {
      switch (loi) {
        case "Pinel":
          return {
            ...this.solutionFiscale.serialiseSupportPinelScpi(),
          };
        case "LMNP":
          return {
            ...this.solutionFiscale.serialiseSupportLmnpScpi(),
          };
        case "LMP":
          return {
            ...this.solutionFiscale.serialiseSupportLmpScpi(),
          };
        case "Régime général":
          return {
            ...this.solutionFiscale.serialiseSupportRegimeGeneraleScpi(),
          };
        case "Monument histo":
          return {
            ...this.solutionFiscale.serialiseSupportMonumentHistoScpi(),
          };
        case "Demembrement":
          return {
            ...this.solutionFiscale.serialiseSupportDemembrementScpi(),
          };
        case "Girardin":
          return {
            ...this.solutionFiscale.serialiseSupportGirardinScpi(),
          };
        case "Denormandie":
          return {
            ...this.solutionFiscale.serialiseSupportDenormandieScpi(),
          };
        case "Malraux":
          return {
            ...this.solutionFiscale.serialiseSupportMalrauxScpi(),
          };
        case "Duflot":
          return {
            ...this.solutionFiscale.serialiseSupportDuflotScpi(),
          };
        case "Scellier Interm":
          return {
            ...this.solutionFiscale.serialiseSupportScellierIntermScpi(),
          };
        case "Scellier 9 ans":
          return {
            ...this.solutionFiscale.serialiseSupportScellierNeufScpi(),
          };
        case "Robien recentré":
          return {
            ...this.solutionFiscale.serialiseSupportRobienRecentreScpi(),
          };
        case "Robien en ZRR":
          return {
            ...this.solutionFiscale.serialiseSupportRobienZRRScpi(),
          };
        case "Borloo populaire":
          return {
            ...this.solutionFiscale.serialiseSupportBorlooScpi(),
          };
        case "ZRR":
          return {
            ...this.solutionFiscale.serialiseSupportZrrScpi(),
          };
      }
    } else {
      switch (loi) {
        case "Pinel":
          return {
            ...this.solutionFiscale.serialiseSupportPinelImmo(),
          };
        case "LMNP":
          return {
            ...this.solutionFiscale.serialiseSupportLmnpImmo(),
          };
        case "LMP":
          return {
            ...this.solutionFiscale.serialiseSupportLmpImmo(),
          };
        case "Régime général":
          return {
            ...this.solutionFiscale.serialiseSupportRegimeGeneralImmo(),
          };
        case "Monument histo":
          return {
            ...this.solutionFiscale.serialiseSupportMonumenthistoImmo(),
          };
        case "Demembrement":
          return {
            ...this.solutionFiscale.serialiseSupportDemembrementImmo(),
          };
        case "Girardin":
          return {
            ...this.solutionFiscale.serialiseSupportGirardinImmo(),
          };
        case "Denormandie":
          return {
            ...this.solutionFiscale.serialiseSupportDenormandieImmo(),
          };
        case "Malraux":
          return {
            ...this.solutionFiscale.serialiseSupportMalrauxImmo(),
          };
        case "Duflot":
          return {
            ...this.solutionFiscale.serialiseSupportDuflotImmo(),
          };
        case "Scellier Interm":
          return {
            ...this.solutionFiscale.serialiseSupportScellierIntermImmo(),
          };
        case "Scellier 9 ans":
          return {
            ...this.solutionFiscale.serialiseSupportScellierNeufImmo(),
          };
        case "Robien recentré":
          return {
            ...this.solutionFiscale.serialiseSupportRobienRecentreImmo(),
          };
        case "Robien en ZRR":
          return {
            ...this.solutionFiscale.serialiseSupportRobienZRRImmo(),
          };
        case "Borloo populaire":
          return {
            ...this.solutionFiscale.serialiseSupportBorlooImmo(),
          };
        case "ZRR":
          return {
            ...this.solutionFiscale.serialiseSupportZrrImmo(),
          };
        default:
          break;
      }
    }
  }

  async addLot(loi: String, isSCPI: boolean) {
    let userData = this.auth.loginAuth.utilisateur;
    let support = this.loadDataBySupport(loi, isSCPI);
    let data = {
      ...support,
      loi: loi,
      partenaireId: userData.id,
    };

    console.log("======");
    console.log(data);
    console.log("======");
    // return true;
    let res = await restClient.post(
      "/supportimmobilier/LotImmobiliers/addLot",
      data
    );
    if (res.status === 200) {
      let datas: any = [];
      datas = await this.getLotBypartenaire(userData.id, loi, isSCPI);
      if (datas?.length > 0) {
        this.solutionFiscale.support.listLot = datas;
      } else {
        this.solutionFiscale.support.listLot = [];
      }
      return true;
    }
    return false;
  }

  async getLotBypartenaire(userId: number | null, loi: String, isSCPI: boolean) {
    let res = await restClient.get(
      "/supportimmobilier/LotImmobiliersByLoi/" + userId + "/" + loi + "/" + isSCPI
    );
    if (res.status === 200) {
      return this.solutionFiscale.support.hydrateListLot(res.data);
    } else {
      return null;
    }
  }

  async suppressionLot(key: any) {
    let userData = this.auth.loginAuth.utilisateur;

    console.log(key);
    let res = await restClient.post(
      "/supportimmobilier/LotImmobiliers/deleteLotImmobilier/" + key,
      key
    );
    let loi = this.solutionFiscale.typeFiscaliteModel.loiCourante;
    if (res.status === 200) {
      let datas: any = [];
      datas = await this.getLotBypartenaire(userData.id, loi, this.solutionFiscale.scpi);
      if (datas?.length > 0) {
        this.solutionFiscale.support.listLot = datas;
      } else {
        this.solutionFiscale.support.listLot = [];
      }
      return true;
    } else {
      return false;
    }
  }

  onChangeProdFisc(e: any) {
    const { id } = e.target;
    let res = true;
    switch (id) {
      case SORT_FISCALE.NOM_ASC:
        this.produitfiscArray.sort(function (a: any | null, b: any | null) {
          if (a.nom > b.nom) return 1;
          if (a.nom < b.nom) return -1;
          return 0;
        });
        break;
      case SORT_FISCALE.NOM_DESC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.nom > b.nom) return 1;
            if (a.nom < b.nom) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.TYPEEMPRUNT_ASC:
        this.produitfiscArray.sort(function (a: any | null, b: any | null) {
          if (a.type_Emprunt > b.type_Emprunt) return 1;
          if (a.type_Emprunt < b.type_Emprunt) return -1;
          return 0;
        });
        break;
      case SORT_FISCALE.TYPEEMPRUNT_DESC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.type_Emprunt > b.type_Emprunt) return 1;
            if (a.type_Emprunt < b.type_Emprunt) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.DUREE_ASC:
        this.produitfiscArray.sort(function (a: any | null, b: any | null) {
          if (a.duree > b.duree) return 1;
          if (a.duree < b.duree) return -1;
          return 0;
        });
        break;
      case SORT_FISCALE.DUREE_DESC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.duree > b.duree) return 1;
            if (a.duree < b.duree) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.TAUXEMPRUNT_ASC:
        this.produitfiscArray.sort(function (a: any | null, b: any | null) {
          if (a.tauxEmprunt > b.tauxEmprunt) return 1;
          if (a.tauxEmprunt < b.tauxEmprunt) return -1;
          return 0;
        });
        break;
      case SORT_FISCALE.TAUXEMPRUNT_DESC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.tauxEmprunt > b.tauxEmprunt) return 1;
            if (a.tauxEmprunt < b.tauxEmprunt) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.ASSURANCE_ASC:
        this.produitfiscArray.sort(function (a: any | null, b: any | null) {
          if (a.assurence > b.assurence) return 1;
          if (a.assurence < b.assurence) return -1;
          return 0;
        });
        break;
      case SORT_FISCALE.ASSURANCE_DESC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.assurence > b.assurence) return 1;
            if (a.assurence < b.assurence) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.DIFFERECAPITAL_ASC:
        this.produitfiscArray.sort(function (a: any | null, b: any | null) {
          if (a.differeCapital > b.differeCapital) return 1;
          if (a.differeCapital < b.differeCapital) return -1;
          return 0;
        });
        break;
      case SORT_FISCALE.DIFFERECAPITAL_DESC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.differeCapital > b.differeCapital) return 1;
            if (a.differeCapital < b.differeCapital) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.DIFFERECAPITALMOIS_ASC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.differeCapitalMois > b.differeCapitalMois) return 1;
            if (a.differeCapitalMois < b.differeCapitalMois) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.DIFFERECAPITALMOIS_DESC:
        this.produitfiscArray.sort(function (a: any | null, b: any | null) {
          if (a.differeCapitalMois > b.differeCapitalMois) return 1;
          if (a.differeCapitalMois < b.differeCapitalMois) return -1;
          return 0;
        });
        break;
      case SORT_FISCALE.DIFFEREINTERET_ASC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.differeInteret > b.differeInteret) return 1;
            if (a.differeInteret < b.differeInteret) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.DIFFEREINTERET_DESC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.differeInteret > b.differeInteret) return 1;
            if (a.differeInteret < b.differeInteret) return -1;
            return 0;
          })
          .reverse();
        break;

      case SORT_FISCALE.DIFFERETINTERETMOIS_ASC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.differeInteretMois > b.differeInteretMois) return 1;
            if (a.differeInteretMois < b.differeInteretMois) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.DIFFERETINTERETMOIS_DESC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.differeInteretMois > b.differeInteretMois) return 1;
            if (a.differeInteretMois < b.differeInteretMois) return -1;
            return 0;
          })
          .reverse();
        break;

      case SORT_FISCALE.TAUXCAPITAL_ASC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.txCapitalisation > b.txCapitalisation) return 1;
            if (a.txCapitalisation < b.txCapitalisation) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_FISCALE.TAUXCAPITAL_DESC:
        this.produitfiscArray
          .sort(function (a: any | null, b: any | null) {
            if (a.txCapitalisation > b.txCapitalisation) return 1;
            if (a.txCapitalisation < b.txCapitalisation) return -1;
            return 0;
          })
          .reverse();
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  onChangeProdImmo(e: any) {
    const { id } = e.target;
    let res = true;
    switch (id) {
      case SORT_IMMOBILIER.NOM_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.nom > b.nom) return 1;
          if (a.nom < b.nom) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.NOM_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.nom > b.nom) return 1;
            if (a.nom < b.nom) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.LOI_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.loi > b.loi) return 1;
          if (a.loi < b.loi) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.LOI_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.loi > b.loi) return 1;
            if (a.loi < b.loi) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.ANNELIVRAISON_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.anneLivraison > b.anneLivraison) return 1;
          if (a.anneLivraison < b.anneLivraison) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.ANNELIVRAISON_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.anneLivraison > b.anneLivraison) return 1;
            if (a.anneLivraison < b.anneLivraison) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.SURFACEFISCALE_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.surfacefiscale > b.surfacefiscale) return 1;
          if (a.surfacefiscale < b.surfacefiscale) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.SURFACEFISCALE_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.surfacefiscale > b.surfacefiscale) return 1;
            if (a.surfacefiscale < b.surfacefiscale) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.ZONE_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.zone > b.zone) return 1;
          if (a.zone < b.zone) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.ZONE_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.zone > b.zone) return 1;
            if (a.zone < b.zone) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.MONTANTTOTAL_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.montantTotal > b.montantTotal) return 1;
          if (a.montantTotal < b.montantTotal) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.MONTANTTOTAL_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.montantTotal > b.montantTotal) return 1;
            if (a.montantTotal < b.montantTotal) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.LOYERMENSUEL_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.loyerMensuel > b.loyerMensuel) return 1;
          if (a.loyerMensuel < b.loyerMensuel) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.LOYERMENSUEL_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.loyerMensuel > b.loyerMensuel) return 1;
            if (a.loyerMensuel < b.loyerMensuel) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.FRAISGESTION_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.fraisGestion > b.fraisGestion) return 1;
          if (a.fraisGestion < b.fraisGestion) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.FRAISGESTION_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.fraisGestion > b.fraisGestion) return 1;
            if (a.fraisGestion < b.fraisGestion) return -1;
            return 0;
          })
          .reverse();
        break;

      case SORT_IMMOBILIER.FRAISGARANTI_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.fraisGarantie > b.fraisGarantie) return 1;
          if (a.fraisGarantie < b.fraisGarantie) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.FRAISGARANTI_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.fraisGarantie > b.fraisGarantie) return 1;
            if (a.fraisGarantie < b.fraisGarantie) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.CHARGECOPROPRIETE_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.chargeCopropriete > b.chargeCopropriete) return 1;
          if (a.chargeCopropriete < b.chargeCopropriete) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.CHARGECOPROPRIETE_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.chargeCopropriete > b.chargeCopropriete) return 1;
            if (a.chargeCopropriete < b.chargeCopropriete) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.MONTANT_TAXE_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.montantTaxe > b.montantTaxe) return 1;
          if (a.montantTaxe < b.montantTaxe) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.MONTANT_TAXE_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.montantTaxe > b.montantTaxe) return 1;
            if (a.montantTaxe < b.montantTaxe) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.EXOTAXE_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.exoTaxe > b.exoTaxe) return 1;
          if (a.exoTaxe < b.exoTaxe) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.EXOTAXE_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.exoTaxe > b.exoTaxe) return 1;
            if (a.exoTaxe < b.exoTaxe) return -1;
            return 0;
          })
          .reverse();
        break;

      case SORT_IMMOBILIER.EXOTAXEPENDANT_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.exoTaxePendant > b.exoTaxePendant) return 1;
          if (a.exoTaxePendant < b.exoTaxePendant) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.EXOTAXEPENDANT_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.exoTaxePendant > b.exoTaxePendant) return 1;
            if (a.exoTaxePendant < b.exoTaxePendant) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_IMMOBILIER.TAUXCHARGE_ASC:
        this.produitImmobilierArray.sort(function (
          a: any | null,
          b: any | null
        ) {
          if (a.tauxCharge > b.tauxCharge) return 1;
          if (a.tauxCharge < b.tauxCharge) return -1;
          return 0;
        });
        break;
      case SORT_IMMOBILIER.TAUXCHARGE_DESC:
        this.produitImmobilierArray
          .sort(function (a: any | null, b: any | null) {
            if (a.tauxCharge > b.tauxCharge) return 1;
            if (a.tauxCharge < b.tauxCharge) return -1;
            return 0;
          })
          .reverse();
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  onChangeClient(e: any) {
    const { id } = e.target;
    let res = true;
    switch (id) {
      case SORT_CLIENT.NOM_ASC:
        this.clientArray.sort(function (a: any | null, b: any | null) {
          if (a.nom > b.nom) return 1;
          if (a.nom < b.nom) return -1;
          return 0;
        });
        break;
      case SORT_CLIENT.NOM_DESC:
        this.clientArray
          .sort(function (a: any | null, b: any | null) {
            if (a.nom > b.nom) return 1;
            if (a.nom < b.nom) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_CLIENT.IMPOT_REFERENCE_ASC:
        this.clientArray.sort(function (a: any | null, b: any | null) {
          if (a.impot > b.impot) return 1;
          if (a.impot < b.impot) return -1;
          return 0;
        });
        break;
      case SORT_CLIENT.IMPOT_REFERENCE_DESC:
        this.clientArray
          .sort(function (a: any | null, b: any | null) {
            if (a.impot > b.impot) return 1;
            if (a.impot < b.impot) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_CLIENT.TRANCHE_MARGINALE_IMPOT_ASC:
        this.clientArray.sort(function (a: any | null, b: any | null) {
          if (a.tache_marginale > b.tache_marginale) return 1;
          if (a.tache_marginale < b.tache_marginale) return -1;
          return 0;
        });
        break;
      case SORT_CLIENT.TRANCHE_MARGINALE_IMPOT_DESC:
        this.clientArray
          .sort(function (a: any | null, b: any | null) {
            if (a.tache_marginale > b.tache_marginale) return 1;
            if (a.tache_marginale < b.tache_marginale) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_CLIENT.NB_PARTFISCALE_ASC:
        this.clientArray.sort(function (a: any | null, b: any | null) {
          if (a.nbr_part > b.nbr_part) return 1;
          if (a.nbr_part < b.nbr_part) return -1;
          return 0;
        });
        break;
      case SORT_CLIENT.NB_PARTFISCALE_DESC:
        this.clientArray
          .sort(function (a: any | null, b: any | null) {
            if (a.nbr_part > b.nbr_part) return 1;
            if (a.nbr_part < b.nbr_part) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_CLIENT.RESULTAT_FONCIER_ASC:
        this.clientArray.sort(function (a: any | null, b: any | null) {
          if (a.resultat_foncier > b.resultat_foncier) return 1;
          if (a.resultat_foncier < b.resultat_foncier) return -1;
          return 0;
        });
        break;
      case SORT_CLIENT.RESULTAT_FONCIER_DESC:
        this.clientArray
          .sort(function (a: any | null, b: any | null) {
            if (a.resultat_foncier > b.resultat_foncier) return 1;
            if (a.resultat_foncier < b.resultat_foncier) return -1;
            return 0;
          })
          .reverse();
        break;
      case SORT_CLIENT.DATE_SIMULATION_ASC:
        this.clientArray.sort(function (a: any | null, b: any | null) {
          if (a.dateSimulation > b.dateSimulation) return 1;
          if (a.dateSimulation < b.dateSimulation) return -1;
          return 0;
        });
        break;
      case SORT_CLIENT.DATE_SIMULATION_DESC:
        this.clientArray
          .sort(function (a: any | null, b: any | null) {
            if (a.dateSimulation > b.dateSimulation) return 1;
            if (a.dateSimulation < b.dateSimulation) return -1;
            return 0;
          })
          .reverse();
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  onChange(e: any) {
    const { id } = e.target;
    let res = true;
    switch (id) {
      case appConstants.SORT.SORTBYCLIENTASC:
        this.accueilArray.sort(function (a: any | null, b: any | null) {
          if (a.client > b.client) return 1;
          if (a.client < b.client) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYCLIENTDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.client > b.client) return 1;
            if (a.client < b.client) return -1;
            return 0;
          })
          .reverse();
        break;
      case appConstants.SORT.SORTBYCREDITASC:
        this.accueilArray.sort(function (a: any, b: any) {
          if (a.credit > b.credit) return 1;
          if (a.credit < b.credit) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYCREDITDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.credit > b.credit) return 1;
            if (a.credit < b.credit) return -1;
            return 0;
          })
          .reverse();
        break;
      case appConstants.SORT.SORTBYDATEASC:
        this.accueilArray.sort(function (a: any, b: any) {
          if (a.date_simulation > b.date_simulation) return 1;
          if (a.date_simulation < b.date_simulation) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYDATEDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.date_simulation > b.date_simulation) return 1;
            if (a.date_simulation < b.date_simulation) return -1;
            return 0;
          })
          .reverse();
        break;
      case appConstants.SORT.SORTBYIMPOTSASC:
        this.accueilArray.sort(function (a: any, b: any) {
          if (a.impots > b.impots) return 1;
          if (a.impots < b.impots) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYIMPOTSDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.impots > b.impots) return 1;
            if (a.impots < b.impots) return -1;
            return 0;
          })
          .reverse();
        break;
      case appConstants.SORT.SORTBYLOIASC:
        this.accueilArray.sort(function (a: any, b: any) {
          if (a.loi > b.loi) return 1;
          if (a.loi < b.loi) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYLOIDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.loi > b.loi) return 1;
            if (a.loi < b.loi) return -1;
            return 0;
          })
          .reverse();
        break;
      case appConstants.SORT.SORTBYLOTASC:
        this.accueilArray.sort(function (a: any, b: any) {
          if (a.lot > b.lot) return 1;
          if (a.lot < b.lot) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYLOTDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.lot > b.lot) return 1;
            if (a.lot < b.lot) return -1;
            return 0;
          })
          .reverse();
        break;
      case appConstants.SORT.SORTBYPARTASC:
        this.accueilArray.sort(function (a: any, b: any) {
          if (a.part > b.part) return 1;
          if (a.part < b.part) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYPARTDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.part > b.part) return 1;
            if (a.part < b.part) return -1;
            return 0;
          })
          .reverse();
        break;
      case appConstants.SORT.SORTBYPRIXASC:
        this.accueilArray.sort(function (a: any, b: any) {
          if (a.prix > b.prix) return 1;
          if (a.prix < b.prix) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYPRIXDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.prix > b.prix) return 1;
            if (a.prix < b.prix) return -1;
            return 0;
          })
          .reverse();
        break;
      case appConstants.SORT.SORTBYTAUXASC:
        this.accueilArray.sort(function (a: any, b: any) {
          if (a.taux > b.taux) return 1;
          if (a.taux < b.taux) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYTAUXDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.taux > b.taux) return 1;
            if (a.taux < b.taux) return -1;
            return 0;
          })
          .reverse();
        break;
      case appConstants.SORT.SORTBYRESULTATASC:
        this.accueilArray.sort(function (a: any, b: any) {
          if (a.resulta_fiscal > b.resulta_fiscal) return 1;
          if (a.resulta_fiscal < b.resulta_fiscal) return -1;
          return 0;
        });
        break;
      case appConstants.SORT.SORTBYRESULTATDESC:
        this.accueilArray
          .sort(function (a: any, b: any) {
            if (a.resulta_fiscal > b.resulta_fiscal) return 1;
            if (a.resulta_fiscal < b.resulta_fiscal) return -1;
            return 0;
          })
          .reverse();
        break;

      default:
        res = false;
        break;
    }
    return res;
  }

  // Gestion de financement
  async addProduit() {
    let userData = this.auth.loginAuth.utilisateur;
    let data = {
      ...this.solutionFiscale.financement.monofinancement.serializeProduitFinac(),
      partenaireId: userData.id,
      nom:
        userData.nom +
        "" +
        new Date().getDate() +
        "/" +
        new Date().getMonth() +
        "/" +
        new Date().getFullYear(),
    };
    let res = await restClient.post(
      "/financement/ProduitFinanciers/addProduitFinancier",
      data
    );
    if (res.status === 200) {
      let datas: any = [];
      datas = await this.getListProduitFinancementBypartenaire(userData.id);
      if (datas?.length > 0) {
        this.solutionFiscale.financement.monofinancement.listProduitFinancier = datas;
      } else {
        this.solutionFiscale.financement.monofinancement.listProduitFinancier = [];
      }
      return true;
    }
    return false;
  }

  //ajout des produit M1 M2 M3 et infiné dans multi-financement
  async addProduitMulti(key: any) {
    let userData = this.auth.loginAuth.utilisateur;
    let data;
    console.log(key);
    if (key === "ammortissement 1") {
      data = {
        ...this.solutionFiscale.financement.amortissement1.serializeProduitFinacAmmortissable(),
        partenaireId: userData.id,
        nom:
          userData.nom +
          "" +
          new Date().getDate() +
          "/" +
          new Date().getMonth() +
          "/" +
          new Date().getFullYear(),
      };
    } else if (key === "ammortissement 2") {
      data = {
        ...this.solutionFiscale.financement.amortissement2.serializeProduitFinacAmmortissable(),
        partenaireId: userData.id,
        nom:
          userData.nom +
          "" +
          new Date().getDate() +
          "/" +
          new Date().getMonth() +
          "/" +
          new Date().getFullYear(),
      };
    } else if (key === "ammortissement 3") {
      data = {
        ...this.solutionFiscale.financement.amortissement3.serializeProduitFinacAmmortissable(),
        partenaireId: userData.id,
        nom:
          userData.nom +
          "" +
          new Date().getDate() +
          "/" +
          new Date().getMonth() +
          "/" +
          new Date().getFullYear(),
      };
    } else if (key === "infine") {
      data = {
        ...this.solutionFiscale.financement.inFine.serializeProduitFinacInfine(),
        partenaireId: userData.id,
        nom:
          userData.nom +
          "" +
          new Date().getDate() +
          "/" +
          new Date().getMonth() +
          "/" +
          new Date().getFullYear(),
      };
    }
    console.log("resss ===");
    let res = await restClient.post(
      "/financement/ProduitFinanciers/addProduitFinancier",
      data
    );
    console.log("resss ===", res);
    if (res.status === 200) {
      let datas: any = [];
      datas = await this.getListProduitFinancementBypartenaire(userData.id);
      if (datas?.length > 0) {
        this.solutionFiscale.financement.amortissement1.listProduitFinancier = datas;
        this.solutionFiscale.financement.amortissement2.listProduitFinancier = datas;
        this.solutionFiscale.financement.amortissement3.listProduitFinancier = datas;
        this.solutionFiscale.financement.inFine.listProduitFinancier = datas;
      } else {
        this.solutionFiscale.financement.amortissement1.listProduitFinancier = [];
        this.solutionFiscale.financement.amortissement2.listProduitFinancier = [];
        this.solutionFiscale.financement.amortissement3.listProduitFinancier = [];
        this.solutionFiscale.financement.inFine.listProduitFinancier = []
      }
      return true;
    }
    return false;
  }

  async getListProduitFinancementBypartenaire(userId: number | null) {
    let res = await restClient.get("/financement/ProduitFinanciers/" + userId);
    if (res.status === 200) {
      return this.solutionFiscale.financement.monofinancement.hydrateProduitFiArray(
        res.data
      );
    } else {
      return null;
    }
  }

  async getListProduitFinancement1Bypartenaire(userId: number | null) {
    let res = await restClient.get("/financement/ProduitFinanciers/" + userId);
    if (res.status === 200) {
      return this.solutionFiscale.financement.amortissement1.hydrateProduitFiArray(
        res.data
      );
    } else {
      return null;
    }
  }

  async getListProduitFinancement2Bypartenaire(userId: number | null) {
    let res = await restClient.get("/financement/ProduitFinanciers/" + userId);
    if (res.status === 200) {
      return this.solutionFiscale.financement.amortissement2.hydrateProduitFiArray(
        res.data
      );
    } else {
      return null;
    }
  }

  async getListProduitFinancement3Bypartenaire(userId: number | null) {
    let res = await restClient.get("/financement/ProduitFinanciers/" + userId);
    if (res.status === 200) {
      return this.solutionFiscale.financement.amortissement3.hydrateProduitFiArray(
        res.data
      );
    } else {
      return null;
    }
  }

  async getListProduitFinancementinFineBypartenaire(userId: number | null) {
    let res = await restClient.get("/financement/ProduitFinanciers/" + userId);
    if (res.status === 200) {
      return this.solutionFiscale.financement.inFine.hydrateProduitFiArray(
        res.data
      );
    } else {
      return null;
    }
  }

  async addProduitMultiFinancierById() {
    let dossierId = localStorage.getItem("keyupdate");
    let data = {
      ...this.solutionFiscale.financement.serializeMultiplefinancement(),
      fraisIntercalaire: this.solutionFiscale.support.estimateurFrais.montant_int,
      montantFraisDeductibleTotal: this.solutionFiscale.support.estimateurFrais.montantFraisDeductibleTotal,
    };
    console.log("====add multi financement====");
    console.log(data);
    console.log("====add multi financement====");
    let res = await restClient.post(
      "/financement/multi/addorupdate/" + dossierId,
      data
    );
    if (res.status === 200) {
      //this.solutionFiscale.financement.monofinancement.apport_initial.versement_initial
      return res.data;
    } else {
      return false;
    }
  }

  async calculAdossementPlacementMulti(path: string) {
    let dossierId = localStorage.getItem("keyupdate");
    // let userData = this.auth.loginAuth.utilisateur;
    let data = this.solutionFiscale.financement.serializeMultiplefinancement();
    let res = await restClient.post(path + "" + dossierId, data);
    if (res.status === 200) {
      return res.data;
    } else {
      return false;
    }
  }

  async calculAdossementPlacement(path: string) {
    let dossierId = localStorage.getItem("keyupdate");
    let data = {
      ...this.solutionFiscale.financement.monofinancement.serialiseMonoFinacement(),
      multiFinancement:
        this.solutionFiscale.mono_financement_mode === true ? false : true,
    };
    let res = await restClient.post(path + "" + dossierId, data);
    if (res.status === 200) {
      return res.data;
    } else {
      return false;
    }
  }

  async calculInteretDiffere() {
    let dossierId = localStorage.getItem("keyupdate");
    let data = {
      ...this.solutionFiscale.financement.monofinancement.serialiseMonoFinacement(),
      multiFinancement:
        this.solutionFiscale.mono_financement_mode === true ? false : true,
    };
    let res = await restClient.post(
      "/financement/mensualite/interet/calcul/" + dossierId,
      data
    );
    if (res.status === 200) {
      console.log(res.data);
      return res.data;
    } else {
      return false;
    }
  }

  async addProduitMonoFinancierById() {
    let dossierId = localStorage.getItem("keyupdate");
    let data = {
      ...this.solutionFiscale.financement.monofinancement.serialiseMonoFinacement(),
      multiFinancement:
        this.solutionFiscale.mono_financement_mode === true ? false : true,
      fraisIntercalaire: this.solutionFiscale.support.estimateurFrais.montant_int,
      montantFraisDeductibleTotal: this.solutionFiscale.support.estimateurFrais.montantFraisDeductibleTotal,
    };
    console.log("====add financement====");
    console.log(data);
    console.log("====add financement====");
    let res = await restClient.post(
      "/financement/addorupdate/" + dossierId,
      data
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return false;
    }
  }

  async calculMensualiteInteretFinancement() {
    let dossierId = localStorage.getItem("keyupdate");
    let data = {
      ...this.solutionFiscale.financement.monofinancement.serialiseMonoFinacement(),
      multiFinancement:
        this.solutionFiscale.mono_financement_mode === true ? false : true,
    };
    let res = await restClient.post(
      "/financement/mensualite/interet/calcul/" + dossierId,
      data
    );
    if (res.status === 200) {
      return res.data;
    } else {
      return false;
    }
  }

  async suppressionMultiProduitFiscale(key: any) {
    let userData = this.auth.loginAuth.utilisateur;
    console.log(key);
    let res = await restClient.post(
      "/financement/ProduitFinanciers/deleteProduitFinancier/" + key,
      key
    );
    if (res.status === 200) {
      let datas: any = [];
      datas = await this.getListProduitFinancement1Bypartenaire(userData.id);
      if (datas?.length > 0) {
        this.solutionFiscale.financement.amortissement1.listProduitFinancier = datas;
        this.solutionFiscale.financement.amortissement2.listProduitFinancier = datas;
        this.solutionFiscale.financement.amortissement3.listProduitFinancier = datas;
        this.solutionFiscale.financement.inFine.listProduitFinancier = datas;
      } else {
        this.solutionFiscale.financement.amortissement1.listProduitFinancier = [];
        this.solutionFiscale.financement.amortissement2.listProduitFinancier = [];
        this.solutionFiscale.financement.amortissement3.listProduitFinancier = [];
        this.solutionFiscale.financement.inFine.listProduitFinancier = [];
      }
      return true;
    } else {
      return false;
    }
  }

  async suppressionProduitFiscale(key: any) {
    let userData = this.auth.loginAuth.utilisateur;
    console.log(key);
    let res = await restClient.post(
      "/financement/ProduitFinanciers/deleteProduitFinancier/" + key,
      key
    );
    if (res.status === 200) {
      let datas: any = [];
      datas = await this.getListProduitFinancementBypartenaire(userData.id);
      if (datas?.length > 0) {
        this.solutionFiscale.financement.monofinancement.listProduitFinancier = datas;
      } else {
        this.solutionFiscale.financement.monofinancement.listProduitFinancier = [];
      }
      return true;
    } else {
      return false;
    }
  }

  async addPlacement() {
    let dossierId = localStorage.getItem("keyupdate");
    let data = this.solutionFiscale.placement.serialisePlacement();
    let res = await restClient.post(
      "/placement/addorupdate/" + dossierId,
      data
    );
    if (res.status === 200) {
      return true;
    }
    return false;
  }

  async calculVersementMinimumAdossementPlacement() {
    let dossierId = localStorage.getItem("keyupdate");
    let data = {
      ...this.solutionFiscale.financement.monofinancement.serialiseMonoFinacement(),
      multiFinancement:
        this.solutionFiscale.mono_financement_mode === true ? false : true,
    };
    let res = await restClient.post(
      "/financement/adossement/versememt/minimum/calcul/" + dossierId,
      data
    );
    if (res.status === 200) {
      //this.solutionFiscale.financement.monofinancement.apport_initial.versement_initial
      return res.data;
    } else {
      return false;
    }
  }

  async calculMensualiteInteretFinancementMulti(path: string) {
    let dossierId = localStorage.getItem("keyupdate");
    let data = this.solutionFiscale.financement.serializeMultiplefinancement();

    console.log("==== calcul sur mensualité====");
    console.log(data);
    console.log("====calcul sur mensualité====");
    let res = await restClient.post(path + "" + dossierId, data);
    if (res.status === 200) {
      //this.solutionFiscale.financement.monofinancement.apport_initial.versement_initial
      console.log("==== resultat calcul sur mensualité====");
      console.log(res.data);
      console.log("==== resultat calcul sur mensualité====");
      return res.data;
    } else {
      return false;
    }
  }

  calculeMontantFinance() {
    if (this.solutionFiscale.mono_financement_mode) {
      this.calculeMontantFinanceMono();
    }
    else {
      this.calculeMontantFinanceMulti();
    }
  }
  calculeMontantFinanceMono() {
    const { financement, support, scpi, typeFiscaliteModel } = this.solutionFiscale;
    const montantProduitTemp = (!scpi) ? support.supportImmobilier.choixImmobilier.montant_produit : support.supportSCPI.choixImmobilier.montant_produit;
    const montantProduit = montantProduitTemp != null ? montantProduitTemp : 0;
    const fraisSupplementaireTemp = (!scpi) ? support.supportImmobilier.choixImmobilier.frais_suplementaire : support.supportSCPI.choixImmobilier.frais_suplementaire;
    const fraisSupplementaire = fraisSupplementaireTemp != null ? fraisSupplementaireTemp : 0;
    const checkFraisSup = financement.monofinancement.choix_financement.financementFraisDeductible;
    const checkFraisRest = financement.monofinancement.choix_financement.financementPremierAnnee;
    const fraisRestaurationTemp = (typeFiscaliteModel.loiCourante === "Demembrement") ?
      support.supportImmobilier.choixImmobilier.dont_montant_reductible :
      support.revenuLocatifImmobillier.fraisRestauration.montant;
    const fraisRestauration = fraisRestaurationTemp != null ? fraisRestaurationTemp : 0;
    let appPers = financement.monofinancement.choix_financement.apportPersonnel != null ? financement.monofinancement.choix_financement.apportPersonnel : 0;
    const fraisEntree = support?.supportSCPI?.chargeIntegre?.frais_entree != null ? support?.supportSCPI?.chargeIntegre?.frais_entree : 0;
    const pasFinan = financement.monofinancement.choix_financement.pasFinancement

    const fiscalite = this.solutionFiscale.currentFiscalite;
    console.log("----" + fiscalite);
    const tvaLmnpActive = this.solutionFiscale?.typeFiscaliteModel?.lmnp?.tva_oui;
    const tvaLmpActive = this.solutionFiscale?.typeFiscaliteModel?.lmp?.tva_oui;
    const tvaMonumentActive = this.solutionFiscale?.typeFiscaliteModel?.monument?.tva_oui;
    const tvaRegimeActive = this.solutionFiscale?.typeFiscaliteModel?.regime?.tva_oui;
    const tvaZrrActive = this.solutionFiscale?.typeFiscaliteModel?.zrr?.tva_oui;

    let tva = support?.supportImmobilier?.choixImmobilier?.montantTVARecup || 0;
    console.log("----tva = " + tva);

    let montantFinance = 0;
    financement.monofinancement.caracteristique_financement.montant_financement = 0;
    financement.monofinancement.trouve_mensualite.capitale_aterme = 0;
    financement.monofinancement.apport_initial_futur.montantApportInitVersement.montant = 0;
    financement.monofinancement.versementSupp_futur.montantVersement.montant = 0;

    if (financement.monofinancement.choix_financement.apportPersonnelSavStatus /* && pasFinan */) {
      appPers = 0;
      financement.monofinancement.choix_financement.apportPersonnelSavStatus = false;
      financement.monofinancement.choix_financement.apportPersonnel = appPers;
    }

    if (!pasFinan) {
      montantFinance = Math.round(montantFinance) + Math.round(montantProduit) - Math.round(appPers);
      if (checkFraisSup) {
        montantFinance = Math.round(montantFinance) + Math.round(fraisSupplementaire);
      }

      if (checkFraisRest) {
        montantFinance = Math.round(montantFinance) + Math.round(fraisRestauration);
      }

      if (scpi) {
        montantFinance = montantFinance + Math.round(fraisEntree) * Math.round(montantProduit) / 100;
      }

      if (tvaLmnpActive || tvaLmpActive || tvaMonumentActive || tvaRegimeActive || tvaZrrActive) {
        montantFinance = montantFinance + Math.round(tva);
      }

      financement.monofinancement.caracteristique_financement.montant_financement = montantFinance;
      financement.monofinancement.trouve_mensualite.capitale_aterme = montantFinance;
      financement.monofinancement.apport_initial_futur.montantApportInitVersement.montant = montantFinance;
      financement.monofinancement.versementSupp_futur.montantVersement.montant = montantFinance;
      financement.monofinancement.errMontantFinencent = (montantFinance !== null && montantFinance !== 0) ? false : true;
    }
    else {
      financement.monofinancement.caracteristique_financement.montant_financement = 0;
      financement.monofinancement.trouve_mensualite.capitale_aterme = 0;
      financement.monofinancement.apport_initial_futur.montantApportInitVersement.montant = 0;
      financement.monofinancement.versementSupp_futur.montantVersement.montant = 0;
      financement.monofinancement.errMontantFinencent = false;
    }
    this.calculSoit();
  }

  calculSoit() {
    const { financement, support, scpi, typeFiscaliteModel } = this.solutionFiscale;
    const checkFraisSup = financement.monofinancement.choix_financement.financementFraisDeductible;
    const appPers = financement.monofinancement.choix_financement.apportPersonnel != null ? financement.monofinancement.choix_financement.apportPersonnel : 0;
    const pasFinan = financement.monofinancement.choix_financement.pasFinancement
    const checkFraisRest = financement.monofinancement.choix_financement.financementPremierAnnee;
    const montantProduitTemp = (!scpi) ? support.supportImmobilier.choixImmobilier.montant_produit : support.supportSCPI.choixImmobilier.montant_produit;
    const montantProduit = montantProduitTemp != null ? montantProduitTemp : 0;
    const fraisSupplementaireTemp = (!scpi) ?
      support.supportImmobilier.choixImmobilier.frais_suplementaire :
      support.supportSCPI.choixImmobilier.frais_suplementaire;
    const fraisSupplementaire = fraisSupplementaireTemp != null ? fraisSupplementaireTemp : 0;
    const fraisRestaurationTemp = (typeFiscaliteModel.loiCourante === "Demembrement") ?
      support.supportImmobilier.choixImmobilier.dont_montant_reductible :
      support.revenuLocatifImmobillier.fraisRestauration.montant;
    const fraisEntree = support?.supportSCPI?.chargeIntegre?.frais_entree != null ? support?.supportSCPI?.chargeIntegre?.frais_entree : 0;
    const fraisRestauration = fraisRestaurationTemp != null ? fraisRestaurationTemp : 0;

    const fiscalite = this.solutionFiscale.currentFiscalite;
    console.log("----" + fiscalite);
/*     const tvaLmnpActive = this.solutionFiscale?.typeFiscaliteModel?.lmnp?.tva_oui;
    const tvaLmpActive = this.solutionFiscale?.typeFiscaliteModel?.lmp?.tva_oui;
    const tvaMonumentActive = this.solutionFiscale?.typeFiscaliteModel?.monument?.tva_oui;
    const tvaRegimeActive = this.solutionFiscale?.typeFiscaliteModel?.regime?.tva_oui;
    const tvaZrrActive = this.solutionFiscale?.typeFiscaliteModel?.zrr?.tva_oui;
 */
    let tva = support?.supportImmobilier?.choixImmobilier?.montantTVARecup || 0;
    console.log("----tva = " + tva);

    let soit = 0;
    financement.monofinancement.choix_financement.soit = 0;
    if (!pasFinan) {
      soit = Math.round(appPers);
      if (financement.monofinancement.choix_financement.apportPersonnelSavStatus) {
        financement.monofinancement.choix_financement.apportPersonnel = financement.monofinancement.choix_financement.apportPersonnelSav;
        financement.monofinancement.choix_financement.apportPersonnelSavStatus = false;
      }
      else {
      }
    }
    else {
      financement.monofinancement.choix_financement.apportPersonnelSavStatus = true;
      financement.monofinancement.choix_financement.apportPersonnelSav = appPers;
    }
    if (!checkFraisSup || pasFinan) {
      soit = soit + Math.round(fraisSupplementaire);
    }

    if (!checkFraisRest || pasFinan) {
      soit = soit + Math.round(fraisRestauration);
    }

    if (pasFinan) {
      soit = soit + Math.round(montantProduit);
    }

    if (scpi && pasFinan) {
      soit = soit + Math.round(fraisEntree) * (null !== montantProduit ? montantProduit : 0) / 100;
    }

    /* if (tvaLmnpActive || tvaLmpActive || tvaMonumentActive || tvaRegimeActive || tvaZrrActive) {
      soit = soit + Math.round(tva);
    } */
    if (pasFinan) {
      financement.monofinancement.choix_financement.apportPersonnel = soit;
      financement.monofinancement.choix_financement.apportPersonnelSav = appPers;
    }


    financement.monofinancement.choix_financement.soit = soit;

  }

  setDureePlacement() {
    let modeFinancement = this.solutionFiscale.mono_financement_mode;
    let pasDeFinancement = false;
    let dureeCapitalisation = null;
    if (modeFinancement) {
      pasDeFinancement = this.solutionFiscale.financement.monofinancement.choix_financement.pasFinancement;
    }
    else {
      pasDeFinancement = this.solutionFiscale.financement.pasFinancement;
    }

    if (!pasDeFinancement) {
      if (null != this.solutionFiscale?.financement?.monofinancement && null != this.solutionFiscale.placement) {
        let dureeFinanc = this.solutionFiscale?.financement?.monofinancement?.caracteristique_financement?.duree != null ? this.solutionFiscale?.financement?.monofinancement?.caracteristique_financement?.duree : 0;

        // Duree Capitalisation
        let nbMoisDiffereCapital = this.solutionFiscale?.financement?.monofinancement?.caracteristique_financement.mois_rembourssement_capital != null ? this.solutionFiscale?.financement.monofinancement?.caracteristique_financement?.mois_rembourssement_capital : 0;
        dureeCapitalisation = Math.round(dureeFinanc);

        if (this.solutionFiscale?.financement?.monofinancement?.caracteristique_financement.rembourssement_capital) {
          dureeCapitalisation = dureeCapitalisation + Math.round(nbMoisDiffereCapital) / 12;
        }
      }
      else {
        dureeCapitalisation = 0;
      }
    }
    if (pasDeFinancement) {
      this.solutionFiscale.placement.resetPlacement();
    }
    // Duree Capitalisation TVA
    this.solutionFiscale.placement.placementDuree = dureeCapitalisation

    // Duree Capitalisation Treso
    this.solutionFiscale.placement.capitalisationDuree = dureeCapitalisation
  }

  isNullOrEmpty(value: any) {
    return value === null || value === 0 || value === "0" || value === "" || value === undefined;
  }

   isNullOrEmptyOrUndefined(value: any) {
    return value === null || value === "" || value === undefined;
  }

  async calculeLoyerMensuelMontantDiffereMinimal() {
    const amortissable = this.solutionFiscale.financement.monofinancement.caracteristique_financement.amortissable;
    var validate = this.solutionFiscale.financement.getActiveCalculeLoyerMensuel();
    const pasFinan = this.solutionFiscale.financement.monofinancement.choix_financement.pasFinancement;

    let monofinancement = this.solutionFiscale.financement.monofinancement;
    if (!pasFinan) {
      if (
        this.isNullOrEmpty(monofinancement.caracteristique_financement.montant_financement) || this.isNullOrEmpty(monofinancement.caracteristique_financement.duree) || this.isNullOrEmpty(monofinancement.caracteristique_financement.taux_interet)
      ) {
        validate = false;
      }
      if (validate) {
        if (monofinancement.caracteristique_financement.rembourssement_interet && amortissable === true) {
          let resp = await this.calculMensualiteInteretFinancement();
          if (resp !== false) {
            this.solutionFiscale.financement.monofinancement.caracteristique_financement.montantInteretDiffere = resp.montantInteretDiffere;
            this.solutionFiscale.financement.monofinancement.versementSupp_futur.montantVersement.montant = resp.capitalATerme;
            this.solutionFiscale.financement.monofinancement.apport_initial_futur.montantApportInitVersement.montant = resp.capitalATerme;
            this.solutionFiscale.financement.monofinancement.trouve_mensualite.capitale_aterme = resp.capitalATerme;
            this.solutionFiscale.financement.monofinancement.caracteristique_financement.mensualiteCreditTotal = resp.mensualiteCreditTotal;
            this.solutionFiscale.financement.monofinancement.apport_initial.versementMensuelMinim = resp.mensualiteMinimal;

            this.solutionFiscale.financement.monofinancement.apport_initial.versement_initial = null;
            this.solutionFiscale.financement.monofinancement.trouve_mensualite.versement_mensuel = null;
            this.solutionFiscale.financement.monofinancement.capital_terme.capitale_aterme = null;

            //this.setDureePlacement();

            this.solutionFiscale.financement.monofinancement.capital_terme.capitale_aterme = null;
          } else {
            throw new Error("Une erreur s'est produite lors du calcul");
          }
        } else {
          let resp = await this.calculMensualiteInteretFinancement();
          if (resp !== false) {
            this.solutionFiscale.financement.monofinancement.caracteristique_financement.montantInteretDiffere = resp.montantInteretDiffere;
            this.solutionFiscale.financement.monofinancement.caracteristique_financement.mensualiteCreditTotal = resp.mensualiteCreditTotal;
            this.solutionFiscale.financement.monofinancement.apport_initial.versementMensuelMinim = resp.mensualiteMinimal;
            this.solutionFiscale.financement.monofinancement.versementSupp_futur.montantVersement.montant = resp.capitalATerme;
            this.solutionFiscale.financement.monofinancement.trouve_mensualite.capitale_aterme = resp.capitalATerme;
            this.solutionFiscale.financement.monofinancement.apport_initial_futur.montantApportInitVersement.montant = resp.capitalATerme;

            this.solutionFiscale.financement.monofinancement.apport_initial.versement_initial = null;
            this.solutionFiscale.financement.monofinancement.trouve_mensualite.versement_mensuel = null;
            this.solutionFiscale.financement.monofinancement.capital_terme.capitale_aterme = null;
          } else {
            throw new Error("Une erreur s'est produite lors du calcul");
          }
        }
      }
    }
    else {
      this.solutionFiscale.financement.monofinancement.caracteristique_financement.montantInteretDiffere = 0;
      this.solutionFiscale.financement.monofinancement.versementSupp_futur.montantVersement.montant = 0;
      this.solutionFiscale.financement.monofinancement.apport_initial_futur.montantApportInitVersement.montant = 0;
      this.solutionFiscale.financement.monofinancement.trouve_mensualite.capitale_aterme = 0;
      this.solutionFiscale.financement.monofinancement.caracteristique_financement.mensualiteCreditTotal = 0;
      this.solutionFiscale.financement.monofinancement.apport_initial.versementMensuelMinim = 0;

      this.solutionFiscale.financement.monofinancement.apport_initial.versement_initial = null;
      this.solutionFiscale.financement.monofinancement.trouve_mensualite.versement_mensuel = null;
      this.solutionFiscale.financement.monofinancement.capital_terme.capitale_aterme = null;

      this.setDureePlacement();

      this.solutionFiscale.financement.monofinancement.capital_terme.capitale_aterme = null;
    }
  }

  calculeMontantFinanceMulti() {
    const { financement, support, scpi, typeFiscaliteModel } = this.solutionFiscale;
    const montantProduitTemp = (!scpi) ? support.supportImmobilier.choixImmobilier.montant_produit : support.supportSCPI.choixImmobilier.montant_produit;

    const montantProduit = montantProduitTemp != null ? montantProduitTemp : 0;
    const fraisSupplementaireTemp = (!scpi) ? support.supportImmobilier.choixImmobilier.frais_suplementaire : support.supportSCPI.choixImmobilier.frais_suplementaire;
    const fraisSupplementaire = fraisSupplementaireTemp != null ? fraisSupplementaireTemp : 0;
    const checkFraisSup = financement.frais_deductible;
    const checkFraisRest = financement.financementravaux;
    const fraisRestaurationTemp = (typeFiscaliteModel.loiCourante === "Demembrement") ?
      support.supportImmobilier.choixImmobilier.dont_montant_reductible :
      support.revenuLocatifImmobillier.fraisRestauration.montant;
    const fraisRestauration = fraisRestaurationTemp != null ? fraisRestaurationTemp : 0;
    let appPers = financement.apportPersonnel != null ? financement.apportPersonnel : 0;
    const fraisEntree = support?.supportSCPI?.chargeIntegre?.frais_entree != null ? support?.supportSCPI?.chargeIntegre?.frais_entree : 0;
    let pasFinan = false;
    if (financement.monofinancement) {
      pasFinan = financement.monofinancement.choix_financement.pasFinancement;
    }
    else {
      pasFinan = financement.pasFinancement;
    }
    console.log("----" + pasFinan);

    const fiscalite = this.solutionFiscale.currentFiscalite;
    console.log("----" + fiscalite);
    const tvaLmnpActive = this.solutionFiscale?.typeFiscaliteModel?.lmnp?.tva_oui;
    const tvaLmpActive = this.solutionFiscale?.typeFiscaliteModel?.lmp?.tva_oui;
    const tvaMonumentActive = this.solutionFiscale?.typeFiscaliteModel?.monument?.tva_oui;
    const tvaRegimeActive = this.solutionFiscale?.typeFiscaliteModel?.regime?.tva_oui;
    const tvaZrrActive = this.solutionFiscale?.typeFiscaliteModel?.zrr?.tva_oui;

    let tva = support?.supportImmobilier?.choixImmobilier?.montantTVARecup || 0;
    console.log("----tva = " + tva);

    let montantFinance = 0;
    financement.monofinancement.caracteristique_financement.montant_financement = 0;
    financement.monofinancement.trouve_mensualite.capitale_aterme = 0;
    financement.monofinancement.apport_initial_futur.montantApportInitVersement.montant = 0;
    financement.monofinancement.versementSupp_futur.montantVersement.montant = 0;

    if (!pasFinan && financement.apportPersonnelSavStatus) {
      // appPers = financement?.apportPersonnelSav || 0;
      financement.apportPersonnel = appPers;
    }
    else {
      appPers = 0;
    }

    montantFinance = Math.round(montantFinance) + Math.round(montantProduit) - Math.round(appPers);
    if (checkFraisSup || pasFinan) {
      montantFinance = Math.round(montantFinance) + Math.round(fraisSupplementaire);
    }

    if (checkFraisRest) {
      montantFinance = Math.round(montantFinance) + Math.round(fraisRestauration);
    }

    if (scpi) {
      montantFinance = montantFinance + Math.round(fraisEntree) * Math.round(montantProduit) / 100;
    }

    if (tvaLmnpActive || tvaLmpActive || tvaMonumentActive || tvaRegimeActive || tvaZrrActive) {
      montantFinance = montantFinance + Math.round(tva);
    }

    financement.montantAfinancer = montantFinance;
    if (pasFinan) {
      financement.amortissement1.resetInput();
      financement.amortissement2.resetInput();
      financement.amortissement3.resetInput();
      financement.inFine.resetInput();

      financement.monofinancement.trouve_mensualite.capitale_aterme = 0;
      financement.monofinancement.apport_initial_futur.montantApportInitVersement.montant = 0;
      financement.monofinancement.versementSupp_futur.montantVersement.montant = 0;
      financement.monofinancement.errMontantFinencent = false;
      financement.montantAfinancer = 0;
    }
    this.calculSoitMulti();
  }

  calculSoitMulti() {
    const { financement, support, scpi, typeFiscaliteModel } = this.solutionFiscale;
    const checkFraisSup = financement.frais_deductible;
    const appPers = financement.apportPersonnel != null ? financement.apportPersonnel : 0;
    const pasFinan = financement.pasFinancement
    const checkFraisRest = financement.financementravaux;
    const montantProduitTemp = (!scpi) ? support.supportImmobilier.choixImmobilier.montant_produit : support.supportSCPI.choixImmobilier.montant_produit;
    const montantProduit = montantProduitTemp != null ? montantProduitTemp : 0;
    const fraisSupplementaireTemp = (!scpi) ?
      support.supportImmobilier.choixImmobilier.frais_suplementaire :
      support.supportSCPI.choixImmobilier.frais_suplementaire;
    const fraisSupplementaire = fraisSupplementaireTemp != null ? fraisSupplementaireTemp : 0;
    const fraisRestaurationTemp = (typeFiscaliteModel.loiCourante === "Demembrement") ?
      support.supportImmobilier.choixImmobilier.dont_montant_reductible :
      support.revenuLocatifImmobillier.fraisRestauration.montant;
    const fraisEntree = support?.supportSCPI?.chargeIntegre?.frais_entree != null ? support?.supportSCPI?.chargeIntegre?.frais_entree : 0;
    const fraisRestauration = fraisRestaurationTemp != null ? fraisRestaurationTemp : 0;

    const fiscalite = this.solutionFiscale.currentFiscalite;
    console.log("----" + fiscalite);
    const tvaLmnpActive = this.solutionFiscale?.typeFiscaliteModel?.lmnp?.tva_oui;
    const tvaLmpActive = this.solutionFiscale?.typeFiscaliteModel?.lmp?.tva_oui;
    const tvaMonumentActive = this.solutionFiscale?.typeFiscaliteModel?.monument?.tva_oui;
    const tvaRegimeActive = this.solutionFiscale?.typeFiscaliteModel?.regime?.tva_oui;
    const tvaZrrActive = this.solutionFiscale?.typeFiscaliteModel?.zrr?.tva_oui;

    let tva = support?.supportImmobilier?.choixImmobilier?.montantTVARecup || 0;
    console.log("----tva = " + tva);

    let soit = 0;
    financement.soit = 0;
    if (!pasFinan) {
      soit = Math.round(appPers);
      if (financement.apportPersonnelSavStatus) {
        financement.apportPersonnelSavStatus = false;
      }
      else {
      }
    }
    else {
      financement.apportPersonnelSavStatus = true;
      financement.apportPersonnelSav = appPers;
    }
    if (!checkFraisSup || pasFinan) {
      soit = soit + Math.round(fraisSupplementaire);
    }

    if (!checkFraisRest || pasFinan) {
      soit = soit + Math.round(fraisRestauration);
    }

    if (pasFinan) {
      soit = soit + Math.round(montantProduit);
    }

    if (scpi && pasFinan) {
      soit = soit + Math.round(fraisEntree) * (null !== montantProduit ? montantProduit : 0) / 100;
    }

    if (tvaLmnpActive || tvaLmpActive || tvaMonumentActive || tvaRegimeActive || tvaZrrActive) {
      soit = soit + Math.round(tva);
    }
    if (pasFinan) {
      financement.apportPersonnel = soit;
      financement.apportPersonnelSav = appPers;
    }
    financement.soit = soit;
  }
}
export { BaseModel };

