class ChargeIntegre {
    frais_gestion: number | null;
    frais_entree: number | null;
    frais_sortie: number | null;
    garantie_locative: number | null;
    charge_copropriete: number | null;
    franchise_sans_loyer_fin_scpi: number | null;
    montant_taxe_fiscal: number | null;
    exoneree_a: number | null;
    exoneree_pendant: number | null;

    constructor(
        frais_gestion: any = "",
        frais_entree: any = "",
        frais_sortie: any = "",
        garantie_locative: any = "",
        charge_copropriete: any = "",
        franchise_sans_loyer_fin_scpi: any = "",
        montant_taxe_fiscal: any = "",
        exoneree_a: any = "",
        exoneree_pendant: any = ""
    ) {
        this.charge_copropriete = charge_copropriete;
        this.exoneree_a = exoneree_a;
        this.exoneree_pendant = exoneree_pendant;
        this.frais_entree = frais_entree;
        this.frais_gestion = frais_gestion;
        this.frais_sortie = frais_sortie;
        this.franchise_sans_loyer_fin_scpi = franchise_sans_loyer_fin_scpi;
        this.garantie_locative = garantie_locative;
        this.montant_taxe_fiscal = montant_taxe_fiscal;
    }

    serializeCharge() {
        return {
            chargeCopropriete: this.charge_copropriete,
            chargeGarantie: this.garantie_locative,
            taxeFonciere: this.montant_taxe_fiscal,
            fraisGestion: this.frais_gestion,
            pourcExoneration: this.exoneree_a,
            dureeExoneration: this.exoneree_pendant,
        }
    }
}
export { ChargeIntegre }