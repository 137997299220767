import React, { Component } from "react";
import { appConstants } from "../../_constants";
import { connector, PropsFromRedux } from "../../_reducers";

class DepenseRow extends Component<PropsFromRedux, any> {
  render() {
    const value1 = this.getValue(
      appConstants.INPUT_KEYS.LOYERS_DONNES
    );
    const checkedValue1 = this.getValue(
      appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES
    );
    const value2 = this.getValue(
      appConstants.INPUT_KEYS.LOYERS_PERCU
    );
    const checkedValue2 = this.getValue(
      appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU
    );
    const value3 = this.getValue(
      appConstants.INPUT_KEYS.AUTRES_CHANGE
    );
    const checkedValue3 = this.getValue(
      appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE
    );

    return (
      <div className="col-lg-12  justify-content-around o-h h-95 pt-0">
        <div className="row mt-1 mt-0 pt-0">
          <div className="col col-lg-12 col-md-12 px-3 mb-0">
            <p className="big-text my-0 text-left">Loyers donnés</p>
          </div>
        </div>
        <div className="row mt-1 mt-0 pt-0">
          <div className="col col-lg-6 col-md-6 px-3 mb-0">
            <div className="d-flex col-lg-12 col-md-12 border-light-gray align-items-center px-0">
              <div className="inputs align-items-center py-1 px-1">
                <input
                  type="number" step="0.01"
                  min="0"
                  id={appConstants.INPUT_KEYS.LOYERS_DONNES}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={() => this.setCalcutaxe()}
                  value={value1}
                />
              </div>

              <div className="align-items-end training py-1 text-center">
                <small className="font-weight-light text-blue">€/mois</small>
              </div>
            </div>
          </div>
          <div className="col col-lg-5 mb-0 px-0">
            <div className="custom-control d-flex justify-content-left custom-checkbox">
              <input
                type="checkbox"
                disabled={value1 === 0 || value1 === null}
                checked={checkedValue1}
                onChange={(e) => this.handleCheckAnneeFutur(e)}
                className="custom-control-input"
                id={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES}
              />
              <label
                className="custom-control-label big-text-dark font-weight-light small-radio"
                htmlFor={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_DONNES}
              >
                Saisir années futures
              </label>
            </div>
          </div>
        </div>
        
        <div className="row mt-1 mt-0 pt-2">
          <div className="col col-lg-12 col-md-12 px-3 mb-0">
            <p className="big-text my-0 text-left">Loyers perçus</p>
          </div>
        </div>
        <div className="row mt-1 mt-0 pt-0">
          <div className="col col-lg-6 col-md-6 px-3 mb-1">
            <div className="d-flex col-lg-12 col-md-12 border-light-gray align-items-center px-0">
              <div className="inputs align-items-center py-1 px-1">
                <input
                  type="number" step="0.01"
                  min="0"
                  id={appConstants.INPUT_KEYS.LOYERS_PERCU}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={() => this.setCalcutaxe()}
                  value={value2}
                />
              </div>
              <div className="align-items-end training py-1 text-center">
                <small className="font-weight-light text-blue">€/mois</small>
              </div>
            </div>
          </div>
          <div className="col col-lg-5 mb-0 px-0">
            <div className="custom-control d-flex justify-content-left custom-checkbox">
              <input
                type="checkbox"
                disabled={value2 === 0 || value2 === null}
                checked={checkedValue2}
                onChange={(e) => this.handleCheckAnneeFutur(e)}
                className="custom-control-input"
                id={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU}
              />
              <label
                className="custom-control-label big-text-dark font-weight-light small-radio"
                htmlFor={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_LOYERS_PERCU}
              >
                Saisir années futures
              </label>
            </div>
          </div>
        </div>

        <div className="row mt-1 mt-0 pt-2">
          <div className="col col-lg-12 col-md-12 px-3 mb-0">
            <p className="big-text my-0 text-left">Autres charges</p>
          </div>
        </div>
        <div className="row mt-1 mt-0 pt-0">
          <div className="col col-lg-6 col-md-6 px-3 mb-0">
            <div className="d-flex col-lg-12 col-md-12 border-light-gray align-items-center px-0">
              <div className="inputs align-items-center py-1 px-1">
                <input
                  type="number" step="0.01"
                  min="0"
                  id={appConstants.INPUT_KEYS.AUTRES_CHANGE}
                  className="form-control2 flex-1 mr-1"
                  onChange={(e) => this.updateMontant(e)}
                  onBlur={() => this.setCalcutaxe()}
                  value={value3}
                />
              </div>
              <div className="align-items-end training py-1 text-center">
                <small className="font-weight-light text-blue">€/mois</small>
              </div>
            </div>
          </div>
          <div className="col col-lg-5 mb-0 px-0">
            <div className="custom-control d-flex justify-content-left custom-checkbox">
              <input
                type="checkbox"
                disabled={value3 === 0 || value3 === null}
                checked={checkedValue3}
                onChange={(e) => this.handleCheckAnneeFutur(e)}
                className="custom-control-input"
                id={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE}
              />
              <label
                className="custom-control-label big-text-dark font-weight-light small-radio"
                htmlFor={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_AUTRES_CHANGE}
              >
                Saisir années futures
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getValue(key: string): any {
    const { model } = this.props;
    return model.situationFiscale.endettement.depense.getValue(
      key
    );
  }

  handleCheckAnneeFutur(e: any) {
    const { model, replaceModel } = this.props;
    const { id, checked } = e.target;
    model.loading = true;
    if (checked) {
      model.situationFiscale.endettement.depense.onChange(
        e
      );
      model.eventUpdateDossier = true;
    }
    model.situationFiscale.revenu.newCalcule = true;
    replaceModel(model);
    model.isModalOpen = true;
    model.loading = false;
    model.modal.provider =
      model.situationFiscale.endettement.depense;
    model.modal.size = "lg";
    model.modal.showCancel = true;
    model.modal.mode = id;
    model.modal.title = model.situationFiscale.endettement.depense.getModalTitle(
      id
    );
    setTimeout(() => {
      replaceModel(model);
    }, 1000);
  }


  async setCalcutaxe() {
    const { model, replaceModel } = this.props;
    model.loading = true;
    replaceModel(model);
    let res = await model.calculTax();
    model.loading = false;
    replaceModel(model);
    if (res) {
      // this.props.replaceModel(model);
      model.situationFiscale.revenu.newCalcule = false;
      this.props.replaceModel(model);
      console.log("replace");
    }
  }

  updateMontant(e: any) {
    const { model, replaceModel } = this.props;
    const res = model.situationFiscale.endettement.depense.onChange(
      e
    );
    if (res) {
      model.situationFiscale.revenu.newCalcule = true;
      model.eventUpdateDossier = true;
      replaceModel(model);
    }
  }
}

export default connector(DepenseRow);
