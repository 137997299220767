import { appConstants } from "../_constants";

class Giradin {
    locatif_intermediaire: boolean;
    locatif_libre: boolean;
    habitat_principale:boolean;
    annee_precedant_livraison_oui: boolean;
    annee_precedant_livraison_non: boolean;
    taux_reduction_annee: boolean;
    taux_reduction: number;
    frais_etablissement: number | null;
    frais_comptabilite: number | null;
    valeur_terrain: number | null;
    valeur_mobilier: number | null;
    taxe_fonciere: number | null;
    pendant: number | null;
    passage: number | null;
    regime:boolean;
    lmnp:boolean;
    id: number | null ;
    loi: String;

    constructor(){
        this.locatif_intermediaire = true;
        this.locatif_libre = false;
        this.habitat_principale = false;
        this.annee_precedant_livraison_oui  = false;
        this.annee_precedant_livraison_non = true;
        this.taux_reduction_annee = false;
        this.taux_reduction = 26;
        this.frais_etablissement = null;
        this.frais_comptabilite = null;
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
        this.passage = 9;
        this.regime = true;
        this.lmnp = false;
        this.loi = "GIRARDIN";
        this.id = null
    }

    resetMiseEnplaceLmnp(){
        this.frais_etablissement = null;
        this.frais_comptabilite = null; 
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
    }

    onChange(e:any){
        const {id,value} = e.target;
        let res = true;
        switch (id) {
            case appConstants.GIRARDIN.LOCATIF_INTERMEDIAIRE:
                this.locatif_intermediaire = true;
                this.locatif_libre = false;
                this.habitat_principale = false;
                this.annee_precedant_livraison_non = true;
                this.annee_precedant_livraison_oui = false;
                this.taux_reduction = 26;
               
            break
            case appConstants.GIRARDIN.LOCATIF_LIBRE:
                this.locatif_intermediaire = false;
                this.locatif_libre = true;
                this.habitat_principale = false;
                this.taux_reduction_annee = false;
                this.annee_precedant_livraison_non = true;
                this.annee_precedant_livraison_oui = false;
                this.taux_reduction = 0;

            break
            case appConstants.GIRARDIN.HABITATION_PRINCIPALE:
                this.locatif_intermediaire = false;
                this.locatif_libre = false;
                this.habitat_principale = true;
                this.taux_reduction_annee = false;
                this.annee_precedant_livraison_non = true;
                this.annee_precedant_livraison_oui = false;
                this.taux_reduction = 0;
            break
            case appConstants.GIRARDIN.TAUX_REDUCTION_ANNEE:
                this.taux_reduction_annee = !this.taux_reduction_annee;
                if (this.locatif_intermediaire && this.taux_reduction_annee) {
                    this.taux_reduction = 40;
                }else if (this.locatif_libre && this.taux_reduction_annee) {
                    this.taux_reduction = 27
                }else if (this.habitat_principale && this.taux_reduction_annee) {
                    this.taux_reduction = 22
                }else  if (this.locatif_intermediaire && !this.taux_reduction_annee) {
                    this.taux_reduction = 26;
                }else if (this.locatif_libre && !this.taux_reduction_annee) {
                    this.taux_reduction = 0
                }else if (this.habitat_principale && !this.taux_reduction_annee) {
                    this.taux_reduction = 0
                }
            break
            case appConstants.GIRARDIN.ANNEE_PRECEDENT_LIVRAISON_NON:
                this.annee_precedant_livraison_non = true;
                this.annee_precedant_livraison_oui = false;
                if (this.locatif_intermediaire) {
                    this.taux_reduction = 26;
                    this.taux_reduction_annee = false;
                }else if (this.locatif_libre) {
                    this.taux_reduction = 0;
                    this.taux_reduction_annee = false;
                }else if (this.habitat_principale) {
                    this.taux_reduction = 0;
                    this.taux_reduction_annee = false;
                }
            break
            case appConstants.GIRARDIN.ANNEE_PRECEDENT_LIVRAISON_OUI:
                this.annee_precedant_livraison_oui = true;
                this.annee_precedant_livraison_non = false;
                if (this.locatif_intermediaire) {
                    this.taux_reduction = 26;
                    this.taux_reduction_annee = false;
                }else if (this.locatif_libre) {
                    this.taux_reduction = 0;
                    this.taux_reduction_annee = false;
                }else if (this.habitat_principale) {
                    this.taux_reduction = 0;
                    this.taux_reduction_annee = false;
                }
            break
            case appConstants.GIRARDIN.FRAIS_ETABLISSEMENT_LMNP:
                this.frais_etablissement = value;
            break
            case appConstants.GIRARDIN.FRAIS_COMPTABILITE_ANNUEL:
                this.frais_comptabilite = value;
            break
            case appConstants.GIRARDIN.VALEUR_MOBILIER:
                this.valeur_mobilier = value;
            break
            case appConstants.GIRARDIN.VALEUR_TERRAIN:
                this.valeur_terrain = value;
            break
            case appConstants.GIRARDIN.TAXE_FONCIERE_EXONEREE:
                this.taxe_fonciere = value;
            break
            case appConstants.GIRARDIN.PENDANT:
                this.pendant = value;
            break
            case appConstants.GIRARDIN.PASSAGE:
                this.passage = value;
            break
            case appConstants.GIRARDIN.REGIME:
                this.regime = true;
                this.lmnp = false;
                this.resetMiseEnplaceLmnp();
            break
            case appConstants.GIRARDIN.LMNP:
                this.regime = false;
                this.lmnp = true;
            break
            default:
            res = false;
        }
        return res
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.GIRARDIN.LOCATIF_INTERMEDIAIRE:
            return this.locatif_intermediaire 
            
            case appConstants.GIRARDIN.LOCATIF_LIBRE:
            return this.locatif_libre 
            
            case appConstants.GIRARDIN.HABITATION_PRINCIPALE:
            return this.habitat_principale 
            
            case appConstants.GIRARDIN.TAUX_REDUCTION_ANNEE:
            return this.taux_reduction_annee 

            case appConstants.GIRARDIN.TAUX_REDUCTION:
                return this.taux_reduction 
            
            case appConstants.GIRARDIN.ANNEE_PRECEDENT_LIVRAISON_NON:
            return this.annee_precedant_livraison_non 
            
            case appConstants.GIRARDIN.ANNEE_PRECEDENT_LIVRAISON_OUI:
            return this.annee_precedant_livraison_oui 

            case appConstants.GIRARDIN.FRAIS_ETABLISSEMENT_LMNP:
            return this.frais_etablissement; 

            case appConstants.GIRARDIN.FRAIS_COMPTABILITE_ANNUEL:
            return this.frais_comptabilite; 

            case appConstants.GIRARDIN.VALEUR_MOBILIER:
            return this.valeur_mobilier; 

            case appConstants.GIRARDIN.VALEUR_TERRAIN:
            return this.valeur_terrain; 

            case appConstants.GIRARDIN.TAXE_FONCIERE_EXONEREE:
            return this.taxe_fonciere;

            case appConstants.GIRARDIN.PENDANT:
            return this.pendant;

            case appConstants.GIRARDIN.PASSAGE:
            return this.passage;

            case appConstants.GIRARDIN.LMNP:
            return this.lmnp;

            case appConstants.GIRARDIN.REGIME:
            return this.regime; 
            
        }
      return false;
    }

}

export {Giradin}