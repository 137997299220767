import React, { Component } from "react";
import { connect } from "react-redux";
import { appActions } from "../../../_actions";
import { InputNumber } from "../CustomInput/InputNumber";
import { appConstants } from "../../../_constants";
import { utils } from "../../../_helpers";
import { Loader } from "../Loader/Loader"

class AnneesFutursNombrePartFiscales extends Component<any, {}> {
  render() {
    const { model } = this.props;
    const { modal } = model;
    return (
      <div className="w-100 d-flex">
        <Loader />
        <div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-11">
                <div className="d-flex mt-2 justify-content-center align-items-center">
                  <small className="font-weight-light text-blue pr-2">
                    {
                      modal?.data?.quotientFamilial?.nombrePartFiscale?.annee
                        ?.value
                    }
                  </small>
                  <div className="d-flex w-75 align-items-center px-1">
                    <span className="form-control2 flex-1 mr-1 text-center text-dark">
                      {modal?.data?.quotientFamilial?.nombrePartFiscale?.valeur}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.getAnneeFuturGroup(0, 10)}
        </div>
        <div>
          <div className="col-md-12" style={{ minHeight: "27px" }}>
            <div className="row">
              <div className="col-md-7"></div>
              <div className="col-md-5 pr-0"></div>
            </div>
          </div>
          {this.getAnneeFuturGroup(10, 20)}
        </div>
      </div>
    );
  }

  getAnneeFuturGroup(offset: number, limit: number): React.ReactNode {
    const { modal } = this.props.model;
    const { nombrePartFiscales } = modal?.data?.quotientFamilial;
    return nombrePartFiscales.slice(offset, limit).map((item: any, i: number) => {
      const options = {
        type: "number",
        id: item.id,
      };
      return (
        <div className="col-md-12" key={i}>
          <div className="d-flex mt-2 justify-content-center align-items-center">
            <small className="font-weight-light text-blue pr-2">{item.annee.value}</small>
            <div className="d-flex border-light-gray w-75 align-items-center px-1">
              <InputNumber
                config={utils().getAnneeFuturInputConfig(
                  item.annee.value,
                  item.valeur,
                  modal?.data?.quotientFamilial,
                  appConstants.ANNEE_FUTURE_NOMBRE_PART_FISCALE,
                  "nbre_part_fiscale_" + item.id,
                  "nbre_part_fiscale_" + item.id,
                  null,
                  options
                )}
              />
              <small className="text-dark-blue  text-blue">part(s)</small>
            </div>
          </div>
        </div>
      );
    });
  }
}
const mapStateToProps = (state: any) => ({
  model: state.app.model,
});

const dispatchToProps = {
  ...appActions,
};

const connected = connect(
  mapStateToProps,
  dispatchToProps
)(AnneesFutursNombrePartFiscales);

export { connected as AnneesFutursNombrePartFiscales };
