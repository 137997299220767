import { RevenuNetImposable } from "./RevenuNetImposable";
import { FraisReel } from "./FraisReel";
import { Benefice } from "./Benefice";
import { cloneDeep } from "lodash";
import { appConstants } from "../_constants";
import { utils } from "../_helpers";

class Declarant {
  id: number;
  idDeclarent: number| null;
  nom: string;
  hasAbattement: boolean;
  hasFraisReel: boolean;
  hasAdhesion: boolean;
  hasAnneFuturRevenuNetImposable: boolean;
  hasAnneFuturBenefice: boolean;
  fraisReel: FraisReel;
  benefice: Benefice;
  revenuNetImposable: RevenuNetImposable;
  benefices: Array<Benefice>;
  ancienBenefices?: Array<Benefice>;
  revenuNetImposables: Array<RevenuNetImposable>;
  ancienRevenuNetImposables?: Array<RevenuNetImposable>;
  validate: boolean;

  constructor(
    id: number = 0,
    nom: string = "",
    hasAbattement: boolean = true,
    hasFraisReel: boolean = false,
    hasAdhesion: boolean = false,
    hasAnneFuturRevenuNetImposable: boolean = false,
    hasAnneFuturBenefice: boolean = false,
    fraisReel: FraisReel = new FraisReel(),
    benefice: Benefice = new Benefice(),
    revenuNetImposable: RevenuNetImposable = new RevenuNetImposable(),
    revenuNetImposables: Array<RevenuNetImposable> = new Array<RevenuNetImposable>(),
    benefices: Array<Benefice> = new Array<Benefice>()
  ) {
    this.id = id;
    this.idDeclarent = null;
    this.nom = nom;
    this.hasAbattement = hasAbattement;
    this.hasFraisReel = hasFraisReel;
    this.hasAdhesion = hasAdhesion;
    this.hasAnneFuturRevenuNetImposable = hasAnneFuturRevenuNetImposable;
    this.hasAnneFuturBenefice = hasAnneFuturBenefice;
    this.fraisReel = fraisReel;
    this.benefice = benefice;
    this.revenuNetImposable = revenuNetImposable;
    this.revenuNetImposables = revenuNetImposables;
    this.benefices = benefices;
    this.validate = false;
  }


  serialize(data:any){
    return{
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  beneficeArraySerialize(){
    if ( this.benefices.length > 0 ) {
      return this.benefices.map((elt)=>this.serialize(elt))
    }
    return []
  }

  revenuNetImposablesArraySerialize(){
    if(this.revenuNetImposables.length > 0){
      return this.revenuNetImposables.map((elt)=>this.serialize(elt))
    }
    return []
  }

  hydrateModelBenefice(data:Array<any>){
    if (data?.length > 0) {
      return data?.map((elt) => new Benefice(elt))
    }
      return this.benefices
  }

  hydrateModelRevenueNetImposable(data:Array<any>){
    if (data?.length > 0) {
      return data?.map((elt) => new RevenuNetImposable(elt) )
    }
      return this.revenuNetImposables
    
  }

  async initRevenuNetImposables() {
    if (this.ancienRevenuNetImposables?.length) {
      this.revenuNetImposables = cloneDeep(this.ancienRevenuNetImposables);
    } else {
      if (this.revenuNetImposables.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const revenuNetImposable = cloneDeep(this.revenuNetImposable);
          revenuNetImposable.annee.value += i;
          revenuNetImposable.id = i;
          this.revenuNetImposables.push(revenuNetImposable);
        }
      }
    }
  }

  async initBenefices() {
    if (this.ancienBenefices?.length) {
      this.benefices = cloneDeep(this.ancienBenefices);
    } else {
      if (this.benefices.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const benefice = cloneDeep(this.benefice);
          benefice.annee.value += i;
          benefice.id = i;
          this.benefices.push(benefice);
        }
      }
    }
  }

  setBeneficeMontant(montant: number) {
    while (this.benefices.length) {
      this.benefices.pop();
    }
    if (this.ancienBenefices?.length) {
      while (this.ancienBenefices.length) {
        this.ancienBenefices.pop();
      }
    }
    this.hasAnneFuturBenefice = false;
    this.benefice.montant = montant;
  }

  setRevenuNetImposableMontant(montant: number) {
    while (this.revenuNetImposables.length) {
      this.revenuNetImposables.pop();
    }
    if (this.ancienRevenuNetImposables?.length) {
      while (this.ancienRevenuNetImposables.length) {
        this.ancienRevenuNetImposables.pop();
      }
    }
    this.hasAnneFuturRevenuNetImposable = false;
    this.revenuNetImposable.montant = montant;
    this.validate = false;
  }

  onBlur(e: any) {
    return false;
  }

  onChange(e: any) {
    let res = true;
    const key = e?.target?.dataset?.key;
    const val = utils().getNumberInputValue(e)
    switch (key) {
      case appConstants.ANNEE_FUTURE_REVENU_NET_IMPOSABLE:
        this.updateRevenuNetImposablesItem(e);
        break;
      case appConstants.ANNEE_FUTURE_BENEFICE:
        this.updateBeneficesItem(e);
        break;
      case appConstants.REVENU_NET_IMPOSABLE:
        this.setRevenuNetImposableMontant(val);
        break;
      case appConstants.FRAIS_REEL:
        this.fraisReel.montant = val;
        break;
      default:
        break;
    }
    return res;
  }

  updateRevenuNetImposablesItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.revenuNetImposables.length;
    const index = this.revenuNetImposables.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j =  0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);

    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.revenuNetImposables[j].montant : this.revenuNetImposable.montant;
        if (value === 0) {
          this.revenuNetImposables[i].montant = montant;
        } else {
          this.revenuNetImposables[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.revenuNetImposables[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.revenuNetImposables[i].montant = Math.round(value);
        this.revenuNetImposables[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateBeneficesItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.benefices.length;
    const index = this.benefices.findIndex(
      (val) => val.id === id || val.id?.toString() === id
    );
    let j =  0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);

    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.benefices[j].montant : this.benefice.montant;
      
        if (value === 0 || value < 0) {
          //this.benefices[j];
        } 
        else {
          this.benefices[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.benefices[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.benefices[i].montant = Math.round(value);
        this.benefices[i].revalorisationAnnuelle = 0;
      }
    }
  }

  onModalClose(save: boolean, key?: string) {
    console.log(save);
    console.log(key);
    if (save) {
      switch (key) {
        case appConstants.ANNEE_FUTURE_REVENU_NET_IMPOSABLE:
          this.ancienRevenuNetImposables = cloneDeep(this.revenuNetImposables);
          break;
        case appConstants.ANNEE_FUTURE_BENEFICE:
          this.ancienBenefices = cloneDeep(this.benefices);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.ANNEE_FUTURE_REVENU_NET_IMPOSABLE:
          if (!this.ancienRevenuNetImposables) {
            this.hasAnneFuturRevenuNetImposable = false;
          }
          while (this.revenuNetImposables.length) {
            this.revenuNetImposables.pop();
          }
          break;
        case appConstants.ANNEE_FUTURE_BENEFICE:
          if (!this.ancienBenefices) {
            this.hasAnneFuturBenefice = false;
          }
          while (this.benefices.length) {
            this.benefices.pop();
          }
          break;
        default:
          break;
      }
    }
  }
}

export { Declarant };
