import { ResultatFoncierExistant } from "./ResultatFoncierExistant";
import { PerisolEnCour } from "./PerisolEnCour";
import { DeficitNonImpute } from "./DeficitNonImpute";
import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { cloneDeep } from "lodash";

class FoyerFiscalResultatFoncier {
  id: number;
  nom: string;
  hasAnneeFuturResultatFoncierExistant: boolean;
  hasAnneeFuturPerisolEnCour: boolean;
  hasAnneeFuturDeficitNonImpute: boolean;
  resultatFoncierExistant: ResultatFoncierExistant;
  perisolEnCour: PerisolEnCour;
  deficitNonImpute: DeficitNonImpute;
  resultatFoncierExistants: Array<ResultatFoncierExistant>;
  perisolEnCours: Array<PerisolEnCour>;
  deficitNonImputes: Array<DeficitNonImpute>;

  ancienResultatFoncierExistants?: Array<ResultatFoncierExistant>;
  ancienPerisolEnCours?: Array<PerisolEnCour>;
  ancienDeficitNonImputes?: Array<DeficitNonImpute>;

  constructor(
    id: number = 0,
    nom: string = "Foyer fiscal",
    hasAnneeFuturResultatFoncierExistant: boolean = false,
    hasAnneeFuturPerisolEnCour: boolean = false,
    hasAnneeFuturDeficitNonImpute: boolean = false,
    resultatFoncierExistant: ResultatFoncierExistant = new ResultatFoncierExistant(),
    perisolEnCour: PerisolEnCour = new PerisolEnCour(),
    deficitNonImpute: DeficitNonImpute = new DeficitNonImpute(),
    resultatFoncierExistants: Array<ResultatFoncierExistant> = new Array<
      ResultatFoncierExistant
    >(),
    perisolEnCours: Array<PerisolEnCour> = new Array<PerisolEnCour>(),
    deficitNonImputes: Array<DeficitNonImpute> = new Array<DeficitNonImpute>()
  ) {
    this.id = id;
    this.nom = nom;
    this.hasAnneeFuturResultatFoncierExistant = hasAnneeFuturResultatFoncierExistant;
    this.hasAnneeFuturPerisolEnCour = hasAnneeFuturPerisolEnCour;
    this.hasAnneeFuturDeficitNonImpute = hasAnneeFuturDeficitNonImpute;
    this.resultatFoncierExistant = resultatFoncierExistant;
    this.perisolEnCour = perisolEnCour;
    this.deficitNonImpute = deficitNonImpute;
    this.resultatFoncierExistants = resultatFoncierExistants;
    this.perisolEnCours = perisolEnCours;
    this.deficitNonImputes = deficitNonImputes;
  }

  serialize(data: any) {
    return {
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  resultatFoncierExistantsArraySerialize() {
    if (this.resultatFoncierExistants.length > 0) {
      return this.resultatFoncierExistants.map((elt) => this.serialize(elt))
    }
    return []
  }

  perisolEnCoursArraySerialize() {
    if (this.perisolEnCours.length > 0) {
      return this.perisolEnCours.map((elt) => this.serialize(elt))
    }
    return []
  }

  deficitNonImputesArraySerialize() {
    if (this.deficitNonImputes.length > 0) {
      return this.deficitNonImputes.map((elt) => this.serialize(elt))
    }
    return []
  }

  hydrateArrayModelresultatFoncierExistants(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new ResultatFoncierExistant(elt))
    }
    return this.resultatFoncierExistants
  }

  hydrateArrayModelperisolEnCours(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new PerisolEnCour(elt))
    }
    return this.perisolEnCours
  }

  hydrateArrayModeldeficitNonImputes(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new DeficitNonImpute(elt))
    }
    return this.deficitNonImputes
  }

  getValue(key: string): any {
    switch (key) {
      case appConstants.INPUT_KEYS.RESULTAT_FONCIER_EXISTANT:
        return this.resultatFoncierExistant.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT:
        return this.hasAnneeFuturResultatFoncierExistant;
      case appConstants.INPUT_KEYS.PERISOL_EN_COUR:
        return this.perisolEnCour.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR:
        return this.hasAnneeFuturPerisolEnCour;
      case appConstants.INPUT_KEYS.DEFICIT_NON_IMPUTE:
        return this.deficitNonImpute.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
        return this.hasAnneeFuturDeficitNonImpute;

      default:
        break;
    }
  }

  setResultatFoncierExistantMontant(montant: number) {
    while (this.resultatFoncierExistants.length) {
      this.resultatFoncierExistants.pop();
    }
    if (this.ancienResultatFoncierExistants?.length) {
      while (this.ancienResultatFoncierExistants.length) {
        this.ancienResultatFoncierExistants.pop();
      }
    }
    this.hasAnneeFuturResultatFoncierExistant = false;
    this.resultatFoncierExistant.montant = montant;
  }

  setPerisolEnCourMontant(montant: number) {
    while (this.perisolEnCours.length) {
      this.perisolEnCours.pop();
    }
    if (this.ancienPerisolEnCours?.length) {
      while (this.ancienPerisolEnCours.length) {
        this.ancienPerisolEnCours.pop();
      }
    }
    this.hasAnneeFuturPerisolEnCour = false;
    this.perisolEnCour.montant = montant;
  }

  setDeficitNonImputeMontant(montant: number) {
    while (this.deficitNonImputes.length) {
      this.deficitNonImputes.pop();
    }
    if (this.ancienDeficitNonImputes?.length) {
      while (this.ancienDeficitNonImputes.length) {
        this.ancienDeficitNonImputes.pop();
      }
    }
    this.hasAnneeFuturDeficitNonImpute = false;
    this.deficitNonImpute.montant = montant;
  }

  onBlur(e: any) {
    return false;
  }

  onChange(e: any): boolean {
    const { id, checked } = e.target;
    let res = true;
    const val = utils().getNumberInputValue(e);
    switch (id) {
      case appConstants.INPUT_KEYS.RESULTAT_FONCIER_EXISTANT:
        this.setResultatFoncierExistantMontant(val);
        break;
      case appConstants.INPUT_KEYS.PERISOL_EN_COUR:
        this.setPerisolEnCourMontant(val);
        break;
      case appConstants.INPUT_KEYS.DEFICIT_NON_IMPUTE:
        this.setDeficitNonImputeMontant(val);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT:
        this.hasAnneeFuturResultatFoncierExistant = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR:
        this.hasAnneeFuturPerisolEnCour = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
        this.hasAnneeFuturDeficitNonImpute = checked;
        break;
      default:
        const res2 = this.checkAnneeFuturChange(e);
        if (!res2) {
          throw new Error("Invalid label key");
        }
        break;
    }
    return res;
  }

  checkAnneeFuturChange(e: any): boolean {
    const key = e.target.dataset.key;
    let res = true;
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT:
        this.updateResultatFoncierExistantItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR:
        this.updatePerisolEnCourItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
        this.updateDeficitNonImputeItem(e);
        break;

      default:
        res = false;
        break;
    }
    return res;
  }

  updateResultatFoncierExistantItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.resultatFoncierExistants.length;
    const index = this.resultatFoncierExistants.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.resultatFoncierExistants[j].montant || 0 : this.resultatFoncierExistant.montant || 0;
        if (value === 0) {
          this.resultatFoncierExistants[i].montant = montant;
        } else {
          this.resultatFoncierExistants[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.resultatFoncierExistants[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.resultatFoncierExistants[i].montant = Math.round(value);
        this.resultatFoncierExistants[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateDeficitNonImputeItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.deficitNonImputes.length;
    const index = this.deficitNonImputes.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);

    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.deficitNonImputes[j].montant || 0 : this.deficitNonImpute.montant || 0;
        if (value === 0) {
          this.deficitNonImputes[i].montant = montant;
        } else {
          this.deficitNonImputes[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.deficitNonImputes[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.deficitNonImputes[i].montant = Math.round(value);
        this.deficitNonImputes[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updatePerisolEnCourItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.perisolEnCours.length;
    const index = this.perisolEnCours.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j = 0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);

    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.perisolEnCours[j].montant || 0 : this.perisolEnCour.montant || 0;
        if (value === 0) {
          this.perisolEnCours[i].montant = montant;
        } else {
          this.perisolEnCours[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.perisolEnCours[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.perisolEnCours[i].montant = Math.round(value);
        this.perisolEnCours[i].revalorisationAnnuelle = 0;
      }
    }
  }

  getLabelConfig(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT:
        return {
          leading: this.resultatFoncierExistant.annee.value,
          trailling: this.resultatFoncierExistant.devise.symbole,
          label: true,
          value: this.resultatFoncierExistant.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR:
        return {
          leading: this.perisolEnCour.annee.value,
          trailling: this.perisolEnCour.devise.symbole,
          label: true,
          value: this.perisolEnCour.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
        return {
          leading: this.deficitNonImpute.annee.value,
          trailling: this.deficitNonImpute.devise.symbole,
          label: true,
          value: this.deficitNonImpute.montant,
        };
      default:
        throw new Error("Invalid label key");
    }
  }

  getItems(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT:
        return this.getResultatFoncierExistants();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR:
        return this.getPerisolEnCours();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
        return this.getDeficitNonImputes();
      default:
        throw new Error("Invalid label key");
    }
  }

  getResultatFoncierExistants() {
    if (this.ancienResultatFoncierExistants?.length && !this.resultatFoncierExistants.length) {
      this.resultatFoncierExistants = cloneDeep(this.ancienResultatFoncierExistants);
    } else {
      if (this.resultatFoncierExistants.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.resultatFoncierExistant);
          item.annee.value += i;
          item.id = i;
          this.resultatFoncierExistants.push(item);
        }
      }
    }
    return this.resultatFoncierExistants;
  }

  getDeficitNonImputes() {
    if (this.ancienDeficitNonImputes?.length && !this.deficitNonImputes.length) {
      this.deficitNonImputes = cloneDeep(this.ancienDeficitNonImputes);
    } else {
      if (this.deficitNonImputes.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.deficitNonImpute);
          item.annee.value += i;
          item.id = i;
          this.deficitNonImputes.push(item);
        }
      }
    }
    return this.deficitNonImputes;
  }

  getPerisolEnCours() {
    if (this.ancienPerisolEnCours?.length && !this.perisolEnCours.length) {
      this.perisolEnCours = cloneDeep(this.ancienPerisolEnCours);
    } else {
      if (this.perisolEnCours.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.perisolEnCour);
          item.annee.value += i;
          item.id = i;
          this.perisolEnCours.push(item);
        }
      }
    }
    return this.perisolEnCours;
  }

  getInputConfig(key: string, item: any, options: any) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      default:
        throw new Error("Invalid label key");
    }
  }

  getModalTitle(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT:
        return "Résultat foncier existant sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR:
        return "Périsol en cour sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
        return "Déficit non imputé sur les 20 prochaines années";
      default:
        throw new Error("Invalid label key");
    }
  }

  onModalClose(save: boolean, key?: string) {
    if (save) {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT:
          this.ancienResultatFoncierExistants = cloneDeep(this.resultatFoncierExistants);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR:
          this.ancienPerisolEnCours = cloneDeep(this.perisolEnCours);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
          this.ancienDeficitNonImputes = cloneDeep(this.deficitNonImputes);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT:
          if (!this.ancienResultatFoncierExistants) {
            this.hasAnneeFuturResultatFoncierExistant = false;
          }
          while (this.resultatFoncierExistants.length) {
            this.resultatFoncierExistants.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR:
          if (!this.ancienPerisolEnCours) {
            this.hasAnneeFuturPerisolEnCour = false;
          }
          while (this.perisolEnCours.length) {
            this.perisolEnCours.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE:
          if (!this.ancienDeficitNonImputes) {
            this.hasAnneeFuturDeficitNonImpute = false;
          }
          while (this.deficitNonImputes.length) {
            this.deficitNonImputes.pop();
          }
          break;
        default:
          break;
      }
    }
  }

}

export { FoyerFiscalResultatFoncier };
