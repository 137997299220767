import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connector, PropsFromRedux } from "../../../_reducers";


class Dialog extends Component<PropsFromRedux, any>{
    constructor(props: any) {
        super(props);
        this.state = {
            check: true,
            showModal: false,
            showOk: true,
            message: "",
            title: "Easy Defisc",
        }
    }
    render() {
        const { message } = this.state;

        return (
            <div className="row p-3">
                <div className="col-md-7">
                    <div className="px-2 pb-2 w-100 ">
                        <div className="d-flex ">
                            <p className="col-md-6 text-left">{message} </p>
                            <div className="d-flex align-items-center px-1 border-light-gray col-md-6 ">
                            </div>
                        </div>



                    </div>

                    {this.getModal()}
                </div>
            </div>
        );
    }

    getModal(): React.ReactNode {
        const { showModal, message, title } = this.state;
        return (
            <Modal
                show={showModal}
                onHide={() => this.closeModal()}
                backdrop="static"
            // size="md"
            >
                <Modal.Header closeButton className="custom-modal-header">
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">{message}</Modal.Body>
                <Modal.Footer>{showModal && this.getFooter()}</Modal.Footer>
            </Modal>
        );
    }

    getFooter() {
        return (
            <div className="w-100 d-flex justify-content-end" >
                <button
                    className="btn font-weight-light text-white bg-orange text-uppercase"
                    style={{ fontSize: "80%" }}
                    onClick={() => this.closeModal()}
                >
                    Ok
            </button>
            </div>
        );
    }

    closeModal() {
        this.setState({
            showModal: false,
            showOk: true,
            showOuiNon: false,
            nav: false,
            route: "",
            message: "",
            title: "Easy Defisc",
        });

    }
}
const connected = connector(Dialog);
export { connected as Dialog };
