import React, { Component } from "react";
import { connect } from "react-redux";
import { appActions } from "../../../_actions";
import ReactLoading from 'react-loading';

class Loader extends Component<any, {}> {
  render() {
    const { loading } = this.props.model;
    return (
      <div id="overlay" style={{display: loading ? 'block' : 'none'}}>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <ReactLoading 
            type="spinningBubbles" 
            color="#F7AB50" 
            width={'15%'} 
            height={'15%'} 
            className="rounded"
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  model: state.app.model,
});

const dispatchToProps = {
  ...appActions,
};

const connectedLoader = connect(mapStateToProps, dispatchToProps)(Loader);

export { connectedLoader as Loader };
