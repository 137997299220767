export const PINELS= {
    ENGARGEMENT_MIN: "ENGARGEMENT_MIN",
    ENGARGEMENT_MAX: "ENGARGEMENT_MAX",
    METROPOLE:"METROPOLE",
    OUTRE_MERE: "OUTRE_MERE",
    TAUX:"TAUX",
    PLAFOND_INVESTISSEMENT: "PLAFOND_INVESTISSEMENT",
    PASSAGE: "PASSAGE",
    REGIME:"REGIME",
    LMNP:"LMNp",
    FRAIS_ETABLISSEMENT_LMNP:"FRAIS_ETABLISSEMENT_LMNP",
    FRAIS_COMPTABILITE_ANNUEL:"FRAIS_COMPTABILITE_ANNUEL",
    VALEUR_TERRAIN: "VALEUR_TERRAIN",
    VALEUR_MOBILIER: "VALEUR_MOBILIER",
    TAXE_FONCIERE_EXONEREE: "TAXE_FONCIERE_EXONEREE",
    PENDANT: "PENDANT",
}