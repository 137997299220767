import React, { Component, Suspense, lazy} from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { history } from '../_helpers';
import { Loader } from '../_components';
import { PropsFromRedux,connector } from '../_reducers';
import { PrivateRoute } from './privateRoute';
import { AuthRoute } from './authRoute';


const Dashboard = lazy(() => import('../pages/Dashboard'));
const Auth = lazy(() => import('../_components/auth/auth'));
const Accueil = lazy(() => import('../_components/Accueil/accueil'));

class App extends Component<PropsFromRedux,any, any> {

  componentDidMount(){
      history.listen(location=>{
        console.log(location.pathname);
        if(location.pathname === "/accueil" || location.pathname === "/") {
          this.setValue(true);
        }
      });
  }

  
  render () {
    return (
      <React.Fragment>
        <Router history={history}>
          <Suspense fallback={<Loader />}>
            <Switch>
              <AuthRoute exact path="/" component={Auth}/>
              <PrivateRoute exact path="/accueil" component={Accueil}/>
              <PrivateRoute exact path="/situation-fiscal" component={Dashboard}/>
              <PrivateRoute exact path="/solution-fiscal" component={Dashboard}/>
              <PrivateRoute exact path="/synthese-fiscal" component={Dashboard}/>
              <Route exact path="/notfound" component={() => <h1 className="text-center mt-5">404 not found !</h1>}/>
              <Redirect from="*" to="/notfound" />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }

  setValue(val:boolean): any {
    const { model, replaceModel } = this.props;
    const res = model.changeEtatNav(val);
    if (res) {
        replaceModel(model);
    }
}
}

const connected = connector(App)

export {connected as App};
