import React, { Component } from 'react';
import { PropsFromRedux,connector } from '../../_reducers';

class Compte extends Component<PropsFromRedux, any> {

    render() {
        const { model } = this.props;
        const{nom,prenom,email,entreprise,adresse,codePostale,telephone} = model.auth.loginAuth.utilisateur;
        return (
            <div className="container-fluid p-2">
                <div className="bg-light">
                    <div className="row mx-0">
                        <div style={{fontWeight:700}} className="col-6 text-left p-1">Votre conseiller</div>
                        <div style={{fontWeight:400}} className="col-6 text-left p-1">{nom} {prenom} </div>
                    </div>
                    <div className="row mx-0">
                        <div style={{fontWeight:700}} className="col-6 text-left p-1">Téléphone </div>
                        <div style={{fontWeight:400}} className="col-6 text-left p-1">{telephone}</div>
                    </div>
                    <div className="row mx-0">
                        <div style={{fontWeight:700}} className="col-6 text-left p-1">Courriel </div>
                        <div style={{fontWeight:400}} className="col-6 text-left p-1">{email}</div>
                    </div>
                    <div className="row mx-0" >
                        <div style={{fontWeight:700}} className="col-6 text-left p-1" >Société</div>
                        <div style={{fontWeight:400}} className="col-6 text-left p-1">{entreprise}  </div>
                    </div>
                    <div className="row mx-0 ">
                        <div style={{fontWeight:700}} className="col-6 text-left p-1 pt-2">Adresse</div>
                        <div style={{fontWeight:400}} className="col-6 text-left p-1">{adresse}<br/> <small>{codePostale}</small></div>
                    </div>
                </div>
            </div>
        );
    }
}
const connected = connector(Compte)
export {connected as Compte}