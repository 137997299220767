import { appConstants } from "../_constants";
import {
  CaracteristiqueFinancement,
  ChoixFinancement,
  ProduitAdossement,
  ProduitFinancier,
  TabProduitAdossement
} from "../_entities";
import { VersementSuppls } from "./versementSuppls";

class MonoFinancement {
  choix_financement: ChoixFinancement;

  caracteristique_financement: CaracteristiqueFinancement;
  produit_adossement: ProduitAdossement;
  trouve_mensualite: TabProduitAdossement;
  apport_initial: TabProduitAdossement;
  capital_terme: TabProduitAdossement;
  errMontantFinencent: boolean;
  errdureefinancement: boolean;
  errtauxInteret: boolean;
  errassuranceDece: boolean;
  errtauxCapitalisation: boolean;
  assurDece: VersementSuppls;
  versementSupp_futur: VersementSuppls;
  apport_initial_futur: VersementSuppls;
  poucentage: boolean;
  euro: boolean;
  annee: boolean;
  mois: boolean;
  produitFinancier: ProduitFinancier;
  listProduitFinancier: Array<ProduitFinancier>;
  id: number | null;
  indexProduit: number | null;
  tabsProduitActif: string;

  constructor(
    amortissable: boolean = true,
    infine: boolean = false,
    pasFinancement: boolean = false,
    assurDece: VersementSuppls = new VersementSuppls(),
    versementSupp: VersementSuppls = new VersementSuppls(),
    apport_initial_futur: VersementSuppls = new VersementSuppls(),
    listProduitFinancier: Array<ProduitFinancier> = new Array<ProduitFinancier>()
  ) {
    this.choix_financement = new ChoixFinancement(
      pasFinancement
    );
    this.caracteristique_financement = new CaracteristiqueFinancement();
    this.produit_adossement = new ProduitAdossement();
    this.trouve_mensualite = new TabProduitAdossement();
    this.apport_initial = new TabProduitAdossement();
    this.capital_terme = new TabProduitAdossement();
    this.errMontantFinencent = true;
    this.errassuranceDece = true;
    this.errdureefinancement = true;
    this.errtauxCapitalisation = true;
    this.errtauxInteret = true;
    this.assurDece = assurDece;
    this.versementSupp_futur = versementSupp;
    this.euro = true;
    this.poucentage = false;
    this.annee = true;
    this.mois = false;
    this.apport_initial_futur = apport_initial_futur;
    this.produitFinancier = new ProduitFinancier();
    this.listProduitFinancier = listProduitFinancier;
    this.id = null;
    this.indexProduit = null;
    this.tabsProduitActif = "mensualite";
  }

  resetInput() {
    this.caracteristique_financement.mensualiteCreditTotal = 0;
    this.caracteristique_financement.taux_interet = null;
    this.caracteristique_financement.duree = null;
    this.caracteristique_financement.assurance_dece_invalid = null;
    //this.caracteristique_financement.montantInteretDiffere = 0;
    this.caracteristique_financement.rembourssement_capital = false;
    this.caracteristique_financement.rembourssement_interet = false;
    this.caracteristique_financement.mois_rembourssement_capital = 12;
    this.caracteristique_financement.mois_rembourssement_interet = 12;
    this.produit_adossement = new ProduitAdossement();
    this.trouve_mensualite = new TabProduitAdossement();
    this.apport_initial = new TabProduitAdossement();
    this.capital_terme = new TabProduitAdossement();
  }

  onchangeCapitalMensuel(value: any) {
    this.trouve_mensualite.capitale_aterme =
      this.trouve_mensualite.capitale_aterme === null
        ? 0
        : this.trouve_mensualite.capitale_aterme + Math.round(value);
  }

  onchangeCapitalInitial(value: any) {
    this.apport_initial.capitale_aterme =
      this.apport_initial.capitale_aterme === null
        ? 0
        : this.apport_initial.capitale_aterme + Math.round(value);
  }

  onChange(e: any) {
    const { id, value } = e.target;
    let res = true;
    console.log(id, value);
    switch (id) {
      case appConstants.FINANCEMENT.AMORTISSEMENT:
        this.caracteristique_financement.amortissable = true;
        this.caracteristique_financement.inFine = false;
        this.resetInput();

        break;
      case appConstants.FINANCEMENT.INFINE:
        this.caracteristique_financement.amortissable = false;
        this.caracteristique_financement.inFine = true;
        this.resetInput();
        break;
      case appConstants.FINANCEMENT.PASFINANCEMENT:
        if (this.choix_financement.pasFinancement) {
          this.choix_financement.apportPersonnel = 0;
          this.choix_financement.financementFraisDeductible = true;
        }
        this.choix_financement.pasFinancement = !this.choix_financement
          .pasFinancement;
        if (this.choix_financement.pasFinancement) {
          this.choix_financement.financementFraisDeductible = !this
            .choix_financement.pasFinancement;
        }
        this.resetInput();
        break;
      case appConstants.FINANCEMENT.FINANCEMENTFRAISDEDUCTIBLE:
        this.choix_financement.financementFraisDeductible = this
          .choix_financement.pasFinancement
          ? false
          : !this.choix_financement.financementFraisDeductible;
        break;
      case appConstants.FINANCEMENT.APPORTPERSONNEL:
        this.choix_financement.apportPersonnel = value;
        break;
      case appConstants.FINANCEMENT.SOIT:
        this.choix_financement.soit = value;
        break;
      case appConstants.FINANCEMENT.FINANCEMENTPREMIEREANNEE:
        this.choix_financement.financementPremierAnnee = this
          .choix_financement.pasFinancement
          ? false
          : !this.choix_financement.financementPremierAnnee;
        break;
      case appConstants.FINANCEMENT.MONTANTFINACEMENT:
        this.checkmontant(value);
        break;
      case appConstants.FINANCEMENT.DUREEFINANCEMENT:
        this.checkDuree(value);
        break;
      case appConstants.FINANCEMENT.ANNEE:
        this.annee = true;
        this.mois = false;
        break;
      case appConstants.FINANCEMENT.MOIS:
        this.annee = false;
        this.mois = true;
        break;
      case appConstants.FINANCEMENT.TAUXINTERET:
        this.checkTauxInteret(value);
        break;
      case appConstants.FINANCEMENT.ASSURANCEDECEINVALIDE:
      case appConstants.INPUT_KEYS.MONTANT_ASSURANCE:
        this.checkAssurance(value);
        break;
      case appConstants.FINANCEMENT.EURO:
        this.euro = true;
        this.poucentage = false;
        break;
      case appConstants.FINANCEMENT.POURCENTAGE:
        this.euro = false;
        this.poucentage = true;
        break;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALOUI:
        this.caracteristique_financement.rembourssement_capital = true;

        break;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALNON:
        this.caracteristique_financement.rembourssement_capital = false;
        this.caracteristique_financement.rembourssement_interet = false;
        break;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETOUI:
        this.caracteristique_financement.rembourssement_interet = true;
        this.caracteristique_financement.rembourssement_capital = true;
        break;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETNON:
        this.caracteristique_financement.rembourssement_interet = false;
        break;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETMOIS:
        this.caracteristique_financement.mois_rembourssement_interet = value;
        break;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALMOIS:
        this.caracteristique_financement.mois_rembourssement_capital = value;
        break;
      case appConstants.FINANCEMENT.MONTANT_INTERET_DIFFERE:
        this.caracteristique_financement.montantInteretDiffere = value;
        break;

      case appConstants.FINANCEMENT.NOMPRODUIT:
        this.produit_adossement.nom_produit = value;
        break;
      case appConstants.FINANCEMENT.DUREEPRODUIT:
        this.produit_adossement.duree = value;
        break;
      case appConstants.FINANCEMENT.TAUXCAPITALISATION:
        this.checkTauxCapitalisation(value);
        break;
      case appConstants.FINANCEMENT.TAUXFRAISENTREE:
        this.produit_adossement.taux_frais_entree = value;
        break;
      case appConstants.FINANCEMENT.TAUXFRAISVERSEMENT:
        this.produit_adossement.taux_frais_versement = value;
        break;
      case appConstants.FINANCEMENT.RACHATECHELONNE:
        this.produit_adossement.rachat_echelone = !this.produit_adossement
          .rachat_echelone;
        break;
      case appConstants.FINANCEMENT.AXATION:
        this.produit_adossement.axation_interet = !this.produit_adossement
          .axation_interet;
        break;
      case appConstants.FINANCEMENT.COTISATION:
        this.produit_adossement.cotisation_tresor = !this.produit_adossement
          .cotisation_tresor;
        break;
      case appConstants.FINANCEMENT.CAPITALISATINTVA:
        this.produit_adossement.capitalisation_tva = !this.produit_adossement
          .capitalisation_tva;
        break;

      case appConstants.FINANCEMENT.VERSEMENT_MENSUEL_INITIAL:
        this.trouve_mensualite.versement_initial = value;
        break;
      case appConstants.INPUT_KEYS.MONTANT_VERSEMENT:
        this.trouve_mensualite.capitale_aterme = value;
        break;
      case appConstants.FINANCEMENT.VERSEMENT_MENSUEL_MENSUALITE:
        this.trouve_mensualite.versement_mensuel = value;
        break;

      case appConstants.FINANCEMENT.VERSEMENT_INITIAL_MENSUALITE:
        this.apport_initial.versement_mensuel = value;
        break;
      case appConstants.FINANCEMENT.VERSEMENT_INITIAL_TERME:
        this.apport_initial.capitale_aterme = value;
        break;
      case appConstants.FINANCEMENT.VERSEMENT_INITIAL_INITIAL:
        this.apport_initial.versement_initial = value;
        break;

      case appConstants.FINANCEMENT.CAPITAL_TERME_INITIAL:
        this.capital_terme.versement_initial = value;
        break;
      case appConstants.FINANCEMENT.CAPITAL_TERME_MENSUALITE:
        this.capital_terme.versement_mensuel = value;
        break;
      case appConstants.FINANCEMENT.CAPITAL_TERME_TERME:
        this.capital_terme.capitale_aterme = value;
        break;

      // case appConstants.FINANCEMENT.TAUXCAPITALISATION:
      //     this.produit_adossement.taux_capitalisation = value;
      // break;
      // case appConstants.FINANCEMENT.TAUXFRAISENTREE:
      //     this.produit_adossement.taux_frais_entree = value;
      // break;
      // case appConstants.FINANCEMENT.TAUXFRAISVERSEMENT:
      //     this.produit_adossement.taux_frais_versement = value;
      // break;

      default:
        res = false;
        break;
    }
    return res;
  }

  getValue(key: string) {
    switch (key) {
      case appConstants.FINANCEMENT.AMORTISSEMENT:
        return this.caracteristique_financement.amortissable;
      case appConstants.FINANCEMENT.INFINE:
        return this.caracteristique_financement.inFine;
      case appConstants.FINANCEMENT.PASFINANCEMENT:
        return this.choix_financement.pasFinancement;
      case appConstants.FINANCEMENT.FINANCEMENTFRAISDEDUCTIBLE:
        return this.choix_financement.financementFraisDeductible;
      case appConstants.FINANCEMENT.APPORTPERSONNEL:
        return this.choix_financement.apportPersonnel;
      case appConstants.FINANCEMENT.FINANCEMENTPREMIEREANNEE:
        return this.choix_financement.financementPremierAnnee;
      case appConstants.FINANCEMENT.MONTANTFINACEMENT:
        return this.caracteristique_financement.montant_financement;
      case appConstants.FINANCEMENT.DUREEFINANCEMENT:
        return this.caracteristique_financement.duree;
      case appConstants.FINANCEMENT.TAUXINTERET:
        return this.caracteristique_financement.taux_interet;
      case appConstants.INPUT_KEYS.MONTANT_ASSURANCE:
      case appConstants.FINANCEMENT.ASSURANCEDECEINVALIDE:
        return this.caracteristique_financement.assurance_dece_invalid;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALOUI:
        return this.caracteristique_financement.rembourssement_capital;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALNON:
        return this.caracteristique_financement.rembourssement_capital;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETOUI:
        return this.caracteristique_financement.rembourssement_interet;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETNON:
        return this.caracteristique_financement.rembourssement_interet;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETMOIS:
        return this.caracteristique_financement.mois_rembourssement_interet;
      case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALMOIS:
        return this.caracteristique_financement.mois_rembourssement_capital;
      case appConstants.FINANCEMENT.MENSUALITETOTALCREDIT:
        return this.caracteristique_financement.mensualiteCreditTotal;
      case appConstants.FINANCEMENT.MONTANT_INTERET_DIFFERE:
        return this.caracteristique_financement.montantInteretDiffere;
      case appConstants.FINANCEMENT.NOMPRODUIT:
        return this.produit_adossement.nom_produit;
      case appConstants.FINANCEMENT.DUREEPRODUIT:
        return this.produit_adossement.duree;
      case appConstants.FINANCEMENT.TAUXCAPITALISATION:
        return this.produit_adossement.taux_capitalisation;
      case appConstants.FINANCEMENT.TAUXFRAISENTREE:
        return this.produit_adossement.taux_frais_entree;
      case appConstants.FINANCEMENT.TAUXFRAISVERSEMENT:
        return this.produit_adossement.taux_frais_versement;
      case appConstants.FINANCEMENT.RACHATECHELONNE:
        return this.produit_adossement.rachat_echelone;
      case appConstants.FINANCEMENT.AXATION:
        return this.produit_adossement.axation_interet;
      case appConstants.FINANCEMENT.COTISATION:
        return this.produit_adossement.cotisation_tresor;
      case appConstants.FINANCEMENT.CAPITALISATINTVA:
        return this.produit_adossement.capitalisation_tva;

      case appConstants.FINANCEMENT.VERSEMENT_MENSUEL_INITIAL:
        return this.trouve_mensualite.versement_initial;
      case appConstants.INPUT_KEYS.MONTANT_VERSEMENT:
        return this.trouve_mensualite.capitale_aterme;
      case appConstants.FINANCEMENT.VERSEMENT_MENSUEL_MENSUALITE:
        return this.trouve_mensualite.versement_mensuel;

      case appConstants.FINANCEMENT.VERSEMENT_INITIAL_MENSUALITE:
        return this.apport_initial.versement_mensuel;
      case appConstants.FINANCEMENT.VERSEMENT_INITIAL_INITIAL:
        return this.apport_initial.versement_initial;
      case appConstants.FINANCEMENT.VERSEMENT_INITIAL_TERME:
        return this.apport_initial.versement_initial;

      case appConstants.FINANCEMENT.CAPITAL_TERME_INITIAL:
        return this.capital_terme.versement_initial;
      case appConstants.FINANCEMENT.CAPITAL_TERME_MENSUALITE:
        return this.capital_terme.versement_mensuel;
      case appConstants.FINANCEMENT.CAPITAL_TERME_TERME:
        return this.capital_terme.capitale_aterme;

      case appConstants.FINANCEMENT.ANNEE:
        return this.annee;
      case appConstants.FINANCEMENT.MOIS:
        return this.mois;
      case appConstants.FINANCEMENT.EURO:
        return this.euro;
      case appConstants.FINANCEMENT.POURCENTAGE:
        return this.poucentage;

      case appConstants.FINANCEMENT.SOIT:
        return this.choix_financement.soit != null
          ? this.choix_financement.soit
          : 0;
      default:
        break;
    }
    return false;
  }

  hydrateProduitFiArray(data: Array<any>) {
    if (data?.length > 0) {
      return data?.map((elt?) => new ProduitFinancier(elt));
    }
    return this.listProduitFinancier;
  }

  serializeProduitFinac() {
    return {
      amortissable: this.caracteristique_financement.amortissable,
      assurance: this.caracteristique_financement.assurance_dece_invalid,
      differeCapital: this.caracteristique_financement.rembourssement_capital,
      differeCapitalMois: this.caracteristique_financement
        .mois_rembourssement_capital,
      differeInteret: this.caracteristique_financement.rembourssement_interet,
      differeInteretMois: this.caracteristique_financement
        .mois_rembourssement_interet,
      duree: this.caracteristique_financement.duree,
      dureeAdossement: this.produit_adossement.duree,
      id: null,
      inFine: this.caracteristique_financement.inFine,
      nomAdossement: this.produit_adossement.nom_produit,
      txCapitalisation: this.produit_adossement.taux_capitalisation,
      txFraisEntree: this.produit_adossement.taux_frais_entree,
      txFraisVersement: this.produit_adossement.taux_frais_versement,
      txInteret: this.caracteristique_financement.taux_interet,
    };
  }

  hydrateModelByProduitFi(data: any) {
    this.caracteristique_financement.amortissable = data?.amortissable || true;
    this.caracteristique_financement.assurance_dece_invalid = data?.assurance;
    this.errassuranceDece = data?.assurance;
    this.caracteristique_financement.rembourssement_capital =
      data?.differeCapital;
    this.caracteristique_financement.mois_rembourssement_capital =
      data?.differeCapitalMois;
    this.caracteristique_financement.rembourssement_interet =
      data?.differeInteret;
    this.caracteristique_financement.mois_rembourssement_interet =
      data?.differeInteretMois;
    this.caracteristique_financement.duree = data?.duree;
    this.caracteristique_financement.id = data?.id;
    this.produit_adossement.duree = data?.dureeAdossement;
    this.caracteristique_financement.inFine = data?.inFine;
    this.produit_adossement.nom_produit = data?.nomAdossement;
    this.produit_adossement.taux_capitalisation = data?.txCapitalisation;
    this.produit_adossement.taux_frais_entree = data?.txFraisEntree;
    this.produit_adossement.taux_frais_versement = data?.txFraisVersement;
    this.caracteristique_financement.taux_interet = data?.credit.txInteret;
  }

  editingFieldBySelectProduit(e: any) {
    const { value } = e.target;
    this.caracteristique_financement.amortissable = this.listProduitFinancier[value]?.amortissable;
    this.assurDece.montantAssurance.montant = this.listProduitFinancier[value]?.assurance || null;
    this.caracteristique_financement.assurance_dece_invalid = this.listProduitFinancier[value]?.assurance;
    this.caracteristique_financement.rembourssement_capital = this.listProduitFinancier[value]?.differeCapital;
    this.caracteristique_financement.mois_rembourssement_capital = this.listProduitFinancier[value]?.differeCapitalMois || 12;
    this.caracteristique_financement.rembourssement_interet = this.listProduitFinancier[value]?.differeInteret;
    this.caracteristique_financement.mois_rembourssement_interet = this.listProduitFinancier[value]?.differeInteretMois || 12;
    this.caracteristique_financement.duree = this.listProduitFinancier[value]?.duree;
    this.produit_adossement.duree = this.listProduitFinancier[value]?.dureeAdossement;
    this.caracteristique_financement.inFine = this.listProduitFinancier[value]?.inFine;
    this.produit_adossement.nom_produit = this.listProduitFinancier[value]?.nomAdossement;
    this.produit_adossement.taux_capitalisation = this.listProduitFinancier[value]?.txCapitalisation;
    this.produit_adossement.taux_frais_entree = this.listProduitFinancier[value]?.txFraisEntree;
    this.produit_adossement.taux_frais_versement = this.listProduitFinancier[value]?.txFraisVersement;
    this.caracteristique_financement.taux_interet = this.listProduitFinancier[value]?.txInteret;
    this.indexProduit = this.listProduitFinancier[value]?.id;

    // this.errMontantFinencent = (this.listProduitFinancier[value] !== null)?false:true;
    this.errassuranceDece = this.listProduitFinancier[value]?.assurance !== null ? false : true;
    this.errdureefinancement = this.listProduitFinancier[value]?.duree !== null ? false : true;
    this.errtauxCapitalisation = this.listProduitFinancier[value].txCapitalisation !== null ? false : true;
    this.errtauxInteret = this.listProduitFinancier[value].txInteret !== null ? false : true;

  }

  checkmontant(val: any) {
    console.log(val);
    if (val === null || val === "" || val <= 0) {
      this.errMontantFinencent = true;
      this.caracteristique_financement.montant_financement = val;
    } else {
      this.errMontantFinencent = false;
      this.caracteristique_financement.montant_financement = val;
    }
  }

  checkAssurance(val: any) {
    console.log(val);
    if (val === null || val === "" || val <= 0) {
      this.errassuranceDece = true;
    } else {
      this.errassuranceDece = false;
    }
    this.caracteristique_financement.assurance_dece_invalid = val;
    this.assurDece.montantAssurance.montant = val;

  }

  checkDuree(val: any) {
    console.log(val);
    if (val === null || val === "" || val <= 0) {
      this.errdureefinancement = true;
      this.caracteristique_financement.duree = val;
    } else {
      this.errdureefinancement = false;
      this.caracteristique_financement.duree = val;
    }
  }

  checkTauxInteret(val: any) {
    console.log(val);
    if (val === null || val === "" || val <= 0) {
      this.errtauxInteret = true;
      this.caracteristique_financement.taux_interet = val;
    } else {
      this.errtauxInteret = false;
      this.caracteristique_financement.taux_interet = val;
    }
  }

  checkTauxCapitalisation(val: any) {
    if (val === null || val === "" || val <= 0) {
      this.errtauxCapitalisation = true;
      this.produit_adossement.taux_capitalisation = val;
    } else {
      this.errtauxCapitalisation = false;
      this.produit_adossement.taux_capitalisation = val;
    }
  }
  // serialize data mono financement
  serialiseMonoFinacement() {
    return {
      adi100: this.poucentage,
      adi10000: this.euro,
      adiFuture: this.assurDece.ArraymontantAssurance.length > 0 ? true : false,
      adiFutureList: this.assurDece.montantAssuranceArraySerialize(),
      amortissable: this.caracteristique_financement.amortissable,
      // montantAFinancer: this.montantAfinancer,
      assurance: this.caracteristique_financement.assurance_dece_invalid,
      differeCapital: this.caracteristique_financement.rembourssement_capital,
      differeCapitalMois:
        this.caracteristique_financement.rembourssement_capital === false
          ? null
          : this.caracteristique_financement.mois_rembourssement_capital,
      differeInteret: this.caracteristique_financement.rembourssement_interet,
      differeInteretMois:
        this.caracteristique_financement.rembourssement_interet === false
          ? null
          : this.caracteristique_financement.mois_rembourssement_interet,
      duree: this.caracteristique_financement.duree,
      txInteret: this.caracteristique_financement.taux_interet,
      id: this.id,
      inFine: this.caracteristique_financement.inFine,
      pasFinancement: this.choix_financement.pasFinancement,
      credit: this.caracteristique_financement.montant_financement,
      financementFrais: this.choix_financement.pasFinancement
        ? false
        : this.choix_financement.financementFraisDeductible,
      appPers: this.choix_financement.apportPersonnel,

      appPersAvecFrais: this.choix_financement.soit,
      mensualiteCreditTotal: this.caracteristique_financement
        .mensualiteCreditTotal,
      montantInteretDiffere: this.caracteristique_financement
        .montantInteretDiffere,
      financementTravaux: this.choix_financement.pasFinancement
        ? false
        : this.choix_financement.financementPremierAnnee,
      produitAdossement:
        this.caracteristique_financement.amortissable === true ||
          this.choix_financement.pasFinancement === true
          ? null
          : {
            apportCapitalTerme: this.apport_initial_futur
              .montantApportInitVersement.montant,
            apportMensualiteVersement: this.apport_initial.versement_mensuel,
            apportVersementInitial: this.apport_initial.versement_initial,
            apportVersementSupplementaireFuture:
              this.apport_initial_futur.ArraymontantApportInitVersement
                .length > 0
                ? true
                : false,
            apportVersementSupplementaireFutureList: this.apport_initial_futur.montantApportArraySerialize(),

            capitalTerme: this.capital_terme.capitale_aterme,
            capitalVersementInitial: this.capital_terme.versement_initial,
            capitalVersementMensuel: this.capital_terme.versement_mensuel,
            capitauxExedent: this.produit_adossement.cotisation_tresor,
            capitauxTva: this.produit_adossement.capitalisation_tva,
            rachat: this.produit_adossement.rachat_echelone,
            taxationInteret: this.produit_adossement.axation_interet,

            mensualiteCapitalTerme: this.versementSupp_futur.montantVersement
              .montant,
            mensualiteVersementInitial: this.trouve_mensualite
              .versement_initial,
            mensualiteVersementMensuel: this.trouve_mensualite
              .versement_mensuel,
            mensualiteVersementSupplementaireFuture:
              this.versementSupp_futur.ArraymontantVersement.length > 0
                ? true
                : false,
            mensualiteVersementSupplementaireFutureList: this.versementSupp_futur.montantVersementMensuelSerialize(),

            nomAdossement: this.produit_adossement.nom_produit,
            txCapitalisation: this.produit_adossement.taux_capitalisation,
            txFraisEntree: this.produit_adossement.taux_frais_entree,
            txFraisVersement: this.produit_adossement.taux_frais_versement,
            dureeAdossement: this.produit_adossement.duree,
            id: this.produit_adossement.id,
          },
    };
  }

  // hydrate mono financement
  hydrateModelMonoFinancement(data: any) {
    this.poucentage = !data?.adi100 && !data?.adi10000 ? true : data?.adi100;
    this.euro = data?.adi10000;
    this.assurDece.montantAssurance.montant = data?.assurance;
    this.assurDece.hasAnneeFuturAssurance = data?.adiFuture;
    this.assurDece.hydrateArrayModelAssurance(data?.adiFutureList);
    this.caracteristique_financement.assurance_dece_invalid = data?.assurance;
    this.caracteristique_financement.rembourssement_capital =
      data?.credit?.differeCapital;
    this.caracteristique_financement.mois_rembourssement_capital =
      data?.credit?.differeCapitalMois || 12;
    this.caracteristique_financement.rembourssement_interet =
      data?.credit?.differeInteret;
    this.caracteristique_financement.mois_rembourssement_interet =
      data?.credit?.differeInteretMois || 12;
    this.caracteristique_financement.duree = data?.credit?.duree;
    this.caracteristique_financement.taux_interet = data?.credit?.txInteret;
    this.caracteristique_financement.id = data?.credit?.id;
    this.caracteristique_financement.mensualiteCreditTotal =
      data?.credit?.mensualiteCreditTotal;
    this.caracteristique_financement.montantInteretDiffere =
      data?.credit?.montantInteretDiffere;
    this.id = data?.id;
    this.caracteristique_financement.inFine = data?.inFine;
    this.caracteristique_financement.amortissable = (data === null || (data?.amortissable === false && data?.inFine === false)) ? true : data?.amortissable;
    this.choix_financement.financementFraisDeductible = (data === null) ? true : data?.financementFrais;
    this.choix_financement.pasFinancement = data?.pasFinancement;
    this.choix_financement.financementPremierAnnee = data?.pasFinancement
      ? false
      : data?.financementTravaux;
    this.caracteristique_financement.montant_financement = data?.credit?.credit;
    this.choix_financement.apportPersonnel = data?.appPers;
    this.choix_financement.soit = data?.appPersAvecFrais;

    this.hydrateProduitFinancier(data?.produitAdossement);
    this.errMontantFinencent =
      data?.credit === null ||
        data?.credit?.credit == null ||
        data?.credit?.credit === 0
        ? true
        : false;
    this.errassuranceDece =
      data === null || data?.assurance_dece_invalid == null || data?.assurance_dece_invalid === 0
        ? true
        : false;
    this.errdureefinancement =
      data?.credit === null ||
        data?.credit?.duree == null ||
        data?.credit?.duree === 0
        ? true
        : false;
    this.errtauxInteret =
      data?.credit === null ||
        data?.credit?.txInteret == null ||
        data?.credit?.txInteret === 0
        ? true
        : false;
  }

  hydrateProduitFinancier(data: any) {
    console.log("----- data -----");
    console.log(data);
    console.log("----- data -----");
    this.produit_adossement.duree = data?.dureeAdossement;
    this.produit_adossement.nom_produit = data?.nomAdossement;
    this.produit_adossement.taux_capitalisation = data?.txCapitalisation;
    this.produit_adossement.taux_frais_entree = data?.txFraisEntree;
    this.produit_adossement.taux_frais_versement = data?.txFraisVersement;
    this.produit_adossement.id = data?.id;

    this.versementSupp_futur.montantVersement.montant =
      data?.mensualiteCapitalTerme;
    this.trouve_mensualite.versement_initial = data?.mensualiteVersementInitial;
    this.trouve_mensualite.versement_mensuel = data?.mensualiteVersementMensuel;
    this.versementSupp_futur.hasAnneeFuturVersement =
      data?.mensualiteVersementSupplementaireFuture;
    this.versementSupp_futur.hydrateArrayModelVersementMensuel(
      data?.mensualiteVersementSupplementaireFutureList
    );

    this.capital_terme.capitale_aterme = data?.capitalTerme;
    this.capital_terme.versement_initial = data?.capitalVersementInitial;
    this.capital_terme.versement_mensuel = data?.capitalVersementMensuel;

    this.apport_initial_futur.montantApportInitVersement.montant =
      data?.apportCapitalTerme;
    this.apport_initial.versement_mensuel = data?.apportMensualiteVersement;
    this.apport_initial.versement_initial = data?.apportVersementInitial;
    this.apport_initial_futur.hasAnneeFuturApportInitVersement =
      data?.apportVersementSupplementaireFuture;
    this.apport_initial_futur.hydrateArrayModelmontantApport(
      data?.apportVersementSupplementaireFutureList
    );

    this.produit_adossement.nom_produit = data?.nomAdossement;
    this.produit_adossement.taux_capitalisation = data?.txCapitalisation;
    this.produit_adossement.taux_frais_entree = data?.txFraisEntree;
    this.produit_adossement.taux_frais_versement = data?.txFraisVersement;
    this.produit_adossement.duree = data?.dureeAdossement;
    this.produit_adossement.id = data?.id;
    this.produit_adossement.cotisation_tresor = data?.capitauxExedent;
    this.produit_adossement.capitalisation_tva = data?.capitauxTva;
    this.produit_adossement.rachat_echelone = data?.rachat;
    this.produit_adossement.axation_interet = data?.taxationInteret;

    this.errtauxCapitalisation = data?.txCapitalisation !== null ? false : true;
  }
}
export { MonoFinancement };

