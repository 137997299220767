import axios, { AxiosInstance, AxiosResponse } from "axios";
import { history } from "../_helpers/history"

export const restClient = {
  get,
  post,
  put,
  remove,
  patch,
  getInstance,
  getFile,
  getToken,
};

const urlServiceRest = "http://82.165.121.81:8080/EasyDefiscRest/";
//const urlServiceRest = "http://127.0.0.1:8081/EasyDefiscRest/";

async function getInstance(multipart?: string): Promise<AxiosInstance> {
  return new Promise(async (resolve, reject) => {
    const headers = getHeaders(multipart);
    resolve(
      axios.create({
        timeout: 60000,
        headers: headers,
        // withCredentials: true,
        baseURL: urlServiceRest,
      })
    );
  });
}
function getToken(): string{
  let dataUser:any = localStorage.getItem("userData");
  let user = JSON.parse(dataUser);
  if(user?.token !== "" &&  user?.token !== null && user?.token !== undefined ){
    return user?.token
  }
    return "";
}

function getHeaders(multipart?: string) {
  return {
    Accept: "application/json",
    "Content-Type": multipart || "application/json",
    'Authorization': getToken()
  };
}

function getPayload(req: any, data?: any) {
  return data || req.body || req;
}

async function get(req: any) {
  const axiosInstance = await getInstance();
  return axiosInstance
    .get(req)
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function getFile(req: any) {
  return axios({
    method: "GET",
    url: urlServiceRest + "" + req,
    responseType: "blob",
    headers: {
      Accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      Authorization: getToken()
    },
  })
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function post(req: any, data?: any) {
  const axiosInstance = await getInstance();
  return axiosInstance
    .post(req, getPayload(req, data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function put(req: any, data?: any) {
  const axiosInstance = await getInstance();
  return axiosInstance
    .put(req, getPayload(req, data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function patch(req: any, data?: any) {
  const axiosInstance = await getInstance();
  return axiosInstance
    .patch(req, getPayload(req, data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

async function remove(req: any, data?: any) {
  const axiosInstance = await getInstance();
  return axiosInstance
    .delete(req, getPayload(req, data))
    .then((res: any) => handleResponse(res))
    .catch((err: any) => handleError(err));
}

function handleResponse(res: AxiosResponse) {
  // console.log("Request response data ==> ", res.data);
  console.log(res);
  return res;
}

function handleError(err: any) {
  // console.log("<== Request Error ==> ");
  let data = null;
  let status = 500;
  let message =
    "Une erreur est survenu lors de l'opération. Merci de reéssayer !";
  if(!err.response && err.message){
    data = null;
    status = 401;
    message ="Token expiré !";

    localStorage.removeItem("userData");
    localStorage.removeItem("keyupdate");
    history.push("/");
  }
  if (err.response) {
    
    data = err.response.data;
    status = err.response.status;
    if (err?.response?.data?.message) {
      message = err.response.data.message;
    }
  } else if (err.request) {
    console.log(err.request);
  } else {
    console.log(err.message);
  }
  
  if (status === 401) {
    data = null;
    status = 401;
    message ="Token expiré !";

    localStorage.removeItem("userData");
    localStorage.removeItem("keyupdate");
    history.push("/");
    
  }
  return { success: false, status, data, message };
}
