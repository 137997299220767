import React, { Component } from 'react';
import { appConstants } from "../../_constants";
import { connector, PropsFromRedux } from "../../_reducers";

const caretup = require("../../_assets/images/optionIcons/caret-up.png");
const caretdown = require("../../_assets/images/optionIcons/caret-down.png");

class GestionClient extends Component<PropsFromRedux, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            dossierLength: 0,
            dossierMaxLength: 16,
            pages: {
                page: 0,
                size: 13,
            },
        };
      
    }

    componentDidMount(){
        this.getMaxlength();
        this.getAllData();
    }

    async getAllData() {
        const { model, replaceModel } = this.props;
        model.loading = true;
        replaceModel(model);
        const config = this.state.pages;
        this.setState({ dossierLength: 0 });
        if (model.clientArray.length === 0) {
            let data = await model.getClientByPartenaire(config);
            model.clientArray = data;
            this.setState({
                pages: {
                    page: this.state.pages.page + 1,
                    size: 13,
                },
                dossierLength: data.length
            });
            model.loading = false;
            replaceModel(model);
        } else {
            this.setState({
                pages: {
                    page: this.state.pages.page + 1,
                    size: 13,
                }
            });
            let data = await model.getClientByPartenaire(this.state.pages);
            this.setState({ dossierLength: data.length });
            for (let dataone of data) {
                model.clientArray.push(dataone);
            }
            model.loading = false;
            replaceModel(model);
        }
    }

    componentWillUnmount(){
        let { model, replaceModel } = this.props;
        model.clientArray = [];
        replaceModel(model);
        this.setState({
            dossierLength: 0,
            dossierMaxLength: 0,
            pages: {
                page: 0,
                size: 13,
            },
        });
    }

    async getMaxlength() {
        const { model } = this.props;
        let res = await model.getNbDossierByPartenaire()
        if (res !== null) {
            this.setState({ dossierMaxLength: res });
        }
    }

    handleScroll(e: any) {
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        const { model } = this.props;
        if (scrollHeight - scrollTop === clientHeight) {
            console.log(model.clientArray.length, " === ", this.state.dossierMaxLength);
            if (model.clientArray.length < this.state.dossierMaxLength) {
                this.getAllData();
            }
        }
    }

    render() {
        const { model } = this.props;
        return (
            <div className="container-fluid p-2">
                <div className="table-box mx-3 mt-4 ">
                        <table className="table table-striped ">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Date de simulation
                                            <div className="d-flex flex-column ml-1">
                                                 <img src={caretup} alt="sort asc " id={appConstants.SORT_CLIENT.NOM_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                                 <img src={caretdown} alt="sort desc" id={appConstants.SORT_CLIENT.NOM_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </th>

                                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Nom du client
                                            <div className="d-flex flex-column ml-1">
                                                <img src={caretup} alt="sort asc " id={appConstants.SORT_CLIENT.NOM_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_CLIENT.NOM_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </th>

                                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Revenu fiscal de référence
                                            <div className="d-flex flex-column ml-1">
                                                <img src={caretup} alt="sort asc " id={appConstants.SORT_CLIENT.REVENUE_FISCAL_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_CLIENT.REVENUE_FISCAL_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </th>
                                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Nombre de parts fiscales 
                                            <div className="d-flex flex-column ml-1">
                                                <img src={caretup} alt="sort asc " id={appConstants.SORT_CLIENT.NB_PARTFISCALE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_CLIENT.NB_PARTFISCALE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                                            </div>   
                                        </div>
                                    </th>
                                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Résultat foncier
                                            <div className="d-flex flex-column ml-1">
                                                <img src={caretup} alt="sort asc " id={appConstants.SORT_CLIENT.RESULTAT_FONCIER_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_CLIENT.RESULTAT_FONCIER_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                                            </div>    
                                        </div>
                                    </th>
                                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Tranche Marginale d'Imposition
                                            <div className="d-flex flex-column ml-1">
                                                <img src={caretup} alt="sort asc " id={appConstants.SORT_CLIENT.TRANCHE_MARGINALE_IMPOT_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_CLIENT.TRANCHE_MARGINALE_IMPOT_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                                            </div>   
                                        </div>
                                    </th>
                                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                                        <div className="d-flex justify-content-center align-items-center">
                                            Impôt de référence
                                            <div className="d-flex flex-column ml-1">
                                                <img src={caretup} alt="sort asc " id={appConstants.SORT_CLIENT.IMPOT_REFERENCE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_CLIENT.IMPOT_REFERENCE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                                            </div>  
                                        </div>
                                    </th>
                                    <th scope="col" className="text-center" style={{fontSize: "11px", verticalAlign: "middle"}} >
                                        <div className="d-flex justify-content-center align-items-center">
                                        Evolution de votre situation actuelle
                                             <div className="d-flex flex-column ml-1">
                                                <img src={caretup} alt="sort asc " id={appConstants.SORT_CLIENT.SUTUATION_ACTUELLE_ASC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginBottom: "1.5px", cursor: "pointer" }} />
                                                <img src={caretdown} alt="sort desc" id={appConstants.SORT_CLIENT.SUTUATION_ACTUELLE_DESC} onClick={(e) => this.setValue(e)} style={{ width: "10px", marginTop: "1.5px", cursor: "pointer" }} />
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                        <tbody onScroll={(e) => { this.handleScroll(e) }}>
                            { (model?.clientArray.length === 0)? 
                                <tr>
                                    <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">Aucune donnée</td>
                                </tr> :
                                model?.clientArray?.map((item, i:number) => {
                                    console.log(model?.clientArray);
                                   return ( 
                                       <tr className="client">
                                           <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">{item?.dateSimulation}</td>
                                           <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">{item?.nom}</td>
                                           <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">{item?.revenu_fiscal === "NaN" || item?.revenu_fiscal === null ? '-': item?.revenu_fiscal }</td>
                                           <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">{item?.nbr_part}</td>
                                           <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">{item?.resultat_foncier} €</td>
                                           <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">{item?.tache_marginale}%</td>
                                           <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">{item?.impot} € </td>
                                           <td style={{ fontSize: "11px", verticalAlign: "middle" }} className="text-center">{item?.evolution ? 'Oui' : 'Non'} </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
            </div>
        );
    }

    setValue(e: React.MouseEvent<HTMLImageElement, MouseEvent>): any {
        console.log('filtre');
        const { model, replaceModel } = this.props;
        const res = model.onChangeClient(e);
        if (res) {
            replaceModel(model);
        }
    }
}
const connected = connector(GestionClient);
export { connected as GestionClient};