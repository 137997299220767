import React, { Component } from "react";
import { appConstants } from "../../_constants";
import { connector, PropsFromRedux } from "../../_reducers";

class Foyer extends Component<PropsFromRedux, any> {
  render() {
    const value1 = this.getValue(appConstants.INPUT_KEYS.RESULTAT_FONCIER_EXISTANT);
    const checkValue1 = this.getValue(appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT);
    const value2 = this.getValue(appConstants.INPUT_KEYS.PERISOL_EN_COUR);
    const checkValue2 = this.getValue(appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR);
    const value3 = this.getValue(appConstants.INPUT_KEYS.DEFICIT_NON_IMPUTE);
    const checkValue3 = this.getValue(appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE);

    return (
      <div className="col-lg-12  justify-content-around o-h h-95 pt-2 px-3">
        <div className="d-flex flex-column declarant-wrapper justify-content-around px-3 pb-3">
          <h1 style={{ textAlign: "left" }}>
            <span className="px-3 big-text font-weight-bold">Résultat foncier du foyer fiscal</span>
          </h1>

          <div className="row mt-1 mt-0 pt-0">
            <div className="col col-lg-5">
              <p className="big-text my-0 mb-0 d-md-block px-2">Résultat foncier existant</p>
            </div>
            <div className="col col-lg-3 px-0 mb-3">
              <div className="d-flex border-light-gray align-items-center px-0">
                <div className="inputs align-items-center py-1 px-1">
                  <input
                    type="number"
                    step="0.01"
                    id={appConstants.INPUT_KEYS.RESULTAT_FONCIER_EXISTANT}
                    min="0"
                    className="form-control2 flex-1 mr-1"
                    onChange={(e) => this.updateMontant(e)}
                    onBlur={(e) => this.setCalcutaxe()}
                    value={value1}
                  />
                </div>
                
                <div className="align-items-end training py-1 text-center">
                  <small className="font-weight-light text-blue">€/an</small>
                </div>
              </div>
            </div>
            <div className="col col-lg-4">
              <div className="px-0 custom-control d-flex justify-content-center custom-checkbox">
                <input
                  type="checkbox"
                  disabled={value1 === 0 || value1 === null}
                  checked={(checkValue1 as boolean)}
                  onChange={(e) => this.handleCheckAnneeFutur(e)}
                  className="custom-control-input"
                  id={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT}
                />
                <label
                  className="custom-control-label text-dark-blue font-weight-light small-radio"
                  htmlFor={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT}
                >
                  Saisir années futures
                  </label>
              </div>
            </div>
          </div>
          <div className="row mt-1 mt-0 pt-0">
            <div className="col col-lg-5">
              <p className="big-text m-0 mb-0 d-md-block px-2">Périsol en cours</p>
              <small className="d-flex" style={{color: "white"}}>-----------------------------------------------</small>
            </div>
            <div className="col col-lg-3 px-0 mb-3">
              <div className="d-flex border-light-gray align-items-center px-0">
                <div className="inputs align-items-center py-1 px-1">
                  <input
                    type="number"
                    step="0.01"
                    min="-100000000"
                    className="form-control2 flex-1 mr-1"
                    onChange={(e) => this.updateMontant(e)}
                    onBlur={(e) => this.setCalcutaxe()}
                    value={value2}
                    id={appConstants.INPUT_KEYS.PERISOL_EN_COUR}
                  />
                </div>
                
                <div className="align-items-end training py-1 text-center">
                  <small className="font-weight-light text-blue">€/an</small>
                </div>
              </div>
            </div>
            <div className="col col-lg-4">
              <div className="px-0 custom-control d-flex justify-content-center custom-checkbox">
                <input
                  type="checkbox"
                  disabled={value2 === 0 || value2 === null}
                  checked={checkValue2}
                  onChange={(e) => this.handleCheckAnneeFutur(e)}
                  className="custom-control-input"
                  id={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR}
                />
                <label
                  className="custom-control-label text-dark-blue font-weight-light small-radio"
                  htmlFor={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_PERISOL_EN_COUR}
                >
                  Saisir années futures
                  </label>
              </div>
            </div>
          </div>

          <div className="row mt-1 mt-0 pt-0">
            <div className="col col-lg-5">
              <p className="big-text my-0 mb-0 d-md-block px-2">Déficit non imputé à reporter</p>
            </div>
            <div className="col col-lg-3 px-0 mb-3">
              <div className="d-flex border-light-gray align-items-center px-0">
                <div className="inputs align-items-center py-1 px-1">
                  <input
                    type="number"
                    step="0.01"
                    min="0"
                    className="form-control2 flex-1 mr-1"
                    onChange={(e) => this.updateMontant(e)}
                    onBlur={(e) => this.setCalcutaxe()}
                    value={value3}
                    id={appConstants.INPUT_KEYS.DEFICIT_NON_IMPUTE}
                  />
                </div>
                
                <div className="align-items-end training py-1 text-center">
                  <small className="font-weight-light text-blue">€/an</small>
                </div>
              </div>
            </div>
            <div className="col col-lg-4">
              <div className="px-0 custom-control d-flex justify-content-center custom-checkbox">
                <input
                  type="checkbox"
                  disabled={value3 === 0 || value3 === null}
                  checked={checkValue3}
                  onChange={(e) => this.handleCheckAnneeFutur(e)}
                  className="custom-control-input"
                  id={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE}
                />
                <label
                  className="custom-control-label text-dark-blue font-weight-light small-radio"
                  htmlFor={appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE}
                >
                  Saisir années futures
                  </label>
              </div>
            </div>
          </div>

          <div className="row mt-1 mt-0 pt-0">
            <div className="col col-lg-5">
              <p className="big-text my-0 mb-0 d-md-block px-2"></p>
            </div>
            <div className="col col-lg-3 px-0 mb-4">
              <div className="d-flex align-items-center px-1">
              </div>
            </div>
            <div className="col col-lg-4 mb-2">
              <div className="px-0 custom-control d-flex justify-content-center custom-checkbox">
              </div>
            </div>
          </div>

        </div>
      </div >
    );
  }

  getValue(key: string): any {
    const { model } = this.props
    return model.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.getValue(key);
  }

  handleCheckAnneeFutur(e: any) {
    const { model, replaceModel } = this.props;
    const { id, checked } = e.target;
    model.loading = true;
    if (checked) {
      model.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.onChange(e);
      model.eventUpdateDossier = true;
    }
    model.situationFiscale.revenu.newCalcule = true;
    replaceModel(model);
    model.isModalOpen = true;
    model.loading = false;
    model.modal.provider = model.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier;
    model.modal.size = "lg";
    model.modal.showCancel = true;
    model.modal.mode = id;
    model.modal.title = model.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.getModalTitle(id);
    setTimeout(() => {
      replaceModel(model);
    }, 1000);
  }

  async setCalcutaxe() {
    const { model, replaceModel } = this.props;
    model.loading = true;
    replaceModel(model);
    let res = await model.calculTax();
    model.loading = false;
    replaceModel(model);
    if (res) {
      // this.props.replaceModel(model);
      model.situationFiscale.revenu.newCalcule = false;
      this.props.replaceModel(model);
      console.log("replace");
    }
  }

  updateMontant(e: any) {
    const { model, replaceModel } = this.props
    const res = model.situationFiscale.resultatFoncier.foyerFiscalResultatFoncier.onChange(e);
    if (res) {
      model.eventUpdateDossier = true;
      model.situationFiscale.revenu.newCalcule = true;
      replaceModel(model);
    }
  }
}

export default connector(Foyer);
