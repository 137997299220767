class OtherData{
    revenuFiscaleReference: number;
    nouvelleEtude: boolean;
    evolutionActuelle: boolean;
    resultatFonctier: number

    constructor(){
        this.revenuFiscaleReference = 0;
        this.nouvelleEtude = true;
        this.evolutionActuelle = false;
        this.resultatFonctier = 0;
    }
}
export {OtherData}