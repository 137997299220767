class Utils {
  private static _instance: Utils | undefined;

  constructor() {
    if (Utils._instance) {
      throw new Error(
        "Error : Instanciation failed : Use Utils.getInstance() instead of new."
      );
    }
    Utils._instance = this;
  }

  public static getInstance(): Utils {
    if (!Utils._instance) {
      return new Utils();
    }
    return Utils._instance;
  }

  public static reset() {
    Utils._instance = undefined;
  }

  getNumberInputValue(e: any): number {
    // console.log("original value "+e.target.value);
    // let mt = parseFloat(e.target.value);
    // console.log("update value "+mt);
    
    // if (mt >= 0) {
    //   // e.target.value = e.target.value.replace(/^0+/, "");
    //   mt = parseFloat(e.target.value);
    // }
    // if (!(typeof mt === "number" && mt > 0)) {
    //   mt = 0;
    // }
    return e.target.value;
  }

  getAnneeFuturInputConfig(
    annee: number,
    montant: number,
    onChange: any,
    key: string,
    name: string,
    id: string,
    onBlur: any,
    options: any = {}
  ) {
    return {
      leading: annee,
      type: "number",
      trailling: "€",
      label: false,
      value: montant,
      onChange: onChange,
      onBlur: null,
      key: key,
      name: name,
      id: id,
      options: JSON.stringify(options),
    };
  }

  getAnneeFuturInput2Config(
    value: number,
    onChange: any,
    key: string,
    name: string,
    id: string,
    onBlur: any,
    options: any = {}
  ) {
    return {
      leading: false,
      type: "number",
      trailling: "%",
      label: false,
      value: value,
      key: key,
      name: name,
      id: id,
      min: 0,
      max: 100,
      onChange: onChange,
      onBlur: null,
      options: JSON.stringify(options),
    };
  }

  getAmountInputConfig(
    value: number,
    onChange: any,
    key: string,
    name: string,
    id: string,
    options: any = {},
    style: any = {},
    border?: any
  ) {
    return {
      border: border,
      leading: false,
      type: "number",
      trailling: "€/an",
      label: false,
      value: value,
      key: key,
      name: name,
      id: id,
      min: 0,
      max: 100000000,
      onChange: onChange,
      style: style,
      options: JSON.stringify(options),
    };
  }

  getRangeInputConfig(
    value: number,
    onChange: any,
    key: string,
    name: string,
    id: string,
    options: any = {},
    style: any = {}
  ) {
    return {
      leading: false,
      type: "number",
      trailling: "€/an",
      label: false,
      value: value,
      key: key,
      name: name,
      id: id,
      min: 0,
      max: 100000000,
      onChange: onChange,
      style: style,
      options: JSON.stringify(options),
    };
  }

  isSolution() {
    return window.location.pathname.indexOf("/solution-fiscal") !== -1 
  }

  isSituation() {
    return window.location.pathname.indexOf("/situation-fiscal") !== -1 
  }

  isSynthese() {
    return window.location.pathname.indexOf("/synthese-fiscal") !== -1 
  }
}

export { Utils };
