export const OBJECTIF_KEY = {
    REDUIRE_IMPOTS : "REDUIRE_IMPOTS",
    CONSTITER_PATRIMOINE: "CONSTITER_PATRIMOINE",
    PROTEGER_FAMILLE: "PROTEGER_FAMILLE",
    COMPLETER_RETRAITRE: "COMPLETER_RETRAITRE",
    PROFITER_INVESTISSEMENT: "PROFITER_INVESTISSEMENT",
    COMPLETER_REVENUE: "COMPLETER_REVENUE",
    MOINDE5ANS: "MOINDE5ANS",
    DE5A9ANS: "DE5A9ANS",
    PLUSDE9ANS: "PLUSDE9ANS",
    PROTECTION_EN_PLACE: "PROTECTION_EN_PLACE"
}