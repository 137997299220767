import { appConstants } from "../_constants";

class Regime { 
    passage:number | null;
    regime:boolean;
    lmnp:boolean;
    taux: boolean;
    frais_etablissement: number | null;
    frais_comptabilite: number | null;
    valeur_terrain: number | null;
    valeur_mobilier: number | null;
    taxe_fonciere: number | null;
    pendant: number | null;
    tva_oui:boolean;
    tva_non:boolean;
    date_repatition:number;
    id : number | null;
    loi : String


    constructor(){
        this.taux = false;
        this.passage = 9;
        this.regime = true;
        this.lmnp = false;
        this.frais_etablissement = null;
        this.frais_comptabilite = null;
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
        this.tva_oui = true;
        this.tva_non = false;
        this.date_repatition = 1;
        this.id = null ;
        this.loi = "REGIME_GENERALE";
    }

    resetMiseEnplaceLmnp(){
        this.frais_etablissement = null;
        this.frais_comptabilite = null; 
        this.valeur_terrain = null;
        this.valeur_mobilier = null;
        this.taxe_fonciere = null;
        this.pendant = null;
    }
    onChange(e:any){
        const {id,value} = e.target;
        let res = true;
        switch (id) {
            case appConstants.MARLAUX.DATE_REPARTITION:
                this.date_repatition = value;
            break
            case appConstants.MARLAUX.PASSAGE:
                this.passage = value;
            break
            case appConstants.MARLAUX.TAUX:
                this.taux = !this.taux;
            break
            case appConstants.MARLAUX.REGIME:
                this.regime = true;
                this.lmnp = false;
                this.resetMiseEnplaceLmnp();
            break
            case appConstants.MARLAUX.LMNP:
                this.lmnp = true;
                this.regime = false;
            break
            case appConstants.PINELS.FRAIS_ETABLISSEMENT_LMNP:
                this.frais_etablissement = value;
            break
            case appConstants.PINELS.FRAIS_COMPTABILITE_ANNUEL:
                this.frais_comptabilite = value;
            break
            case appConstants.PINELS.VALEUR_MOBILIER:
                this.valeur_mobilier = value;
            break
            case appConstants.PINELS.VALEUR_TERRAIN:
                this.valeur_terrain = value;
            break
            case appConstants.PINELS.TAXE_FONCIERE_EXONEREE:
                this.taxe_fonciere = value;
            break
            case appConstants.PINELS.PENDANT:
                this.pendant = value;
            break
            case appConstants.LMNP.TVA_OUI:
                this.tva_oui = true;
                this.tva_non = false;
            break
            case appConstants.LMNP.TVA_NON:
                this.tva_non = true;
                this.tva_oui = false;
            break
            default:
            res = false;
            break;
        }
        return res
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.MARLAUX.DATE_REPARTITION:
            return this.date_repatition; 
            case appConstants.MARLAUX.LMNP:
            return this.lmnp; 
            case appConstants.MARLAUX.PASSAGE:
            return this.passage; 
            case appConstants.MARLAUX.REGIME:
            return this.regime; 
            case appConstants.MARLAUX.TAUX:
            return this.taux;
            case appConstants.MARLAUX.FRAIS_ETABLISSEMENT_LMNP:
            return this.frais_etablissement; 
            case appConstants.MARLAUX.FRAIS_COMPTABILITE_ANNUEL:
            return this.frais_comptabilite; 
            case appConstants.MARLAUX.VALEUR_MOBILIER:
            return this.valeur_mobilier; 
            case appConstants.MARLAUX.VALEUR_TERRAIN:
            return this.valeur_terrain; 
            case appConstants.MARLAUX.TAXE_FONCIERE_EXONEREE:
            return this.taxe_fonciere;
            case appConstants.MARLAUX.PENDANT:
            return this.pendant; 
            case appConstants.MARLAUX.TVA_OUI:
            return this.tva_oui; 
            case appConstants.MARLAUX.TVA_NON:
            return this.tva_non;
        }
      return false;
    }
}
export {Regime}