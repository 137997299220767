class ChargeScpiSynthese {
    id: any = 0;
    labelChargeScpi: any = "";
    labelChargeGestionScpi: any = "";
    chargeGestionScpi: any = "";
    fraisEntree: any = "";
    fraisRetrait: any = "";    
    fraisFinancier = "";

    formJson(data : any) {
        if(!data){
            return;
        }else{
           this.id = data?.id;
           this.labelChargeScpi = data?.labelCharge;
           this.labelChargeGestionScpi = data?.labelChargeGestion;
           this.chargeGestionScpi = data?.chargeGestion;
           this.fraisEntree = data?.fraisEntree;
           this.fraisRetrait = data?.fraisRetrait;
           this.fraisFinancier = data?.fraisFinancier;           
        }
    }
        
}
export {ChargeScpiSynthese}