export const DOSSIER_KEY = {
    NOM_CLIENT : "NOM_CLIENT",
    ADDRESS1: "ADDRESS1",
    ADDRESS2: "ADDRESS2",
    CODE_POSTAL: "CODE_POSTAL",

    TELEPHONE2: "TELEPHONE2",
    PROFESSION2: "PROFESSION2",
    DATE_NAISSANCE2: "DATE_NAISSANCE2",

    TELEPHONE3: "TELEPHONE3",
    PROFESSION3: "PROFESSION3",
    DATE_NAISSANCE3: "DATE_NAISSANCE3",
    
    TELEPHONE: "TELEPHONE",
    PROFESSION: "PROFESSION",
    DATE_NAISSANCE: "DATE_NAISSANCE",
}