import React, { Component } from "react";
import Emprunt from "./Emprunt";
import { PropsFromRedux, connector } from "../../_reducers";
import Depense from "./Depense";
import { appConstants } from "../../_constants";

class Endettement extends Component<PropsFromRedux, any> {
  render() {
    const checkValue1 = this.getValue(
      appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_REDUCTION_LOI_TEPA
    );
    const checkValue2 = this.getValue(
      appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_REDUCTION_BBC
    );

    return (
      <div className="row bg-white mx-3 h-100 light-shadow content-row">
        <div className="col-md-2 o-h h-95 pt-3 justify-content-around text-center d-flex flex-column">
          <div className="d-flex flex-column justify-content-around">
            <div>
              <p className="big-text my-0">Résidence Principal</p>
              <div className="d-flex flex-column pl-2 justify-content-start mt-2 pt-0">
                <div className="custom-control text-left text-small custom-checkbox">
                  <input
                    type="checkbox"
                    checked={checkValue1 as boolean}
                    onChange={(e) => this.updateMontant(e)}
                    className="custom-control-input"
                    id={
                      appConstants.INPUT_KEYS
                        .RESIDENCE_PRINCIPALE_REDUCTION_LOI_TEPA
                    }
                  />
                  <label
                    className="custom-control-label big-text-blue font-weight-light"
                    htmlFor={
                      appConstants.INPUT_KEYS
                        .RESIDENCE_PRINCIPALE_REDUCTION_LOI_TEPA
                    }
                  >
                    Réduction Loi Tepa
                  </label>
                </div>
                <div className="custom-control text-left text-small custom-checkbox">
                  <input
                    type="checkbox"
                    checked={checkValue2 as boolean}
                    onChange={(e) => this.updateMontant(e)}
                    className="custom-control-input"
                    id={
                      appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_REDUCTION_BBC
                    }
                  />
                  <label
                    className="custom-control-label big-text-blue font-weight-light"
                    htmlFor={
                      appConstants.INPUT_KEYS.RESIDENCE_PRINCIPALE_REDUCTION_BBC
                    }
                  >
                    BBC
                  </label>
                </div>
              </div>
            </div>

            <div className="py-3">
              <p className="big-text m-0">Autre emprunt</p>
            </div>

            <div className="py-3">
              <p className="big-text m-0">Autre emprunt</p>
            </div>
          </div>

          <div>
            <p className="big-text my-0">Déficit non imputé à reporter</p>
          </div>
        </div>
        <div className="col-md-10  justify-content-around o-h h-95 pt-3">
          <Emprunt />
          <Depense />
        </div>
      </div>
    );
  }

  getValue(key: string): any {
    const { model } = this.props;
    return model.situationFiscale.endettement.getValue(key);
  }
  updateMontant(e: any) {
    const { model, replaceModel } = this.props;
    const res = model.situationFiscale.endettement.onChange(e);
    if (res) {
      replaceModel(model);
    }
  }
}

const connected = connector(Endettement);
export { connected as Endettement };
