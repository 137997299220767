import { Devise } from "./Devise";
import { Annee } from "./Annee";
import { BaseAmount } from "./BaseAmount";

class Subvention extends BaseAmount{
  nom: string;
  idkey: number | null;
  constructor(
    data?:any
  ) {
    super(data?.id || 0, data?.montant|| 0, new Devise(), data?.taux|| 0, new Annee());
    this.nom = "";
    this.idkey = data?.id || null;
  }
}

export { Subvention }