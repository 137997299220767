import { CaracteristiqueFinancement, ProduitAdossement,ProduitFinancier,TabProduitAdossement } from '../_entities';
import { appConstants } from '../_constants';
import { VersementSuppls } from './versementSuppls';

class MultiFinancement {
    caracteristique_financement: CaracteristiqueFinancement;
    produit_adossement: ProduitAdossement;
    trouve_mensualite:TabProduitAdossement;
    apport_initial:TabProduitAdossement;
    capital_terme:TabProduitAdossement;
    errMontantFinencent:boolean;
    errdureefinancement:boolean;
    errtauxInteret:boolean;
    errtauxCapitalisation:boolean;
    errassuranceDece:boolean;
    assurDece: VersementSuppls;
    versementSupp_futur: VersementSuppls;
    annee: boolean;
    mois: boolean;
    poucentage: boolean;
    euro: boolean;
    relaisTva: boolean;
    id:number | null;
    apport_initial_futur: VersementSuppls;
    produitFinancier: ProduitFinancier;
    listProduitFinancier: Array<ProduitFinancier>;
    listProduitFinancierInfine: Array<ProduitFinancier>;
    indexProduit: number|null;
    
    
    constructor(
        relaisTva: boolean = false, 
        assurDece: VersementSuppls = new VersementSuppls(),
        versementSupp: VersementSuppls = new VersementSuppls(),
        apport_initial_futur: VersementSuppls = new VersementSuppls(),
        listProduitFinancier: Array<ProduitFinancier> = new Array<ProduitFinancier>(),
        listProduitFinancierInfine: Array<ProduitFinancier> = new Array<ProduitFinancier>(),
    ){
        this.caracteristique_financement = new CaracteristiqueFinancement();
        this.produit_adossement = new ProduitAdossement();
        this.trouve_mensualite = new TabProduitAdossement();
        this.apport_initial = new TabProduitAdossement();
        this.capital_terme = new TabProduitAdossement();
        this.errMontantFinencent = true;
        this.errassuranceDece = true;
        this.errdureefinancement = true;
        this.errtauxCapitalisation = true;
        this.errtauxInteret = true;
        this.assurDece = assurDece; 
        this.versementSupp_futur= versementSupp;
        this.annee = true;
        this.mois = false;
        this.euro = true;
        this.poucentage = false;
        this.relaisTva = relaisTva;
        this.produitFinancier = new ProduitFinancier();
        this.apport_initial_futur = apport_initial_futur;
        this.listProduitFinancier = listProduitFinancier;
        this.listProduitFinancierInfine = listProduitFinancierInfine;
        this.indexProduit = null;
        this.id = null;
    }

    resetInput(){
        this.caracteristique_financement.mensualiteCreditTotal = 0;
        this.caracteristique_financement.taux_interet = 0;
        this.caracteristique_financement.duree = 0;
        //this.caracteristique_financement.montantInteretDiffere = 0;
        this.caracteristique_financement.rembourssement_capital = false;
        this.caracteristique_financement.rembourssement_interet = false;
        this.caracteristique_financement.mois_rembourssement_capital = 12;
        this.caracteristique_financement.mois_rembourssement_interet = 12;
        this.produit_adossement = new ProduitAdossement();
        this.trouve_mensualite = new TabProduitAdossement();
        this.apport_initial = new TabProduitAdossement();
        this.capital_terme = new TabProduitAdossement();
    }

    resetresultCalcul(){
        this.apport_initial.versement_initial = null;
        this.trouve_mensualite.versement_mensuel = null;
        this.capital_terme.capitale_aterme = null;
    }
    onChange(e:any){
        const { id, value } = e.target;
        let res = true;
        console.log(id, value);
        switch (id) {
            case appConstants.FINANCEMENT.PASFINANCEMENT:
                this.caracteristique_financement = new CaracteristiqueFinancement();
                this.produit_adossement = new ProduitAdossement();
                this.trouve_mensualite = new TabProduitAdossement();
                this.apport_initial = new TabProduitAdossement();
                this.capital_terme = new TabProduitAdossement();
            break;
            
            case appConstants.FINANCEMENT.MONTANTFINACEMENT:
                this.checkmontant(value);
            break;
            case appConstants.FINANCEMENT.DUREEFINANCEMENT:
                this.checkDuree(value);
            break;
            case appConstants.INPUT_KEYS.MONTANT_ASSURANCE:
                this.checkAssuranceDeces(value);
            break;
            case appConstants.FINANCEMENT.ANNEE:
                this.annee = true;
                this.mois = false;
            break;
            case appConstants.FINANCEMENT.MOIS:
                this.annee = false;
                this.mois = true;
            break;
            case appConstants.FINANCEMENT.RELAIS_TVA:
                this.relaisTva = value;
                break;
            case appConstants.FINANCEMENT.TAUXINTERET:
                this.checkTauxInteret(value);
            break;
            case appConstants.FINANCEMENT.EURO:
                this.euro = true;
                this.poucentage = false;
                break;
            case appConstants.FINANCEMENT.POURCENTAGE:
                this.euro = false;
                this.poucentage = true;
                break;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALOUI:
                this.caracteristique_financement.rembourssement_capital = true;
                
            break;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALNON:
                this.caracteristique_financement.rembourssement_capital = false;
                this.caracteristique_financement.rembourssement_interet = false;
            break;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETOUI:
                this.caracteristique_financement.rembourssement_interet = true;
                this.caracteristique_financement.rembourssement_capital = true;
            break;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETNON:
                this.caracteristique_financement.rembourssement_interet = false;
            break;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETMOIS:
                this.caracteristique_financement.mois_rembourssement_interet = value;
            break;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALMOIS:
                this.caracteristique_financement.mois_rembourssement_capital = value;
            break;


            case appConstants.FINANCEMENT.NOMPRODUIT:
                this.produit_adossement.nom_produit = value;
            break;
            case appConstants.FINANCEMENT.DUREEPRODUIT:
                this.produit_adossement.duree = value;
            break;
            case appConstants.FINANCEMENT.TAUXCAPITALISATION:
                this.checkTauxCapitalisation(value);
            break;
            case appConstants.FINANCEMENT.TAUXFRAISENTREE:
                this.produit_adossement.taux_frais_entree = value;
            break;
            case appConstants.FINANCEMENT.TAUXFRAISVERSEMENT:
                this.produit_adossement.taux_frais_versement = value;
            break;
            case appConstants.FINANCEMENT.RACHATECHELONNE:
                this.produit_adossement.rachat_echelone = !this.produit_adossement.rachat_echelone;
            break;
            case appConstants.FINANCEMENT.AXATION:
                this.produit_adossement.axation_interet = !this.produit_adossement.axation_interet;
            break;
            case appConstants.FINANCEMENT.COTISATION:
                this.produit_adossement.cotisation_tresor = !this.produit_adossement.cotisation_tresor;
            break;
            case appConstants.FINANCEMENT.CAPITALISATINTVA:
                this.produit_adossement.capitalisation_tva = !this.produit_adossement.capitalisation_tva;
            break;


            case appConstants.FINANCEMENT.VERSEMENT_MENSUEL_INITIAL:
                this.trouve_mensualite.versement_initial = value;
            break;
            case appConstants.INPUT_KEYS.MONTANT_VERSEMENT:
                this.trouve_mensualite.capitale_aterme = value;
            break;
            case appConstants.FINANCEMENT.VERSEMENT_MENSUEL_MENSUALITE:
                this.trouve_mensualite.versement_mensuel = value;
            break;

            case appConstants.FINANCEMENT.VERSEMENT_INITIAL_MENSUALITE:
                this.apport_initial.versement_mensuel = value;
            break;
            case appConstants.FINANCEMENT.VERSEMENT_INITIAL_TERME:
                this.apport_initial.capitale_aterme = value;
            break;
            case appConstants.FINANCEMENT.VERSEMENT_INITIAL_INITIAL:
                this.apport_initial.versement_initial = value;
            break;

            case appConstants.FINANCEMENT.CAPITAL_TERME_INITIAL:
                this.capital_terme.versement_initial = value;
            break;
            case appConstants.FINANCEMENT.CAPITAL_TERME_MENSUALITE:
                this.capital_terme.versement_mensuel = value;
            break;
            case appConstants.FINANCEMENT.CAPITAL_TERME_TERME:
                this.capital_terme.capitale_aterme = value;
            break;

            // case appConstants.FINANCEMENT.TAUXCAPITALISATION:
            //     this.produit_adossement.taux_capitalisation = value;
            // break;
            // case appConstants.FINANCEMENT.TAUXFRAISENTREE:
            //     this.produit_adossement.taux_frais_entree = value;
            // break;
            // case appConstants.FINANCEMENT.TAUXFRAISVERSEMENT:
            //     this.produit_adossement.taux_frais_versement = value;
            // break;

            default:
            res = false;
            break;
        }
        return res;
    }

    hydrateProduitFiArray(data: Array<any>) {
        if (data?.length > 0) {
            return data?.map((elt?) => new ProduitFinancier(elt))
        }
        return this.listProduitFinancier;
    }

    serializeProduitFinacAmmortissable() {
        return {
            credit: this.caracteristique_financement.montant_financement,
            txInteret: this.caracteristique_financement.taux_interet,
            assurance: this.caracteristique_financement.assurance_dece_invalid,
            differeCapital: this.caracteristique_financement.rembourssement_capital,
            differeInteret: this.caracteristique_financement.rembourssement_interet,
            differeCapitalMois: this.caracteristique_financement.mois_rembourssement_capital,
            differeInteretMois: this.caracteristique_financement.mois_rembourssement_interet,
            duree: this.caracteristique_financement.duree,
            id: null,
            amortissable: true,
            inFine: false,
        };
    }

    serializeProduitFinacInfine() {
        return {
            txInteret: this.caracteristique_financement.taux_interet,
            assurance: this.caracteristique_financement.assurance_dece_invalid,
            differeInteret: this.caracteristique_financement.rembourssement_interet,
            differeInteretMois: this.caracteristique_financement.mois_rembourssement_interet,
            duree: this.caracteristique_financement.duree,
            id: null,
            amortissable: false,
            inFine: true,
            dureeAdossement: this.produit_adossement.duree,
            nomAdossement: this.produit_adossement.nom_produit,
            txCapitalisation: this.produit_adossement.taux_capitalisation,
            txFraisEntree: this.produit_adossement.taux_frais_entree,
            txFraisVersement: this.produit_adossement.taux_frais_versement,
        };
    }
    editingFieldBySelectProduit(e: any) {
        const { value } = e.target;
        console.log("value : " + value);
        this.caracteristique_financement.assurance_dece_invalid = this.listProduitFinancier[value]?.assurance;
        this.caracteristique_financement.rembourssement_capital = this.listProduitFinancier[value]?.differeCapital;
        this.caracteristique_financement.mois_rembourssement_capital = this.listProduitFinancier[value]?.differeCapitalMois || 12;
        this.caracteristique_financement.rembourssement_interet = this.listProduitFinancier[value]?.differeInteret;
        this.caracteristique_financement.mois_rembourssement_interet = this.listProduitFinancier[value]?.differeInteretMois || 12;
        this.caracteristique_financement.duree = this.listProduitFinancier[value]?.duree;
        this.produit_adossement.duree = this.listProduitFinancier[value]?.dureeAdossement;
        this.produit_adossement.nom_produit = this.listProduitFinancier[value]?.nomAdossement;
        this.produit_adossement.taux_capitalisation = this.listProduitFinancier[value]?.txCapitalisation;
        this.produit_adossement.taux_frais_entree = this.listProduitFinancier[value]?.txFraisEntree;
        this.produit_adossement.taux_frais_versement = this.listProduitFinancier[value]?.txFraisVersement;
        this.caracteristique_financement.taux_interet = this.listProduitFinancier[value]?.txInteret;
        this.indexProduit = this.listProduitFinancier[value]?.id;
        console.log("index produit :: " +this.indexProduit)

        // this.errMontantFinencent = (this.listProduitFinancier[value] !== null)?false:true;
        this.errdureefinancement = (this.listProduitFinancier[value]?.duree === null) ? true:false;
        this.errtauxCapitalisation = (this.listProduitFinancier[value]?.txCapitalisation === null) ? true:false;
        this.errtauxInteret = (this.listProduitFinancier[value]?.txInteret === null) ? true:false;
        this.errassuranceDece = (this.listProduitFinancier[value]?.assurance === null) ? true:false;
    }

    hydrateProduitFinancier(data: any) {
        console.log("----- data -----");
        console.log(data);
        console.log("----- data -----");
        this.produit_adossement.duree = data?.dureeAdossement;
        this.produit_adossement.nom_produit = data?.nomAdossement;
        this.produit_adossement.taux_capitalisation = data?.txCapitalisation;
        this.produit_adossement.taux_frais_entree = data?.txFraisEntree;
        this.produit_adossement.taux_frais_versement = data?.txFraisVersement;
        this.produit_adossement.id = data?.id;

        this.versementSupp_futur.montantVersement.montant = data?.mensualiteCapitalTerme || null;
        this.trouve_mensualite.versement_initial = data?.mensualiteVersementInitial || null;
        this.trouve_mensualite.versement_mensuel = data?.mensualiteVersementMensuel || null;
        this.versementSupp_futur.hasAnneeFuturVersement = data?.mensualiteVersementSupplementaireFuture || false;
        this.versementSupp_futur.hydrateArrayModelVersementMensuel(data?.mensualiteVersementSupplementaireFutureList);

        this.capital_terme.capitale_aterme = data?.capitalTerme || null;
        this.capital_terme.versement_initial = data?.capitalVersementInitial || null;
        this.capital_terme.versement_mensuel = data?.capitalVersementMensuel || null;
        this.produit_adossement.cotisation_tresor = data?.capitauxExedent || false;
        this.produit_adossement.capitalisation_tva = data?.capitauxTva || false;
        this.produit_adossement.rachat_echelone = data?.rachat || false;
        this.produit_adossement.axation_interet = data?.taxationInteret || false;

        this.apport_initial_futur.montantApportInitVersement.montant = data?.apportCapitalTerme || null;
        this.apport_initial.versement_mensuel = data?.apportMensualiteVersement || null;
        this.apport_initial.versement_initial = data?.apportVersementInitial || null;
        this.apport_initial_futur.hasAnneeFuturApportInitVersement = data?.apportVersementSupplementaireFuture || false;
        this.apport_initial_futur.hydrateArrayModelmontantApport(data?.apportVersementSupplementaireFutureList);

        this.produit_adossement.nom_produit = data?.nomAdossement || null;
        this.produit_adossement.taux_capitalisation = data?.txCapitalisation || null;
        this.produit_adossement.taux_frais_entree = data?.txFraisEntree || null;
        this.produit_adossement.taux_frais_versement = data?.txFraisVersement || null;
        this.produit_adossement.duree = data?.dureeAdossement || null;
        this.produit_adossement.id = data?.id || null

        this.errtauxCapitalisation = (data === null || data?.txCapitalisation === null) ? true : false;
    }

    serializeProduitAdossement(){
        return {
            
                apportCapitalTerme: this.apport_initial_futur.montantApportInitVersement.montant,
                apportMensualiteVersement: this.apport_initial.versement_mensuel,
                apportVersementInitial: this.apport_initial.versement_initial,
                apportVersementSupplementaireFuture: (this.apport_initial_futur.ArraymontantApportInitVersement.length > 0) ? true : false,
                apportVersementSupplementaireFutureList: this.apport_initial_futur.montantApportArraySerialize(),

                capitalTerme: this.capital_terme.capitale_aterme,
                capitalVersementInitial: this.capital_terme.versement_initial,
                capitalVersementMensuel: this.capital_terme.versement_mensuel,
                capitauxExedent: this.produit_adossement.cotisation_tresor,
                capitauxTva: this.produit_adossement.capitalisation_tva,
                rachat: this.produit_adossement.rachat_echelone,
                taxationInteret: this.produit_adossement.axation_interet,

                mensualiteCapitalTerme: this.versementSupp_futur.montantVersement.montant,
                mensualiteVersementInitial: this.trouve_mensualite.versement_initial,
                mensualiteVersementMensuel: this.trouve_mensualite.versement_mensuel,
                mensualiteVersementSupplementaireFuture: (this.versementSupp_futur.ArraymontantVersement.length > 0) ? true : false,
                mensualiteVersementSupplementaireFutureList: this.versementSupp_futur.montantVersementMensuelSerialize(),

                nomAdossement: this.produit_adossement.nom_produit,
                txCapitalisation: this.produit_adossement.taux_capitalisation,
                txFraisEntree: this.produit_adossement.taux_frais_entree,
                txFraisVersement: this.produit_adossement.taux_frais_versement,
                dureeAdossement: this.produit_adossement.duree,
                id: this.produit_adossement.id,
           
        };
    }

    hydrateMultifinanCaracterisque(data:any){
        console.log("hydrate data");
        console.log(data);
        console.log("hydrate data");
        this.caracteristique_financement.taux_interet = data?.txInteret;
        this.caracteristique_financement.montant_financement = data?.credit;
        this.caracteristique_financement.mois_rembourssement_capital =  data?.differeCapitalMois || 12;
        this.caracteristique_financement.rembourssement_capital = data?.differeCapital || false;
        this.caracteristique_financement.rembourssement_interet = data?.differeInteret || false ;
        this.caracteristique_financement.mois_rembourssement_interet = data?.differeInteretMois || 12;
        this.caracteristique_financement.duree = data?.duree || 0;
        this.caracteristique_financement.mensualiteCreditTotal = data?.mensualiteCreditTotal;
        this.caracteristique_financement.montantInteretDiffere = data?.montantInteretDiffere;
        this.annee = data?.dureeAn || true;
        this.mois = data?.dureeMois || false;
        this.id = data?.id;

        this.errMontantFinencent = (data === null || data?.credit === null) ? true : false;
        this.errdureefinancement = (data === null || data?.duree === null) ? true : false;
        this.errtauxInteret = (data === null || data?.txInteret === null) ? true : false;
        this.errassuranceDece = (data === null || data?.assurance_dece_invalid === null) ? true : false;
    }


    serializeMultifinanCaracterisque(){
        
        return {
            txInteret: this.caracteristique_financement.taux_interet,
            credit: this.caracteristique_financement.montant_financement,
            differeCapital: this.caracteristique_financement.rembourssement_capital,
            differeCapitalMois: (this.caracteristique_financement.rembourssement_capital === false)?null:this.caracteristique_financement.mois_rembourssement_capital,
            differeInteret: this.caracteristique_financement.rembourssement_interet,
            differeInteretMois: (this.caracteristique_financement.rembourssement_interet === false)?null:this.caracteristique_financement.mois_rembourssement_interet,
            duree: this.caracteristique_financement.duree,
            dureeAn: this.annee,
            dureeMois: this.mois,
            id: this.id,
            mensualiteCreditTotal: this.caracteristique_financement.mensualiteCreditTotal,
            montantInteretDiffere: this.caracteristique_financement.montantInteretDiffere,
        }   
        
    }

    checkmontant(val: any){
        this.caracteristique_financement.montant_financement = val
        if(val === "" || val <= 0){
            this.errMontantFinencent = true;
        }else{
            this.errMontantFinencent = false;
        }
    }

   
    getMoisAnne(val: any){
        let value = Math.round(val)/12;
        return value.toFixed(2);
    }

    checkDuree(val: any){
        this.caracteristique_financement.duree = val
        if(val === "" || val <= 0){
            this.errdureefinancement = true;
        }else{
            this.errdureefinancement = false;
        }
    }

    checkAssuranceDeces(val: any){
        this.caracteristique_financement.assurance_dece_invalid = val
        if(val === "" || val <= 0){
            this.errassuranceDece = true;
        }else{
            this.errassuranceDece = false;
        }
    }

    checkTauxInteret(val: any){
        this.caracteristique_financement.taux_interet = val
        if(val === "" || val <= 0){
            this.errtauxInteret = true;
        }else{
            this.errtauxInteret = false;
        }
    }

    checkTauxCapitalisation(val: any){
        this.produit_adossement.taux_capitalisation = val
        if(val === "" || val <= 0){
            this.errtauxCapitalisation = true;
        }else{
            this.errtauxCapitalisation = false;
        }
    }

    getValue(key: string) {
        switch (key) {
            case appConstants.FINANCEMENT.MONTANTFINACEMENT:
                return this.caracteristique_financement.montant_financement !==0? this.caracteristique_financement.montant_financement : "";
            case appConstants.FINANCEMENT.DUREEFINANCEMENT:
                return this.caracteristique_financement.duree !==0? this.caracteristique_financement.duree : "";
            case appConstants.FINANCEMENT.TAUXINTERET:
                return this.caracteristique_financement.taux_interet !==0? this.caracteristique_financement.taux_interet : "";
            case appConstants.INPUT_KEYS.MONTANT_ASSURANCE:
                return this.caracteristique_financement.assurance_dece_invalid ;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALOUI:
                return this.caracteristique_financement.rembourssement_capital ;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALNON:
                return this.caracteristique_financement.rembourssement_capital ;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETOUI:
                return this.caracteristique_financement.rembourssement_interet ;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETNON:
                return this.caracteristique_financement.rembourssement_interet ;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTINTERETMOIS:
                return this.caracteristique_financement.mois_rembourssement_interet ;
            case appConstants.FINANCEMENT.DIFFEREREMBOURSEMENTCAPITALMOIS:
                return this.caracteristique_financement.mois_rembourssement_capital ;
            case appConstants.FINANCEMENT.MENSUALITETOTALCREDIT:
                return this.caracteristique_financement.mensualiteCreditTotal;
            case appConstants.FINANCEMENT.MONTANT_INTERET_DIFFERE:
                return this.caracteristique_financement.montantInteretDiffere;
            

            case appConstants.FINANCEMENT.NOMPRODUIT:
                return this.produit_adossement.nom_produit ;
            case appConstants.FINANCEMENT.DUREEPRODUIT:
                return this.produit_adossement.duree ;
            case appConstants.FINANCEMENT.TAUXCAPITALISATION:
                return this.produit_adossement.taux_capitalisation ;
            case appConstants.FINANCEMENT.TAUXFRAISENTREE:
                return this.produit_adossement.taux_frais_entree ;
            case appConstants.FINANCEMENT.TAUXFRAISVERSEMENT:
                return this.produit_adossement.taux_frais_versement ;
            case appConstants.FINANCEMENT.RACHATECHELONNE:
                return this.produit_adossement.rachat_echelone ;
            case appConstants.FINANCEMENT.AXATION:
                return this.produit_adossement.axation_interet ;
            case appConstants.FINANCEMENT.COTISATION:
                return this.produit_adossement.cotisation_tresor ;
            case appConstants.FINANCEMENT.CAPITALISATINTVA:
                return this.produit_adossement.capitalisation_tva ;
            
            case appConstants.FINANCEMENT.VERSEMENT_MENSUEL_INITIAL:
                return this.trouve_mensualite.versement_initial;
            case appConstants.INPUT_KEYS.MONTANT_VERSEMENT:
                return this.trouve_mensualite.capitale_aterme;
            case appConstants.FINANCEMENT.VERSEMENT_MENSUEL_MENSUALITE:
                return this.trouve_mensualite.versement_mensuel;

            case appConstants.FINANCEMENT.VERSEMENT_INITIAL_MENSUALITE:
                return this.apport_initial.versement_mensuel;
            case appConstants.FINANCEMENT.VERSEMENT_INITIAL_INITIAL:
                return this.apport_initial.versement_initial;
            case appConstants.FINANCEMENT.VERSEMENT_INITIAL_TERME:
                return this.apport_initial.capitale_aterme ;

            case appConstants.FINANCEMENT.CAPITAL_TERME_INITIAL:
                return this.capital_terme.versement_initial;
            case appConstants.FINANCEMENT.CAPITAL_TERME_MENSUALITE:
                return this.capital_terme.versement_mensuel ;
            case appConstants.FINANCEMENT.CAPITAL_TERME_TERME:
                    return this.capital_terme.capitale_aterme;

            case appConstants.FINANCEMENT.ANNEE:
                return this.annee;
            case appConstants.FINANCEMENT.MOIS:
                return this.mois;
            case appConstants.FINANCEMENT.RELAIS_TVA:
                return this.relaisTva;
            case appConstants.FINANCEMENT.EURO:
                return this.euro;
            case appConstants.FINANCEMENT.POURCENTAGE:
                return this.poucentage;
            
            default:
                break;
        }
      return false;
    }
}
export {MultiFinancement}