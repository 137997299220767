class Utilisateur{
    id: number| null;
    nom : String ;
    prenom : String ;
    entreprise : String;
    adresse : String;
    codePostale: String;
    email: String;
    password: string;
    telephone: number|null;
    token: string| null;

    constructor(data?: any){
        this.id = data?.id || null;
        this.nom = data?.nom || "";
        this.email = data?.email || "";
        this.prenom = data?.prenom || "";
        this.entreprise = data?.entreprise || "";
        this.adresse = data?.adresse || "";
        this.codePostale = data?.codePostalVille || "";
        this.password = data?.password || "";
        this.telephone = data?.telephone || null;
        this.token = data?.token || null;
        
    }

    serialize(){
        return {
            login: this.email,
            password: this.password,
        };
    }

    currentUser(){
        return {
            login: this.email,
            token: this.token
        };
    }
}

export {Utilisateur};