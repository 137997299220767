import { Devise } from "./Devise";
import { Annee } from "./Annee";
import { BaseAmount } from "./BaseAmount";

class RevenueLocatif extends BaseAmount{
  nom: string;
  constructor(
    nom: string = "",
    devise: Devise = new Devise(),
    annee: Annee = new Annee(),
    data?: any
  ) {
    super(data?.id || 0, data?.montant || null, devise, data?.taux, annee);
    this.nom = nom;
  }
}

export { RevenueLocatif }