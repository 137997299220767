import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connector, PropsFromRedux } from "../../_reducers";

const iconRevenu = require("../../_assets/images/sidebarIcons/revenus.png");
const iconRevenuActif = require("../../_assets/images/sidebarIcons/revenus_active.png");
const iconResultat = require("../../_assets/images/sidebarIcons/resultats_foncier.png");
const iconResultatActif = require("../../_assets/images/sidebarIcons/resultats_foncier_active.png");
// const iconAutres = require("../../_assets/images/sidebarIcons/autres.png");
// const iconAutresActif = require("../../_assets/images/sidebarIcons/autres_active.png");
// const iconEndettements = require("../../_assets/images/sidebarIcons/endettements.png");
// const iconEndettementsActif = require("../../_assets/images/sidebarIcons/endettements_active.png");
const iconDetailIfi = require("../../_assets/images/sidebarIcons/details_ifi.png");
const iconDetailIfiActif = require("../../_assets/images/sidebarIcons/details_ifi_active.png");
const iconClient = require("../../_assets/images/sidebarIcons/client.png");
const iconClientActif = require("../../_assets/images/sidebarIcons/client_active.png");

const items = [
  {
    label: "Revenus",
    key: "revenus",
    img: iconRevenu,
    activeImg: iconRevenuActif,
    alt: "iconRevenus",
  },
  {
    label: "Autres",
    key: "resultats",
    img: iconResultat,
    activeImg: iconResultatActif,
    alt: "iconResultatFoncier",
  },
  // {
  //   label: "Autres",
  //   key: "autres",
  //   img: iconAutres,
  //   activeImg: iconAutresActif,
  //   alt: "iconAutres",
  // },
  /*{
    label: "Endettements",
    key: "endettements",
    img: iconEndettements,
    activeImg: iconEndettementsActif,
    alt: "iconEndettements",
  },*/
  {
    label: "Détails",
    key: "details",
    img: iconDetailIfi,
    activeImg: iconDetailIfiActif,
    alt: "iconDetail",
  },
  {
    label: "Client",
    key: "client",
    img: iconClient,
    activeImg: iconClientActif,
    alt: "iconClient",
  },
];

class Sidebar extends Component<PropsFromRedux, any> {
  constructor(props: any) {
    super(props);
    this.handleHover = this.handleHover.bind(this);
    this.handleOut = this.handleOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      showModal: false,
      showOk: true,
      showOuiNon: false,
      message: "",
      lastIndex: -1,
      title: "Easy Defisc",
    }
  }
  render() {
    const { model } = this.props;
    const { activeSection } = model.situationFiscale;

    return (
      <div id="sidebar-wrapper" className="pl-0">
        <ul
          className="navbar-nav d-flex  justify-content-between light-shadow bg-white"
          style={{ minHeight: "100%" }}
        >
          {Array.isArray(items) &&
            items.length &&
            items.map((item, i) => {
              if (item.key === activeSection) {
                return (
                  <li
                    key={i}
                    className={`sidebar-item py-3 active text-center d-flex flex-column justify-content-center align-items-center`}
                  >
                    <img
                      id={`img_${item.key}`}
                      src={item.activeImg}
                      alt={item.alt}
                    />
                    <span>{item.label}</span>
                  </li>
                );
              }
              return (
                <li
                  key={i}
                  onClick={(e) => this.handleClick(e, item)}
                  className="sidebar-item py-3 d-flex text-center flex-column justify-content-center align-items-center"
                  onMouseOver={(e) => this.handleHover(e, item)}
                  onMouseOut={(e) => this.handleOut(e, item)}
                >
                  <img id={`img_${item.key}`} src={item.img} alt={item.alt} />
                  <span>{item.label}</span>
                </li>
              );
            })}
        </ul>
        {this.getModal()}
      </div>
    );
  }

  getModal(): React.ReactNode {
    const { showModal, message, title } = this.state;
    return (
      <Modal
        show={showModal}
        onHide={() => this.closeModal()}
        backdrop="true"
      >
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">{message}</Modal.Body>
        <Modal.Footer>{showModal && this.getFooter()}</Modal.Footer>
      </Modal>
    );
  }

  getFooter() {
    const { showOuiNon, showOk } = this.state;
    return (
      <div
        className={
          "w-100 d-flex " +
          (!showOk ? "justify-content-between" : "justify-content-end")
        }
      >
        {showOuiNon && (
          <button
            className="btn font-weight-light text-white bg-orange text-uppercase"
            style={{ fontSize: "80%" }}
            onClick={() => this.closeModal()}
          >
            Non
          </button>
        )}
        {showOuiNon && <button
          className="btn font-weight-light text-white bg-orange text-uppercase"
          style={{ fontSize: "80%" }}
          onClick={() => this.closeModal(true)}
        >
          Oui
        </button>}
        {showOk && <button
          className="btn font-weight-light text-white bg-orange text-uppercase"
          style={{ fontSize: "80%" }}
          onClick={() => this.closeModal()}
        >
          Ok
        </button>}
      </div>
    );
  }

  async closeModal(oui?: boolean) {
    if (oui) {
      const { model, changeSection } = this.props;
      changeSection(model, items[this.state.lastIndex].key);
    }
    this.setState({
      showModal: false,
      showOk: true,
      showOuiNon: false,
      message: "",
      lastIndex: -1,
      title: "Easy Defisc",
    })

  }

  handleClick(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    item: { label: string; key: string; img: any; activeImg: any; alt: string }
  ): void {
    const { changeSection, model, replaceModel } = this.props;
    const { activeSection } = model.situationFiscale;
    const index1 = items.findIndex((val) => val.key === activeSection);
    const index2 = items.findIndex((val) => val.key === item.key);
    const { revenu, quotientFamilial } = model.situationFiscale;
    const revenuNet1 = revenu.declarant1.revenuNetImposable.montant;

    if (model.eventUpdateDossier && item.key === "revenus") {
      model.calculTax();
      model.eventUpdateDossier = false;
    }

    if (index2 > index1) {
      const part = model.situationFiscale.quotientFamilial.nombrePartFiscale.valeur;
      if (revenuNet1 === 0 || revenuNet1 === null) {
        revenu.declarant1.validate = true;
        model.loading = false;
        replaceModel(model);
        return this.setState({
          showModal: true,
          showOk: true,
          showOuiNon: false,
          route: "",
          message: "Attention vous n'avez pas saisie, un revenu net imposable"
        });
      }
      if (part <= 0 || part === null) {
        model.situationFiscale.quotientFamilial.validate = true;
        replaceModel(model);
        return this.setState({
          showModal: true,
          message: "Attention vous n'avez pas saisie le nombre de parts fiscales !"
        });
      }
      if (revenu.newCalcule || quotientFamilial.newCalcule) {
        return this.setState({
          showModal: true,
          showOk: true,
          showOuiNon: false,
          lastIndex: index2,
          message: "Vos données ont été modifiées. Les impôts doivent être recalculés !"
        });
      }
    }

    this.calculateOrUpdateFolder(model, item.key);

    changeSection(model, item.key);
  }

  handleHover(e: React.MouseEvent<HTMLLIElement, MouseEvent>, item: any): void {
    const img = document.getElementById(`img_${item.key}`);
    (img as any).src = item.activeImg;
  }

  handleOut(e: React.MouseEvent<HTMLLIElement, MouseEvent>, item: any): void {
    const img = document.getElementById(`img_${item.key}`);
    (img as any).src = item.img;
  }

  async calculateOrUpdateFolder(
    e: any,
    key: string
  ) {
    const { model, replaceModel } = this.props;
    replaceModel(model);
    if (model.isUpdateModel && model.eventUpdateDossier && model.situationFiscale.activeSection !== "revenus") {
      let res = await model.updateDossier();
      if (res) {
        model.eventUpdateDossier = false;
        model.loading = false;
        replaceModel(model);
      }
    } else if (model.eventUpdateDossier && model.situationFiscale.activeSection !== "revenus") {
      let res = model.calculTax();

      if (await res === true) {
        console.log(res);
        model.eventUpdateDossier = false;
        model.loading = false;
        replaceModel(model);
      } else {
        model.loading = false;
        replaceModel(model);
        return this.setState({
          showModal: true,
          showOk: true,
          showOuiNon: false,
          route: "",
          message: "Une erreur s'est produite lors du calcul de impôts"
        });
      }
    } else {
      model.eventUpdateDossier = false;
      model.loading = false;
      replaceModel(model);
    }
    //section = model.situationFiscale.getNextSection();
  }
}

const connected = connector(Sidebar);
export { connected as Sidebar };

