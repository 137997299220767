export const GIRARDIN = {
    LOCATIF_INTERMEDIAIRE: "LOCATIF_INTERMEDIAIRE",
    LOCATIF_LIBRE: "LOCATIF_LIBRE",
    HABITATION_PRINCIPALE: "HABITATION_PRINCIPALE",
    ANNEE_PRECEDENT_LIVRAISON_OUI: "ANNEE_PRECEDENT_LIVRAISON_OUI",
    ANNEE_PRECEDENT_LIVRAISON_NON: "ANNEE_PRECEDENT_LIVRAISON_NON",
    TAUX_REDUCTION_ANNEE: "TAUX_REDUCTION_ANNEE",
    TAUX_REDUCTION: "TAUX_REDUCTION",
    FRAIS_ETABLISSEMENT_LMNP:"FRAIS_ETABLISSEMENT_LMNP",
    FRAIS_COMPTABILITE_ANNUEL:"FRAIS_COMPTABILITE_ANNUEL",
    VALEUR_TERRAIN: "VALEUR_TERRAIN",
    VALEUR_MOBILIER: "VALEUR_MOBILIER",
    TAXE_FONCIERE_EXONEREE: "TAXE_FONCIERE_EXONEREE",
    PENDANT: "PENDANT",
    PASSAGE: "PASSAGE",
    REGIME:"REGIME",
    LMNP:"LMNp",
}