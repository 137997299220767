class MontageFinancier {
  id: number = 0;
  adi: string = "";
  capitalEmprunte: string = "";
  dureeCredit: string = "";
  labelMontageFinancier: string = "";
  labelTypeCredit: string = "";
  txEmprunt: string = "";
  typeCredit: string = "";

  fromJson(data: any) {
    if (!data) {
      return;
    }
    this.id = data.id;
    this.adi = data.adi;
    this.capitalEmprunte = data.capitalEmprunte;
    this.dureeCredit = data.dureeCredit;
    this.dureeCredit = data.dureeCredit;
    this.labelTypeCredit = data.labelTypeCredit;
    this.txEmprunt = null != data.txEmprunt ? Math.round(data.txEmprunt * 100) / 100 : data.txEmprunt;
    this.typeCredit = data.typeCredit;
  }
}

export { MontageFinancier };

