import { Devise } from "./Devise";
import { Annee } from "./Annee";
import { BaseAmount } from "./BaseAmount";

class LoyerDonne extends BaseAmount{

  idkey: number | null;
  nom: string;
  constructor(
    data?:any
  ) {
    super(data?.id || 0, data?.montant || 0, new Devise(), data?.taux || 0,new Annee());
    this.nom = "";
    this.idkey = data?.id || null;
  }
}

export { LoyerDonne }