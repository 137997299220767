export const INPUT_KEYS = {
  RESULTAT_FONCIER_EXISTANT: 'RESULTAT_FONCIER_EXISTANT',
  HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT: 'HAS_ANNEE_FUTUR_RESULTAT_FONCIER_EXISTANT',
  PERISOL_EN_COUR: 'PERISOL_EN_COUR',
  HAS_ANNEE_FUTUR_PERISOL_EN_COUR: 'HAS_ANNEE_FUTUR_PERISOL_EN_COUR',
  DEFICIT_NON_IMPUTE: 'DEFICIT_NON_IMPUTE',
  HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE: 'HAS_ANNEE_FUTUR_DEFICIT_NON_IMPUTE',

  REVENU_MOBILIER_CAPITAUX: 'REVENU_MOBILIER_CAPITAUX',
  HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX: 'HAS_ANNEE_FUTUR_REVENU_MOBILIER_CAPITAUX',
  PENSION: 'PENSION',
  HAS_ANNEE_FUTUR_PENSION: 'HAS_ANNEE_FUTUR_PENSION',
  DEDUCTION_FISCALE: 'DEDUCTION_FISCALE',
  HAS_ANNEE_FUTUR_DEDUCTION_FISCALE: 'HAS_ANNEE_FUTUR_DEDUCTION_FISCALE',
  REDUCTION_FISCALE: 'REDUCTION_FISCALE',
  HAS_ANNEE_FUTUR_REDUCTION_FISCALE: 'HAS_ANNEE_FUTUR_REDUCTION_FISCALE',

  REALISES_METROPOLE: 'REALISES_METROPOLE',
  REALISES_OUTRE_MER: 'REALISES_OUTRE_MER',
  HAS_ANNEE_FUTUR_REALISES_METROPOLE: 'HAS_REALISES_METROPOLE',
  HAS_ANNEE_FUTUR_REALISES_OUTRE_MER: 'HAS_REALISES_OUTRE_MER',

  VALORISATION_NETTE_PATRIMOINE: 'VALORISATION_NETTE_PATRIMOINE',
  NOMBRE_ENFANT_CHARGE: 'NOMBRE_ENFANT_CHARGE',
  NOMBRE_ENFANT_TOTAL: 'NOMBRE_ENFANT_TOTAL',
  HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE: 'HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE',
  HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE: 'HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE',
  HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL: 'HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL',

  LOYERS_DONNES: 'LOYERS_DONNES',
  AUTRES_CHANGE: 'AUTRES_CHANGE',
  LOYERS_PERCU: 'LOYERS_PERCU',
  HAS_ANNEE_FUTUR_LOYERS_DONNES: 'HAS_ANNEE_FUTUR_LOYERS_DONNES',
  HAS_ANNEE_FUTUR_AUTRES_CHANGE: 'HAS_ANNEE_FUTUR_AUTRES_CHANGE',
  HAS_ANNEE_FUTUR_LOYERS_PERCU: 'HAS_ANNEE_FUTUR_LOYERS_PERCU',

  RESIDENCE_PRINCIPALE_REDUCTION_LOI_TEPA: 'RESIDENCE_PRINCIPALE_REDUCTION_LOI_TEPA',
  RESIDENCE_PRINCIPALE_REDUCTION_BBC: 'RESIDENCE_PRINCIPALE_REDUCTION_BBC',
  RESIDENCE_PRINCIPALE_DATE_DEBUT: 'RESIDENCE_PRINCIPALE_DATE_DEBUT',
  RESIDENCE_PRINCIPALE_MONTANT_EMPRUNTE: 'RESIDENCE_PRINCIPALE_MONTANT_EMPRUNTE',
  RESIDENCE_PRINCIPALE_DUREE: 'RESIDENCE_PRINCIPALE_DUREE',
  RESIDENCE_PRINCIPALE_TAUX: 'RESIDENCE_PRINCIPALE_TAUX',
  AUTRE_EMPRUNT_1_DATE_DEBUT: 'AUTRE_EMPRUNT_1_DATE_DEBUT',
  AUTRE_EMPRUNT_1_MONTANT_EMPRUNTE: 'AUTRE_EMPRUNT_1_MONTANT_EMPRUNTE',
  AUTRE_EMPRUNT_1_DUREE: 'AUTRE_EMPRUNT_1_DUREE',
  AUTRE_EMPRUNT_1_TAUX: 'AUTRE_EMPRUNT_1_TAUX',
  AUTRE_EMPRUNT_2_DATE_DEBUT: 'AUTRE_EMPRUNT_2_DATE_DEBUT',
  AUTRE_EMPRUNT_2_MONTANT_EMPRUNTE: 'AUTRE_EMPRUNT_2_MONTANT_EMPRUNTE',
  AUTRE_EMPRUNT_2_DUREE: 'AUTRE_EMPRUNT_2_DUREE',
  AUTRE_EMPRUNT_2_TAUX: 'AUTRE_EMPRUNT_2_TAUX',

  MONTANT_MONUMENT_SUBVENTION: 'MONTANT_MONUMENT_SUBVENTION',
  MONTANT_MALRAUX_SUBVENTION: 'MONTANT_MALRAUX_SUBVENTION',
  HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION: 'HAS_ANNEE_MONUMENT_FUTUR_SUBVENTION',
  HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION: 'HAS_ANNEE_MALRAUX_FUTUR_SUBVENTION',

  MONTANT_REVENUE_LOCATIF: 'MONTANT_REVENUE_LOCATIF',
  HAS_ANNEE_FUTUR_REVENUE_LOCATIF: 'HAS_ANNEE_FUTUR_REVENUE_LOCATIF',

  MONTANT_VERSEMENT: 'MONTANT_VERSEMENT',
  HAS_ANNEE_FUTUR_VERSEMENT: 'HAS_ANNEE_FUTUR_VERSEMENT',

  MONTANT_ASSURANCE: 'MONTANT_ASSURANCE',
  HAS_ANNEE_FUTUR_ASSURRANCE: 'HAS_ANNEE_FUTUR_ASSURRANCE',

  MONTANT_APPORT_INITIAL: 'MONTANT_APPORT_INITIAL',
  HAS_ANNEE_FUTUR_APPORT_INITIAL: 'HAS_ANNEE_FUTUR_APPORT_INITIAL',

}