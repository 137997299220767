import { ValorisationNettePatrimoine, NombreEnfant } from "../_entities";
import { appConstants } from "../_constants";
import { utils } from "../_helpers";
import { cloneDeep } from "lodash";

class ImpotFortuneImmobilier {
  valorisationNettePatrimoine: ValorisationNettePatrimoine;
  nombreEnfant: NombreEnfant;
  hasAnneeFuturValorisationNettePatrimoine: boolean;
  valorisationNettePatrimoines: Array<ValorisationNettePatrimoine>;
  ancienValorisationNettePatrimoines?: Array<ValorisationNettePatrimoine>;

  constructor(
    hasAnneeFuturValorisationNettePatrimoine: boolean = false,
    valorisationNettePatrimoine: ValorisationNettePatrimoine = new ValorisationNettePatrimoine(),
    nombreEnfant: NombreEnfant = new NombreEnfant(),
    valorisationNettePatrimoines: Array<ValorisationNettePatrimoine> = new Array<ValorisationNettePatrimoine>()
  ) {
   this.hasAnneeFuturValorisationNettePatrimoine = hasAnneeFuturValorisationNettePatrimoine;
   this.valorisationNettePatrimoine = valorisationNettePatrimoine;
   this.nombreEnfant = nombreEnfant;
   this.valorisationNettePatrimoines = valorisationNettePatrimoines;
  }

  serialize(data:any){
    return{
      id: data?.idkey || null,
      montant: data?.montant,
      taux: data?.revalorisationAnnuelle,
    }
  }

  valorisationNettePatrimoinesArraySerialize(){
    if( this.valorisationNettePatrimoines.length > 0){
      return this.valorisationNettePatrimoines.map((elt)=>this.serialize(elt))
    }
    return []
  }

  hydrateArrayModelValorisationNettePatrimoines(data:Array<any>){
    if (data?.length > 0) {
      return data?.map((elt?) => new ValorisationNettePatrimoine(elt))
    }
    return this.valorisationNettePatrimoines;
  }

  getValue(key: string): any {
    switch (key) {
      case appConstants.INPUT_KEYS.VALORISATION_NETTE_PATRIMOINE:
        return this.valorisationNettePatrimoine.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE:
        return this.hasAnneeFuturValorisationNettePatrimoine;
      case appConstants.INPUT_KEYS.NOMBRE_ENFANT_CHARGE:
        return this.nombreEnfant.nombreEnfantCharge.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE:
        return this.nombreEnfant.hasAnneeFuturNombreEnfantCharge;
      case appConstants.INPUT_KEYS.NOMBRE_ENFANT_TOTAL:
        return this.nombreEnfant.nombreEnfantTotal.montant;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL:
        return this.nombreEnfant.hasAnneeFuturNombreEnfantTotal;
    
      default:
        break;
    }
  }

  setValorisationNettePatrimoineMontant(montant: number) {
    while (this.valorisationNettePatrimoines.length) {
      this.valorisationNettePatrimoines.pop();
    }
    if (this.ancienValorisationNettePatrimoines?.length) {
      while (this.ancienValorisationNettePatrimoines.length) {
        this.ancienValorisationNettePatrimoines.pop();
      }
    }
    this.hasAnneeFuturValorisationNettePatrimoine = false;
    this.valorisationNettePatrimoine.montant = montant;
  }

  setNombreEnfantChargeMontant(montant: number) {
    while (this.nombreEnfant.nombreEnfantCharges.length) {
      this.nombreEnfant.nombreEnfantCharges.pop();
    }
    if (this.nombreEnfant.ancienNombreEnfantCharges?.length) {
      while (this.nombreEnfant.ancienNombreEnfantCharges.length) {
        this.nombreEnfant.ancienNombreEnfantCharges.pop();
      }
    }
    this.nombreEnfant.hasAnneeFuturNombreEnfantCharge = false;
    this.nombreEnfant.nombreEnfantCharge.montant = montant;
  }

  setNombreEnfantTotalMontant(montant: number) {
    while (this.nombreEnfant.nombreEnfantTotals.length) {
      this.nombreEnfant.nombreEnfantTotals.pop();
    }
    if (this.nombreEnfant.ancienNombreEnfantTotals?.length) {
      while (this.nombreEnfant.ancienNombreEnfantTotals.length) {
        this.nombreEnfant.ancienNombreEnfantTotals.pop();
      }
    }
    this.nombreEnfant.hasAnneeFuturNombreEnfantTotal = false;
    this.nombreEnfant.nombreEnfantTotal.montant = montant;
  }

  onBlur(e: any) {
    return false;
  }

  onChange(e: any): boolean {
    const { id, checked } = e.target;
    let res = true;
    const val = utils().getNumberInputValue(e);
    switch (id) {
      case appConstants.INPUT_KEYS.VALORISATION_NETTE_PATRIMOINE:
        this.setValorisationNettePatrimoineMontant(val);
        break;
      case appConstants.INPUT_KEYS.NOMBRE_ENFANT_CHARGE:
        this.setNombreEnfantChargeMontant(val);
        break;
      case appConstants.INPUT_KEYS.NOMBRE_ENFANT_TOTAL:
        this.setNombreEnfantTotalMontant(val);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE:
        this.hasAnneeFuturValorisationNettePatrimoine = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE:
        this.nombreEnfant.hasAnneeFuturNombreEnfantCharge = checked;
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL:
        this.nombreEnfant.hasAnneeFuturNombreEnfantTotal = checked;
        break;
      default:
        const res2 = this.checkAnneeFuturChange(e);
        if (!res2) {
          throw new Error("Invalid label key");
        }
        break;
    }
    return res;
  }

  checkAnneeFuturChange(e: any): boolean {
    const key = e.target.dataset.key;
    let res = true;
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE:
        this.updateValorisationNettePatrimoineItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE:
        this.updateNombreEnfantChargeItem(e);
        break;
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL:
        this.updateNombreEnfantTotalItem(e);
        break;
      default:
        res = false;
        break;
    }
    return res;
  }

  updateValorisationNettePatrimoineItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.valorisationNettePatrimoines.length;
    const index = this.valorisationNettePatrimoines.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j =  0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.valorisationNettePatrimoines[j].montant || 0: this.valorisationNettePatrimoine.montant|| 0;
        if (value === 0) {
          this.valorisationNettePatrimoines[i].montant = montant;
        } else {
          this.valorisationNettePatrimoines[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.valorisationNettePatrimoines[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.valorisationNettePatrimoines[i].montant = Math.round(value);
        this.valorisationNettePatrimoines[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateNombreEnfantChargeItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.nombreEnfant.nombreEnfantCharges.length;
    const index = this.nombreEnfant.nombreEnfantCharges.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j =  0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.nombreEnfant.nombreEnfantCharges[j].montant || 0: this.nombreEnfant.nombreEnfantCharge.montant|| 0;
        if (value === 0) {
          this.nombreEnfant.nombreEnfantCharges[i].montant = montant;
        } else {
          this.nombreEnfant.nombreEnfantCharges[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.nombreEnfant.nombreEnfantCharges[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.nombreEnfant.nombreEnfantCharges[i].montant = Math.round(value);
        this.nombreEnfant.nombreEnfantCharges[i].revalorisationAnnuelle = 0;
      }
    }
  }

  updateNombreEnfantTotalItem(e: any) {
    const options = JSON.parse(e?.target?.dataset?.options);
    const { id, type } = options;
    const count = this.nombreEnfant.nombreEnfantTotals.length;
    const index = this.nombreEnfant.nombreEnfantTotals.findIndex(
      (val) => val.id === id || val.id.toString() === id
    );
    let j =  0;
    let montant = 0;
    const value = utils().getNumberInputValue(e);
    if (type === "revalorisation") {
      for (let i = index; i < count; i++) {
        j = i - 1;
        montant = j > -1 ? this.nombreEnfant.nombreEnfantTotals[j].montant || 0: this.nombreEnfant.nombreEnfantTotal.montant|| 0;
        if (value === 0) {
          this.nombreEnfant.nombreEnfantTotals[i].montant = montant;
        } else {
          this.nombreEnfant.nombreEnfantTotals[i].montant = Math.round(montant) + Math.round(montant * (Math.round(value) / 100));
        }
        this.nombreEnfant.nombreEnfantTotals[i].revalorisationAnnuelle = value;
      }
    } else if (type === "year") {
      for (let i = index; i < count; i++) {
        this.nombreEnfant.nombreEnfantTotals[i].montant = Math.round(value);
        this.nombreEnfant.nombreEnfantTotals[i].revalorisationAnnuelle = 0;
      }
    }
  }

  getLabelConfig(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE:
        return {
          leading: this.valorisationNettePatrimoine.annee.value,
          trailling: this.valorisationNettePatrimoine.devise.symbole,
          label: true,
          value: this.valorisationNettePatrimoine.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE:
        return {
          leading: this.nombreEnfant.nombreEnfantCharge.annee.value,
          trailling: this.nombreEnfant.nombreEnfantCharge.devise.symbole,
          label: true,
          value: this.nombreEnfant.nombreEnfantCharge.montant,
        };
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL:
        return {
          leading: this.nombreEnfant.nombreEnfantTotal.annee.value,
          trailling: this.nombreEnfant.nombreEnfantTotal.devise.symbole,
          label: true,
          value: this.nombreEnfant.nombreEnfantTotal.montant,
        };
          
      default:
        throw new Error("Invalid label key");
    }
  }

  getItems(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE:
        return this.getValorisationNettePatrimoines();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE:
        return this.getNombreEnfantCharges();
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL:
        return this.getNombreEnfantTotals();
     
      default:
        throw new Error("Invalid label key");
    }
  }

  getValorisationNettePatrimoines() {
    if (this.ancienValorisationNettePatrimoines?.length && !this.valorisationNettePatrimoines.length) {
      this.valorisationNettePatrimoines = cloneDeep(this.ancienValorisationNettePatrimoines);
    } else {
      if (this.valorisationNettePatrimoines.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.valorisationNettePatrimoine);
          item.annee.value += i;
          item.id = i;
          this.valorisationNettePatrimoines.push(item);
        }
      }
    }
    return this.valorisationNettePatrimoines;
  }

  getNombreEnfantCharges() {
    if (this.nombreEnfant.ancienNombreEnfantCharges?.length && !this.nombreEnfant.nombreEnfantCharges.length) {
      this.nombreEnfant.ancienNombreEnfantCharges = cloneDeep(this.nombreEnfant.ancienNombreEnfantCharges);
    } else {
      if (this.nombreEnfant.nombreEnfantCharges.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.nombreEnfant.nombreEnfantCharge);
          item.annee.value += i;
          item.id = i;
          this.nombreEnfant.nombreEnfantCharges.push(item);
        }
      }
    }
    return this.nombreEnfant.nombreEnfantCharges;
  }

  getNombreEnfantTotals() {
    if (this.nombreEnfant.ancienNombreEnfantTotals?.length && !this.nombreEnfant.nombreEnfantTotals.length) {
      this.nombreEnfant.ancienNombreEnfantTotals = cloneDeep(this.nombreEnfant.ancienNombreEnfantTotals);
    } else {
      if (this.nombreEnfant.nombreEnfantTotals.length === 0) {
        let i = 0;
        while (i < 20) {
          i++;
          const item = cloneDeep(this.nombreEnfant.nombreEnfantTotal);
          item.annee.value += i;
          item.id = i;
          this.nombreEnfant.nombreEnfantTotals.push(item);
        }
      }
    }
    return this.nombreEnfant.nombreEnfantTotals;
  }


  getInputConfig(key: string, item: any, options: any) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL:
        if (options?.type === "year") {
          return utils().getAnneeFuturInputConfig(
            item.annee.value,
            item.montant,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL,
            "item_" + item.id,
            "item_" + item.id,
            this,
            options
          );
        } else {
          return utils().getAnneeFuturInput2Config(
            item.revalorisationAnnuelle,
            this,
            appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL,
            "item_percent_" + item.id,
            "item_percent_" + item.id,
            null,
            options
          );
        }
      default:
        throw new Error("Invalid label key");
    }
  }

  getModalTitle(key: string) {
    switch (key) {
      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE:
        return "Valorisation nette du patrimoine sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE:
        return "Nombre d'enfants à charge sur les 20 prochaines années";

      case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL:
        return "Nombre d'enfants au total sur les 20 prochaines années";

      default:
        throw new Error("Invalid label key");
    }
  }

  onModalClose(save: boolean, key?: string) {
    if (save) {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE:
          this.ancienValorisationNettePatrimoines = cloneDeep(this.valorisationNettePatrimoines);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE:
          this.nombreEnfant.ancienNombreEnfantCharges = cloneDeep(this.nombreEnfant.nombreEnfantCharges);
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL:
          this.nombreEnfant.ancienNombreEnfantCharges = cloneDeep(this.nombreEnfant.nombreEnfantCharges);
          break;
        default:
          break;
      }
    } else {
      switch (key) {
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_VALORISATION_NETTE_PATRIMOINE:
          if (!this.ancienValorisationNettePatrimoines) {
            this.hasAnneeFuturValorisationNettePatrimoine = false;
          }
          while (this.valorisationNettePatrimoines.length) {
            this.valorisationNettePatrimoines.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_CHARGE:
          if (!this.nombreEnfant.ancienNombreEnfantCharges) {
            this.nombreEnfant.hasAnneeFuturNombreEnfantCharge = false;
          }
          while (this.nombreEnfant.nombreEnfantCharges.length) {
            this.nombreEnfant.nombreEnfantCharges.pop();
          }
          break;
        case appConstants.INPUT_KEYS.HAS_ANNEE_FUTUR_NOMBRE_ENFANT_TOTAL:
          if (!this.nombreEnfant.ancienNombreEnfantTotals) {
            this.nombreEnfant.hasAnneeFuturNombreEnfantTotal = false;
          }
          while (this.nombreEnfant.nombreEnfantTotals.length) {
            this.nombreEnfant.nombreEnfantTotals.pop();
          }
          break;
        default:
          break;
      }
    }
  }

}

export { ImpotFortuneImmobilier }