import { Devise } from "./Devise";

class Amount {
  id: number;
  montant: number | null;
  devise: Devise;

  constructor(
    id: number = 0,
    montant: number | null,
    devise: Devise = new Devise(),
  ) {
    this.id = id;
    this.montant = montant;
    this.devise = devise;
  }
}

export { Amount }